import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthGuardMdl } from './../../_guards/auth.guard.mdl';  
import { NavbarTopData } from './navbar-top.data';
import { NgModule } from '@angular/core';
import { PageHeadingModule } from '../page-heading/page-heading.mdl';
import { NavbarTopComponent } from './navbar-top.cmp';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from '../‏‏auto-complete/auto-complete.mdl';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    imports: [
        PageHeadingModule,
        CommonModule,
        RouterModule, 
        FormsModule,
        ReactiveFormsModule,
        AutoCompleteModule,
         AuthGuardMdl,
         MaterialModule,
        FlexLayoutModule
    ],
    exports: [NavbarTopComponent],
    declarations: [NavbarTopComponent],
    providers: [NavbarTopData],
})
export class NavbarTopModule { }
