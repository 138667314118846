import { DonationCategoryMain } from './donationCategory/donationCategory-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { DonationCategoryEdit } from './donationCategory/donationCategory-edit.cmp';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { CurrencyPipeMdl } from './../components/pipes/currency-pipe';
import { CommitmentTitleModule } from './../commitment-title/commitment-title.module';
import { AmountAndCurrencyInputModule } from './../components/amount-and-currency-input/amount-and-currency-input.module';
import { MaterialModule } from './../material.module';
//import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { DonationCategoryShortDetails } from './donationCategory/donationCategory-short-details.cmp';
import { DonationCategoryDetailsDlg } from './donationCategory/donationCategory-details-dlg.cmp';
import { PhonePipeMdl } from '../components/pipes/phone-pipe';
import { DonationCategoriesRoutes } from './donationCategories.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { DonationCategoriesData } from './donationCategories.data';
import { DonationCategoriesList } from './donationCategories-list.cmp';
import { DonationCategoryDetails } from './donationCategory/donationCategory-details.cmp';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(DonationCategoriesRoutes),
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        DateInputModule,
        FooterEditButtonsMdl,
        AutoCompleteModule,
        MaterialModule,
        AmountAndCurrencyInputModule,
        CommitmentTitleModule,
        CurrencyPipeMdl,
        ModelInfoModule,
        AutoCompleteModule,
        PhonePipeMdl,

		MainFormModalModule
    ],
    declarations: [
        DonationCategoriesList,
        DonationCategoryDetailsDlg,
        DonationCategoryShortDetails,
        DonationCategoryDetails,
        DonationCategoryEdit,
        DonationCategoryMain
    ],
    exports: [
        DonationCategoriesList,
        DonationCategoryMain
    ],
    providers: [
        DonationCategoriesData
    ],
    entryComponents: [
        DonationCategoryDetailsDlg,
    ]
})
export class DonationCategoriesMdl { }



