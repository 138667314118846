import { TishArea } from './tishArea/tishArea';
import { Injectable, EventEmitter } from '@angular/core';
//import 'rxjs/add/operator/toPromise';
import { ToraxHttp } from '../utils/custom-http.svc';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable()

export class TishAreasData {
    private url = 'api/tishAreas';

    constructor(
        private toraxHttp: ToraxHttp,
        private formBuilder: FormBuilder
    ) { }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number){
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(TishArea: TishArea) {
        return this.toraxHttp.postWithResponce(this.url, TishArea);
    }

    getAdd(tishEventID: number) {
        return this.toraxHttp.get(`${this.url}/add/${tishEventID || 0}`);
    }


    public initForm() {
      return this.formBuilder.group({ createBy:[], createDate:[], changeBy:[], changeDate:[],
          tishAreaID: [0, Validators.required],
          tishAreaName: [],
          areaColor:[],
          tishEventID: [, Validators.required],
          tishEventTitle: [],
          description: [],
          comment: [],
      });

  }
     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }

}
