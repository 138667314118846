<div #modal class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div [ngStyle]="{'width': modalData?.Width}" style="margin: 0 auto;" class="modal-content">
            <div class="alert-info modal-header">
                <button type="button" class="close" (click)="closeModal()" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                  <h4 class="modal-title">{{ modalData?.Header }}</h4>
            </div>
            <div #modalBody class="modal-body">
                <div [innerHTML]="modalData?.Body" *ngIf="modalData?.Body"></div>
                <span #bodyCmp></span>
            </div>
            <div class="alert-info modal-footer">
                <button type="button" *ngFor="let a of modalData?.Actions" class="btn {{ a.cssClass }}"
                    (click)="onAction(a.Action, a.Validate)">{{ a.Text }}</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->