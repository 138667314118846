<div fxLayout="column" *ngIf="formData" [formGroup]="formData">
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <dateMonth-input  [placeholder]="'החל מ:'" [monthInputControl]="formData.controls.holdingFromMonth"
            [yearInputControl]="formData.controls.holdingFromYear"></dateMonth-input>
    </div>    
    
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
            <label>סכום:</label>
            <input class="form-control" apply-enter formControlName="holdingAmount" placeholder="סכום לתשלום:">
        </div>&nbsp;
        <div class="input-group mb-3">
            <label>מטבע:</label>
            <select apply-enter class="form-control narrow" formControlName="holdingCurrency" placeholder="מטבע">
                <option [value]="">...</option>
                <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                    {{c.name}}
                </option>
            </select>
        </div>&nbsp;
        <div class="input-group mb-3">
            <label>תוספת אדר אלול:</label>
            <input class="form-control" apply-enter formControlName="holdingSpecialtyMonthsAmount"
                placeholder="תוספת אדר אלול:">
        </div>
    </div>
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <auto-complete apply-enter [placeholder]="'שם להזכרה'" [controllerName]="'Contacts'"
            [formGroupModel]="formData" [idControl]="formData.controls.holdingContactID"
            [nameControl]="formData.controls.holdingContactName">
        </auto-complete>
        &nbsp;
        <div class="input-group mb-3">
            <label>יחס/קירבה:</label>
            <select apply-enter class="form-control narrow" formControlName="holdingContactRelationTypeID" placeholder="יחס/קירבה">
                <option [value]="">...</option>
                <option *ngFor="let c of servicesData.RelationTypes" [value]="c.id">
                    {{c.title}}
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="formData.controls.paymentsPlanID.value > 0" class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3" style="width: 100%;">
            <label>כולל רשומות ישנות?:</label>
            <input type="checkbox" formControlName="reOldHoldings">
            <small *ngIf="!formData.controls.reOldHoldings.valid" class="text-danger">*</small>
            <br>
            <input-info [alwaysShow]="true" [control]="formData.controls.reOldHoldings">
                כאשר מסומן יחושבו מחדש גם רשימות ישנות הקודמות לתאריך המצוין לעיל ושעדיין לא הודפסו
            </input-info>
        </div>
    </div>
</div>