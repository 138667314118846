import { ServicesData } from './../../services/services.data';
import { TishAreasData } from './../tishAreas.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TishArea } from './tishArea';
export class PaymentOption { }
export class Api { }

@Component({

    selector: 'tishArea-edit',
    templateUrl: './tishArea-edit.tmpl.html'
})

export class TishAreaEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private tishAreasData: TishAreasData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {
    }


    tishArea: TishArea;
    id: number;
    tishEventID: number;
    tishAreaForm: FormGroup;


    public load(): void {

        if (this.id > 0) {
            this.tishAreasData.getEdit(this.id)
                .subscribe((tg:any) => {
                    this.tishArea = tg;
                    this.fillForm(tg);
                });
        } else {
            this.tishAreasData.getAdd(this.tishEventID)
                .subscribe((tg:any) => {
                    this.tishArea = tg;
                    this.fillForm(tg);
                });
        }
    }

    private fillForm(tg: TishArea): void {
        this.tishAreaForm.patchValue(tg);
    }

    public save(): void {
        console.log('save run');
        if (this.tishAreaForm.valid) {
            this.tishAreasData.update(this.tishAreaForm.value)
                .subscribe((c: any) => {
                    this.location.back();
                    //this.router.navigate(['tishAreas', c.id], { relativeTo: this.activatedRoute });
                });
        } else {
            console.log('not valid');
            console.log(this.tishAreaForm.errors);
        }
    }

    public cancel(): void {
        this.location.back();
    }


    private tishEvents: Array<any>;

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['tishAreaID'];
            this.tishEventID = +params['tishEventID'];
        });
        //  this.servicesData.getList("TishEvents").subscribe(e => { this.tishEvents = e; }, error => { });
    this.tishAreaForm =  this.tishAreasData.initForm();
        this.load();

    }
}





