import { CampaignsData } from './../campaigns.data';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Campaign } from './campaign';

@Component({
	
	selector: 'campaign-details',
	templateUrl: './campaign-details.tmpl.html'
})

export class CampaignDetails implements OnInit {
	constructor(
		private campaignsData: CampaignsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.campaign && id === this.campaign.campaignID) {
				this.loadCampaign();
			}
		});
	}

	public campaign: Campaign;
	private id: number;
 

	public loadCampaign(): void {
		this.campaignsData.get(this.id)
			.subscribe((c:any) => this.campaign = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['campaignID'];
			this.loadCampaign();
		});
	}
}
