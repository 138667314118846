<form  *ngIf="data" class="example-form" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
      <h2>
          <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
              class="btn  btn-circle.btn-lg">
              <i class="fa fa-times"></i>
          </button>
          <small>הזזת מקומות</small>
          <br>
      </h2>
  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
      <div fxLayout="column" style="display: block;">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>ממקום:</label>
            <input autocomplete="off" class="form-control" apply-enter formControlName="fromPlace"
              placeholder="ממקום...">
          </div>

        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>עד מקום:</label>
            <input autocomplete="off" class="form-control" apply-enter formControlName="tillPlace"
              placeholder="עד מקום...">
          </div>

        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מקומות:</label>
            <input autocomplete="off" apply-enter  class="form-control" apply-enter formControlName="places" placeholder="מקומות...">
          </div>

        </div>

      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button apply-enter  [disabled]="formData.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="formData"><i
                class="fa fa-save"></i> שמור </button>
    </mat-dialog-actions>

</form>
