import { SelectionModel } from '@angular/cdk/collections';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';

import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';

import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ShtibelsData } from './shtibels.data';

import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';

@Component({
  selector: 'shtibels-list',
  templateUrl: 'shtibels-list.tmpl.html',
})

export class ShtibelsList implements OnInit, AfterViewInit {
  stopLoadingPoints: boolean = false;
  constructor(
    public shtibelsData: ShtibelsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private cdref: ChangeDetectorRef) {
  }
  options = {
    //timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true
  };
  displayedColumns: string[] = ['select', 'buttons', 'shtibelID', 'shtibelName', 'gabbai', 'representativeName', 'street', 'cityName', 'codeIchudForShtibel'];
  data: any[] = [];

  addNew() {
    this.router.navigate(['', { outlets: { popup: ['shtibels','add'] } }]);
  }

  openSelectedRow(shtibelID) {
    this.router.navigate(['', { outlets: { popup: ['shtibels', shtibelID]}}]);
  }

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('cityID') cityID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;
  @ViewChild('gabaiID') gabaiID: AutoCompleteComponent;
  @ViewChild('representativeID') representativeID: AutoCompleteComponent;
  @ViewChild('holdingsResponsibleID') holdingsResponsibleID: AutoCompleteComponent;

  @Output() selectedRowsChange = new EventEmitter<any>();
  selection = new SelectionModel<number>(true, []);

  //comments;
  searchShtibelsListForm: FormGroup;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.shtibelID === row.shtibelID);
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  pageChange(e) {
    this.searchShtibelsListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getShtibels();
  }

  public clerFilter() {
    this.initForm();
    this.getShtibels();
  }

  public getShtibels() {
    if (this.paginator) {
      this.searchShtibelsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchShtibelsListForm.value));
    return this.shtibelsData!.getList(this.searchShtibelsListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        //trick
        this.stopLoadingPoints = false;
      });
  }


  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;

  initForm() {
    this.searchShtibelsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      shtibelID: [],
      shtibelName: [],
      cityID: [],
      cityTitle: [],
      gabbaiID: [],
      gabbaiName: [],
      representativeID:[],
      representativeName:[],
      holdingsResponsibleID: [],
      holdingsResponsibleName: [],
      // responsibleID:[],
      // responsibleName:[],
      gabaiPriority: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchShtibelsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.shtibelID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchShtibelsListForm.patchValue(frm);
    }
    this.getShtibels();
    this.selection.isSelected = this.isChecked.bind(this);


  }


  printSticks() {
    let shtibelIDs = this.searchShtibelsListForm.controls['selectedRows'].value.map(x => x).join();
  }

  ngAfterViewInit() {
    let qur = this.searchShtibelsListForm.controls['query'].value;
    if (qur != null && qur != '') {
      this.getShtibels();
    }
    this.cdref.detectChanges();
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getShtibels();
  }
  public search() {
  }
  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchShtibelsListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }
  pageEvent: PageEvent;
  pageSizeOptions;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchShtibelsListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}
