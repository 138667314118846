import { saveAs } from 'file-saver';
import { Payment } from 'src/app/payments/payment/payment';
import { AlertService } from './../components/alert/alert.svc';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from '../utils/custom-http.svc';
import { IndexService } from '../services/index-service';
import { DateRangeClass } from '../components/dateInput-range/date-input-range.class';
import { PaymentsPlansData } from './paymentsPlans.data';
import { MatSelect } from '@angular/material/select';
import { SubscriptionLike } from 'rxjs';



@Component({
  selector: 'paymentsPlans-list',
  templateUrl: 'paymentsPlans-list.tmpl.html',
})

export class PaymentsPlansList implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public paymentsPlansData: PaymentsPlansData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/paymentsPlans') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
     this.subscriptions.forEach(s => s.unsubscribe());
  }
  groupingColumn = '';
  groupingOrderColumn = '';

  exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  public referrerCmpUrl = "";
  displayedColumns: string[] = ['buttons', 'paymentsPlanID', 'contactName', 'paymentsPlanTypeName', 'pulseAmount', 'qty', 'payMethodID', 'fromDate', 'tillDate', 'closedDate', 'commitmentID', 'commitmentSubject'];
  data: any[] = [];
  panelOpenState = false;
  resultsLength = 0;
  isRateLimitReached = false;
  defaultvalues;
  searchPaymentsPlansListForm: FormGroup;
  indexService: IndexService;

  @Input('contactID') public contactID?: number;
  @Input('commitmentID') public commitmentID?: number;

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.paymentsPlanID === row.paymentsPlanID);
    if (found) {
      return true;
    }
    return false;
  }

  //   public printReport(): void {
  //     this.paymentsPlansData.getListReport(this.searchPaymentsPlansListForm.value)
  //         .subscribe(
  //           data => {
  //             let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"');
  //             popupWinindow.document.open();
  //             popupWinindow.document.write('<html>' + (<any>data).value + '</html>');
  //             popupWinindow.document.close();
  //             }
  //         )
  // }
  openSelectedRow(paymentsPlanID) {
    this.router.navigate(['', { outlets: { popup: ['paymentsPlans', paymentsPlanID] } }]);
  }
  openDetails() {
  }


  //   public getPaymentsPlansPlusReport(): void {

  //     this.paymentsPlansData.exportExcel(this.searchPaymentsPlansListForm.value)
  //       .subscribe(blob => {
  //         let link = document.createElement('a');
  //         link.href = window.URL.createObjectURL(blob);
  //         link.download = `דוח תוכניות תשלום - פלוס.xlsx`;
  //         link.click();
  //       });
  //   }

  public getPaymentsPlansReport(): void {
    // this.paymentsPlansData.exportExcel(this.searchPaymentsPlansListForm.value)
    //   .subscribe(blob => {
    //     let link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = `דוח התחייבויות.xlsx`;
    //     link.click();
    //   });
  }

  // public getPaymentsPlans() {
  //   this.searchPaymentsPlansListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
  //   this.searchPaymentsPlansListForm.controls['pageRows'].setValue(this.paginator.pageSize);

  //   if (this.contactID && this.contactID > 0) {
  //     this.searchPaymentsPlansListForm.controls['contactID'].setValue(this.contactID);
  //   }
  //   if (this.commitmentID && this.commitmentID > 0) {
  //     this.searchPaymentsPlansListForm.controls['commitmentID'].setValue(this.commitmentID);
  //   }


  //   if (this.contactID == null) {
  //     sessionStorage.setItem(this.router.url, JSON.stringify(this.searchPaymentsPlansListForm.value));
  //   }

  //   sessionStorage.setItem(this.router.url, JSON.stringify(this.searchPaymentsPlansListForm.value));


  //   return this.paymentsPlansData!.getList(this.searchPaymentsPlansListForm.value)
  //     .subscribe((data: any) => {
  //       this.data = data;
  //       this.sourceList = data.list;
  //       this.rowsCount = data.rowsCount;
  //       if (data.list) {
  //         this.page = data.list.length;
  //         this.pageRows = data.list.length;
  //       }


  //       this.isRateLimitReached = false;

  //     });
  // }

  private initForm() {
    this.searchPaymentsPlansListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      paymentsPlanID: [],
      contactName: [],
      paymentsPlanTypes: [null],
      fromDateRange: [],
      tillDateRange: [],
      pulseAmountRange: [],
      commitmentID: [],
      withTillDate: [null],
      cityIDs: [null],
      shtibelID: [null],
      commitmentSubjects: [],
      shtibelName: [],
      withPaymentsForTreatment: [],
      activePaymentsPlans: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });


    this.selection.changed.subscribe(x => {
      this.searchPaymentsPlansListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.paymentID));
      this.selectedRowsChange.emit(x.source.selected);
    });
    let frm = this.searchPaymentsPlansListForm;


    this.defaultvalues = Object.keys(this.searchPaymentsPlansListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});

  }


  isLink(pmId) {
    return this.exludePayMethodsIDs.indexOf(pmId) < 0;
  }



  toogleState() {
    this.panelOpenState = !this.panelOpenState;
  }

  ngOnInit() {
    this.initForm();

    if (this.toraxHttp.previousUrl == "/dashboard/posMode") {
      let item = sessionStorage.getItem("dashboard/paymentsPlans/dbPaymentsPlans");
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem("dashboard/paymentsPlans/dbPaymentsPlans") || "");
        this.searchPaymentsPlansListForm.patchValue(frm);
      }
    } else {
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchPaymentsPlansListForm.patchValue(frm);
      }
    }

    this.searchPaymentsPlansListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      { key: "contactID", value: this.contactID },
      { key: "commitmentID", value: this.commitmentID },
    ];

    this.indexService = new IndexService(this.searchPaymentsPlansListForm, params, this.paymentsPlansData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

  }

}
