import { Subscription } from 'rxjs';
import { DonationCategoriesData } from './../donationCategories.data';
import { ToraxHttp } from '../../utils/custom-http.svc';
import {  FormBuilder } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from '../../components/alert/alert.svc';
import { ServicesData } from '../../services/services.data';

@Component({
    selector: 'donationCategory-details',
    templateUrl: './donationCategory-details.tmpl.html'
})

export class DonationCategoryDetails implements OnInit {
    id: number;
    donationCategory: any;
    signalRSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        public servicesData: ServicesData,
        private donationsCategoryData: DonationCategoriesData,
        private alert: AlertService,
        ) {
    }

    public loadDonationCategory(): void {
        this.donationsCategoryData.get(this.id)
            .subscribe((x: any) => {
                this.donationCategory = x;
            });

    }


     ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = + params['donationCategoryID'];
        });
        this.loadDonationCategory();
    }




}
