<div>
    <form [formGroup]="searchShtibelsListForm">
        <div class="example-container" style="background-color: white; border-bottom: 10px solid rgb(243, 243, 244)">
            <div style="z-index: 10; position: relative; left: 0;  top:0px;" class="example-button-row">

            </div>

            <div class="example-button-row" style="white-space: nowrap;" fxLayout="row"
                style="position: fixed; float: left; left: 5px;  z-index: 9999;">
                <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן"
                    (click)="selectionChanged($event)"><i class="fa fa-filter"></i></button>
                <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                        class="material-icons">clear</i></button>
                <button style="margin: 3px;" mat-mini-fab color="primary" title="הוסף" (click)="addNew()"
                    aria-label="הוסף חדש">
                    <i class="material-icons">add</i>
                </button>
            </div>
            <div class="column" style="padding-top: 12px; overflow: auto;">
                <div fxLayout="row" class="scrolling-wrapper">
                    <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" #cityID
                        [placeholder]="'עיר...'" (onSelect)="selectionChanged($event)" [controllerName]="'Cities'"
                        [formGroupModel]="searchShtibelsListForm" [idControl]="searchShtibelsListForm.controls.cityID"
                        [nameControl]="searchShtibelsListForm.controls.cityTitle">
                    </auto-complete>
                    &nbsp;&nbsp;
                    <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" #shtibelID
                        [placeholder]="'שטיבל...'" (onSelect)="selectionChanged($event)" [controllerName]="'Shtibels'"
                        [formGroupModel]="searchShtibelsListForm"
                        [idControl]="searchShtibelsListForm.controls.shtibelID"
                        [nameControl]="searchShtibelsListForm.controls.shtibelName">
                    </auto-complete>
                    &nbsp;&nbsp;
                    <auto-complete [smallTemplate]="true" [placeholder]="'גבאי...'"
                        (onSelect)="selectionChanged($event)" [controllerName]="'Contacts'"
                        [formGroupModel]="searchShtibelsListForm"
                        [idControl]="searchShtibelsListForm.controls.gabbaiID"
                        [nameControl]="searchShtibelsListForm.controls.gabbaiName">
                    </auto-complete>
                    &nbsp;&nbsp;
                    <auto-complete [smallTemplate]="true" [placeholder]="'אחראי החזקת ביהמד...'"
                        (onSelect)="selectionChanged($event)" [controllerName]="'Contacts'"
                        [formGroupModel]="searchShtibelsListForm"
                        [idControl]="searchShtibelsListForm.controls.holdingsResponsibleID"
                        [nameControl]="searchShtibelsListForm.controls.holdingsResponsibleName">
                    </auto-complete>
                    &nbsp;&nbsp;
                    <auto-complete [smallTemplate]="true" [placeholder]="'נציג ביהמד...'"
                        (onSelect)="selectionChanged($event)" [controllerName]="'Contacts'"
                        [formGroupModel]="searchShtibelsListForm"
                        [idControl]="searchShtibelsListForm.controls.representativeID"
                        [nameControl]="searchShtibelsListForm.controls.representativeName">
                    </auto-complete>
                </div>
            </div>
        </div>

        <div class="example-container">
            <div fxLayout="row">
                <span class="example-fill-remaining-space"></span>
                <button class="btn btn-outline btn-success  dim" type="button"
                    style="width: min-content; font-weight: 500; color:#ff4081"
                    *ngIf="selection && selection.selected.length >= 1" (click)="printSticks()">
                    הדפס מדבקות ל-{{selection.selected.length}} רשומות נבחרות
                </button>
            </div>
            <div class="bounds">

                <div class="content" style="height: 70%; overflow: auto" loading-status
                    [stopLoadingWhen]="stopLoadingPoints || sourceList">
                    <mat-table [dataSource]="sourceList" style="overflow-x: scroll;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">
                        <ng-container matColumnDef="select">
                            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                                <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                                    *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">

                                    <a mat-menu-item
                                    [routerLink]="['', {outlets: {popup: ['shtibels', row.shtibelID]}}]">
                                        <mat-icon>pageview</mat-icon>
                                        <span>פרטים מלאים</span>
                                    </a>

                                    <a mat-menu-item
                                    [routerLink]="['', {outlets: {popup: ['shtibels', row.shtibelID, 'edit']}}]">
                                        <mat-icon>edit</mat-icon>
                                        <span>עריכה</span>
                                    </a>

                                    <a mat-menu-item>
                                        <mat-icon>information</mat-icon>
                                           <model-info style="padding: 4px" [dataProvider]="shtibelsData" [id]="row.shtibelID" [title]="'מידע רשומה'">
                                        </model-info>

                                    </a>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="shtibelID">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="מזהה">
                                <a   [routerLink]="['', {outlets: {popup: ['shtibels', row.shtibelID]}}]">
                                    {{ row.shtibelID }}
                                </a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="shtibelName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> שם שטיבל </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="שם שטיבל"> {{row.shtibelName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="gabbai">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> גבאים </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="גבאים">
                                <a [routerLink]="['/contacts', row.gabbaiID1]">
                                    {{row.gabbaiName1}}
                                </a> <br>
                                <a [routerLink]="['/contacts',row.gabbaiID2]">
                                    {{ row.gabbaiName2 }}
                                </a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="representativeName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> נציג איחוד </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="נציג איחוד">
                                <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', row.representativeID]}}]">
                                    {{row.representativeName}}
                                </a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="street">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> כתובת</mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="כתובת"> {{row.Street}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="cityName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> עיר </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="עיר"> {{row.cityName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="codeIchudForShtibel">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> קוד שטיבל באיחוד
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="קוד שטיבל באיחוד">
                                {{row.codeIchudForShtibel}}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="openSelectedRow(row.shtibelID)">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </form>
</div>
