import { Component} from '@angular/core';

@Component({
    selector: 'tishEvent-main',
    templateUrl: './tishEvent-main.tmpl.html'
})

export class TishEventMain {
    editMode: boolean = null;

    public setVal(val:boolean) {
        this.editMode = val;
    }
}
