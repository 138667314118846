import { ToraxHttp } from './../../utils/custom-http.svc';

import { VouchersOrgPaymentPacksData } from './../vouchersOrgPaymentPacks.data'; 
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { VouchersOrgPaymentPack } from './vouchersOrgPaymentPack'; 
import { FormBuilder, FormGroup } from '@angular/forms'; 

@Component({
	
	selector: 'vouchersOrgPaymentPack-details',
	templateUrl: './vouchersOrgPaymentPack-details.tmpl.html'
})

export class VouchersOrgPaymentPackDetails implements OnInit {
	constructor(
		private vouchersOrgPaymentPacksData: VouchersOrgPaymentPacksData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private formBuilder: FormBuilder,
		private cdref: ChangeDetectorRef,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (this.vouchersOrgPaymentPack && id === this.vouchersOrgPaymentPack.vouchersOrgPaymentPackID) {
				this.loadvouchersOrgPaymentPack();
			}
		});
	}

	public vouchersOrgPaymentPack: VouchersOrgPaymentPack;
	private id: number;
	vouchersOrgPaymentPackForm: FormGroup;

         
	public loadvouchersOrgPaymentPack(): void {
		this.vouchersOrgPaymentPacksData.get(this.id)
			.subscribe((c:any) => this.vouchersOrgPaymentPack = c);
	}


	private initForm() {
		this.vouchersOrgPaymentPackForm = this.formBuilder.group({
			vouchersOrgPaymentPackID: [this.id || 0]
		});
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['vouchersOrgPaymentPackID'];
			this.loadvouchersOrgPaymentPack();
		});
		this.initForm();
	}

	activeTabIndex;
	public itemSelect(activeTabIndex: number) {
		this.activeTabIndex = activeTabIndex;
		this.cdref.detectChanges();
	}
}

