import { Component, Input, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'model-info-dlg',
    templateUrl: 'model-info-dlg.tmpl.html',
    styleUrls: ['model-info.cmp.scss']
})
export class ModelInfoDlg {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        console.log(this.data);
    }

    @Input() modelInfo: any = null;
    @Input() title: string = "";


}
