import { ServicesData } from '../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssistancesData } from '../assistances.data';
import { Assistance } from './assistance';

@Component({
    selector: 'assistance-edit',
    templateUrl: './assistance-edit.tmpl.html'
})

export class AssistanceEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private assistancesData: AssistancesData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,

    ) {
    }

    assistance: Assistance;
    id: number;
    contactId: number;
    assistanceForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.assistancesData.getEdit(this.id || 0)
                .subscribe((c:any)  => {
                    this.assistance = c;
                    this.assistanceForm.patchValue(c);
                });
        } else {
            this.assistancesData.getAdd(this.contactId)
                .subscribe((c:any)  => {
                    this.assistance = c;
                    this.assistanceForm.patchValue(c);
                });
        }
    }



    private initForm() {
        this.assistanceForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            assistanceID: [this.id || 0, Validators.required],
            assistanceDate: [new Date(), Validators.required],
            contactID: [this.contactId, Validators.required],
            contactName: [],
            amount: [],
            currency: [],
            chickNumber: [, Validators.required],
            chickDetails: [],
            assistanceTypeTitle: [],
            reason: [, Validators.required],
            comment: [],
            donationCategoryID: [],
            donationCategoryTitle:[],
            });
     }



    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['assistanceID'];
            this.contactId = +params['contactID'];
        });
        this.initForm();
        this.load();
    }
}



