 
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HonorTypesData } from './honor-types.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';


@Component({
	selector: 'honor-types-list',
	templateUrl: 'honor-types-list.tmpl.html'
})

export class HonorTypesList implements OnInit {
	constructor(
		private honorsData: HonorTypesData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,	 
		private router: Router,
		public _matDialog: MatDialog,
	) {
	}

	displayedColumns: string[] = ['buttons', 'honorTypeID', 'honorDepartmentName', 'honorTypeDescription', 'letterText', 'letterSettingTitle'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;
	commitmentID;
	@ViewChild(MatAccordion) accordion: MatAccordion;
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;
  

  
	searchHonorTypesListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
		this.getHonorTypes();
	}

	addNew() {		
		this.router.navigate(['', { outlets: { popup: ['honorTypes','add'] } }]);		
	}

	openSelectedRow(honorTypeID) {
	 	this.router.navigate(['', { outlets: { popup: ['honorTypes', honorTypeID]}}]);  
	}
  


	  getHonorTypes() {
		this.searchHonorTypesListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
 
		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchHonorTypesListForm.value));

		return this.honorsData!.getList(this.searchHonorTypesListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;
		

				this.isRateLimitReached = false;

			});
	}
 
	private initForm() {
		this.searchHonorTypesListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],  
			honorDepartmentID: [],
			honorDepartmentName: [],
			selectedRows:[],
			orders: this.formBuilder.array([]), 
		});
	}
 


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;

	ngOnInit() {
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
        if (item) {
            let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
            this.searchHonorTypesListForm.patchValue(frm);
        }
		this.getHonorTypes(); 
	}

	pageChange(e){
		this.searchHonorTypesListForm.controls['pageRows'].setValue(e.pageSize || 20);   
		this.selectionChanged(e);
	  }

	selectionChanged(e) {
		this.getHonorTypes();
	}


	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getHonorTypes();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchHonorTypesListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}

	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchHonorTypesListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}

	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}