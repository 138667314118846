import { ToraxHttp } from '../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { HonorsData } from '../honors.data';
import { Honor } from './honor';

@Component({

	selector: 'honor-details',
	templateUrl: './honor-details.tmpl.html'
})

export class HonorDetails implements OnInit {
	constructor(
		private honorsData: HonorsData,
		private toraxHttp: ToraxHttp,
		private activatedRoute: ActivatedRoute
	) {

		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.honor && id === this.honor.honorID) {
				this.loadHonor();
			}
		});
	}


	public honor: Honor;
	private id: number;


	public createAndPrintHonorLetter(): void {
		this.honorsData.createAndPrintHonorLetter(this.id)
			.subscribe(data => {
					let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
					popupWinindow.document.open();
					popupWinindow.document.write('<html><head><style type="text/css">.MsoNormal { margin-top: 0px; margin-bottom: 0px;} @page { size: letter; margin: 0; } @media print { } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
					popupWinindow.document.close();
			});
	};


	public loadHonor(): void {
		this.honorsData.get(this.id)
			.subscribe((c:any) => this.honor = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['honorID'];
			this.loadHonor();
		});
	}
}
