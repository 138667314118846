<form [formGroup]="honorForm" novalidate (ngSubmit)="save()" *ngIf="honor">
  <div class="modal-header">
      <h1>עריכת פרטי כיבוד [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
      <div *ngIf="honor" class="example-form">
          <div class="col-sm-12">
              <fieldset class="form-group" fxLayout="column">
                  <legend>פרטי כיבוד</legend>
                  <div class="form-row" fxLayout="row" fxLayout.xs="column">
                      <div style="width: 250px !important;" class="input-group mb-3">
                          <label>שם איש:</label>
                          <h3> {{ honor.contactName }}</h3>
                      </div>
                  </div>

                  <div class="form-row" fxLayout="row" fxLayout.xs="column">
                      <auto-complete apply-enter [controllerName]="'Contacts'" [formGroupModel]="honorForm"
                          [placeholder]="'איש מקבל...'" [idControl]="honorForm.controls.receivingContactID"
                          [nameControl]="honorForm.controls.receivingContactName"></auto-complete>
                      &nbsp;
                      <div class="input-group mb-3">
                          <label>יחס/קירבה:</label>
                          <mat-select class="form-control narrow" formControlName="receivingRelationType"
                              placeholder="יחס/קירבה...">
                              <mat-option *ngFor="let rt of servicesData.RelationTypes" [value]="rt.id">
                                  {{rt.title}}
                              </mat-option>
                          </mat-select>
                      </div>
                  </div>
                  <auto-complete apply-enter [placeholder]="'שבת/אירוע...'" [controllerName]="'HonorsSets'"
                      [formGroupModel]="honorForm" [idControl]="honorForm.controls.honorsSetID"
                      [nameControl]="honorForm.controls.honorsSetTitle"></auto-complete>

                  <auto-complete  [withSubTitle]="true" apply-enter [controllerName]="'HonorTypes'"
                      [placeholder]="'כיבוד...'" [formGroupModel]="honorForm"
                      [idControl]="honorForm.controls.honorTypeID" [nameControl]="honorForm.controls.honorTypeTitle">
                  </auto-complete>
                  <div class="form-row" fxLayout="row" fxLayout.xs="column">
                      <div class="input-group mb-3">
                          <label>הערה/סיבה:</label>
                          <input apply-enter class="form-control" formControlName="reasonComment">
                      </div>
                  </div>
                  <date-input [placeholder]="'תאריך'" [dateControl]="honorForm.controls.honorDate">Loading...
                  </date-input>

                  <div class="form-row" fxLayout="row" fxLayout.xs="column">
                      <div class="input-group mb-3">
                          <label>סכום התחייבות התרומה במי-שברך:</label>
                          <div style="white-space: nowrap; display: inline-flex;">
                              <input class="form-control" placeholder="סכום..."
                                  formControlName="contributionAmount" />
                              &nbsp;
                              <mat-select placeholder="מטבע..." class="form-control narrow"
                                  formControlName="currency">
                                  <mat-option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                                      {{ c.name }}
                                  </mat-option>
                              </mat-select>
                          </div>
                      </div>

                  </div>


                  <div class="form-row" fxLayout="row" fxLayout.xs="column"
                      style="white-space: nowrap; display: inline-flex;">
                      <div class="input-group mb-3">
                          <label>התחייבות:</label>
                          <mat-checkbox apply-enter fxLayout="row" formControlName="doOpenCommitment"
                              *ngIf="(!honorForm.controls.commitmentID) || (honorForm.controls.commitmentID.value <= 0); else cId">
                              לפתוח התחייבות?
                          </mat-checkbox>
                          <a #cId *ngIf="honorForm.controls.commitmentID.value"
                              [routerLink]="['/commitments',honorForm.controls.commitmentID.value]">{{honorForm.controls.commitmentID.value}}</a>
                      </div>
                      &nbsp;

                      <div class="input-group mb-3">
                          <label>מכתב:</label>
                          <mat-checkbox apply-enter fxLayout="row" formControlName="doOpenCommitment"
                              *ngIf="(!honorForm.controls.contactLetterID) || (honorForm.controls.contactLetterID.value <= 0); else lId">
                              ליצור מכתב?
                          </mat-checkbox>
                          <a #lId *ngIf="honorForm.controls.contactLetterID.value > 0"
                              [routerLink]="['/commitments',honorForm.controls.contactLetterID.value]">
                              {{honorForm.controls.contactLetterID.value}}</a>
                      </div>
                  </div>


              </fieldset>
          </div>
      </div>
  </div>
  <footer-edit-buttons [dataProvider]="honorsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
      [modelFormGroup]="honorForm"></footer-edit-buttons>

</form>
