import { AssistanceMain } from './assistance/assistance-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { AssistancesList } from './assistances-list.cmp';

export const AssistancesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'assistances', component: AssistancesList, data: {  roles: "assistances",  title: 'רשימת תמיכות' } },
    { canActivate: [AuthGuard], path: 'assistances/add', component: AssistanceMain, data: { mode: 'edit', roles: "assistances", title: 'הוסף תמיכה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'assistances/:assistanceID', component: AssistanceMain, data: {  roles: "assistances",  title: 'פרטי תמיכה' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'assistances/:assistanceID/edit', component: AssistanceMain, data: { mode: 'edit', roles: "assistances",  title: 'עריכת פרטי תמיכה' }, outlet: 'popup' }
];
