<div fxLayout="row" fxLayoutAlign="center center" *ngIf="contact">
    <div *ngIf="subjects" fxLayout="row" fxFlex>
        <div *ngFor="let subject of subjects" style="padding:6px;">
            <div style="margin: 0 auto; vertical-align: middle; text-align: center; color: #3f51b5"
                (click)="openSubjectCommitments(subject.subject, subject.subjectName)">
                <i style="font-size: 40px;margin: 0 auto;" class="rounded-circle fa fa-folder-open">
                    <span class="label label-primary"
                        style="font-size: 10px; margin-left: -10px;">{{subject.count}}</span></i>
                <p
                    style="font-size: 14px;font-family: 'Rubik'; font-weight: bold; width: min-content;margin: 0 auto; line-height:normal">
                    {{subject.subjectName}}</p>
            </div>
        </div>
    </div>

    <!-- <div fxFlex="1 1 45%">
                <div *ngFor="let cg of contact.contactGroups; let i=index" fxLayout="row" fxLayoutAlign="start center">
                    <i fxLayout="column" class="fa fa-circle text-navtext-navy" [ngStyle]="{'color': cg.group.groupColor}"></i> &nbsp;&nbsp;
                    <div style="padding-right:7px" fxLayout="column" fxFlex>
                        <div fxLayout="row">
                            <h3 style="font-weight: bold;">{{ cg.group.groupName }}</h3>
                        </div>
                        <div fxLayout="row"><small>{{ cg.comment }}</small></div>
                    </div>
                </div>
            </div> -->

    <div fxFlex="1 1 45%" style="direction: ltr; flex-wrap: nowrap">
        <div *ngFor="let p of contact.phones">
            <i *ngIf="p.type == 1" class="fa fa-phone"></i>
            <i *ngIf="p.type == 2" class="fa fa-mobile"></i>
            <i *ngIf="p.type == 3" class="fa fa-fax"></i>
            <i *ngIf="p.type == 4" class="fa fa-briefcase"></i>
            <i *ngIf="p.type == 5" class="fa fa-car"></i> &nbsp;
            <span style="direction: ltr; padding: 3px">
                {{ p.number | phone }}
            </span>
        </div>
        <div *ngFor="let e of contact.emails">
            <i class="fa fa-envelope"></i>&nbsp;<span style="direction: ltr;float: left;"><a
                    [href]="'mailto:' + e.address">{{ e.address }}</a></span>
        </div>
    </div>

</div>
