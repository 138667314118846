<form [formGroup]="searchDonationCategoriesListForm">
  <div class="ibox-content m-b-sm border-bottom">

    <div class="row" style="padding: 10px 15px;">
      <div class="example-container">
        <div fxLayout="row">
          <auto-complete apply-enter (onSelect)="selectionChanged($event)" [controllerName]="'DonationCategories'"
            [placeholder]="'קטגוריה...'" [formGroupModel]="searchDonationCategoriesListForm"
            [idControl]="searchDonationCategoriesListForm.controls.donationCategoryID"
            [nameControl]="searchDonationCategoriesListForm.controls.donationCategoryTitle">
          </auto-complete>

          &nbsp;&nbsp;

          <span fxFlex class="example-fill-remaining-space"></span>
          <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">


            <button mat-mini-fab color="primary" title="סנן" (click)="getDonationCategories()"> <i
                class="fa fa-filter"></i></button>
            &nbsp;&nbsp;
            <button mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                class="material-icons">clear</i></button>
                &nbsp;&nbsp;
                <button style="margin: 3px;" mat-mini-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף חדש">
                  <i class="material-icons">add</i>
                </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="example-container">
    <div class="bounds">
      <div class="content" style="height: 70%; overflow: auto">
        <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
          class="example-table" matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item (click)="openDetails(row);">
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>תצוגה מהירה</span>
                </a>
                <a mat-menu-item
                  [routerLink]="[ '', {outlets: {popup: [ 'donationCategories', row.donationCategoryID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים ופעולות</span>
                </a>

                <a mat-menu-item
                  [routerLink]="[ '', {outlets: {popup: [ 'donationCategories', row.donationCategoryID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>
                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="donationCategoriesData" [id]="row.donationCategoryID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="donationCategoryID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
              קטגוריה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה קטגוריה">
              {{ row.donationCategoryID }}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם קטגוריה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שם קטגוריה"> {{ row.title }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="keyNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>מס' לחצן </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מס' לחצן"> <b>{{ row.keyNumber }}</b>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="donationCategoryType">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סוג </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סוג"> {{
              row.donationCategoryTypeName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="isIncludeRecord">
            <mat-header-cell *matHeaderCellDef mat-sort-header> אפשרות הקלטה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="אפשרות הקלטה"> {{
              (row.isIncludeRecord == true? 'כן':'לא') }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="openDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך פתיחה</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך פתיחה"> {{ row.openDate |
              date:'dd/MM/yyyy' }}
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"
            (dblclick)="openSelectedRow(row.donationCategoryID)">
          </mat-row>
        </mat-table>
      </div>
    </div>
    <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false" [length]="rowsCount"
      [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
  </div>
</form>
