import { OrderChargeDetails } from './signalr-view-models/order-charge-details.cmp';
import { MaterialModule } from './../../material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SignalMainRService } from './signalr-main.service'; 
import { NgModule } from '@angular/core'; 
import { SignalrMainComponent } from './signalr-main.component';
import { MessageDetails } from './signalr-view-models/message-details.cmp';

@NgModule({
    imports: [
        CommonModule,        
        //BrowserModule,
     //   BrowserAnimationsModule,   
        MaterialModule
    ],  
    declarations: [
        SignalrMainComponent, 
        OrderChargeDetails,
        MessageDetails
    ],
    exports: [
        SignalrMainComponent,
    ],
    providers:[
        SignalMainRService
    ]
})
export class SignalrMainModule {

}
