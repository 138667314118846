import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateMonthInput } from '../../components/dateMonth-input/dateMonth-input.cmp';
import { ServicesData } from '../../services/services.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { PaymentsPlansData } from '../paymentsPlans.data';
import { HoldingsSetting } from './paymentsPlan';


@Component({
    templateUrl: './paymentsPlan-holdings-setting-dlg.tmpl.html'
})
export class PaymentsPlanHoldingsSettingDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PaymentsPlanHoldingsSettingDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private paymentsPlansData: PaymentsPlansData

    ) { }

    public formData: FormGroup;
    holdingsSetting: HoldingsSetting;


    ngOnInit(): void {
        this.initForm();
        this.loadHoldingsSetting();
    }

    loadHoldingsSetting() {
        if (this.data.holdingsSettingID > 0) {
            this.paymentsPlansData.getHoldingsSetting(this.data.holdingsSettingID)
                .subscribe((x: any) => {
                    this.holdingsSetting = x;
                    this.formData.patchValue(this.holdingsSetting);
                    this.formData.controls['paymentsPlanID'].setValue(this.data.paymentsPlanID); //חובה בנפרד
                });
        }
        else {
            this.data.holdingsSettingID = 0;
        }
    }


    private initForm(): void {
        this.formData = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            holdingsSettingID: [this.data.holdingsSettingID || 0, Validators.required],
            paymentsPlanID: [this.data.paymentsPlanID, Validators.required],
            contactID: [this.data.contactID, Validators.required],
            holdingFromMonth: [],
            holdingFromYear: [],
            holdingAmount: [],
            holdingSpecialtyMonthsAmount: [],
            holdingCurrency: [],
            holdingContactIsDied: [false, Validators.required],
            lastHoldingRecordOn: [],
            contactName: [],
            holdingContactID: [],
            holdingContactName: [],
            holdingContactRelationTypeID: [],
            holdingContactRelationTypeName: [],
            reOldHoldings: [false, Validators.required],
        });
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}

