<form [formGroup]="tishPlaceForm" novalidate (ngSubmit)="save()" *ngIf="tishPlace">
    <div class="modal-header">
        <h1>עריכת פרטי מקום [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="פרטי שולחן" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-sm-2">מספר מקום:</label>
                                <div class="col-sm-10">
                                    <h3> {{ tishPlace.placeNumber }}</h3>
                                    <input type="hidden" class="form-control" formControlName="placeNumber" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2">שולחן:</label>
                                <div class="col-sm-10">
                                    <h3> {{ tishPlace.tishTableName }}</h3>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="isActive">פעיל:</label>
                                <mat-slide-toggle formControlName="isActive">
                                </mat-slide-toggle>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="comment">שם מקום:</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" formControlName="placeName" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-sm-2" for="tishGroupID">מוזמן:</label>
                            <div class="col-sm-10">
                                <auto-complete apply-enter [controllerName]="'TishInviteds/' + tishEventID"
                                    [formGroupModel]="tishPlaceForm" [idControl]="tishPlaceForm.controls.tishInvitedID"
                                    [nameControl]="tishPlaceForm.controls.tishInvitedName"></auto-complete>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-sm-2" for="tishAreaID">איזור:</label>
                            <div class="col-sm-10">
                                <auto-complete apply-enter [controllerName]="'TishAreas/' + tishEventID"
                                    [formGroupModel]="tishPlaceForm" [idControl]="tishPlaceForm.controls.tishAreaID"
                                    [nameControl]="tishPlaceForm.controls.tishAreaName"></auto-complete>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-sm-2" for="tishGroupID">קבוצה:</label>
                            <div class="col-sm-10">
                                <auto-complete apply-enter [controllerName]="'TishGroups/' + tishEventID"
                                    [formGroupModel]="tishPlaceForm" [idControl]="tishPlaceForm.controls.tishGroupID"
                                    [nameControl]="tishPlaceForm.controls.tishGroupName"></auto-complete>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-sm-2" for="comment">הערה:</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" formControlName="comment" />
                            </div>
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="tishPlacesData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="tishPlaceForm"></footer-edit-buttons>

</form>
