import { PhoneEditDlg } from './phone/phone-edit-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from './../components/alert/alert.svc';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhonesData } from './phones.data';
import { Component, OnInit, Input } from '@angular/core';
import { Phone } from './phone/phone';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'phones-list',
	templateUrl: './phones-list.tmpl.html'
})
export class PhonesList implements OnInit {

	constructor(
        private phonesData: PhonesData,
      	private formBuilder: FormBuilder,
		private alertSvc: AlertService,
		public dialog: MatDialog,
		) {
	}


	phones: Phone[];
	searchPhonesListForm: FormGroup;
	tempNewEmail;
	tempNewPhone;

	viwHistory = false;
	@Input('contactID') public contactID: number;  

	phoneChangeEvent() {
		this.getPhones();
	}
	
	onEditClick(obj:Phone) {
		this.openAddOrEditPhone(obj);
	}
	
	public getPhones(): void {
		this.searchPhonesListForm.controls['contactID'].setValue(this.contactID);
		this.phonesData!.getList(this.searchPhonesListForm.value)
			.subscribe((x: Phone[]) => {
				this.phones = x;
			}
			); 
	}


	openAddOrEditPhone(phone): void {      
		setTimeout(()=> {
			const dialogRef = this.dialog.open(PhoneEditDlg, {
				data: { contactId: phone.contactID | this.contactID, id: phone.phoneID, phone: phone, communicationType: phone.communicationType }
			});
	
	
			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.savePhone(result);
				}
			});
		}, 200);
    }

    private savePhone(phoneForm: FormGroup) {
        this.phonesData.update(phoneForm.value)
            .subscribe(() => {
				this.getPhones();
				return true;
            })
    }



	loadAll() {		
		this.viwHistory = !this.viwHistory;
		this.searchPhonesListForm.controls['isActive'].setValue(this.viwHistory);
		this.getPhones();
	}


	private initForm() {
		this.searchPhonesListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [this.contactID, Validators.required],
			selectedRows:[],
			orders: this.formBuilder.array([]),
			isActive: [true, Validators.required],
		});

	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(this.phones, event.previousIndex, event.currentIndex);
			let p = event.item.data;
			if (p) {
				//יש להוסיף ערך 1 כדי לשמור ב-DB 
				let priorityToSave = (event.currentIndex + 1);
				let previousPhone = this.phones[event.previousIndex];
				
				this.phonesData.setPhonePriority(p.contactID, previousPhone.phoneID, p.phoneID, priorityToSave).subscribe(x => {
					this.alertSvc.info((<any>x).value);
					this.getPhones();
       
				});
			}
		}
	}

	
	ngOnInit(): void {
		this.tempNewPhone = new Phone();
		this.tempNewPhone.contactID  = this.contactID;
		this.tempNewPhone.communicationType  = 'phoneType';
		
		this.tempNewEmail = new Phone();
		this.tempNewEmail.contactID  = this.contactID;
		this.tempNewEmail.communicationType  = 'emailType';
	
		this.initForm();
		this.getPhones();

	}



}