import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { ObjectsListResult } from '../services/objects-list-results';
import { CloneTishEventModel, TishEvent } from './tishEvent/tishEvent';



@Injectable()

export class TishEventsData {


    public url = 'api/tishEvents';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    getFile(url: string): Observable<string> {
        return this.toraxHttp.getFile(url)
            .pipe(
                switchMap(response => this.readFile(response))
            );
    }

    private readFile(blob: Blob): Observable<string> {
        return Observable.create(obs => {
            const reader = new FileReader();

            reader.onerror = err => obs.error(err);
            reader.onabort = err => obs.error(err);
            reader.onload = () => obs.next(reader.result);
            reader.onloadend = () => obs.complete();

            return reader.readAsDataURL(blob);
        });
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }
    getDuplicates(id: number) {
      return this.toraxHttp.get(`${this.url}/duplicates/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getMap(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/map`);
    }

    getEventStatus(id: number) {
        return this.toraxHttp.get(`${this.url}/getEventStatus/${id || 0}`);
    }

    update(TishEvent: TishEvent) {
        return this.toraxHttp.postWithResponce(this.url, TishEvent);
    }

    cloneTishEvent(cte: CloneTishEventModel) {
        return this.toraxHttp.postWithResponce(`${this.url}/cloneTishEvent`, cte);
    }

    getAdd() {
        return this.toraxHttp.getWithResponce(`${this.url}/add`);
    }

    downloadExls(id) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls/${id}`, null);
    }

    downloadTicketsExls(id) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadTicketsExls/${id}`, null);
    }

    downloadTishEventTicketsForLettersExls(id) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadTishEventTicketsForLettersExls/${id}`, null);
    }

    downloadExlsForMerge(id) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExlsForMerge/${id}`, null);
    }

    downloadExlsPlacesSticks(id) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExlsPlacesSticks/${id}`, null);
    }

    downloadPdf(id) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadPdf/${id}`, null);
    }


     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }

}
