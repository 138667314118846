<form  [formGroup]="processForm" novalidate (ngSubmit)="save()" *ngIf="process">
    <div class="modal-header">
        <h1>עריכת פרטי תהליך [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="עריכת פרטי תהליך" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-sm-2">שם איש:</label>
                                <div class="col-sm-10">
                                    <h3> {{ process.contactName }}</h3>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="processTypeID">סוג:</label>
                                <div class="col-sm-10">
                                    <select class="form-control" formControlName="processTypeID">
                                           <option *ngFor="let at of servicesData.ProcessTypes"
                                                    [value]="at.id">
                                                {{at.title}}
                                            </option>
                                        </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="processTypeID">תיאור:</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" formControlName="description" /></div>
                            </div>
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="processesData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0" [onSaveGoToBack]="true" [modelFormGroup]="processForm"></footer-edit-buttons>
</form>