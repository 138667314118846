import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { LetterSettingsData } from './../letter-settings.data';
import { LetterSetting } from './letter-setting';

@Component({    
    selector: 'letter-setting-details',
    templateUrl: './letter-setting-details.tmpl.html'
})

export class LetterSettingDetails implements OnInit {
    constructor(
        private letterSettingsData: LetterSettingsData,
        private activatedRoute: ActivatedRoute,
        private toraxHttp: ToraxHttp,
    ) {
        this.toraxHttp.updatedEvent.subscribe((id) => {
            if (id > 0 && this.letterSetting && id === this.letterSetting.letterSettingID) {
                this.loadLetterSetting();
            }
        });
    }

    public letterSetting: LetterSetting;
    private id: number;

    public loadLetterSetting(): void {
        this.letterSettingsData.get(this.id)
            .subscribe((c:any) => this.letterSetting = c);
    }

    ngOnInit(): void {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = + params['letterSettingID'];
            this.loadLetterSetting();
        });
    }
}
