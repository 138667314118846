<form class="example-form" [formGroup]="contactEventForm" novalidate>
    <div *ngIf="contactEvent" matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} אירוע אישי</small>
            <br>
            {{ contactEvent.contactName }}
        </h2>

    </div>
    <mat-dialog-content style="padding:0px 24px 24px 24px">
              <div *ngIf="contactEvent" fxLayout="column" style="display: block;">
            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                    <label>סוג אירוע:</label>
                    <select class="form-control" apply-enter cdkFocusInitial  formControlName="contactEventType" placeholder="סוג...">
                        <option *ngFor="let ce of servicesData.ContactEventTypes" [value]="ce.value">
                            {{ce.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="input-group mb-3">
                <label>תיאור:</label>
                <input class="form-control" apply-enter formControlName="description" placeholder="תיאור..." />
            </div> 
             <div class="input-group mb-3">
                <date-input [placeholder]="'תאריך:'"  apply-enter [dateControl]="contactEventForm.controls.fromDateTime"></date-input>
            </div>
            
        </div>


    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button [disabled]="contactEventForm.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="contactEventForm"><i class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="contactEvent.contactEventID > 0"
            [mat-dialog-close]="contactEventForm" (click)="delete()"><i class="fa fa-warning"></i> מחק
        </button>
        <!-- <button mat-button mat-dialog-close [mat-dialog-close]="contactEventForm" class="btn btn-danger navbar-btn"><i
                class="fa fa-trash-o"></i> מחק לנצח
        </button> -->

    </mat-dialog-actions>