<div *ngIf="vouchersOrganization">
    <div class="modal-header">
        <div class="row">
            <div style="padding: 0px;" class="col-sm-12">
                <div class="col-sm-5">
                    <h1>
                        [{{ vouchersOrganization.vouchersOrganizationID }}] {{ vouchersOrganization.organizationName }}
                    </h1>
                    <hr>
                    <dl style="padding-right: 0;" class="col-sm-6" *ngIf="vouchersOrganization.contact">
                        כרטיס איש קשר:
                        <a
                            [routerLink]="[ '', {outlets: {popup: [ 'contacts', vouchersOrganization.contact.contactID]}}]">[{{vouchersOrganization.contact.contactID}}]
                            {{ vouchersOrganization.contact.firstName }} {{ vouchersOrganization.contact.lastName }}</a> 
                    </dl>
                </div>
                <div class="col-sm-7 text-left">
                    <button class="btn btn-outline btn-success  dim" type="button" style="font-weight: bold"
                        *ngFor="let p of groupedVouchers" (click)="openAddOrEditPayment(p)">
                        צור תשלום בסכום {{p.amount | number}} {{p.currencyIcon}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" style="resize: both; overflow: auto;" fxLayout="row" fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <mat-tab-group style="overflow: hidden;  width: -webkit-fill-available;" [selectedIndex]="activeTabIndex"
            (selectedTabChange)="itemSelect($event)">
            <mat-tab #pendings>
                <ng-template mat-tab-label>
                    שוברים ממתינים
                </ng-template>
                        <payments-list *ngIf="activeTabIndex == 0" style="overflow: auto;"
                            (selectedRowsChange)="selectedPaymentsRowsChange($event)"
                            [vouchersOrganizationID]="vouchersOrganization.vouchersOrganizationID"  
                            [statusPayments]="[1,3]">
                            Loading...</payments-list> 
            </mat-tab>
            <mat-tab #paids>
                <ng-template mat-tab-label>
                    שוברים ששולמו
                </ng-template>
                <payments-list *ngIf="activeTabIndex == 1"  style="overflow: auto;"
                    [vouchersOrganizationID]="vouchersOrganization.vouchersOrganizationID" [statusPayments]="[2]">Loading...
                </payments-list>
            </mat-tab>
            <mat-tab #accepteds>
                <ng-template mat-tab-label>
                    תשלומים שהתקבלו
                </ng-template>
                <payments-list *ngIf="activeTabIndex == 2 && vouchersOrganization.contactID > 0"  style="overflow: auto;"
                     [contactID]="vouchersOrganization.contactID" >Loading...</payments-list>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>