import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { Holding, HoldingsPrintDetailsMdl } from './holding/holding';
import { ObjectsListResult } from '../services/objects-list-results';
import { promise } from 'selenium-webdriver';

@Injectable()

export class HoldingsData {




    private url = 'api/holdings';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    exportExcel(searchHoldingsListForm: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, searchHoldingsListForm);
    }

    downloadHoldingsPivotReportExls(year: number, responsibleID?: number) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadHoldingsPivotReportExls/${year}/${responsibleID}`, {});
    }


    cancelHoldingsPrint(holdingsSeriePrintID: number) {
        return this.toraxHttp.getWithResponce(`${this.url}/cancelHoldingsPrint/${holdingsSeriePrintID}`);
    }

    printHoldingsHtml(holdingsPrintDetailsMdl: any) {
      return this.toraxHttp.postWithResponce(`${this.url}/printHoldingsHtml`, holdingsPrintDetailsMdl);
  }

  printExludedHoldingsHtml(holdingsPrintDetailsMdl: any) {
    return this.toraxHttp.postWithResponce(`${this.url}/printExludedHoldingsHtml`, holdingsPrintDetailsMdl);
}


    getCompleteList(searchHoldingsListForm: any){
        return this.toraxHttp.post(`${this.url}/completeHoldings`, searchHoldingsListForm);
    }

    getList(searchHoldingsListForm: any)  {
        return this.toraxHttp.post(`${this.url}/List`, searchHoldingsListForm);
    }

    getSmallList(searchHoldingsListForm: any) {
        return this.toraxHttp.post(`${this.url}/smallList`, searchHoldingsListForm);
    }

    getHoldingsPaymentToEdit(paymentCommitmentID: number) {
        return this.toraxHttp.get(`${this.url}/getHoldingsPaymentToEdit/${paymentCommitmentID}`);
      }

      saveHoldingsPayment(holdingsPaymentForm: any) {
        return this.toraxHttp.post(`${this.url}/saveHoldingsPayment`, holdingsPaymentForm);
    }


    getHoldingsPrintedGroup(searchPrintsListForm:any) {
        return this.toraxHttp.post(`${this.url}/getHoldingsPrintedGroup`, searchPrintsListForm);
    }

}

