import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { HonorTypesData } from './../honor-types.data';
import { HonorType } from './honor-type';

@Component({
	
	selector: 'honor-type-details',
	templateUrl: './honor-type-details.tmpl.html'
})

export class HonorTypeDetails implements OnInit {
	constructor(
		private honorTypesData: HonorTypesData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.honorType && id === this.honorType.honorTypeID) {
				this.loadHonorType();
			}
		});
	}


	public honorType: HonorType;
	private id: number;

	public loadHonorType(): void {
		this.honorTypesData.get(this.id)
			.subscribe((c:any) => this.honorType = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['honorTypeID'];
			this.loadHonorType();
		});
	}
}
