export class Holding {
  paymentCommitmentID: number;
  holdingID: number;
  contactID: number;
  currency: number;
  month: string;
  year: number;
  amount: number;
  holdingContactID: number;
  holdingContactName: string;
}

export class HoldingsPrintDetailsMdl {
}

