export class Group {
    groupID: number;
    groupName: string;
    groupColor: string;
}

export class GroupUser {
    groupID :number;
    gxUserName:string;
}
