<form [formGroup]="searchIvrDonationDetailsListForm">
  <div class="ibox-content m-b-sm border-bottom">


    <div *ngIf="!contactID && !commitmentID" class="row" style="padding: 10px 15px;">
      <div class="example-container" *ngIf="!contactID ||contactID == 0">
        <div fxLayout="row">

          <auto-complete apply-enter [controllerName]="'DonationCategories'" [placeholder]="'קטגוריה...'"
            [formGroupModel]="searchIvrDonationDetailsListForm"
            [idControl]="searchIvrDonationDetailsListForm.controls.donationCategoryID"
            [nameControl]="searchIvrDonationDetailsListForm.controls.donationCategoryTitle">
          </auto-complete>
          &nbsp;&nbsp;
          <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
            [formGroupModel]="searchIvrDonationDetailsListForm"
            [idControl]="searchIvrDonationDetailsListForm.controls.contactID"
            [nameControl]="searchIvrDonationDetailsListForm.controls.contactName"></auto-complete>
          &nbsp;&nbsp;
          <span fxFlex class="example-fill-remaining-space"></span>
          <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
            <button mat-mini-fab color="primary" title="סנן" (click)="getIvrDonationDetails()"> <i
                class="fa fa-filter"></i></button>
            &nbsp;
            <button mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                class="material-icons">clear</i></button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="example-container">
    <div class="bounds">
      <div class="content" style="height: 70%; overflow: auto">
        <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
          class="example-table" matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item
                  [routerLink]="[ '', {outlets: {popup: [ 'ivrDonationDetails', row.ivrDonationDetailID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item
                  [routerLink]="['', {outlets: {popup: ['ivrDonationDetails', row.ivrDonationDetailID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="ivrDonationDetailsData"
                    [id]="row.ivrDonationDetailID" [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="ivrDonationDetailID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה">
              <a [routerLink]="[ '', {outlets: {popup: [ 'ivrDonationDetails', row.ivrDonationDetailID]}}]">
                {{ row.ivrDonationDetailID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם איש </mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
              <a [routerLink]="[ '/contacts', row.contactID]">{{ row.firstName }} {{ row.lastName }}</a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="shtibelName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שטיבל </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שטיבל">
              {{row.shtibelName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="phoneNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון
              מתקשר
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="טלפון מתקשר" style="direction: ltr; padding-left: 12px;">
              {{row.phoneNumber | phone}}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef> קטגוריה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="קטגוריה">
              <a [routerLink]="[ '', {outlets: {popup: [ 'donationCategories', row.donationCategoryID]}}]">
                <b> {{ row.title }} </b>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef> תאריך ושעת תרומה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך ושעת תרומה">
              {{ row.createDate | date:'dd/MM/yyyy hh:mm:ss' }}
              <br>
              {{ row.jewishCreateDate }}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef> סכום</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סכום">
              {{ row.amount | number}}{{row.currency| currency:'usd':true:'1.0-0' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="qty">
            <mat-header-cell *matHeaderCellDef> תשלומים</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תשלומים">
              {{ row.qty }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="statusPayment">
            <mat-header-cell *matHeaderCellDef>סטטוס</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label=" 'amount',">
              <i class="fa fa-circle text-navtext-navy" style="font-size: 8px;"  [matTooltip]="row.paymentID"
                [ngStyle]="{'color': (row.statusPayment == 3 ? 'red' : ((row.statusPayment == 2) || (row.payMethodID == 89 && row.statusPayment == 1) ? 'green' : 'darkorange'))}"></i>&nbsp;{{ row.statusPaymentName}}
                &nbsp;
               <a [routerLink]="[ '', {outlets: {popup: [ 'payments', row.paymentID]}}]">{{ row.paymentID}}  </a>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="commitmentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> התחייבות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="התחייבות">
              <a *ngIf="row.commitmentID" [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentID]}}]">
                {{ row.commitmentID }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="methodTitle">
            <mat-header-cell *matHeaderCellDef> באמצעות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="באמצעות">
              <a *ngIf="row.payMethodID" [routerLink]="[ '', {outlets: {popup: [ 'payMethods', row.payMethodID]}}]">
                {{ row.methodTitle }}</a>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"
            (dblclick)="openSelectedRow(row.ivrDonationDetailID)">
          </mat-row>
        </mat-table>
      </div>
    </div>
    <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false" [length]="rowsCount"
      [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
  </div>
</form>
