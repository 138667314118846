import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { CommitmentReasonsData } from './../commitmentReasons.data';
import { CommitmentReason } from './commitmentReason';
import { ToraxHttp } from './../../utils/custom-http.svc';

@Component({	
	selector: 'commitmentReason-details',
	templateUrl: './commitmentReason-details.tmpl.html'
})

export class CommitmentReasonDetails implements OnInit {
	constructor(
		private commitmentReasonsData: CommitmentReasonsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private router: Router
      
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
            if (id > 0 && this.commitmentReason && id === this.commitmentReason.commitmentReasonID) {
                this.loadCommitmentReason();
            }
        });
	}

	public commitmentReason: CommitmentReason;
	private id: number;

	public loadCommitmentReason(): void {
		this.commitmentReasonsData.get(this.id)
			.subscribe((c:any) => this.commitmentReason = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['commitmentReasonID'];
			this.loadCommitmentReason();
		});
	}
}
