<form [formGroup]="searchHonorsSetsListForm">
    <div class="ibox-content m-b-sm border-bottom">
        <div class="row" style="padding: 10px 15px;">
            <!-- <div class="input-group mb-3">
                <label>תחום...</label>
                <mat-select apply-enter class="form-control" placeholder="תחום..."
                    [formControl]="searchHonorsSetsListForm.controls.honorDepartmentID">
                    <mat-option *ngFor="let ht of servicesData.HonorDepartments" [value]="ht.id">
                        {{ht.name}}
                    </mat-option>
                </mat-select>
            </div> -->
            &nbsp;&nbsp;
            <span fxFlex class="example-fill-remaining-space"></span>
            <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
                <button mat-mini-fab color="primary" title="סנן" (click)="getHonorsSets()"> <i
                        class="fa fa-filter"></i></button>
                &nbsp;
                <button mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                        class="material-icons">clear</i></button>
                &nbsp;
                <button  mat-mini-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף חדש">
                    <i class="material-icons">add</i></button>
            </div>
        </div>
    </div>
    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">
                    <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <a mat-menu-item
                                    [routerLink]="[ '', {outlets: {popup: [ 'honorsSets', row.honorsSetID]}}]">
                                    <mat-icon>pageview</mat-icon>
                                    <span>פרטים מלאים</span>
                                </a>

                                <a mat-menu-item
                                    [routerLink]="['', {outlets: {popup: ['honorsSets', row.honorsSetID, 'edit']}}]">
                                    <mat-icon>edit</mat-icon>
                                    <span>עריכה</span>
                                </a>

                                <a mat-menu-item>
                                    <mat-icon>information</mat-icon>
                                    <model-info style="padding: 4px" [dataProvider]="honorsSetsData" [id]="row.honorsSetID" [title]="'מידע רשומה'">
                                    </model-info>
                                </a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="honorsSetID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מזהה">
                            <a [routerLink]="[ '', {outlets: {popup: [ 'honorsSets', row.honorsSetID]}}]">
                                {{ row.honorsSetID }}
                            </a>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>תיאור</mat-header-cell>
                        <mat-cell  *matCellDef="let row" data-label="תיאור">
                            {{ row.description }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="honorsSetDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תאריך">
                            {{ row.honorsSetDate | date:'dd/MM/yyyy' }}
                        <br>
                          {{row.honorsSetJewishDate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="honorsSetParash">
                        <mat-header-cell *matHeaderCellDef> פרשה </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="פרשה">
                            {{row.honorsSetParash}}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                        (dblclick)="openSelectedRow(row.honorsSetID)">
                    </mat-row>
                </mat-table>
            </div>
        </div>
        <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
            [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
    </div>
</form>
