import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()

export class TermsData {


    private url = 'api/terms';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }


    get(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(Term: any) {
        return this.toraxHttp.update(this.url, Term);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

