import { ToraxHttp } from "../../utils/custom-http.svc";
import { Injectable } from "@angular/core";

@Injectable()
export class AutoCompleteInputDataService {
  private url = "api/Services";

  constructor(private toraxHttp: ToraxHttp) {}

  search(sourceList: string, stringQuery: string) {
    return this.toraxHttp.get(
      `${this.url}/get${sourceList}?stringQuery=${stringQuery}&rowsCount=${20}`
    );
  }
}
