<mat-card *ngIf="ivrDonationDetail" fxLayout="column" class="example-card">
  <mat-card-header fxLayout="row">
    <mat-card-title fxLayout="row">
      <h3>פרטי תרומה [{{ (id > 0 ? id : 'חדש') }}] </h3>
      <span class="example-fill-remaining-space"></span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="width: 100%;">
    <dl class="dl-horizontal" style="white-space: nowrap;">
      <dt>שם</dt>
      <dd>
        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', ivrDonationDetail.contactID]}}]">
          {{ ivrDonationDetail.firstName }} {{ ivrDonationDetail.lastName }}
        </a>
      </dd>
      <hr>
      <dt>שטיבל</dt>
      <dd>{{ ivrDonationDetail.shtibelName }}</dd>
      <hr>
      <dt>טלפון מתקשר</dt>
      <dd>{{ ivrDonationDetail.phoneNumber | phone }}
        <mat-icon matSuffix matTooltip="העתק"
        [cdkCopyToClipboard]="(ivrDonationDetail.phoneNumber)"
        style="color: gray; font-size: 75%; margin: 0 auto;">content_copy</mat-icon>
      </dd>
      <hr>
      <dt>סכום</dt>
      <dd style="width:fit-content;"><b>{{ ivrDonationDetail.amount | number }}</b><small>{{ ivrDonationDetail.currency | currency }}</small></dd>
      <hr>
      <dt>קטגוריה</dt>
      <dd><b>{{ ivrDonationDetail.title }}</b></dd>
      <hr>
      <!-- <dt *ngIf="ivrDonationDetail.targetAudioBytes">קטגוריה מוקלטת</dt>
      <dd *ngIf="ivrDonationDetail.targetAudioBytes"><audio #targetAudioBytes id="targetAudioBytes" controls></audio></dd>
      <hr *ngIf="ivrDonationDetail.targetAudioBytes"> -->
      <dt>התחייבות</dt>
      <dd>
        <a *ngIf="ivrDonationDetail.commitmentID"
          [routerLink]="[ '', {outlets: {popup: [ 'commitments', ivrDonationDetail.commitmentID]}}]">
          {{ ivrDonationDetail.commitmentID }}</a>
      </dd>
      <hr>
      <dt>אמצעי תשלום</dt>
      <dd>
        <a *ngIf="ivrDonationDetail.payMethodID"
          [routerLink]="[ '', {outlets: {popup: [ 'payMethods', ivrDonationDetail.payMethodID]}}]">
          {{ ivrDonationDetail.methodTitle }}
        </a>
        <mat-icon matSuffix matTooltip="העתק"
        [cdkCopyToClipboard]="(ivrDonationDetail.methodTitle)"
        style="color: gray; font-size: 75%; margin: 0 auto;">content_copy</mat-icon>
      </dd>
      <hr>
      <dt>כתובת</dt>
      <dd >{{ ivrDonationDetail.addressTitle }}
        <mat-icon matSuffix matTooltip="העתק"
        [cdkCopyToClipboard]="(ivrDonationDetail.addressTitle)"
        style="color: gray; font-size: 75%; margin: 0 auto;">content_copy</mat-icon>
      </dd>
      <hr>
      <dt>הקלטה מצורפת מס' 1</dt>
      <dd *ngIf="ivrDonationDetail.targetAudioBytes"><audio #targetAudioBytes id="targetAudioBytes" controls></audio></dd>
      <dd *ngIf="!ivrDonationDetail.targetAudioBytes">אין הקלטה!</dd>
      <hr>

      <dt>הקלטה מצורפת מס' 2</dt>
      <dd><audio *ngIf="ivrDonationDetail.recordFileBytes" #recordFileBytes id="recordFileBytes" controls></audio></dd>
      <dd *ngIf="!ivrDonationDetail.recordFileBytes">אין הקלטה!</dd>
      <hr>
    </dl>
  </mat-card-content>
</mat-card>
