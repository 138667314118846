import { ToraxHttp } from '../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { PayMethodsData } from '../payMethods.data';
import { PayMethod } from './payMethod';
import { AlertService } from '../../components/alert/alert.svc';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'payMethod-details',
	templateUrl: './payMethod-details.tmpl.html'
})

export class PayMethodDetails implements OnInit {
	constructor(
		private payMethodsData: PayMethodsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private alertSvc: AlertService,
		private formBuilder: FormBuilder,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (this.payMethod && id === this.payMethod.payMethodID) {
				this.loadPayMethod();
			}
		});
	}

	public payMethod: PayMethod;
	private id: number;
	private contactId: number;
	payMethodForm: FormGroup;


	public loadPayMethod(): void {
		this.payMethodsData.get(this.id)
			.subscribe((c:any) => this.payMethod = c);
	}





	testCcAndCreateToken() {
		this.payMethodsData.convertToToken(this.payMethodForm.value)
			.subscribe((c: any) => {
				let debitApproveNumber = +c.debitApproveNumber;
				if (c) {
					this.alertSvc.success("כרטיס תקין!");
				}
			},
			error => {
				this.alertSvc.error("כרטיס לא תקין");
			});
	}

	private initForm() {
		this.payMethodForm = this.formBuilder.group({
			payMethodID: [this.id || 0]
		});
	}
	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['payMethodID'];
			this.contactId = +params['contactID'];
			this.loadPayMethod();
		});
		this.initForm();
	}
}

