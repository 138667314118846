import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core'; 
import { ObjectsListResult } from '../services/objects-list-results';
import { ProcessesTypesData } from './processes-types.data';


@Component({
	
	selector: 'processes-types-list',
	templateUrl: './processes-types-list.tmpl.html'
})

export class ProcessesTypesList implements OnInit {
	constructor(
		private processTypesData: ProcessesTypesData,
		private formBuilder: FormBuilder
	) {
	}
	public objectsListResult: ObjectsListResult; 
	searchProcessTypesListForm: FormGroup;

	public query: string | null = null;
	public freeTextQuery: string;

	public getProcessTypes(): void {
		this.processTypesData.getList(this.searchProcessTypesListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			error => { });
	}

	public changePage(page: number) {
		this.searchProcessTypesListForm.controls['page'].setValue(page);
		this.getProcessTypes();
	}

	public search() {
		this.getProcessTypes();
	}

	private initForm() {
		this.searchProcessTypesListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			selectedRows:[],
			orders: this.formBuilder.array([])
		});
	}
	ngOnInit(): void {
		this.initForm();
		this.getProcessTypes();
	}



	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchProcessTypesListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
		this.getProcessTypes();
	}

	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}

	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchProcessTypesListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>(lst.controls[i])).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}

	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}

}
