<div class="modal-header" *ngIf="registrationEvent">
    <div class="row">
        <div style="padding: 0px;" class="col-sm-12">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6 text-left">
            </div>
        </div>
    </div>
    <div class="modal-body" fxLayout.xs="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <registrationEvent-short-details [registrationEvent]="registrationEvent"></registrationEvent-short-details>
    </div>
    <div fxLayout="row">
        <button [disabled]="btnDisabled" style="text-align: center; width: 100%;" (click)="runCharge()">סליקת
            אשראי</button>
    </div>
</div>