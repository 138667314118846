<div loading-status [stopLoadingWhen]="contact != null">
  <div *ngIf="contact">
    <div class="modal-header" fxLayoutAlign="start start" fxLayout="row" fxLayout.xs="column">
      <span style="position: absolute; top:0; left:12px; background-color: white;">[{{ contact.contactID }}]</span>
      <div fxFlex="65" style="white-space: nowrap;" fxLayout="row"
        [ngStyle]="{'color':(contact.affiliationName == 'אינו אנש' ? 'red' : '')}">
        <h1 #hebTitle style="width: fit-content;">
          <mat-icon matFerfix matTooltip="העתק קישור" [cdkCopyToClipboard]="url+location.path()"
            style="color: blue; font-size: 75%; margin-top: 40px; position: absolute; ">link</mat-icon>
          <mat-icon matSuffix matTooltip="העתק שם וקוד"
            [cdkCopyToClipboard]="(contact.contactTitle + ' - ' + contact.codeIchud)"
            style="color: gray; font-size: 75%; margin: 0 auto;">content_copy</mat-icon>
          <!--   -->
          {{ contact.contactTitle }}
        </h1>
        <span
          style="padding: 4px;margin:20px; padding: 4px 6px; border: solid; border-width: 1px; font-weight: bold; font-size: 125%;width: fit-content; background: WHITE;">
          קוד: {{ contact.codeIchud }}
        </span>
        <h3 *ngIf="contact.localFirstName || contact.localLastName" [style.max-width.px]="(hebTitle.offsetWidth + 20)"
          style="direction: ltr;padding-top:10px; border-top: 0.5px solid;">
          {{contact.localPerfix}} {{contact.localFirstName}}
          {{contact.localLastName}}{{contact.localSuffix}}
        </h3>

      </div>
      <div fxFlex style="white-space: break-spaces;margin: 15px auto;">
        <span class="example-fill-remaining-space"></span>
        <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="end start">

          <div class="btn-group">
            <button *authGuardDirective="'commitments,payments'" data-toggle="dropdown"
              class="btn btn-primary dropdown-toggle">קליטת תרומות והתחייבוית&nbsp;&nbsp;
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li *authGuardDirective="'payments'">
                <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'payments', 'add']}}]">תרומה (תשלום)</a>
              </li>
              <li *authGuardDirective="'commitments'">
                <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'commitments', 'add']}}]">התחייבות חדשה</a>
              </li>
              <li *authGuardDirective="'paymentsPlans'">
                <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'paymentsPlans', 'add']}}]">תוכנית
                  תשלום</a>
              </li>
            </ul>
          </div>
          <a *authGuardDirective="'payments,receipts'" class="btn btn-info" (click)="openReciptsForContactDlg()">
            <i class="fa fa-plus"></i>&nbsp;צור והדפס קבלות מרוכזת</a>
          <contact-report-action [contact]="contact"></contact-report-action>

          <div class="btn-group">
            <button *authGuardDirective="'processes,honors,registrations_add'" data-toggle="dropdown"
              class="btn btn-primary dropdown-toggle">שונות&nbsp;&nbsp;
              <span class="caret"></span>
            </button>
            <ul *authGuardDirective="'processes,honors,registrations_add'" class="dropdown-menu">

              <li *authGuardDirective="'payMethods'">
                <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'payMethods', 'add', 1]}}]">קליטת אשראי חדש
                </a>
              </li>
              <li *authGuardDirective="'assistances'">
                <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'assistances', 'add']}}]">קליטת תמיכה</a>
              </li>

            </ul>
          </div>

          <div class="btn-group">
            <button *authGuardDirective="'payments,contacts,commitments,receipts'" data-toggle="dropdown"
              class="btn btn-primary dropdown-toggle">הדפסות&nbsp;&nbsp;
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li *authGuardDirective="'receipts'">
                <a (click)="printNewReceiptsForContact()">הדפס קבלות שלא הודפסו</a>
              </li>
              <li *authGuardDirective="'receipts'">
                <a (click)="createAndrintNewReceiptsForContact()">צור והדפס קבלות שלא הודפסו</a>
              </li>

              <li *authGuardDirective="'contacts'">
                <a (click)="printEnvelopeorContact()">הדפס מעטפה</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row modal-body" *ngIf="!rerender" style="height: auto;">

      <mat-tab-group style="height: calc(100vh - 125px);" [selectedIndex]="activeTabIndex"
        (selectedTabChange)="itemSelect($event)">
        <mat-tab #tab_0 label="0" *authGuardDirective="'Contacts'">
          <ng-template mat-tab-label>
            מבט
          </ng-template>
          <div *ngIf="activeTabIndex == tab_0.textLabel" fxLayout="row" fxLayout.xs="column"
            style="background-color: white; padding: 35px 10px;">
            <div fxFlex.sm="40" fxLayout="column" fxFlex="100">
              <mat-card fxLayout="column" class="example-card">
                <mat-card-header fxLayout="row">
                  <mat-card-title fxLayout="row">
                    <h3>פרטי איש: [{{ id }}]</h3>
                    <span class="example-fill-remaining-space"></span>
                    <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'edit']}}]">
                      <i class="fa fa-pencil"></i>
                    </a>
                    <model-info *ngIf="modelInfo" [modelInfo]="modelInfo"></model-info>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content style="width: 100%;">
                  <contact-short-details [contact]="contact"></contact-short-details>
                </mat-card-content>
              </mat-card>
            </div>
            &nbsp;&nbsp;
            <div fxFlex.sm="30" fxLayout="column" fxFlex="100">
              <addresses-list [contactID]="id"></addresses-list>
            </div>
            &nbsp;&nbsp;
            <div fxFlex.sm="30" fxLayout="column" fxFlex="100">
              <phones-list [contactID]="id"></phones-list>
            </div>
            <!-- &nbsp;&nbsp;
            <div fxFlex.sm="25" fxLayout="column" fxFlex="100">
              <contactGroups-list *authGuardDirective="'contactGroups'" [contactID]="id">
              </contactGroups-list>
              &nbsp;&nbsp;
              <contactAppointments-list *authGuardDirective="'appointments'" [contactID]="id">
              </contactAppointments-list>
            </div>
            &nbsp;&nbsp;
            <div fxFlex.sm="25" fxLayout="column" fxFlex="100">
              <contactEvents-list *authGuardDirective="'contactEvents'" [contactID]="id">
              </contactEvents-list>
              &nbsp;&nbsp;
              <contactCompensations-list *authGuardDirective="'compensations'" [contactID]="id">
              </contactCompensations-list>
            </div> -->
          </div>
        </mat-tab>
        <mat-tab #tab_1 label="1" *authGuardDirective="'commitments'">
          <ng-template mat-tab-label>
            התחייבויות
          </ng-template>
          <commitments-list (selectedRowsChange)="selectedCommitmentsRowsChange($event)"
            *ngIf="activeTabIndex == tab_1.textLabel" [contactID]="contact.contactID">Loading...</commitments-list>
        </mat-tab>
        <mat-tab #tab_2 label="2" *authGuardDirective="'paymentsPlans'">
          <ng-template mat-tab-label>
            תוכניות תשלום
          </ng-template>
          <paymentsPlans-list *ngIf="activeTabIndex == tab_2.textLabel" [contactID]="contact.contactID">Loading...
          </paymentsPlans-list>
        </mat-tab>
        <mat-tab #tab_3 label="3" *authGuardDirective="'payments'">
          <ng-template mat-tab-label>
            תשלומים
          </ng-template>
          <payments-list *ngIf="activeTabIndex == tab_3.textLabel" [contactID]="contact.contactID">Loading...
          </payments-list>
        </mat-tab>
        <mat-tab #tab_4 label="4" *authGuardDirective="'ivrDonationDetails'">
          <ng-template mat-tab-label>
            תרומות טלפוניות
          </ng-template>
          <ivrDonationDetails-list *ngIf="activeTabIndex == tab_4.textLabel" [contactID]="contact.contactID">Loading...
          </ivrDonationDetails-list>
        </mat-tab>

        <mat-tab #tab_5 label="5" *authGuardDirective="'payMethods'" [textLabel] [aria-label]="'payMethods'">
          <ng-template mat-tab-label>
            אמצעי תשלום
          </ng-template>
          <payMethods-list *ngIf="activeTabIndex == tab_5.textLabel" [contactID]="contact.contactID">Loading...
          </payMethods-list>
        </mat-tab>

        <mat-tab #tab_6 label="6" *authGuardDirective="'receipts'">
          <ng-template mat-tab-label>
            קבלות
          </ng-template>
          <receipts-list *ngIf="activeTabIndex == tab_6.textLabel"
            [contactID]="contact.contactID">Loading...</receipts-list>
        </mat-tab>

        <mat-tab #tab_7 label="7" *authGuardDirective="'assistances'">
          <ng-template mat-tab-label>
            תמיכות
          </ng-template>
          <assistances-list *ngIf="activeTabIndex == tab_7.textLabel"
            [contactID]="contact.contactID">Loading...</assistances-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
