export class DateRangeClass {

  // public get displayValue(): string {
  //     return `[${this.id}] ${this.title}`;
  // }

  constructor(
      public start?: Date,
      public end?: Date,
      public includeNulls:boolean = false
    ) {

  }
}
