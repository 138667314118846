import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicesData } from './../services/services.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { HoldingsData } from './holdings.data';
import { HoldingsPrintDetailsMdl } from './holding/holding';


@Component({
  templateUrl: './holdings-print-dlg.tmpl.html'
})
export class HoldingsPrintHtmlDlg implements OnInit, AfterViewInit {
  public formData: FormGroup;

  shtibelMode: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private holdingsData: HoldingsData,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
  ) { }
  ngAfterViewInit(): void {
    if (this.data && this.data.shtibelID && this.data.shtibelID) {
      this.formData.controls['shtibelID'].setValue(this.data.shtibelID);
      this.formData.controls['shtibelName'].setValue(this.data.shtibelName);
      this.shtibelMode = true;
    }

    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.initForm();
  }


  private initForm(): void {
    this.formData = this.formBuilder.group({
      shtibelID: [], //, Validators.required
      shtibelName: [], //, Validators.required
      responsibleID: [],
      responsibleName: [],
      dateMonthsRange: [null, Validators.required],
      isPrint: [false, Validators.required],
      markPrinted: [, Validators.required],
      includeOldRecords: [false, Validators.required],
      includeRelatedHoldings: [false, Validators.required],

    });
  }

  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }



  printHoldingsHtml(holdingsPrintHtmlDlg: any) {
    if (holdingsPrintHtmlDlg) {
      this.holdingsData.printHoldingsHtml(<HoldingsPrintDetailsMdl>holdingsPrintHtmlDlg)
        .subscribe(data => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write((<any>data).value);
                popupWinindow.document.close();
          return true;
        });
    }
  }

  printExludedHoldingsHtml(holdingsPrintHtmlDlg: any) {
    if (holdingsPrintHtmlDlg) {
      this.holdingsData.printExludedHoldingsHtml(<HoldingsPrintDetailsMdl>holdingsPrintHtmlDlg)
        .subscribe(data => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write((<any>data).value);
          popupWinindow.document.write('<style type="text/css"> .containerbackground { position: absolute; top: 20%; left: 50%; bottom: 0; z-index: -1; transform: rotate(310deg); color: #c6afaf; font-size: 200%;"></style><div class="containerbackground"> לא נכללו ברשימות</div>');
          popupWinindow.document.close();
          return true;
        });
    }
  }

}
