<form [formGroup]="searchPrintsListForm">

    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">
                    <ng-container matColumnDef="buttons">
                        <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                            ...
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <a (click)="canceloldingsPrint(row.holdingsSeriePrintID)">
                                <i class="fa fa-undo"></i>&nbsp;לחץ לביטול הפעולה</a>
                        </mat-cell>
                    </ng-container>


                    <!-- <ng-container matColumnDef="responsibleContactFirstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם פרטי אחראי
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="שם פרטי אחראי">
                            {{row.responsibleContactFirstName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="responsibleContactLastName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם משפחה אחראי
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="שם משפחה אחראי">
                            {{row.responsibleContactLastName}} </mat-cell>
                    </ng-container> -->
                    <ng-container matColumnDef="shtibelName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>שטיבל
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="שטיבל">
                            {{row.shtibelName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="printDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך הדפסה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תאריך הדפסה">
                            {{row.printDate | date:'dd/MM/yyyy'}}
                            <br> {{row.jewishPrintDate}}

                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="period">
                        <mat-header-cell *matHeaderCellDef> תקופה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תקופה">
                            {{row.startJewishMonth}} {{row.startJewishYear}} - {{row.endJewishMonth}}
                            {{row.endJewishYear}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="includeOldRecords">
                        <mat-header-cell *matHeaderCellDef> כולל ישן
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="כולל ישן">
                            <i *ngIf="row.includeOldRecords" class="fa fa-check"></i>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="holdingsSeriePrintID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> מס' סדרה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מס' סדרה">
                            {{row.holdingsSeriePrintID}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="count">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> מס' רשומות
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מס' רשומות">
                            <span [ngStyle]="{'color': (row.count > 0 ? 'inherit' : 'red')}"> {{row.count}}<small
                                    *ngIf="row.count == 0"><br>ללא רשומות</small></span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>
            </div>
        </div>
        <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
            [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
    </div>

</form>