<div *ngIf="tishPlace">
    <div class="col-sm-6">
        <div>
            <panel-box title="פרטי מקום  [{{ id }}]" [showClose]="false" [showCollapse]="false">
                <span class="panel-tools">
                    <a [routerLink]="['/tishPlaces', {outlets: {popup: [ id, 'edit']}}]">
                        <i class="fa fa-pencil"></i>
                    </a>
                </span>
                <div class="panel-content">
                    <div>
                        <dl class="dl-horizontal">
                            <dt>שם מוזמן</dt>
                            <dd>{{ tishPlace.tishInvitedName }}</dd>
                            <hr>
                            <dt>כותרת</dt>
                            <dd>{{ tishPlace.tishInvitedTitle }}</dd>
                            <hr>
                            <dt>איזור</dt>
                            <dd> {{ tishPlace.tishAreaName }} </dd>
                            <hr>
                            <dt>קבוצה</dt>
                            <dd> {{ tishPlace.tishGroupName }} </dd>
                            <hr>
                            <dt>שולחן</dt>
                            <dd> {{ tishPlace.tishTableName }}</dd>
                            <hr>
                            <dt>אירוע</dt>
                            <dd> {{ tishPlace.tishEventTitle }}</dd>
                            <hr>
                        </dl>
                    </div>
                </div>
            </panel-box>
        </div>
    </div>
</div>