import { ProcessesData } from './../processes.data';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { Process } from './process';

@Component({
	
	selector: 'process-details',
	templateUrl: './process-details.tmpl.html'
})

export class ProcessDetails implements OnInit {
	constructor(
		private processesData: ProcessesData,
		private activatedRoute: ActivatedRoute
	) {
	}

	public process: Process;
	private id: number;

	public loadProcess(): void {
		this.processesData.get(this.id)
			.subscribe((c:any) => this.process = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['processID'];
			this.loadProcess();
		});
	}
}
