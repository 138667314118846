import { AlertService } from './../../services/alert.service';
import { TasksData } from './../tasks.data'; 
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';  
import { Task } from './task';

@Component({
	selector: 'task-details',
	templateUrl: './task-details.tmpl.html'
})

export class TaskDetails implements OnInit {
	constructor(
		private TasksData: TasksData,
		private activatedRoute: ActivatedRoute,
		private alertSvc: AlertService,
		private formBuilder: FormBuilder
	) {
	}


	task: Task;
	id: number;
	taskForm: FormGroup;

	public loadTask(): void {
		this.TasksData.get(this.id)
			.subscribe((p:any) => {
				this.task = p;
				this.fillForm(p);
			});
	}

	private fillForm(p: Task): void {
		this.taskForm.patchValue(p);
	}

	private initForm() {
		this.taskForm = this.formBuilder.group({
			taskID: [this.id],
			canMakeClearing: [],
		});
	}


	ngOnInit() {
		this.id = + this.activatedRoute.params['taskID'];
		this.loadTask();
		this.initForm();
	}
}
