<form [formGroup]="searchIvrDonationDetailsListForm">
    <div class="ibox-content m-b-sm border-bottom">
        <div class="row" style="padding: 10px 15px;">
            <div class="example-container">
                <div fxLayout="row">
                    <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
                        [formGroupModel]="searchIvrDonationDetailsListForm"
                        [idControl]="searchIvrDonationDetailsListForm.controls.contactID"
                        [nameControl]="searchIvrDonationDetailsListForm.controls.contactName"></auto-complete>

                <span fxFlex class="example-fill-remaining-space"></span>
                    <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
                        <button mat-mini-fab color="primary" title="סנן" (click)="getGroupIvrDonationDetails()">
                            <i class="fa fa-filter"></i></button>
                        &nbsp;&nbsp;
                        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()">
                            <i class="material-icons">clear</i>
                        </button>
                        &nbsp;&nbsp;
                        <button mat-mini-fab color="primary" title="ייצוא לאקסל"
                            (click)="downloadGroupIvrDonationDetailsExls()">
                            <i class="fa fa-file-excel-o"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">
                    <!-- <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div fxFlex="row" fxLayoutAlign="start center">
                                <button mat-icon-button (click)="openDetails(row);" aria-label="הצג עוד...">
                                    <mat-icon style="color: gray;">remove_red_eye</mat-icon>
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="contactID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
                            איש </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מזהה איש">
                            <a [routerLink]="[ '/contacts', row.contactID]">
                                {{ row.contactID }}
                            </a>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם איש </mat-header-cell>
                        <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
                            <a [routerLink]="[ '/contacts', row.contactID]">{{ row.firstName }} {{ row.lastName }}</a>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="phoneNumber1">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 1
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="טלפון 1" style="direction: ltr">
                            {{row.phoneNumber1 | phone}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="phoneNumber2">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 2
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="טלפון 2" style="direction: ltr">
                            {{row.phoneNumber2 | phone}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="phoneNumber3">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 3
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="טלפון 3" style="direction: ltr">
                            {{row.phoneNumber3 | phone}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="phoneNumber4">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 4
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="טלפון 4" style="direction: ltr">
                            {{row.phoneNumber4 | phone}}
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="countIvrDonationDetails">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ס''ה רישומים
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="ס''ה רישומים"> {{ row.countIvrDonationDetails }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="sumQuantity">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ס''ה מקומות
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="ס''ה מקומות"> {{ row.sumQuantity }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="sumPrices">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ס''ה לתשלום
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="ס''ה לתשלום">
                            {{ row.sumPrices | number }}</mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="sumAmountPaid">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>ס''ה שולם
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="ס''ה שולם">
                            {{ row.sumAmountPaid | number }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="sumAmountToPay">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> יתרות לתשלום
                        </mat-header-cell>
                        <mat-cell [ngStyle]="{'color':(row.sumAmountToPay > 0 ? 'red' : 'green')}" *matCellDef="let row"
                            data-label="יתרות לתשלום">
                            {{ row.sumAmountToPay | number }} {{ row.currencyIcon }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ivrDonationDetailEventTypeID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>סוג אירוע
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סוג אירוע">
                            {{ row.ivrDonationDetailEventTypeName }} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                        (dblclick)="openSelectedRow(row.ivrDonationDetailID)">
                    </mat-row>
                </mat-table>
            </div>
        </div>
        <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
            [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
    </div>
</form>
