import { CollectionBuilderMain } from './collection-builder/collection-builder-main.cmp';

import { AuthGuard } from '../_guards/auth.guard';
import { Routes } from '@angular/router';
import { CollectionBuildersList } from './collection-builders-list.cmp';

export const CollectionBuildersRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'collectionBuilders', component: CollectionBuildersList, data: {  roles: "collectionBuilders",  title: 'רשימת גביות' } },
    { canActivate: [AuthGuard], path: 'collectionBuilders/add', component: CollectionBuilderMain, data: { mode: 'edit', roles: "collectionBuilders", title: 'הוסף גביה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'collectionBuilders/add/:termID/:paymentID', component: CollectionBuilderMain, data: { mode: 'edit', roles: "collectionBuilders", title: 'הוסף גביה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'collectionBuilders/:collectionBuilderID', component: CollectionBuilderMain, data: {  roles: "collectionBuilders",  title: 'פרטי גביה' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'collectionBuilders/:collectionBuilderID/edit', component: CollectionBuilderMain, data: { mode: 'edit', roles: "collectionBuilders",  title: 'עריכת פרטי גביה' }, outlet: 'popup' }
];
