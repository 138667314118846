import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { IvrDonationUserTasksData } from './../ivr-donation-user-tasks.data';
import { IvrDonationUserTask } from './ivr-donation-user-task';
import { AlertService } from './../../components/alert/alert.svc';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({

	selector: 'ivr-donation-user-task-details',
	templateUrl: './ivr-donation-user-task-details.tmpl.html'
})

export class IvrDonationUserTaskDetails implements OnInit, AfterViewInit {
	constructor(
		public ivrDonationUserTasksData: IvrDonationUserTasksData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private alertSvc: AlertService,
		private formBuilder: FormBuilder,
		public router: Router
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (this.ivrDonationUserTask && id === this.ivrDonationUserTask.ivrDonationUserTaskID) {
				this.loadIvrDonationUserTask();
			}
		});
	}

	public ivrDonationUserTask: IvrDonationUserTask;
	id: number;
	contactId: number;
	ivrDonationUserTaskForm: FormGroup;

	contactEdit: boolean = false;



	public loadIvrDonationUserTask(): void {
		this.ivrDonationUserTasksData.get(this.id)
			.subscribe((c: any) => {
				this.ivrDonationUserTask = c;
				this.ivrDonationUserTaskForm.patchValue(this.ivrDonationUserTask);
			}
			);
	}

	private initForm() {
		this.ivrDonationUserTaskForm = this.formBuilder.group({
			createBy: [], createDate: [], changeBy: [], changeDate: [],
			ivrDonationUserTaskID: [this.id, Validators.required],
			ivrDonationDetailID: [, Validators.required],
			doneDate: [new Date()],
			doneByUserName: [],
			identifiedContactID: [],
			identifiedContactTitle: [],
			contactID: [],
			contactTitle: [],
			comment: [],
		});
	}


	public getAudioByts(): void {
		setTimeout(() => {
			this.ivrDonationUserTasksData.getAudioByts(this.id)
				.subscribe(blob => {
					let elmnt = (<any>document.getElementById("audioFile"));
					if (elmnt) {
						elmnt.src = window.URL.createObjectURL(blob);
					}
				}),
				error => { };
		}, 200);
	}


	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['ivrdonationusertaskID'] || 0;
		});
		this.initForm();
		this.loadIvrDonationUserTask();
	}

	ngAfterViewInit() {
		this.getAudioByts();

	}
}

