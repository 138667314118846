import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { BrowserModule } from '@angular/platform-browser';

import { ScreenComponent  } from './screen.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from '../material.module';
import { Campaign } from '../campaign/campaign/campaign';
import { CampaignsData } from '../campaign/campaigns.data';
import { CampaignAttachedsData } from '../campaign-attached/campaign-attacheds.data';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		MaterialModule,
	],
	declarations: [
		ScreenComponent,
	],
	exports:[ScreenComponent],
	providers: [CampaignsData,CampaignAttachedsData]
})
export class ScreenModule { }

