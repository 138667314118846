<form class="example-form" [formGroup]="contactReportModelForm" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>בחר פרטים לדו"ח</small>
            <br>
            {{ data.contactName }}
        </h2>

    </div>
    <mat-dialog-content>
        <div fxFlex="100" fxLayout="column">
            <!-- <mat-slide-toggle fxLayout="row" class="example-margin" formControlName="includeHoldings">הצג חודשי החזקת
                ביהמ"ד
            </mat-slide-toggle>
            &nbsp;&nbsp; -->
            <mat-slide-toggle fxLayout="row" class="example-margin" formControlName="includeWaitingPayments">כלול
                תשלומים ממתינים
            </mat-slide-toggle>
            &nbsp;&nbsp;
            <mat-slide-toggle fxLayout="row" class="example-margin" formControlName="includeClosedCommitments">
                כלול התחייבויות שנסגרו
            </mat-slide-toggle>
            &nbsp;&nbsp;
            <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                *ngIf="servicesData.CommitmentSubjects"
                [listControl]="contactReportModelForm.controls.commitmentSubjects" [placeholder]="'נושא...'"
                [list]="servicesData.CommitmentSubjects">
            </mat-select-multiple-dropdown>
            &nbsp;&nbsp;
            <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                *ngIf="servicesData.DonationTypes" [listControl]="contactReportModelForm.controls.donationTypes"
                [placeholder]="'סוג...'" [list]="servicesData.DonationTypes">
            </mat-select-multiple-dropdown>
            &nbsp;&nbsp;
            <date-input-range [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
                [dateRangeCtrl]="contactReportModelForm.controls.fromDateRange">Loading...
            </date-input-range>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button [disabled]="contactReportModelForm.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="contactReportModelForm"><i class="fa fa-download"></i> הורד </button>
        <button class="btn btn-warning navbar-btn" (click)="cancel()"><i class="fa fa-warning"></i> ביטול
        </button>
    </mat-dialog-actions>
