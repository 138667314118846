<div *ngIf="assistance">
  <div class="modal-header">
    <div class="row">
      <div class="col-sm-4">
        <!-- <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', assistance.contactID]}}]"> -->
        <h2>פרטי תמיכה: [{{ assistance.assistanceID }}] </h2>
        <!-- </a> -->
      </div>

      <div class="col-sm-8 text-left">

      </div>

    </div>
  </div>
  <div class="modal-body">
    <div style="overflow: auto">
      <mat-card fxLayout="column" class="example-card">

        <mat-card-content style="width: 100%;">
          <div class="dl-horizontal" style="white-space: nowrap;">

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">שם איש</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row" style="font-weight: bold;">{{ assistance.contactName }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">קטגוריה</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row">{{ assistance.donationCategoryTitle }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">סכום</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row">{{ assistance.amount |number}} {{ assistance.currencyIcon }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">תאריך</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row">{{assistance.assistanceDate | date:'dd/MM/yyyy' }}<br>{{ assistance.assistanceJewishDate }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">מס' שיק</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row">{{ assistance.chickNumber }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">פרטי השיק</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row">{{ assistance.chickDetails }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">סיבה</div>
              <div class="f_val" fxFlex="70" fxLayout.sm="row">{{ assistance.reason }}</div>
            </div>

            <div class="f_content" fxLayout="row" fxLayout.sm="column">
              <div class="f_name" fxFlex="30" fxLayout.sm="row">הערה</div>
              <div class="f_val comment" fxFlex="70" fxLayout.sm="row" >{{ assistance.comment }}</div>
            </div>



          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
