import { ServicesData } from './../../services/services.data';
import { TishTablesData } from './../tishTables.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

const dropDownRequired = c => {
    if (!c.value || c.value === 'undefined') {
        return { required: true };
    }
    return null;
};

@Component({
    templateUrl: './tishTable-move-dlg.tmpl.html'
})
export class TishTableMoveDlg implements OnInit {
    public formData: FormGroup;

    //  public data: any;

    constructor(
        public dialogRef: MatDialogRef<TishTableMoveDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public servicesData: ServicesData,
        private formBuilder: FormBuilder,
        private tishTablesData: TishTablesData
    ) { }

    ngOnInit(): void {

        if (this.data) {
            this.formData = this.formBuilder.group({
                tishEventID: [, Validators.required],
                tishTableID: [, Validators.required],
                tishTableName: [],
                tishTableLeft: [, Validators.required],
                tishTableTop: [, Validators.required]
            });
            this.formData.patchValue(this.data); 
        }


    }



    public isValid(): boolean {
        
        return (this.formData && this.formData.valid);
    }


}
