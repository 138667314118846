<form [formGroup]="letterSettingForm" novalidate *ngIf="letterSetting">
    <div class="modal-header">
        <h1>עריכת פרטי מכתב [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div  class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי מכתב</legend>

                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div style="width: 250px !important;" class="input-group mb-3">
                            <label>שם המכתב:</label>
                            <input type="text" class="form-control" formControlName="letterName" />
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תבנית כותרת וכתובת:</label>
                           <textarea formControlName="titleAndShippingAddressTemplate"></textarea>
                            <!-- <app-ngx-editor   [spellcheck]="true" formControlName="titleAndShippingAddressTemplate"></app-ngx-editor> -->

                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תבנית גוף מכתב:</label>
                            <textarea formControlName="mainTemplate"></textarea>
                            <!-- <app-ngx-editor   [spellcheck]="true" formControlName="mainTemplate"></app-ngx-editor> -->

                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תבנית פרטי תשלום בקבלה:</label>
                            <textarea formControlName="paymentTemplate"></textarea>
                            <!-- <app-ngx-editor   [spellcheck]="true" formControlName="paymentTemplate"></app-ngx-editor> -->

                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>מימין לשמאל:</label>
                            <input type="checkbox" formControlName="rtl" />
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>אחוזי הגדלה לחתימה:</label>
                            <input type="text" class="form-control" formControlName="widthPercentage" />
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>פורמט תאריך:</label>
                            <input type="text" class="form-control" formControlName="letterDateFormat" />
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>גופן (TTF):</label>
                            <input type="text" class="form-control" formControlName="fontName" />
                        </div>
                    </div>


                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>שוליים:</label>
                            <div class="form-inline">
                                <input type="number" class="form-control" formControlName="marginL" />
                                <small *ngIf="!letterSettingForm.controls.marginL.valid" class="text-danger">*</small>
                                <input type="number" class="form-control" formControlName="marginR" />
                                <small *ngIf="!letterSettingForm.controls.marginR.valid" class="text-danger">*</small>
                                <input type="number" class="form-control" formControlName="marginT" />
                                <small *ngIf="!letterSettingForm.controls.marginT.valid" class="text-danger">*</small>
                                <input type="number" class="form-control" formControlName="marginB" />
                                <small *ngIf="!letterSettingForm.controls.marginB.valid" class="text-danger">*</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>שפה:</label>
                            <select class="form-control" formControlName="language">
                                <option value="">...</option>
                                <option *ngFor="let language of servicesData.Languages" [value]="language.value">
                                    {{language.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>שמור כתבנית קבלה::</label>
                            <input type="checkbox" formControlName="saveAsReceiptTemplate" />
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [dataProvider]="letterSettingsData" [router]="router" [id]="id"
        [disabledDeleteButton]="id <= 0" [modelFormGroup]="letterSettingForm"></footer-edit-buttons>

</form>