import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ServicesData } from './../../services/services.data';
import { TishPlace } from './tishPlace';
import { TishPlacesData } from './../tishPlaces.data';

export class PaymentOption { }
export class Api { }

@Component({
    
    selector: 'tishPlace-edit',
    templateUrl: './tishPlace-edit.tmpl.html'
})

export class TishPlaceEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private tishPlacesData: TishPlacesData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {
    }


    tishPlace: TishPlace;
    id: number = 0;
    tishPlaceForm: FormGroup;
    tishEventID;

    public load(): void {

        if (this.id > 0) {
            this.tishPlacesData.getEdit(this.id)
                .subscribe((tg:any) => {
                    this.tishPlace = tg;
                    this.fillForm(tg);
                });
        }
        //  else {
        //     this.tishPlacesData.getAdd()
        //         .subscribe(tg => {
        //             this.tishPlace = tg;
        //             this.fillForm(tg);
        //         });
        // }
    }

    private fillForm(tg: TishPlace): void {
        this.tishPlaceForm.patchValue(tg);
    }

    public save(): void {
        console.log('save run');
        if (this.tishPlaceForm.valid) {
            this.tishPlacesData.update(this.tishPlaceForm.value)
                .subscribe((c: any) => {
                    this.location.back();
                    //this.router.navigate(['tishPlaces', c.id], { relativeTo: this.activatedRoute });
                });
        } else {
            console.log('not valid');
            console.log(this.tishPlaceForm.errors);
        }
    }

    public cancel(): void {
        this.location.back();
    }

    private initForm() {
        this.tishPlaceForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            tishPlaceID: [0, Validators.required],
            tishInvitedID: [], 
            placeName: [],
            isActive: [true, Validators.required],
            tishInvitedName: [],
            tishAreaID: [],
            tishAreaName: [],
            tishGroupID: [],
            tishGroupName: [],
            tishTableID: [],
            tishTableName: [],
            placeNumber: [, Validators.required],
            comment: [],
            tishInvited: this.formBuilder.group({})
        });
    }

    private tishInviteds: Array<any>;
    private tishAreas: Array<any>;
    private tishGroups: Array<any>;

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['tishPlaceID'];
            this.tishEventID = +params['tishEventID'];
        });

        //this.servicesData.getList("tishInviteds").subscribe(e => { this.tishInviteds = e; }, error => { });

        this.initForm();
        this.load();
        // if (this.id > 0) {
        // } else {
        //     this.tishPlace = new TishPlace();
        //     this.tishPlace.tishPlaceID = 0;
        // }

        // this.tishPlaceForm.controls['tishEventID'].valueChanges.subscribe(data => {
        //     if (data > 0) {
        //         this.servicesData.getList(`TishAreas/${data}`).subscribe(e => { this.tishAreas = e; }, error => { });
        //         this.servicesData.getList(`TishGroups/${data}`).subscribe(e => { this.tishGroups = e; }, error => { });
        //     }
        // });

    }
}





