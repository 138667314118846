<form *ngIf="shtibel">
    <div class="modal-header">
        <h1 style="margin: 0;"> <small>עריכת פרטי שטיבל [{{ (id > 0 ? id : 'חדש') }}] </small>
            <br>
            {{ shtibel.shtibelName }}
        </h1>
    </div>
    <div class="modal-body">
        <div class="example-form" [formGroup]="shtibelForm" novalidate>
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">

                    <legend>פרטי שטיבל</legend>
                    <div class="form-row" fxLayout="row">
                        <div class="input-group mb-3">
                            <label for="shtibelName">שם שטיבל:</label>
                            <input type="text" class="form-control" formControlName="shtibelName" />
                        </div>
                        &nbsp;
                        <div class="input-group mb-3">
                            <label for="codeIchudForShtibel">קוד:</label>
                            <input type="text" class="form-control narrow" formControlName="codeIchudForShtibel" />
                        </div>

                    </div>
                    <div style="margin-top: 15px;" class="form-row" fxLayout="row">
                        <auto-complete apply-enter [placeholder]="'גבאי 1'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.gabbaiID1"
                            [nameControl]="shtibelForm.controls.gabbaiName1">
                        </auto-complete>
                        &nbsp;
                        <auto-complete apply-enter [placeholder]="'גבאי 2'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.gabbaiID2"
                            [nameControl]="shtibelForm.controls.gabbaiName2">
                        </auto-complete>
                        &nbsp;
                        <auto-complete apply-enter [placeholder]="'גבאי 3'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.gabbaiID3"
                            [nameControl]="shtibelForm.controls.gabbaiName3">
                        </auto-complete>
                    </div>
                    <div style="margin-top: 15px;" class="form-row" fxLayout="row">
                        <auto-complete apply-enter [placeholder]="'גבאי החזקת ביהמד 1'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.holdingsResponsibleID1"
                            [nameControl]="shtibelForm.controls.holdingsResponsibleName1">
                        </auto-complete>
                        <auto-complete apply-enter [placeholder]="'גבאי החזקת ביהמד 2'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.holdingsResponsibleID2"
                            [nameControl]="shtibelForm.controls.holdingsResponsibleName2">
                        </auto-complete>
                        &nbsp;
                        <auto-complete apply-enter [placeholder]="'גבאי החזקת ביהמד 3'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.holdingsResponsibleID3"
                            [nameControl]="shtibelForm.controls.holdingsResponsibleName3">
                        </auto-complete>
                    </div>
                    <div style="margin-top: 15px;" class="form-row" fxLayout="row">
                        <auto-complete apply-enter [placeholder]="'גבאי החזקת ביהמד כללי'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm"
                            [idControl]="shtibelForm.controls.holdingsResponsibleExternalID"
                            [nameControl]="shtibelForm.controls.holdingsResponsibleExternalName">
                        </auto-complete>
                    </div>
                    <div style="margin-top: 15px;" class="form-row" fxLayout="row">
                        <textarea rows="2"  placeholder="כותרת לקביעות..."
                        formControlName="holdingsTitle"></textarea>
                    </div>

                    <div style="margin-top: 15px;" class="form-row" fxLayout="row">
                        <auto-complete apply-enter [placeholder]="'נציג ביהמד'" [controllerName]="'Contacts'"
                            [formGroupModel]="shtibelForm" [idControl]="shtibelForm.controls.representativeID"
                            [nameControl]="shtibelForm.controls.representativeName">
                        </auto-complete>
                    </div>
                    <div style="margin-top: 15px;" class="form-row" fxLayout="row" fxLayout.xs="column">
                        <auto-complete apply-enter (onSelect)="countryIDItemSelect($event)" [placeholder]="'ארץ'"
                            [controllerName]="'Countries'" [formGroupModel]="shtibelForm"
                            [idControl]="shtibelForm.controls.countryID"
                            [nameControl]="shtibelForm.controls.countryName">
                        </auto-complete>&nbsp;
                        <auto-complete #cityCmp apply-enter [firstFocus]="true" (onSelect)="cityIDItemSelect($event)"
                            [placeholder]="'עיר, מחוז'" [controllerName]="'Cities'" [formGroupModel]="shtibelForm"
                            [idControl]="shtibelForm.controls.cityID" [nameControl]="shtibelForm.controls.cityName">
                        </auto-complete>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <auto-complete-input #streetInput apply-enter [placeholder]="'רחוב'"
                            [controllerName]="'Streets'" [stringValueControl]="shtibelForm.controls.street"
                            [formGroupModel]="shtibelForm">
                        </auto-complete-input>
                        &nbsp;
                        <div class="input-group mb-3">
                            <label>בית:</label>
                            <input class="form-control narrow" apply-enter formControlName="houseNum"
                                placeholder="בית..." />
                        </div>&nbsp;
                        <div class="input-group mb-3">
                            <label>דירה:</label>
                            <input class="form-control narrow" apply-enter formControlName="aptNum"
                                placeholder="דירה..." />
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>כניסה:</label>
                            <input class="form-control narrow" apply-enter formControlName="entry"
                                placeholder="כניסה..." />
                        </div>&nbsp;
                        <div class="input-group mb-3">
                            <label>מיקוד:</label>
                            <input class="form-control narrow" apply-enter formControlName="zip"
                                placeholder="מיקוד..." />
                        </div>&nbsp;
                        <div class="input-group mb-3">
                            <label>ת.ד.:</label>
                            <input class="form-control  narrow" apply-enter formControlName="pob"
                                placeholder="ת.ד...." />
                        </div>
                    </div>

                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="shtibelsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="shtibelForm"></footer-edit-buttons>

</form>
