import { TishPlace } from './tishPlace/tishPlace';
import { ServicesData } from './../services/services.data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { TishPlacesData } from './tishPlaces.data'; 

import { ObjectsListResult, ObjectListItem } from '../services/objects-list-results';

@Component({	
	selector: 'tishPlaces-list',
	templateUrl: './tishPlaces-list.tmpl.html'
})
export class TishPlacesList implements OnInit {
	public query: string | null = null;

	public objectsListResult: ObjectsListResult;
	searchTishPlacesListForm: FormGroup;

	@Input('tableID') public tableID?: number;

	constructor(
		private tishPlacesData: TishPlacesData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
	) {
	}

	public getTishPlaces(): void {
		this.searchTishPlacesListForm.controls['tableID'].setValue(this.tableID);

		this.tishPlacesData.getList(this.searchTishPlacesListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			error => { });
	}

	public changePage(page: number) {
		this.searchTishPlacesListForm.controls['page'].setValue(page);
		this.getTishPlaces();
	}
	public search() {
		this.getTishPlaces();
	}

	private initForm() {
		this.searchTishPlacesListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			tableID: [],
		});
	}

	ngOnInit(): void {
		this.initForm();
		this.getTishPlaces();
	}
}