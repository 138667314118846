import { Address } from './../contacts/contact/contact';
import { Country } from './country/country';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class CountriesData {
    private url = 'api/countries';

    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }


    get(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(Country: Country) {
        return this.toraxHttp.update(this.url, Country);
    }


    // loadTerms(subject:number) {
    //     return this.toraxHttp.get(`api/services/getSubjectTerms/${subject}`);
    // }


     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
