<div *ngIf="processType">
    <div class="col-sm-6">
        <div>
            <panel-box title="פרטי סוג תהליך: {{ id }}" [showClose]="false" [showCollapse]="false">
                <span class="panel-tools">
                    <a [routerLink]="['/processTypee', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
                </span>
                <div class="panel-content">
                    <div>
                        <dl class="dl-horizontal">
                            <dt>כותרת-נושא</dt>
                            <dd>{{ processType.title }}</dd>
                            <hr>
                        </dl>
                    </div>
                </div>
            </panel-box>
        </div>
    </div>
</div>