<div style="text-align: right; display: inline-block; overflow: visible; vertical-align: middle;" class="form-group">
    <label *ngIf="withLabel" class="control-label">{{placeholder}}</label>
    <div style="width:min-content;" class="input-group mt-2">
        <mat-select class="form-control" [formControl]="listControl" multiple  (selectChange)="selectionChanged($event)">
            <mat-option *ngFor="let item of list" [value]="item[keyProperty]">
                <i *ngIf="colorOfitem(item)" class="fa fa-circle text-navtext-navy"
                    [ngStyle]="{'color': colorOfitem(item)}">&nbsp;</i>
                <!-- {{ titleOfitem(item) }} -->

                {{(item[titleProperty])}}
            </mat-option>
        </mat-select>
    </div>
</div>
