import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { ModalService } from './../components/modal/modal.svc';
//import { UpdateCashRegisterDlg } from './registrations-updateCashRegister-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { RegistrationsData } from './registrations.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
	selector: 'registrations-list',
	templateUrl: 'registrations-list.tmpl.html'
})

export class RegistrationsList implements OnInit {
	constructor(
		private registrationsData: RegistrationsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private modal: ModalService,
		private router: Router,
		public _matDialog: MatDialog,
	) {
	}

	displayedColumns: string[] = ['buttons', 'registrationID', 'lastName', 'shtibelName', 'registeringPhone', 'callerNumber', 'registrationEventTitle', 'eventDate', 'quantity', 'commitmentID', 'paymentPayMethodTitle', 'sumPrice', 'amountPaid', 'amountToPay'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;
	commitmentID;
	@ViewChild(MatAccordion) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('registrationEventID') registrationEventID: AutoCompleteComponent;
	@ViewChild('shtibelID') shtibelID: AutoCompleteComponent;

	@Input('contactID') public contactID?: number | null = null;
	@Input('registrationID') public registrationID?: number | null = null;

	// private openUpdateCashRegisterDlg(): void {

	// 	this.modal.show({
	// 		Header: 'עדכון תשלומי קופה רושמת',
	// 		BodyComponentType: UpdateCashRegisterDlg,
	// 		// Width : '40%',
	// 		Actions: [
	// 			{ Text: 'יציאה', CssClass: 'btn-default', Action: () => true },
	// 		],
	// 		Data: {}
	// 	});
	// }



	searchRegistrationsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
		this.getRegistrations();
	}

	addNew() {
		this.router.navigate([{ outlets: { popup: ['add'] } }]);
	}

	openSelectedRow(registrationID) {
		this.router.navigate(['', { outlets: { popup: ['registrations', registrationID] } }]);
	}


	public downloadExcel() {
		this.registrationsData.exportExcel(this.searchRegistrationsListForm.value)
			.subscribe(blob => {
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `סטנציא.xlsx`;
				link.click();
			});
	}


	private getRegistrations() {
		this.searchRegistrationsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

		if (this.contactID && this.contactID > 0) {
			this.searchRegistrationsListForm.controls['contactID'].setValue(this.contactID);
		}
		if (this.registrationID && this.registrationID > 0) {
			this.searchRegistrationsListForm.controls['registrationID'].setValue(this.registrationID);
		}


		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchRegistrationsListForm.value));

		return this.registrationsData!.getList(this.searchRegistrationsListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;


				this.isRateLimitReached = false;

			});
	}

	registrationsDebitBalance = 0;
	getRegistrationsDebitBalance() {
		if (this.contactID && this.contactID > 0) {
			this.registrationsData.getRegistrationsDebitBalance(this.contactID).subscribe(
				(r: any) => {
					this.registrationsDebitBalance = r;
				},
				error => { });
		}
	}
	private initForm() {
		this.searchRegistrationsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			registrationID: [],
			statusPayment: [],
			registrationEventID: [],
			registrationEventTitle: [],
			registeringPhone: [],
			callerNumber: [],
			paymentOptions: [],
			paymentPayMethodID: [],
			shtibelID: [],
			shtibelName: [],
			contactID: [],
			contactName: [],
			registrationEventTypes: [],
			selectedRows: [],
			orders: this.formBuilder.array([]),
			updateCashRegisterModel: this.formBuilder.array([this.initUpdateCashRegisterModel()])
		});
	}

	initUpdateCashRegisterModel(): FormGroup {
		return this.formBuilder.group({
			contactID: [, Validators.required],
			commitmentID: [, Validators.required],
			amount: [, Validators.required]
		});
	}


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;

	ngOnInit() {
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchRegistrationsListForm.patchValue(frm);
		}
		this.getRegistrations();
		this.getRegistrationsDebitBalance();
	}

	pageChange(e) {
		this.searchRegistrationsListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}

	selectionChanged(e) {
		this.getRegistrations();
	}


	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getRegistrations();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchRegistrationsListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}

	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchRegistrationsListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}

	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}
