import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Commitment, ClosedCommitmentMdl } from './commitment/commitment';



@Injectable({ providedIn: 'root' })

export class CommitmentsData {
      public url = 'api/commitments';

    constructor(
        private toraxHttp: ToraxHttp,
    ) {


     }


    downloadOneCommitmentReport(contactID,commitmentID) {
       //  return this.toraxHttp.postAndDownload(`${this.url}/downloadOneCommitmentReport`, commitment);
      return this.toraxHttp.getWithResponceToDownload(`${this.url}/downloadOneCommitmentReport/${contactID}/${commitmentID}`);
    }

    closeCommitment(closedCommitmentMdl: ClosedCommitmentMdl) {
        return this.toraxHttp.post(`${this.url}/closedCommitment`, closedCommitmentMdl);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    exportExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
    }
    exportSmallExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadSmallExls`, baseQuery);
    }
    exportFullExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadFullExls`, baseQuery);
    }



    getCommitmentsBeforeEnd(baseQuery: any) {
        return this.toraxHttp.get(`${this.url}/getCommitmentsAboutToExpire`);
    }
    getCommitmentsCloseds(baseQuery: any) {
        return this.toraxHttp.get(`${this.url}/getClosedCommitments`);
    }

    getCommitmentsDbCommitmentsd(baseQuery: any) {
        return this.toraxHttp.get(`${this.url}/getCommitmentsDbCommitmentsd`);
    }
    getCommitmentsTreatmentCommitments(baseQuery: any) {
        return this.toraxHttp.get(`${this.url}/getCommitmentsTreatmentCommitments`);
    }

    // getCommitmentInfo(commitmentID){
    //     return this.toraxHttp.get(`${this.url}/getCommitmentInfo/${commitmentID}`);
    // }

    getCommitmentInfoPlus(commitmentID) {
        return this.toraxHttp.get(`${this.url}/getCommitmentInfoPlus/${commitmentID}`);
    }

    contactSubjectCommitments(contactID, subject) {
        return this.toraxHttp.get(`${this.url}/contactSubjectCommitments/${contactID}/${subject}`);
    }

    getPaymentCommitmentsVM(commitmentID) {
        return this.toraxHttp.get(`${this.url}/getPaymentCommitmentsVM/${commitmentID}`);
    }


    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getCommitmentPayments(commitmentId: number) {
        return this.toraxHttp.get(`${this.url}/getCommitmentPayments/${commitmentId}`);
    }




    getCommitments(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/getdbCommitments`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getCommitmentClosedDetails(commitmentID: any) {
        return this.toraxHttp.get(`${this.url}/getCommitmentClosedDetails/${commitmentID || 0}`);
    }

    // createAndPrintReceiptsForCommitment(commitmentID: number, paymentsIds: string = "") {
    //     return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintReceiptsForCommitment?commitmentID=${commitmentID}&paymentsIds=${paymentsIds}`);
    // }




    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number, donationType: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}/${donationType || 0}`);
    }

    update(commitment: Commitment) {
        return this.toraxHttp.update(this.url, commitment);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }

}
