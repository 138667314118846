<div fxLayout="row" style="padding:0 10px ;">

  <div fxLayout="column" fxFlex>
    <div fxLayout="row" style="padding:10px;align-items: center;border-bottom: solid 1px;">
      <div fxFlex>
        <h1>דו"ח תוכניות לטיפול ואנשים ללא תוכנית</h1>
        <h4>מעקב תוכניות לטיפול שיש להם תשלום פעיל במערכת ב-40 יום אחרונים ואו אנשים ללא תוכנית מסודרת</h4>
      </div>
      <div>
        <button class="rep-btn excel" (click)="pplDlg()">
          <i style="line-height: 24px; font-size: 24px;" class="fa fa-toggle-left"></i>
        </button>
      </div>
    </div>
    <div fxLayout="row" style="padding:10px;align-items: center;border-bottom: solid 1px;">
      <div fxFlex>
        <h1>דו"ח תשלומים שנכשלו</h1>
        <h4>רשימת תשלומים שחזרו למעקב וטיפול בטווח ימים אחורה בהתאם לבחירה</h4>
      </div>
      <div>
        <button class="rep-btn excel" (click)="paymentsFaildDlg()">
          <i style="line-height: 24px; font-size: 24px;" class="fa fa-toggle-left"></i>
        </button>
      </div>
    </div>
    <!-- <div fxLayout="row"  style="padding:10px;align-items: center;border-bottom: solid 1px;">
      <div fxFlex>
        <h1>דו"ח תוכניות לטיפול ואנשים ללא תוכנית</h1>
        <h4>מעקב תוכניות לטיפול שיש להם תשלום פעיל במערכת ב-40 יום אחרונים ואו אנשים ללא תוכנית מסודרת</h4>
      </div>
      <div>
        <button class="rep-btn excel" (click)="pplDlg()">
        <i style="line-height: 24px; font-size: 24px;" class="fa fa-toggle-left"></i>
      </button>
    </div>
  </div> -->

  </div>
  <div fxLayout="column" fxFlex>

  </div>
  <div fxLayout="column" fxFlex>
  </div>

</div>
