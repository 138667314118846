import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IvrDonationDetailMain } from './ivrDonationDetail/ivrDonationDetail-main.cmp';

import { InputInfoModule } from './../components/input-info/input-info.module';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { MaterialModule } from './../material.module';
import { IvrDonationDetailsGroupList } from './ivrDonationDetails-group-list.cmp';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';

import { IvrDonationDetailsRoutes } from './ivrDonationDetails.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { IvrDonationDetailsData } from './ivrDonationDetails.data';
import { IvrDonationDetailEdit } from './ivrDonationDetail/ivrDonationDetail-edit.cmp';
import { IvrDonationDetailsList } from './ivrDonationDetails-list.cmp';
import { IvrDonationDetailDetails } from './ivrDonationDetail/ivrDonationDetail-details.cmp';
//import { UpdateCashRegisterDlg } from './ivrDonationDetails-updateCashRegister-dlg.cmp';
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';
import { AuthGuardMdl } from '../_guards/auth.guard.mdl';
import { CurrencyPipeMdl } from '../components/pipes/currency-pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        AutoCompleteModule,
         RouterModule.forChild(IvrDonationDetailsRoutes),
        MaterialModule,
        PhonePipeMdl,
        ModelInfoModule,
        InputInfoModule,
        MainFormModalModule,
        PayMethodsMdl,
        AuthGuardMdl,
        CurrencyPipeMdl,
        ClipboardModule
    ],
    declarations: [
        IvrDonationDetailEdit,
        IvrDonationDetailDetails,
        IvrDonationDetailsList,
        IvrDonationDetailsGroupList,
        //UpdateCashRegisterDlg,
        IvrDonationDetailMain,


    ],
    exports: [
        IvrDonationDetailEdit,
        IvrDonationDetailDetails,
        IvrDonationDetailsList,
        IvrDonationDetailsGroupList,
        IvrDonationDetailMain

    ],
    providers: [
        IvrDonationDetailsData
    ],
    entryComponents: [
      //  UpdateCashRegisterDlg,

    ]
})
export class IvrDonationDetailsMdl { }



