<form [formGroup]="searchReceiptsListForm">
  <div *ngIf="!contactID || contactID == 0" class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">

      <auto-complete apply-enter [placeholder]="'שם איש...'" (onSelect)="indexService.selectionChanged($event)"
        [controllerName]="'Contacts'" [formGroupModel]="searchReceiptsListForm"
        [idControl]="searchReceiptsListForm.controls.contactID"
        [nameControl]="searchReceiptsListForm.controls.contactName"></auto-complete>
      &nbsp;&nbsp;

      <div class="input-group mb-3">
        <label>תוכניות תשלום...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="תוכניות תשלום..." formControlName="withReceiptsPlan">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">כולל</mat-option>
          <mat-option [value]="false">לא כולל</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;


      <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()">
          <i class="fa fa-filter"></i></button> &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()">
          <i class="material-icons">clear</i></button> &nbsp;
      </div>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService">
    <cdk-virtual-scroll-viewport itemSize="10"
      [style.height.vh]="indexService?.sourceList ? (indexService.height ? indexService.height : 65) : 5"
      style="background: rgb(243 243 244)">
      <mat-table *ngIf="indexService?.sourceList; else loading" [dataSource]="indexService.sourceList"
        style="width: 100%;" (matSortChange)="indexService.sortData($event)" class="example-table" matSort
        matSortDisableClear matSortDirection="asc">

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

              <a mat-menu-item [disabled]="!row.receiptNumber" (click)="createAndPrintReceipt(row)">
                <mat-icon>print</mat-icon>
                <span>הדפס קבלה</span>
              </a>
              <!-- <a mat-menu-item [disabled]="!row.receiptNumber" (click)="createAndDownloadReceipt(row)">
                <mat-icon>file_download</mat-icon>
                <span>הורד קבלה</span>
              </a> -->
              <a mat-menu-item>
                <mat-icon>information</mat-icon>
                <model-info style="padding: 4px" [dataProvider]="receiptsData" [id]="row.receiptID"
                  [title]="'מידע רשומה'">
                </model-info>
              </a>
            </mat-menu>

          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="receiptID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מזהה"> {{ row.receiptID }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="receiptNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מס' קבלה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label=" מס' קבלה"> {{ row.series }}{{(row.series ? '-' : '')}}{{
            row.receiptNumber }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="receiptCreatedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך הנפקה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך הנפקה"> {{ row.receiptCreatedDate  | date: 'dd/MM/yyyy'}} <br>{{
            row.receiptjewishCreatedDate }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="datePrint">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך הדפסת מקור
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך הדפסת מקור"> <br> {{ row.datePrint | date: 'dd/MM/yyyy'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;let index = dataIndex;"
          [ngClass]="{alternate: index % 2 != 0 }" (dblclick)="openSelectedRow(row.receiptID)">
        </mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
    <mat-paginator (page)="indexService.pageChange($event)" [length]="indexService.rowsCount"
      [pageSize]="indexService.pageRows"></mat-paginator>
  </div>
</form>

<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
