import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactsPlansAndPaymentsReportsDlg } from './rc-dialogs/rc-contacts-payments-plans.cmp';
import { PaymentsPlansReportsDlg } from './rc-dialogs/rc-payments-plans.cmp';
import { PaymentsTreatReportsDlg } from './rc-dialogs/rc-payments-treat.cmp';

@Component({
  selector: 'app-reports-center',
  templateUrl: './reports-center.component.html',
  styleUrls: ['./reports-center.component.scss']
})
export class ReportsCenterComponent  {

  constructor(
    public dialog: MatDialog) { }


  pplDlg() {
      setTimeout(() => {
          const dialogRef = this.dialog.open(ContactsPlansAndPaymentsReportsDlg, {
              // data: tp
          });
          dialogRef.afterClosed().subscribe(result => {
              if (result && result.valid) {
                  alert(result);
              }
          });
      }, 200);

  }
  paymentsFaildDlg() {
    setTimeout(() => {
        const dialogRef = this.dialog.open(PaymentsTreatReportsDlg, {
            // data: tp
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.valid) {
                alert(result);
            }
        });
    }, 200);

}
}
