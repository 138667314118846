<div *ngIf="shtibel">
    <div class="modal-header">
        <div class="row">
            <div style="padding: 0px;" class="col-sm-12">
                <div class="col-sm-6">
                    <h1>
                        [{{ shtibel.shtibelID }}] {{ shtibel.shtibelName }}
                    </h1>
                    <hr>
                    <dl *ngIf="shtibel.codeIchudForShtibel">
                        <span>קוד לשטיבל: {{ shtibel.codeIchudForShtibel }}</span>
                    </dl>
                </div>
                <div class="col-sm-6 text-left">
                    <button style="background-color: navy;" class="btn btn-primary" (click)="openHoldingsPrintDlg()">
                        הדפס רשימה</button>

                    <div class="btn-group" *authGuardDirective="'holdings'">
                        <button data-toggle="dropdown" class="btn btn-primary dropdown-toggle">דוח החזקת ביהמ''ד
                            לשטיבל&nbsp;&nbsp;
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu" style="overflow: auto; max-height: 400px;">
                            <li *ngFor="let y of servicesData.JewishYearOptions">
                                <a (click)="downloadHoldingsPivotReportExls(y.value, id)">{{y.label}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" fxLayout.xs="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-card style="overflow: auto;" fxLayout="column" class="example-card">
            <mat-card-header fxLayout="row">
                <mat-card-title fxLayout="row">
                    <h3>פרטי שטיבל: [{{ id }}] {{ shtibel.shtibelName }}</h3>
                    <span class="example-fill-remaining-space"></span>
                    <!-- <a [routerLink]="['/shtibels', {outlets: {popup: [ id, 'edit']}}]">
                        <i class="fa fa-pencil"></i>
                    </a> -->
                    <model-info *ngIf="modelInfo" [modelInfo]="modelInfo"></model-info>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <dl class="dl-horizontal">
                    <dt>גבאים</dt>
                    <dd>
                        <a [routerLink]="['/contacts', shtibel.gabbaiID1]">{{ shtibel.gabbaiName1 }}</a>
                        <br>
                        <a *ngIf="shtibel.gabbaiID2" [routerLink]="['/contacts', shtibel.gabbaiID2]">{{
                            shtibel.gabbaiName2 }}</a>
                        <br>
                        <a *ngIf="shtibel.gabbaiID3" [routerLink]="['/contacts', shtibel.gabbaiID3]">{{
                            shtibel.gabbaiName3 }}</a>
                    </dd>
                    <hr>
                    <dt>גבאי החזקת ביהמ"ד</dt>
                    <dd>
                        <a [routerLink]="['/contacts', shtibel.holdingsResponsibleID1]">{{
                            shtibel.holdingsResponsibleName1 }}</a>
                        <br>
                        <a *ngIf="shtibel.holdingsResponsibleID2"
                            [routerLink]="['/contacts', shtibel.holdingsResponsibleID2]">{{
                            shtibel.holdingsResponsibleName2 }}</a>
                        <br>
                        <a *ngIf="shtibel.holdingsResponsibleID3"
                            [routerLink]="['/contacts', shtibel.holdingsResponsibleID3]">{{
                            shtibel.holdingsResponsibleName3 }}</a>
                    </dd>
                    <hr>
                    <dt>גבאי החזקת ביהמ"ד כללי</dt>
                    <dd>
                        <a
                            [routerLink]="[ '', {outlets: {popup: [ 'contacts', shtibel.holdingsResponsibleExternalID]}}]">{{
                            shtibel.holdingsResponsibleExternalName }}</a>
                    </dd>
                    <hr>
                    <dt>כותרת לקביעות</dt>
                    <dd>
                        <div [innerHTML]="shtibel.holdingsTitle"></div>
                    </dd>

                    <hr>
                    <dt>נציג ביהמ"ד</dt>
                    <dd>
                        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', shtibel.representativeID]}}]">{{
                            shtibel.representativeName }}</a>
                    </dd>
                    <hr>
                    <dt>כתובת</dt>
                    <dd>{{ shtibel.streetLine }}</dd>
                    <hr>
                    <dt>עיר</dt>
                    <dd>{{ shtibel.cityName }}</dd>
                    <hr>
                    <dt>קוד לשטיבל</dt>
                    <dd>{{ shtibel.codeIchudForShtibel }}</dd>
                    <hr>
                </dl>

            </mat-card-content>
        </mat-card>

        <div style="overflow: auto;" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
            <mat-card style="overflow: auto;" class="example-card">
                <mat-card-header fxLayout="row">
                    <mat-card-title fxLayout="row">
                        <h3>רשומות החזקת ביהמ"ד שהודפסו לשטיבל {{id}}</h3>
                        <span class="example-fill-remaining-space"></span>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <holdings-prints-list #holdingsPrintList [shtibelID]="id"></holdings-prints-list>
                    <!-- <ng-template #holdingsPrintsList>
                            <h4>לא נמצאו רשומות שהודפסו לשטיבל זה</h4>
                        </ng-template> -->
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
