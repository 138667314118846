import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CollectionBuildersData } from './collection-builders.data';
import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
  selector: 'collection-builders-list',
  templateUrl: 'collection-builders-list.tmpl.html'
})

export class CollectionBuildersList implements OnInit {
  constructor(
    private collectionBuildersData: CollectionBuildersData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp
  ) {
  }

  displayedColumns: string[] = ['collectionBuilderID', 'termID', 'collectionDate', 'plannedCount', 'plannedSum', 'succeededCount', 'succeededSum', 'exludesCount', 'exludesSum', 'contactID', 'paymentID'];
  data: any[] = [];

  resultsLength = 0;
  isRateLimitReached = false;

  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('termID') public termID?: number | null = null;
  @Input('collectionDateRange') public collectionDateRange?: Date | null = null;
  //@Input('statusResponse') public statusResponse?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();

  togleTT = false;
  showPmTrans() {
    this.togleTT = this.togleTT
  }


  searchCollectionBuildersListForm: FormGroup;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  selection = new SelectionModel<number>(true, []);

  public clerFilter() {
    this.initForm();
    this.getCollectionBuilders();
  }


  addNew() {
    this.router.navigate(['', { outlets: { popup: ['collectionBuilders', 'add'] } }]);
  }

  openSelectedRow(collectionBuilderID) {

  }

  openDetails(row) {

  }




  public getCollectionBuilders() {
    this.searchCollectionBuildersListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

    if (this.collectionDateRange) {
      this.searchCollectionBuildersListForm.controls['collectionDateRange'].setValue(this.collectionDateRange);
    }
    // if (this.statusResponse && this.statusResponse > 0) {
    // 	this.searchCollectionBuildersListForm.controls['statusResponse'].setValue(this.statusResponse);
    // }
    if (this.termID) {
      let termIDs = new Array();
      termIDs.push(this.termID);
      this.searchCollectionBuildersListForm.controls['termIDs'].setValue(termIDs);
    }

    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchCollectionBuildersListForm.value));

    //	console.log(this.searchCollectionBuildersListForm);

    return this.collectionBuildersData!.getList(this.searchCollectionBuildersListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isRateLimitReached = false;
      });
  }

  private initForm() {
    this.searchCollectionBuildersListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      collectionDateRange: [],
      termIDs: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchCollectionBuildersListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.collectionBuilderID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }
  selectedItems;
  dropdownSettings;
  onItemSelect(item: any) {
    //	console.log(item);
    //	console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    //	console.log(item);
    //	console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    //	console.log(items);
  }
  onDeSelectAll(items: any) {
    //	console.log(items);
  }

  ngOnInit() {
    this.initForm();
    if (this.toraxHttp.previousUrl == "/dashboard/posMode") {
      let item = sessionStorage.getItem("dashboard/collectionBuilders/dbCollectionBuilders");
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem("dashboard/collectionBuilders/dbCollectionBuilders") || "");
        this.searchCollectionBuildersListForm.patchValue(frm);
      }
    }
    else {
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchCollectionBuildersListForm.patchValue(frm);
      }
    }
    this.getCollectionBuilders();
  }

  selectionChanged(e) {
    this.getCollectionBuilders();
  }

  pageChange(e) {
    this.searchCollectionBuildersListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }
  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getCollectionBuilders();
  }
  public search() {
  }
  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchCollectionBuildersListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }
  pageEvent: PageEvent;
  pageSizeOptions;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }
  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchCollectionBuildersListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }
  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}
