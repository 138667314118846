import { SelectionModel } from '@angular/cdk/collections';
import { ObjectsListResult } from '../services/objects-list-results';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { IvrAssignmentUserTasksData } from './ivr-assignment-user-tasks.data';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';

@Component({
  selector: 'ivr-assignment-user-tasks-list',
  templateUrl: 'ivr-assignment-user-tasks-list.tmpl.html',
})

export class ivrAssignmentUserTasksList implements OnInit, AfterViewInit {

  constructor(
    public ivrAssignmentUserTasksData: IvrAssignmentUserTasksData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog) {
  }
  displayedColumns: string[] = ['select', 'buttons', 'createDate', 'subject', 'comment', 'userName', 'contactName', 'doneByUserName', 'doneDate'];
  data: any[] = [];

  public objectsListResult: ObjectsListResult;
  searchIvrAssignmentUserTasksListForm: FormGroup;

  openSelectedRow(ivrAssignmentUserTaskID) {
    this.router.navigate(['ivrAssignmentUserTasks', ivrAssignmentUserTaskID, { outlets: { popup: ['edit'] } }]);
  }
  addNew() {
    this.router.navigate(['ivrAssignmentUserTasks', { outlets: { popup: ['add'] } }]);
  }

  exportToExcel() {
    this.ivrAssignmentUserTasksData.exportToExcel(this.searchIvrAssignmentUserTasksListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח משימות.xlsx`;
        link.click();
      });
  }

  public downloadPdf(): void {

    this.ivrAssignmentUserTasksData.downloadPdf(this.searchIvrAssignmentUserTasksListForm.value)
      .subscribe(blob => {
        // saveAs(blob, 'testing.pdf');
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `PDF.pdf`;
        link.click();
        // const data = new Blob([blob], { type: 'application/pdf' });
        // saveAs(data);
        // console.log(data);
      });
  }
  public changePage(page: number) {
    this.searchIvrAssignmentUserTasksListForm.controls['page'].setValue(page);
    this.getIvrAssignmentUserTasks();
  }

  public search() {
    this.getIvrAssignmentUserTasks();
  }

  private initForm() {
    this.searchIvrAssignmentUserTasksListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      hasDone: [false],
      selectedRows: [],
      subjects: [],
      userNames: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchIvrAssignmentUserTasksListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.ivrAssignmentUserTaskID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() selectedRowsChange = new EventEmitter<any>();
  selection = new SelectionModel<number>(true, []);

  sourceList;
  pageRows = 20;
  rowsCount;
  page;

  pageChange(e) {
    this.searchIvrAssignmentUserTasksListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }
  selectionChanged(e) {
    this.getIvrAssignmentUserTasks();
  }

  public clerFilter() {
    this.initForm();
    this.getIvrAssignmentUserTasks();
  }

  public getIvrAssignmentUserTasks() {
    let selectedsCtrl = this.searchIvrAssignmentUserTasksListForm.controls['selectedRows'];
    if (selectedsCtrl && selectedsCtrl.value) {
      let ivrAssignmentUserTaskIDs = selectedsCtrl.value.map(x => x).join();
    }

    if (this.paginator) {
      this.searchIvrAssignmentUserTasksListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    //this.searchIvrAssignmentUserTasksListForm.controls['pageRows'].setValue(this.paginator.pageSize);
    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchIvrAssignmentUserTasksListForm.value));
    return this.ivrAssignmentUserTasksData!.getList(this.searchIvrAssignmentUserTasksListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      });
  }



  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;


  ngOnInit() {
    if (!this.searchIvrAssignmentUserTasksListForm) {
      this.initForm();
    }

    //this.ivrAssignmentUserTasksData.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchIvrAssignmentUserTasksListForm.patchValue(frm);
    } 
  }


  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.ivrAssignmentUserTaskID === row.ivrAssignmentUserTaskID);
    if (found) {
      return true;
    }
    return false;
  }


  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  ngAfterViewInit() {
    this.getIvrAssignmentUserTasks();

    this.selection.isSelected = this.isChecked.bind(this);

  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getIvrAssignmentUserTasks();
  }

  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchIvrAssignmentUserTasksListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }
  pageEvent: PageEvent;
  pageSizeOptions;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchIvrAssignmentUserTasksListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}