import { MatDialog } from '@angular/material/dialog';
import { HoldingsPaymentEditDlg } from './holdings-payment-edit-dlg.cmp';
import { Commitment } from './../commitments/commitment/commitment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HoldingsData } from './holdings.data';

@Component({
    selector: 'holdings-list-small',
    templateUrl: './holdings-list-small.tmpl.html',
    styleUrls:['./holdings.scss']
})

export class HoldingsListSmall implements OnInit {
    data: any;
    constructor(
        private holdingsData: HoldingsData,
        private formBuilder: FormBuilder,
		public dialog: MatDialog,
    ) {
    }
    @Input('paymentID') public paymentID?: number | null = null;
    @Input('commitmentID') public commitmentID?: number | null = null;
    @Input('paymentCommitmentID') public paymentCommitmentID?: number | null = null;



    displayedColumns: string[] = ['amount', 'month', 'year', 'holdingContactName'];


    // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    // @ViewChild(MatSort, { static: true }) sort: MatSort;
    sourceList;
    pageRows = 20;
    rowsCount;
    page;
    searchHoldingsListForm: FormGroup;
    isLoadingResults: boolean = false;

    private getHoldings() {
        if (this.paymentID && this.paymentID > 0) {
            this.searchHoldingsListForm.controls['paymentID'].setValue(this.paymentID);
          }

          if (this.paymentCommitmentID && this.paymentCommitmentID > 0) {
            this.searchHoldingsListForm.controls['paymentCommitmentID'].setValue(this.paymentCommitmentID);
          }
          if (this.commitmentID && this.commitmentID > 0) {
            this.searchHoldingsListForm.controls['commitmentID'].setValue(this.commitmentID);
          }



            this.isLoadingResults = true;

        return this.holdingsData!.getSmallList(this.searchHoldingsListForm.value)
            .subscribe((data: any) => {
                this.data = data;
                this.sourceList = data.list;
                this.page = data.list.length;
                this.pageRows = data.list.length;
                this.rowsCount = data.rowsCount;
                this.isLoadingResults = false;
            });
    }

	editPaymentHoldingsDlg(): void {
        if(this.paymentCommitmentID)     {
            setTimeout(()=> {
                const dialogRef = this.dialog.open(HoldingsPaymentEditDlg, {
                    data:{  paymentCommitmentID : this.paymentCommitmentID}
                });


                dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.valid || result.status == "DISABLED" )) {
                        this.saveHoldingsPayment(result);
                    }
                });
            }, 200);
        }

    }

    private saveHoldingsPayment(saveHoldingsPaymentsForm: FormGroup) {
        this.holdingsData.saveHoldingsPayment(saveHoldingsPaymentsForm.getRawValue())
            .subscribe(() => {
				this.getHoldings();
				return true;
            })
    }


    private initForm() {
        this.searchHoldingsListForm = this.formBuilder.group({
            page: [1],
            pageRows: [30],
            paymentID: [],
            paymentCommitmentID: [],
            commitmentID: [],
            orders: this.formBuilder.array([])
        });
    }

    sortData(e) {
        this.addOrder(e.active, e.direction != "asc");
        this.getHoldings();
    }

    addOrder(propertyName: string, desc: boolean = false) {
        const ordersControl = <FormArray>this.searchHoldingsListForm.controls['orders'];

        let ord = this.getOrder(propertyName);
        if (ord != null) {
            ord.controls["value"].setValue(desc);
        } else {
            ord = this.initOrder(propertyName, desc);
            ordersControl.push(ord);
        }
    }

    initOrder(propertyName: string, desc: boolean = false) {
        let propName = propertyName;
        let des = desc;
        return this.formBuilder.group({
            key: [propName],
            value: [des],
        });
    }

    getOrder(columnName: string): any {
        let lst = <FormArray>this.searchHoldingsListForm.controls['orders'];
        if (lst == null) return null;

        for (var i = 0; i < lst.length; i++) {
            if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
                return lst.controls[i];
            }
        }
        return null;
    }
    pageChange(e) {
        this.searchHoldingsListForm.controls['pageRows'].setValue(e.pageSize || 20);
        this.getHoldings();
    }

    ngOnInit(): void {
        this.initForm();
        this.getHoldings();
    }
}
