import { TishPlace } from './tishPlace/tishPlace';
import { TishInvitedsData } from './../tishInviteds/tishInviteds.data';
import { Injectable, EventEmitter } from '@angular/core';

import { ToraxHttp } from '../utils/custom-http.svc';
import { Validators, FormBuilder } from '@angular/forms';


@Injectable()

export class TishPlacesData {

  private url = 'api/tishPlaces';

  constructor(
    private toraxHttp: ToraxHttp,
    private formBuilder: FormBuilder,
    private tishInvitedsData: TishInvitedsData
  ) { }

  movePlaces(tishTableID: number, doubleOrOdd: boolean, fromPlace: any, tillPlace: any, places: any) {
    return this.toraxHttp.get(`${this.url}/movePlaces/${tishTableID}/${doubleOrOdd}/${fromPlace}/${tillPlace}/${places}`);
  }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  // update(TishPlace: TishPlace): Promise<TishPlace> {
  //     return this.toraxHttp.postPromise(this.url, TishPlace);
  // }

  update(TishPlace: TishPlace) {
    return this.toraxHttp.postWithResponce(this.url, TishPlace);
  }

  getMap(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/map`);
  }

  getAdd(tishEventID: number) {
    return this.toraxHttp.get(`${this.url}/add/${tishEventID || 0}`);
  }

  getCountAdd(tishEventID: number) {
    return this.toraxHttp.get(`${this.url}/add/${tishEventID || 0}`);
  }

  public initForm() {
    return this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      tishPlaceID: [0, Validators.required],
      tishInvitedID: [],
      tishInvitedName: [],
      tishAreaID: [],
      tishAreaName: [],
      tishGroupID: [],
      tishGroupName: [],
      tishTableID: [0, Validators.required],
      tishTableName: [],
      placeNumber: [0],
      comment: [],
      isRight: [null],
      narrowTable: [],
      areaColor: [],
      tishInvited: this.tishInvitedsData.initForm(),
    });
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }

}
