<div class="input-group md-3" *ngIf="!smallTemplate; else smallTemplateView" [formGroup]="formGroupModel">
  <label *ngIf="withLabel">{{placeholder}}:</label>
  <!-- <input apply-enter [ngStyle]="{'width': width}" class="form-control" *ngIf="setFocus" #txtSearch
    cdkFocusInitial [matAutocomplete]="auto" [placeholder]="placeholder"
    (cdkAutofill)="isAutofilled = $event.isAutofilled" [formControl]="nameControl" /> -->
 <!-- <div style="padding:0; margin: 0;"  fxLayout="row"> -->
    <input apply-enter [ngStyle]="{'width': width}" class="form-control" [first-focus]="firstFocus" #txtSearch
    [matAutocomplete]="auto" [placeholder]="placeholder" (cdkAutofill)="isAutofilled = $event.isAutofilled"
    [formControl]="nameControl" />
    <!-- <mat-icon (click)="openDetails(row);">remove_red_eye</mat-icon> -->
  <!-- </div> -->
  <mat-autocomplete [panelWidth]="auto" [autoActiveFirstOption]="true" #auto="matAutocomplete" #matAutocomplete>
    <!-- <mat-option  value="">...</mat-option> -->
    <mat-option fxLayout="row" *ngIf="searchResults && searchResults.length > 0"
      style="border-bottom: solid; border-bottom-width:1px; font-weight: 500; white-space: nowrap;" value="">
      <div fxFlex="100" fxLayout="row" style="white-space: nowrap;">
        <span fxFlex="15" style="padding-left: 10px" fxLayout="column">מזהה</span>
        <span style="padding-left: 10px;" fxLayout="column">תיאור</span>
      </div>
    </mat-option>
    <mat-option style="white-space: nowrap;" *ngFor="let item of searchResults"
      (onSelectionChange)="setActive($event, item)" [ngClass]="{active: isActive(item)}" [value]="item.title"
      fxLayout="row">
      <div fxFlex="100" fxLayout="row" style="white-space: nowrap">
        <!-- <div fxFlex="20" fxLayout="column">{{ commitment.contactName }}</div> -->
        <span style="padding-left: 10px;" fxFlex="15" fxLayout="column">
          {{item.id}}
        </span>
        <span style="padding-left: 10px;" fxFlex fxLayout="column">
          {{ item.title }}
          <small *ngIf="withSubTitle">{{item.subTitle}}</small>
        </span>
      </div>
    </mat-option>
    <!-- <mat-option *ngFor="let item of searchResults" (onSelectionChange)="setActive($event, item)"
      [ngClass]="{active: isActive(item)}" [value]="item.title">
      [{{item.id}}] {{ item.title }}
    </mat-option> -->
  </mat-autocomplete>
</div>


<ng-template #smallTemplateView style="width: inherit;" [formGroup]="formGroupModel">
  <input apply-enter [ngStyle]="{'width': width}" class="form-control small-input" [first-focus]="firstFocus" #txtSearch
    [matAutocomplete]="auto" [placeholder]="placeholder" (cdkAutofill)="isAutofilled = $event.isAutofilled"
    [formControl]="nameControl" />
  <mat-autocomplete [panelWidth]="auto" [autoActiveFirstOption]="true" #auto="matAutocomplete" #matAutocomplete>
    <mat-option *ngFor="let item of searchResults" (onSelectionChange)="setActive($event, item)"
      [ngClass]="{active: isActive(item)}" [value]="item.title">
      <span> [{{item.id}}] {{ item.title }} </span>
      <br *ngIf="withSubTitle">
      <small *ngIf="withSubTitle">{{item.subTitle}}</small>
    </mat-option>
  </mat-autocomplete>
</ng-template>
