
import { filter, tap, take } from 'rxjs/operators';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DonationCategory } from './donationCategory';


@Component({
    selector: 'donationCategory-details-dlg',
    templateUrl: './donationCategory-details-dlg.tmpl.html'
})
export class DonationCategoryDetailsDlg {
  constructor(
    public matDialogRef: MatDialogRef<DonationCategoryDetailsDlg>,
    @Inject(MAT_DIALOG_DATA)
    public data: DonationCategory ,
    router: Router
) {

    // Close dialog ref on route changes
    router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.matDialogRef.close()),
      take(1),
    ).subscribe();
  }


}

