export class TishGroup {
    tishGroupID: number;
    contactID: number;
    creditNumber: number;
    expiryMonth: number;
    expiryYear: number;
    tishEventID;
    tishEventTitle;
    // billingType: number;
    // currency :number;
    // currencyIcon: string;

}


