import { Group } from './../contacts/contact/contact';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class GroupsData {
    private url = 'api/groups';

    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }


    get(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(Group: Group) {
        return this.toraxHttp.update(this.url, Group);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
