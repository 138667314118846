import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { ObjectsListResult } from '../services/objects-list-results';
import { PayMethod } from './payMethod/payMethod';
import { AlertCenterService } from '../components/alert-center/alert-center.svc';
import { tap } from 'rxjs';

@Injectable()

export class PayMethodsData {

  public url = 'api/payMethods';
  constructor(
    private toraxHttp: ToraxHttp,
    public alertCenterService: AlertCenterService,
  ) { }


  exportExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(payMethod: PayMethod) {
    return this.toraxHttp.post(this.url, payMethod).pipe(
      tap(
        data => {
          this.alertCenterService.info((data as any)?.item3);
        }
      ));
  }


  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`).pipe(
      tap(
         (data) =>  this.alertCenterService.info((data as any)?.item3),

         (error) => {
          this.alertCenterService.error((error as any)?.item3);
        },

      ), );
  }

  post(payMethod: PayMethod) {
    return this.toraxHttp.post(this.url, payMethod);
  }

  getAdd(contactID: number, paymentOption: number) {
    return this.toraxHttp.get(`${this.url}/add/${contactID || 0}/${paymentOption}`);
  }

  convertToToken(payMethod: PayMethod) {
    return this.toraxHttp.update(`${this.url}/convertToToken`, payMethod);
  }



  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
      if (this.updatedEvent) {
          this.updatedEvent.emit(id);
      }
  }
}
