<mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
        <h1 mat-dialog-title>ריכוז הדפסת קבלות</h1>
    </mat-toolbar-row>
</mat-toolbar>
<div mat-dialog-content [formGroup]="formData">
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
            <label>נושא:</label>
            <select class="form-control" apply-enter placeholder="נושא:"
                [formControl]="formData.controls.commitmentSubject">
                <option [value]="">...</option>
                <option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                    {{cs.name}}</option>
            </select>
        </div> &nbsp;
        <div class="input-group mb-3" *ngIf="formData.controls.commitmentSubject.value == 6">
            <label>תחום:</label>
            <select class="form-control" apply-enter placeholder="תחום:"
                [formControl]="formData.controls.registrationEventType">
                <option [value]="">...</option>
                <option *ngFor="let rt of servicesData.RegistrationEventTypes" [value]="rt.value">
                    {{rt.name}}</option>
            </select>
        </div>
    </div>
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
            <label>תוכניות תשלום...</label>
            <mat-select class="form-control" placeholder="תוכניות תשלום..." formControlName="withPaymentsPlan">
                <mat-option [value]="">הכל</mat-option>
                <mat-option [value]="true">כולל</mat-option>
                <mat-option [value]="false">לא כולל</mat-option>
            </mat-select>
        </div>
    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="onNoClick()">ביטול</button>
        <button mat-button [mat-dialog-close]="formData">צור</button>
    </div>
</div>