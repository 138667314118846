import { Process } from './process/process';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ProcessesData } from './processes.data'; 
import { ObjectsListResult, ObjectListItem } from '../services/objects-list-results';
//import { Ng2SmartTableModule } from 'ng2-smart-table';

@Component({
	
	selector: 'processes-list',
	templateUrl: './processes-list.tmpl.html'
})

export class ProcessesList implements OnInit {
	constructor(
		private processesData: ProcessesData,
		private formBuilder: FormBuilder
	) {
	}
	public objectsListResult: ObjectsListResult;
	//public processes: any[];
	searchProcessesListForm: FormGroup;

	public query: string | null = null;
	public freeTextQuery: string;

	public getProcesses(): void {
		this.processesData.getList(this.searchProcessesListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			error => { });
	}

	public changePage(page: number) {
		this.searchProcessesListForm.controls['page'].setValue(page);
		this.getProcesses();
	}

	public search() {
		this.getProcesses();
	}

	private initForm() {
		this.searchProcessesListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			selectedRows:[],
			orders: this.formBuilder.array([])
		});
	}
	ngOnInit(): void {
		this.initForm();
		this.getProcesses();
	}



	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchProcessesListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
		this.getProcesses();
	}

	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}

	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchProcessesListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>(lst.controls[i])).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}

	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}

}
