import { HoldingsPrintHtmlDlg } from './../../holdings/holdings-print-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { HoldingsData } from './../../holdings/holdings.data';
import { HoldingsPrintDetailsMdl } from './../../holdings/holding/holding';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { ShtibelsData } from '../shtibels.data';
import { Shtibel } from './shtibel';
import { HoldingsPrintsList } from 'src/app/holdings/holdings-prints-list.cmp';
import { ServicesData } from 'src/app/services/services.data';

@Component({
	
	selector: 'shtibel-details',
	templateUrl: './shtibel-details.tmpl.html'
})

export class ShtibelDetails implements OnInit {
	constructor(
		private shtibelsData: ShtibelsData,
		private holdingsData: HoldingsData,		
		private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private toraxHttp: ToraxHttp,
        public servicesData: ServicesData,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.shtibel && id === this.shtibel.shtibelID) {
				this.loadShtibel();
			}
		});
	}
	
	public shtibel: Shtibel;
	private id: number;
	@ViewChild("holdingsPrintList", { static: false }) holdingsPrintList: HoldingsPrintsList;
 
	 
	public loadShtibel(): void {
		this.shtibelsData.get(this.id)
			.subscribe((c:any) => this.shtibel = c);
	}


	    
	openHoldingsPrintDlg(): void {
		  const dialogRef = this.dialog.open(HoldingsPrintHtmlDlg, {
			data: {
                shtibelID: this.id,				
                shtibelName: this.shtibel?.shtibelName
            }
		  });
  
		  dialogRef.afterClosed().subscribe(result => {
			  if (result && result.value) { 
				  this.printHoldingsHtml(result.value);
			  }
		  });  
	  }
	  
	  private printHoldingsHtml = (holdingsPrintHtmlDlg: any): void => {
		    if (holdingsPrintHtmlDlg) {
			  this.holdingsData.printHoldingsHtml(<HoldingsPrintDetailsMdl>holdingsPrintHtmlDlg)
				  .subscribe(data => {
						  let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
						  popupWinindow.document.open();
						  popupWinindow.document.write((<any>data).value);
						  popupWinindow.document.close();
						  if(this.holdingsPrintList){
							  this.holdingsPrintList.getHoldingsPrintedGroup();
						  }
						  return true;
				  });
		  }
	  }

	  
	public downloadHoldingsPivotReportExls(year: number, shtibelID?: number): void {
		this.shtibelsData.downloadHoldingsPivotReportExls(year, shtibelID)
			.subscribe(blob => {
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `דוח החזקת ביהמ''ד שטיבל.xlsx`;
				link.click();
			});
	}


	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['shtibelID'];
			this.loadShtibel();
		});
	}
}
