import { AuthenticationService } from './../_guards/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';

import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TishTablesData } from './tishTables.data';

import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';

import { AlertService } from '../components/alert/alert.svc';
//import * as signalR from '@aspnet/signalr';

@Component({
  selector: 'tishTables-list',
  templateUrl: 'tishTables-list.tmpl.html',
})

export class TishTablesList implements OnInit, AfterViewInit {

  constructor(
    public tishTablesData: TishTablesData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private authService: AuthenticationService,

    private alert: AlertService,
    private cdref: ChangeDetectorRef) {
  }


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('eventID') public eventID?: number;
  @Input('groupID') public groupID?: number;

  @Output() selectedRowsChange = new EventEmitter<any>();

  selectedRow: any;
  viwSelectedRow: boolean = false

  orderButtonIsChicked: boolean = false;
  selection = new SelectionModel<number>(true, []);
  resultsLength = 0;
  stopLoadingPoints: boolean = false;
  isLoadingResults = false;
  isRateLimitReached = false;
  searchTishTablesListForm: FormGroup;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  pageEvent: PageEvent;
  pageSizeOptions;
  displayedColumns: string[] = ['buttons', 'tishTableID', 'tishTableName', 'countPlaces', 'countInviteds', 'tishEventTitle', 'comment'];


  data: any[] = [];
  addNew() {
    this.router.navigate(['', { outlets: { popup: ['tishTables', 'add'] } }]);
  }
  onlySortRecentlyOpenedByCurUser = false;
  topCurUserOpenings() {
    this.onlySortRecentlyOpenedByCurUser = !this.onlySortRecentlyOpenedByCurUser;
    this.searchTishTablesListForm.controls['onlySortRecentlyOpenedByCurUser'].setValue(this.onlySortRecentlyOpenedByCurUser);

    this.getTishTables();

  }
  topOpenings() {
    this.orderButtonIsChicked = !this.orderButtonIsChicked;
    if (this.orderButtonIsChicked) {
      this.addOrder('openingDateTime', true, 1);
    } else {
      this.removeOrder('openingDateTime');
    }

    this.getTishTables();
  }


  openSelectedRow(tishTableID) {
    this.router.navigate(['', { outlets: { popup: ['tishTables', tishTableID] } }]);
  }
  setSelectedRow(row) {
    this.viwSelectedRow = false;
    this.selectedRow = null;
    setTimeout(() => {
      this.selectedRow = row;
      this.viwSelectedRow = true;
    }, 150);
  }
  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.tishTableID === row.tishTableID);
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  pageChange(e) {
    this.searchTishTablesListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getTishTables();
  }

  public clerFilter() {
    this.initForm();
    this.getTishTables();
  }

  public getTishTables() {
    if (this.paginator) {
      this.searchTishTablesListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    if (this.eventID && this.eventID > 0) {
      this.searchTishTablesListForm.controls['eventID'].setValue(this.eventID);
    }

    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchTishTablesListForm.value));
    return this.tishTablesData!.getList(this.searchTishTablesListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;

        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;

        //trick
        this.stopLoadingPoints = false;


        this.selection.isSelected = this.isChecked.bind(this);
      });
  }

  initForm() {
    this.searchTishTablesListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      eventID: [],
      areaID: [],
      groupID: [],
      tishTableName: [],
      tishTableID: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchTishTablesListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.tishTableID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchTishTablesListForm.patchValue(frm);
    }
    this.getTishTables();
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getTishTables();
  }

  public search() {
  }

  removeOrder(propertyName: string) {
    const ordersControl = <FormArray>this.searchTishTablesListForm.controls['orders'];
    let i = null;
    ordersControl.controls.forEach((ctrl, ix) => {
      if ((<any>ctrl).value.key == propertyName) {
        i = ix;
      }
    });
    if (i >= 0) {
      ordersControl.removeAt(i);
    }
  }

  addOrder(propertyName: string, desc: boolean = false, priorityOrder: number = null) {
    const ordersControl = <FormArray>this.searchTishTablesListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      if (priorityOrder >= 1) {
        ordersControl.insert(priorityOrder - 1, ord);
      } else {

        ordersControl.push(ord);
      }
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchTishTablesListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}
