<div *ngIf="tishTable" class="row">
    <div class="col-sm-6">
        <panel-box title="פרטי שולחן: [{{ id }}]" [showClose]="false" [showCollapse]="false">
            <span class="panel-tools">
                <a [routerLink]="['/tishTables', {outlets: {popup: [ id, 'edit']}}]">
                    <i class="fa fa-pencil"></i>
                </a>
            </span>
            <div class="row">
                <dl class="dl-horizontal">
                    <dt>שם אירוע</dt>
                    <dd>{{ tishTable.tishEventTitle }}</dd>
                    <hr>
                    <dt>שם שולחן</dt>
                    <dd>{{ tishTable.tishTableName }}</dd>
                    <hr>
                    <dt>מס' מקומות</dt>
                    <dd>{{ tishTable.countPlaces }}</dd>
                    <hr>
                    <dt>מס' משובצים</dt>
                    <dd>{{ tishTable.countInviteds }}</dd>
                    <hr>
                    <dt>הערה</dt>
                    <dd>{{ tishTable.comment }}</dd>
                    <hr>
                </dl>
            </div>
        </panel-box>
    </div>
    <div class="col-sm-6">
        <tishPlaces-list [tableID]="tishTable.tishTableID">Loading...</tishPlaces-list>
    </div>


</div>
