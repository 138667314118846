import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { VouchersOrganizationsData } from './vouchersOrganizations.data'; 
import { ObjectsListResult } from '../services/objects-list-results';

@Component({
	
	selector: 'vouchersOrganizations-list',
	templateUrl: './vouchersOrganizations-list.tmpl.html'
})
export class VouchersOrganizationsList implements OnInit {
	constructor(
		private vouchersOrganizationsData: VouchersOrganizationsData,
		private formBuilder: FormBuilder
	) {
	}
	//	@Input('vouchersOrganizationID') public vouchersOrganizationID?: number;

	public objectsListResult: ObjectsListResult;
	searchVouchersOrganizationsListForm: FormGroup;

	public query: string | null = null;
	public freeTextQuery: string;


	public getVouchersOrganizations(): void {
		//this.searchVouchersOrganizationsListForm.controls['vouchersOrganizationID'].setValue(this.vouchersOrganizationID);
		
		this.vouchersOrganizationsData.getList(this.searchVouchersOrganizationsListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			error => { });
	}

	public changePage(page: number) {
		this.searchVouchersOrganizationsListForm.controls['page'].setValue(page);
		this.getVouchersOrganizations();
	}

	public search() {
		this.getVouchersOrganizations();
	}

	private initForm() {
		this.searchVouchersOrganizationsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [],
			selectedRows:[],
			orders: this.formBuilder.array([])
		});
	}

	ngOnInit(): void {
		this.initForm();
		this.getVouchersOrganizations();
	}
}