import { ToraxHttp } from './../utils/custom-http.svc';
import { FormGroup } from '@angular/forms';
import { Injectable, EventEmitter } from '@angular/core';
import { IvrDonationDetail } from './ivrDonationDetail/ivrDonationDetail';

@Injectable()

export class IvrDonationDetailsData {
    private url = 'api/ivrDonationDetails';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getGroupIvrDonationDetailsList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/groupIvrDonationDetailsList`, baseQuery);
    }

    getIvrDonationDetailsDebitBalance(id: number) {
        return this.toraxHttp.getWithResponce(`${this.url}/getIvrDonationDetailsDebitBalance/${id || 0}`);
    }


    getAudioByts(ivrDonationDetail: number) {
      let headers = new Headers();
      headers.append('Content-Type', 'audio/wav');
      return this.toraxHttp.postAndDownload(`${this.url}/getAudioByts/${ivrDonationDetail}`,{});
  }

  getTargetAudioByts(ivrDonationDetail: number) {
    let headers = new Headers();
    headers.append('Content-Type', 'audio/wav');
    return this.toraxHttp.postAndDownload(`${this.url}/getTargetAudioByts/${ivrDonationDetail}`,{});
}

    exportExcel(baseQuery: any)  {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
    }

    exportGroupIvrDonationDetailsExcel(baseQuery: any)  {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadGroupIvrDonationDetailsExls`, baseQuery);
    }


    get(id: number) {
      return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }


  getEventType(evntID: number) {
    return this.toraxHttp.get(`${this.url}/getEventType/${evntID || 0}`);
}

    getTopUpdatesInCashRegister(eventId: number) {
        return this.toraxHttp.get(`${this.url}/getTopUpdatesInCashRegister/${eventId || 0}`);
    }

    getIvrDonationDetailRow(ivrDonationDetailID: number, contactID: number) {
        return this.toraxHttp.getWithResponce(`${this.url}/getIvrDonationDetailRow/${ivrDonationDetailID || 0}/${contactID || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(ivrDonationDetail: IvrDonationDetail) {
        return this.toraxHttp.update(this.url, ivrDonationDetail);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    postUpdateCashRegister(updateCashRegisterForm: FormGroup) {
        return this.toraxHttp.update(`${this.url}/addCashRegisterPayment`, updateCashRegisterForm);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

