<div *ngIf="data">
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            {{data.contact.firstName}} {{data.contact.lastName}}<br>
            <small>התחייבויות {{subjectName}} </small>
        </h2>
    </div>
    <mat-dialog-content style="padding: 0px  !important;">
        <div  loading-status [stopLoadingWhen]="commitmentsList">
            <ul class="list-group">
                <li style="padding: 0px !important;" class="list-group-item" *ngFor="let commitment of commitmentsList">
                    <commitment-details-view [commitment]="commitment"></commitment-details-view>
                </li>
            </ul>

        </div>
        <ng-template #loading>
            <h4>
                <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
            </h4>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="center">
        <button class="btn btn-warning navbar-btn" [mat-dialog-close]="">
            <i style="padding: 3px;" class="fa fa-arrow-right"></i>
            <span class="example-fill-remaining-space"></span>
            חזור
        </button>
    </mat-dialog-actions>


</div>