import { ToraxHttp } from '../../utils/custom-http.svc';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class NavbarTopData {
    private url = 'api/navbarTop';


    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    getPendingUsaReceipts(){                 
        return this.toraxHttp.get(`${this.url}/pendingUsaReceipts`);
    }
    getPendingUkReceipts(){
        return this.toraxHttp.get(`${this.url}/pendingUkReceipts`);
    }
    getPendingReceipts(){
        return this.toraxHttp.get(`${this.url}/pendingReceipts`);
    }
    getWaitingActivities(uid: string){
        return this.toraxHttp.get(`${this.url}/waitingActivities?uid=${uid}`);
    }
    // getPending_IL_PaymentsForReceipts(){
    //     return this.toraxHttp.get(`${this.url}/pending_IL_PaymentsForReceipts`);
    // }
    // getPending_USA_PaymentsForReceipts(){
    //     return this.toraxHttp.get(`${this.url}/pending_USA_PaymentsForReceipts`);
    // }
    // getPending_UK_PaymentsForReceipts(){
    //     return this.toraxHttp.get(`${this.url}/pending_UK_PaymentsForReceipts`);
    // }
}
