//import { saveAs } from 'file-saver-es';
import { Payment } from 'src/app/payments/payment/payment';
import { AlertService } from './../components/alert/alert.svc';
import { SelectionModel } from '@angular/cdk/collections';
import { PaymentDetailsDlg } from './payment/payment-details-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsData } from './payments.data';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from '../utils/custom-http.svc';
import { IndexService } from '../services/index-service';
import { DateRangeClass } from '../components/dateInput-range/date-input-range.class';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SubscriptionLike } from 'rxjs';
import { MatSelectMultipleDropdownComponent } from '../components/mat-select-multiple-dropdown/mat-select-multiple-dropdown.component';
import { AutoCompleteInputComponent } from '../components/auto-complete-input/auto-complete-input.cmp';

@Component({
  selector: 'payments-list',
  templateUrl: 'payments-list.tmpl.html',
  styleUrls: ['./payments.scss'],
  encapsulation: ViewEncapsulation.None


})

export class PaymentsList implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public paymentsData: PaymentsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService,
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/payments') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  @ViewChild('subjectsSelect') subjectsSelect: MatSelectMultipleDropdownComponent;

  @ViewChild('designationCmp') designationCmp: AutoCompleteInputComponent;



  @Input('contactID') public contactID?: number | null = null;
  @Input('payMethodID') public payMethodID?: number | null = null;
  @Input('waitingToDeposits') public waitingToDeposits?: boolean | null = null;
  @Input('waitingToFutureDeposits') public waitingToFutureDeposits?: boolean | null = null;
  @Input('depositID') public depositID?: number | null = null;
  @Input('paymentGroupID') public paymentGroupID?: number | null = null;
  @Input('commitmentID') public commitmentID?: number | null = null;
  @Input('paymentsPlanID') public paymentsPlanID?: number | null = null;
  @Input('includeRelatedPayments') public includeRelatedPayments: boolean = true;
  @Input('vouchersOrganizationID') public vouchersOrganizationID?: number | null = null;
  @Input('vouchersOrgPaymentID') public vouchersOrgPaymentID?: number | null = null;
  @Input('statusPayments') public statusPayments?: number[] = null;

  public selection = new SelectionModel<number>(true, []);
  @Output() selectedRowsChange = new EventEmitter<any>();
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  orderButtonIsChicked: boolean = false;
  expandedRowID;
  yearsList: number[];
  fullViewMode: boolean = true;
  stopLoadingPoints: boolean = false;
  defaultvalues;
  selectedItems;
  groupingColumn = 'isPastDate';
  groupingOrderColumn = 'billingDate';
  paymentOptionsList = [
    { value: 1, name: "אשראי" },
    { value: 2, name: "מזומן" },
    { value: 3, name: "שיקים" },
    { value: 4, name: "הוראות קבע" },
    { value: 5, name: "העברה בנקאית" },
    { value: 6, name: "קופה רושמת" },
    { value: 7, name: "Vouchers" },
    { value: 8, name: "תוכנה חיצונית" },
    { value: 9, name: "שווי כסף" }
  ];
  exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];
  panelOpenState = false;
  currency = null;
  treatmentsDays = null;
  accountLocation = null;
  statusPayment = null;
  searchPaymentsListForm: FormGroup;
  togleTT = false;
  resultsLength = 0;
  isRateLimitReached = false;
  indexService: IndexService;
  actualMinusHeight = '185';


  subjectCommitmentReasons: [];


  public get displayedColumns(): string[] {
    let fullViewColumns = ['select', 'buttons', 'paymentID', 'contactName', 'commitmentID', 'commitmentSubject', 'amount', 'calculatedAmountInILS', 'doneOn', 'billingDate', 'payMethodID', 'paymentsPlanID', 'statusPayment', 'transactionID', 'receiptNumber', 'vouchersOrgPaymentID', 'responsible'];
    let shortViewColumns = ['select', 'buttons', 'commitmentSubject', 'amount', 'billingDate', 'payMethodID', 'statusPayment'];
    if (this.contactID) {
      fullViewColumns.splice(fullViewColumns.indexOf('contactID'), 1);
      fullViewColumns.splice(fullViewColumns.indexOf('contactName'), 1);
    }
    if (this.paymentsPlanID) {
      fullViewColumns.splice(fullViewColumns.indexOf('contactID'), 1);
      fullViewColumns.splice(fullViewColumns.indexOf('contactName'), 1);
      fullViewColumns.splice(fullViewColumns.indexOf('paymentsPlanID'), 1);
    }
    if (this.commitmentID) {
      fullViewColumns.splice(fullViewColumns.indexOf('contactID'), 1);
      fullViewColumns.splice(fullViewColumns.indexOf('contactName'), 1);
      fullViewColumns.splice(fullViewColumns.indexOf('commitmentID'), 1);
      fullViewColumns.splice(fullViewColumns.indexOf('commitmentSubject'), 1);
      shortViewColumns.splice(shortViewColumns.indexOf('commitmentSubject'), 1);
    }
    if (this.fullViewMode != undefined && !this.fullViewMode) {
      return shortViewColumns;
    } else {
      return fullViewColumns;
    }
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ['add'] } }]);
  }

  showPmTrans() {
    this.togleTT = this.togleTT
  }

  toggleExpandableSymbol(id): void {
    this.expandedRowID = (this.expandedRowID === id
      ? null
      : id);
  }

  toogleFullView() {
    this.fullViewMode = !this.fullViewMode;
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.paymentID === row.paymentID);
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  public createAndPrintReceipt(row): void {
    let confirmMargeReceipt = true;
    if (!row.receiptNumber && row.paymentGroupID && row.paymentGroupID > 0) {
      confirmMargeReceipt = confirm("תשלום זה הינו חלק מסידרת תשלומים\nהאם ברצונך להפיק קבלה מרוכזת לכל הסידרה?")
    }

    let doDownload = false;
    this.paymentsData.createAndPrintReceipt(row.paymentID, doDownload, (confirmMargeReceipt ? row.paymentGroupID : null))
      .subscribe(blob => {
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html style="background-image: url(\'usaReceiptBackround.jpg\');" ><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { } </style></head><body onload="window.print()"><div>' + (<any>blob).value + '</div></body></html>');
        popupWinindow.document.close();
        this.toraxHttp.updatedEvent.emit(row.paymentID);
      });
  };



  // public createAndDownloadReceipt(row): void {
  //   let confirmMargeReceipt = true;
  //   if (!row.receiptNumber && row.paymentGroupID && row.paymentGroupID > 0) {
  //     confirmMargeReceipt = confirm("תשלום זה הינו חלק מסידרת תשלומים\nהאם ברצונך להפיק קבלה מרוכזת לכל הסידרה?")
  //   }

  //   let doDownload = true;
  //   this.paymentsData.createAndPrintReceipt(row.paymentID, doDownload, (confirmMargeReceipt ? row.paymentGroupID : null))
  //     .subscribe((blob: any) => {
  //       var blb = new Blob([blob], { type: "application/pdf;charset=utf-8" });
  //       saveAs(blb, `קבלה תשלום מס' ${row.paymentID}.pdf`);
  //     });
  // };


  public createAndPrintPaymentLetter(row): void {
    this.paymentsData.createAndPrintPaymentLetter(row.paymentID)
      .subscribe(blob => {
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html style="background-image: url(\'usaReceiptBackround.jpg\');" ><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { } </style></head><body onload="window.print()"><div>' + (<any>blob).value + '</div></body></html>');
        popupWinindow.document.close();
        this.toraxHttp.updatedEvent.emit(row.paymentID);
      });
  };

  openSelectedRow(paymentID) {
    this.router.navigate(['', { outlets: { popup: ['payments', paymentID] } }]);
  }

  openDetails(payment: Payment) {
    this._matDialog.open(PaymentDetailsDlg, {
      panelClass: 'payment-details',
      data: payment //.paymentCommitments.map(x => x.paymentCommitmentID)
    });
  }

  public getPaymentsReport(all: boolean = false): void {
    if (all) {
      this.searchPaymentsListForm.controls['includeAllColumns'].setValue(true);
    }
    this.paymentsData.exportExcel(this.searchPaymentsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח תשלומים.xlsx`;
        link.click();
      });
  }

  refreshPaymentCurrenciesRate(paymentID) {
    this.paymentsData.refreshPaymentCurrenciesRate(paymentID)
      .subscribe((x: any) => {
        this.indexService.getList();
        this.alert.success(x.message);
      }), err => {
        this.alert.success(err.message);
      };
  }
  fieldForPaymentSearch = 'RelevantPaymentDate';
  // canLoadDetails: boolean = false;
  updateDateControl(e) {
    console.log(e);
    this.fieldForPaymentSearch = e.value;
    switch (e.value) {
      case 'doneOn':
        this.searchPaymentsListForm.controls['billingDateRange'].setValue(null);
        this.searchPaymentsListForm.controls['relevantStatusPayments'].setValue(null);
        break;
      case 'billingDate':
        this.searchPaymentsListForm.controls['doneOnRange'].setValue(null);
        this.searchPaymentsListForm.controls['relevantStatusPayments'].setValue(null);
        break;
      default:
        this.searchPaymentsListForm.controls['billingDateRange'].setValue(null);
        this.searchPaymentsListForm.controls['doneOnRange'].setValue(null);
        break;
    }
  }
  designationDetailsList: any[];
  loadDesignationDetails(e) {
    if (e) {
      this.servicesData.getList(`DesignationDetails//${e}`).subscribe((x: any[]) => {
        this.designationDetailsList = x;
      });
    } else {
      this.designationDetailsList = [];

    }
  }
  private initForm() {
    this.searchPaymentsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [30],
      query: [],
      contactID: [],
      selectedRows: [],
      contactName: [],
      commitmentID: [],
      paymentsPlanID: [],
      shtibelID: [],
      payMethodID: [],
      depositID: [],
      designation: [],
      designationDetails: [],
      friendsInStiebel: [],
      shtibelName: [],
      daysBack: [null],
      isActive: [null],
      withPaymentsPlan: [null],
      paymentsForTreatment: [false, Validators.required],
      paymentOptions: [null],
      commitmentReasonID: [null],
      billingDateRange: [null],
      amountRange: [null],
      doneOnRange: [null],
      vouchersOrganizationID: [null],
      voucherNumber: [null],
      organizationName: [],
      vouchersOrgPaymentID: [null],
      termID: [null],
      commitmentSubjects: [],
      accountLocations: [],
      statusPayments: [null],
      groupIdsToExludeContacts: [null],
      groupIdsToIncludeContacts: [null],
      paymentID: [null],
      paymentIDs: [],
      currency: [null],
      year: [null],
      waitingToDeposits: [null],
      waitingToFutureDeposits: [null],
      relevantPaymentDateRange: [null],
      relevantStatusPayments: [null],
      isClosedCommitment: [null],
      isClosedPaymentsPlan: [null],
      includeRelatedPayments: [true, Validators.required],
      includeAllColumns: [false, Validators.required],
      orders: this.formBuilder.array([])
    });




    //this.searchPaymentsListForm.controls['orders'].setValue(['billingDate']);

    this.selection.changed.subscribe(x => {
      this.searchPaymentsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.paymentID));
      this.selectedRowsChange.emit(x.source.selected);
    });

    this.searchPaymentsListForm.controls['year'].valueChanges.subscribe(x => {
      if (this.indexService) {
        this.indexService.getList();
      }
    });


    this.searchPaymentsListForm.controls['includeRelatedPayments'].valueChanges.subscribe(x => {
      if (this.indexService) {
        //this.indexService.getList();
      }
    });
    this.searchPaymentsListForm.controls['commitmentSubjects'].valueChanges.subscribe(x => {
      if (x && x.length) {
        this.servicesData.getList(`CommitmentReasons/${x}`)
          .subscribe(
            (r: any) => {
              this.subjectCommitmentReasons = r;
            },
            error => { });
      }
    });


    let frm = this.searchPaymentsListForm;
    this.defaultvalues = Object.keys(this.searchPaymentsListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});


  }

  topOpenings() {
    this.orderButtonIsChicked = true;
    this.indexService.addOrder(this.groupingOrderColumn, true);
  }

  isLink(pmId) {
    return this.exludePayMethodsIDs.indexOf(pmId) < 0;
  }

  toogleState() {
    this.panelOpenState = !this.panelOpenState;
    this.actualMinusHeight = this.panelOpenState ? `${(+this.actualMinusHeight) + (242)}` : this.actualMinusHeight;
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();


    this.activatedRoute.params.forEach((params: Params) => {
      let treatmentsDays = +params['treatmentsDays'] || 0;
      if (treatmentsDays > 0) {

        let dateRange = new DateRangeClass();
        let date = new Date();
        date.setDate(date.getDate() - treatmentsDays);
        dateRange.start = date;
        this.searchPaymentsListForm.controls['relevantPaymentDateRange'].setValue(dateRange);


        this.searchPaymentsListForm.controls['paymentsForTreatment'].setValue(true);
        this.searchPaymentsListForm.controls['currency'].setValue(+params['currency'] || null);

        let al = +params['accountLocation'];
        if (al) {
          this.searchPaymentsListForm.controls['accountLocations'].setValue(al);
        }

        let rs = +params['relevantStatus'];
        if (rs) {
          this.searchPaymentsListForm.controls['relevantStatusPayments'].setValue(rs);
        }

      }
    });

    if (this.toraxHttp.previousUrl == "/dashboard/dashboardDirector") {
      let item = sessionStorage.getItem("dashboard/payments/dbPayments");
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem("dashboard/payments/dbPayments") || "");
        this.searchPaymentsListForm.patchValue(frm);
      }
    } else if (this.toraxHttp.previousUrl == "/dashboard" || this.toraxHttp.previousUrl == "/") {

      let item = sessionStorage.getItem("dashboard/payments/dbCollectionStatus");
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem("dashboard/payments/dbCollectionStatus") || "");
        this.searchPaymentsListForm.patchValue(frm);
        //this.searchPaymentsListForm.controls.relevantPaymentDateRange.setValue(frm.relevantPaymentDateRange);
        sessionStorage.removeItem("dashboard/payments/dbCollectionStatus"); //<=== מחיקה

      }

    } else if (document.location.pathname.indexOf('treatmentsPayments') == -1 && this.contactID == null && this.commitmentID == null && this.paymentsPlanID == null && this.vouchersOrganizationID == null) {

      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchPaymentsListForm.patchValue(frm);
      }
    } else {

    }

    if (this.statusPayments) {
      let lst: Array<number> = [];
      this.statusPayments.forEach(sp => {
        lst.push(sp);
      });

      this.searchPaymentsListForm.controls.statusPayments.setValue(lst);
    }

    this.searchPaymentsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      { key: "contactID", value: this.contactID },
      { key: "payMethodID", value: this.payMethodID },
      { key: "waitingToDeposits", value: this.waitingToDeposits },
      { key: "waitingToFutureDeposits", value: this.waitingToFutureDeposits },
      { key: "depositID", value: this.depositID },
      { key: "paymentGroupID", value: this.paymentGroupID },
      { key: "commitmentID", value: this.commitmentID },
      { key: "paymentsPlanID", value: this.paymentsPlanID },
      { key: "includeRelatedPayments", value: this.includeRelatedPayments },
      { key: "vouchersOrganizationID", value: this.vouchersOrganizationID },
      { key: "vouchersOrgPaymentID", value: this.vouchersOrgPaymentID },
      { key: "statusPayments", value: this.statusPayments },
    ];



    this.indexService = new IndexService(this.searchPaymentsListForm, params, this.paymentsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.topOpenings();
    this.indexService.OnLoaddedEnd.subscribe(x => this.setFocus(x));

    this.loadDesignationDetails(this.searchPaymentsListForm.controls['designation'].value);

    this.indexService.getList();

    this.loadYearList();

    if (this.commitmentID || this.paymentsPlanID || this.vouchersOrganizationID) {
      this.actualMinusHeight = '460';
    }
    else if (this.contactID) {
      this.actualMinusHeight = '362';
    }
    else if (this.payMethodID) {
      this.actualMinusHeight = '248';
    }
  }

  setFocus(e) {
    setTimeout(x => {

      let ix1 = this.indexService.sourceList?.findIndex(row => row.groupName == "isPastDate false");
      if (this.viewPort) {
        this.viewPort.scrollToIndex(ix1);
      }
    }, 500)
  }

  loadYearList() {
    this.paymentsData.paymentsYears(this.searchPaymentsListForm).subscribe((x: any) => {
      this.yearsList = x;
    });
  }

  // includeRelatedPaymentsChanged(e) {
  //   this.includeRelatedPayments = !this.includeRelatedPayments;
  //   this.searchPaymentsListForm.controls['includeRelatedPayments'].setValue(this.includeRelatedPayments);
  //   this.indexService.getList();
  // }
}

