<div *ngIf="commitment">
  <div class="modal-header">
    <div class="row">
      <div style="padding: 0px;" class="col-sm-12">
        <div fxLayout="row" style="direction: ltr; border-bottom: solid 1px;">
          <!-- <a *ngIf="commitment.commitmentClosedDate == null" class="btn btn-primary" (click)="openReciptsForCommitmentDlg()">
                          <i class="fa fa-plus"></i>&nbsp;צור והדפס קבלות מרוכזת</a> -->
          <a class="btn btn-primary"
            [ngStyle]="{'border-color':(!commitment.closedDate ? 'red' : ''), 'color':(!commitment.closedDate ? 'red' : ''), 'background-color':(!commitment.closedDate ? 'white': '')}"
            (click)="openClosedCommitmentDlg()">
            <i style="color: red;" class="fa fa-ban"></i>&nbsp;{{ commitment.closedDate ? 'פרטי ' : 'סיום ו'
            }}סגירת
            התחייבות</a>

          <a *ngIf="commitment.commitmentClosedDate == null" class="btn btn-primary"
            [routerLink]="['', {outlets: {popup: ['commitments', id, 'payments', 'add']}}]">
            <i class="fa fa-plus"></i>&nbsp;הוסף תשלום</a>

          <a *ngIf="commitment.commitmentClosedDate == null" class="btn btn-primary"
            [routerLink]="['', {outlets: {popup: ['commitments', id, 'paymentsPlans', 'add']}}]">
            <i class="fa fa-plus"></i>&nbsp;הוסף תוכנית תשלום</a>

          <!-- <a class="btn btn-primary" (click)="createAndPrintReceiptsForCommitment()"><i class="fa fa-print"></i>&nbsp;צור והדפס קבלות שלא הודפסו</a>  -->
        </div>
        <div fxLayout="row">
          <h1>
            {{ commitment.firstName }} {{ commitment.lastName }}
            <small>התחייבות עבור: {{ commitment.commitmentSubjectName }} <small>
                {{commitment.commitmentReasonName }}</small>&nbsp;&nbsp;
              <i customToolTip [contentTemplate]="closedTemplate" class="btn btn-danger fa fa-ban"
                *ngIf="commitment.commitmentClosedDate != null"></i>
              <ng-template #closedTemplate>
                <div class="well">
                  <h3 style="color: red;text-align: center; text-decoration: underline;">התחייבות
                    נסגרה
                  </h3>
                  <dl class="dl-horizontal">
                    <dt>בתאריך</dt>
                    <dd>{{ commitment.commitmentClosedStrDate }}</dd>
                    <hr>
                    <dt>סטטוס</dt>
                    <dd>{{ commitment.commitmentClosedStatusName }}</dd>
                    <hr>
                    <dt>הערה</dt>
                    <dd>{{ commitment.commitmentClosedComment }}</dd>
                    <hr>
                    <h3 class="label">לביטול או עריכה יש ללחוץ על 'פרטי סגירת התחייבות' בחלון זה
                    </h3>
                  </dl>
                </div>
              </ng-template>
            </small>
          </h1>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-body" style="resize: both; overflow: auto;" fxLayout="row" fxLayoutAlign="start start"
    fxLayoutGap="15px">
    <div style="overflow: auto;" fxLayout="column" class="example-card">
      <mat-card fxLayout="column" style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>פרטי התחייבות: [{{ id }}]</h3>
            <span class="example-fill-remaining-space"></span>


            <span class="label label-primary" *ngIf="commitment.commitmentFundraisingID > 0"
              style="background-color: #304cf1; color: #FFFFFF;float: left; text-align: center; margin-left: 5px; height: fit-content; padding: 5px; vertical-align: middle; margin-top: 2px;">
              {{ commitment.commitmentDonationTypeName }}&nbsp;ל:
              <a style="color:inherit ;text-decoration: underline; cursor: alias;"
                [routerLink]="[ '', {outlets: {popup: [ 'commitments', commitment.commitmentFundraisingID]}}]">{{
                commitment.commitmentFundraisingID }}</a></span>
            <span class="label label-primary" *ngIf="commitment.isFundraising"
              style="background-color: #1bd453; color: #FFFFFF;float: left; text-align: center; margin-left: 5px; height: fit-content; padding: 5px; vertical-align: middle; margin-top: 2px;">
              התחייבות לגיוס
            </span>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <dl class="dl-horizontal" style="white-space: nowrap;">
            <dt *ngIf="commitment.globalAmount">סכום התחייבות</dt>
            <dd *ngIf="commitment.globalAmount">
              <span *ngIf="commitment.globalAmount > 0">
                {{ commitment.globalAmount | number}}{{commitment.commitmentCurrencyIcon}}</span>
            </dd>
            <hr *ngIf="commitment.globalAmount">
            <dt *ngIf="commitment.pulseAmount">סכום-חודשי</dt>
            <dd *ngIf="commitment.pulseAmount">
              {{ commitment.pulseAmount | number}}{{commitment.commitmentCurrencyIcon}}
              <span style="float: left; padding-left: 50px;"> <b>תוספת אדר אלול:</b>
                &nbsp;&nbsp;{{ commitment.specialtyMonthsAmount | number }}{{
                commitment.commitmentCurrencyIcon }}</span>
            </dd>
            <hr *ngIf="commitment.pulseAmount">
            <dt>תאריך פגישה/התחייבות</dt>
            <dd>{{ commitment.commitmentOrAppointmentStrDate }}</dd>
            <hr>
            <dt>תאריך תשלום ראשון</dt>
            <dd>{{ commitment.firstPaymentStrDate }}</dd>
            <hr>

            <dt>סוג התחייבות</dt>
            <dd>{{ commitment.commitmentDonationTypeName }}</dd>
            <hr>
            <dt>אופן תשלום</dt>
            <dd>{{ commitment.commitmentPayMethodTitle }}</dd>
            <hr>

            <dt>תאריך סיום התחייבות (מתוכנן)</dt>
            <dd>{{ commitment.commitmentTillStrDate }}</dd>
            <hr>
            <dt>מספר תשלומים (עד כעת)</dt>
            <dd>{{ commitment.countPayments }}</dd>
            <hr>
            <dt>שולם עד כעת</dt>
            <dd>
              <!-- <span *ngIf="commitment.totalPaidDollars">{{ commitment.totalPaidDollars }}$</span>
                            <span *ngIf="commitment.totalPaidShekels">{{ commitment.totalPaidShekels }}₪</span>
                            <span *ngIf="commitment.totalPaidEurosy">{{ commitment.totalPaidEurosy }}€</span>
                            <span *ngIf="commitment.totalPaidPounds">{{ commitment.totalPaidPounds }}£</span>
                            <span *ngIf="commitment.totalPaidFrancs">{{ commitment.totalPaidFrancs }}+</span> -->
              <span *ngIf="commitment.sumSucceededPartialValueInCommitment">משוקלל: {{
                commitment.sumSucceededPartialValueInCommitment |
                number}} {{ commitment.commitmentCurrencyIcon}}</span>
            </dd>
            <hr>
            <dt>תאריך תשלום אחרון (עד כעת)</dt>
            <dd>{{ commitment.lastPaymentStrDate }}</dd>
            <hr>
            <dt>סטטוס תשלום אחרון</dt>
            <dd>{{ commitment.lastPaymentStatusName }}</dd>
            <hr>
            <dt>תאריך תשלום הקרוב</dt>
            <dd>{{ commitment.nextPaymentStrhDate }}</dd>
            <hr>
            <dt>הערה</dt>
            <dd>{{ commitment.commitmentComment }}</dd>
            <hr>
            <dt *ngIf="commitment.commitmentClosedStrDate">תאריך סגירה/סיום</dt>
            <dd style="white-space: initial;" *ngIf="commitment.commitmentClosedStrDate">{{
              commitment.commitmentClosedStrDate }} <span style="float: left; padding-left: 50px;">
                <b>סטטוס:</b>
                &nbsp;&nbsp;{{ commitment.commitmentClosedStatusName }}</span>
            </dd>
            <hr *ngIf="commitment.commitmentClosedStrDate">
            <dt *ngIf="commitment.commitmentClosedStrDate">הערת סגירת התחייבות</dt>
            <dd style="white-space: initial;" *ngIf="commitment.commitmentClosedStrDate">{{
              commitment.commitmentClosedComment }}</dd>
            <hr *ngIf="commitment.commitmentClosedStrDate">
          </dl>
        </mat-card-content>
      </mat-card>
    </div>
    <div fxFlex fxLayout="column">
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row" (click)="expendPaymentsPlans=!expendPaymentsPlans">
            <span *ngIf="expendPaymentsPlans == false"> פירוט תוכניות תשלום להתחייבות זו</span>
            <span *ngIf="expendPaymentsPlans == true"> הסתר תוכניות תשלום</span>
            <mat-icon *ngIf="expendPaymentsPlans == false">expand_more</mat-icon>
            <mat-icon *ngIf="expendPaymentsPlans == true">expand_less</mat-icon>

          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%; padding: 0 !important;" *ngIf="expendPaymentsPlans">
          <paymentsPlans-list style="overflow: auto;" *ngIf="commitment.countPaymentsPlans > 0; else paymentsPlansList"
            #paymentsPlansTab [commitmentID]="id">Loading...
          </paymentsPlans-list>
        </mat-card-content>
      </mat-card>
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row" (click)="expendPayments=!expendPayments">
            <span *ngIf="expendPayments == false"> לחץ כדי להציג רשומות תשלומים להתחייבות</span>
            <span *ngIf="expendPayments == true"> הסתר תשלומים</span>
            <mat-icon *ngIf="expendPayments == false">expand_more</mat-icon>
            <mat-icon *ngIf="expendPayments == true">expand_less</mat-icon>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%; padding: 0 !important;" *ngIf="expendPayments">
          <payments-list style="overflow: auto;" #paymentsTab
            [commitmentID]="id">Loading...
          </payments-list>
        </mat-card-content>
      </mat-card>
      <!-- <mat-card style="overflow: auto;" class="example-card" *ngIf="commitment.isHoldingCommitment">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row" (click)="expendHoldings=!expendHoldings">
            <span *ngIf="expendHoldings == false"> לחץ כדי להציג רשומות החזקת ביהמ"ד להתחייבות</span>
            <span *ngIf="expendHoldings == true"> הסתר רשומות החזקת ביהמ"ד</span>
            <mat-icon *ngIf="expendHoldings == false">expand_more</mat-icon>
            <mat-icon *ngIf="expendHoldings == true">expand_less</mat-icon>
          </mat-card-title>

        </mat-card-header>
        <mat-card-content style="width: 100%; padding: 0 !important;" *ngIf="expendHoldings">
          <holdings-list style="overflow: auto;" loading-status [stopLoadingWhen]="commitment.isHoldingCommitment"
            [commitmentID]="id">
            Loading...
          </holdings-list>
        </mat-card-content>
      </mat-card> -->
    </div>
  </div>
</div>
