import { filter, map } from 'rxjs/operators';

import { NgModule, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, Params } from '@angular/router';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';

@Injectable()
export class FriendlyTitleService {
    private contactID = 0;

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private toraxHttp: ToraxHttp
    ) {
        // this.router.events
        //     .filter(event => event instanceof NavigationEnd)
        //     .map(() => this.activatedRoute)
        //     .subscribe((ar) => {
        //         let conID = this.findContactID(ar);
        //         if (conID) {
        //             if (!this.contactID || this.contactID !== conID) {
        //                 this.contactID = conID;
        //                 this.setContactTitle();
        //             }
        //         } else {
        //             let r: ActivatedRoute | null = ar;
        //             let titleResult = '✘';
        //             while (r) {
        //                 if (r.data && (<any>r.data).value && (<any>r.data).value.title) {
        //                     titleResult = (<any>r.data).value.title;
        //                 }
        //                 r = r.firstChild;
        //             }
        //             this.titleService.setTitle(`G | ${titleResult}`);
        //         }
        //     });
    }
    init() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
        ).subscribe((ar) => {
            let conID = this.findContactID(ar);
            if (conID) {
                if (!this.contactID || this.contactID !== conID) {
                    this.contactID = conID;
                    this.setContactTitleFromServer();
                }
            } else {
                let r: ActivatedRoute | null = ar;
                let titleResult = '';
                while (r) {
                    if (r.data && (<any>r.data).value && (<any>r.data).value.title) {
                        titleResult = (<any>r.data).value.title;
                    }
                    r = r.firstChild;
                }
                this.setTitle(`${titleResult}`);
            }
        });
        // this.router.events
        // .filter((event) => event instanceof NavigationEnd)
        // .map(() => {
        //     let route = this.activatedRoute;
        //     while (route.firstChild) route = route.firstChild;
        //     return route;
        // })
        // .filter((route) => route.outlet === 'primary')
        // .mergeMap((route) => route.data)
        // .map((data) => {
        //     if (data.title) {
        //         // If a route has a title set (e.g. data: {title: "Foo"}) then we use it
        //         return data.title;
        //     } else {
        //         // If not, we do a little magic on the url to create an approximation
        //         return this.router.url.split('/').reduce((acc, frag) => {
        //             if (acc && frag) { acc += SEPARATOR; }
        //             return acc + TitleService.ucFirst(frag);
        //         });
        //     }
        // })
        // .subscribe((pathString) => {
        //     let t = `${(data != null ? data.title : "")} ${pathString}`;
        //     this.setTitle(`${(data != null ? data.title : "")} ${pathString}`)
        // });
    }

    static ucFirst(string) {
        if (!string) { return string; }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    private findContactID(ar: ActivatedRoute): number {
        let c = 0;
        ar.params.forEach((params: Params) => {
            c = (+params['contactID']);
        });

        if (!c && ar.firstChild) {
            c = this.findContactID(ar.firstChild);
        }
        return c;
    }

    private setContactTitleFromServer(): void {
        this.toraxHttp.get(`api/services/getContactName/${this.contactID}`)
            .subscribe(cn => {
                let t = `${cn}`;
                this.setTitle(t);
            });
    }

    public setContactTitle(contactName: String): void {
        let t = `${contactName}`;
        this.setTitle(t);
    }

    private setTitle(title: String): void {
        let t = `המרכז העולמי | ${title}`;
        this.titleService.setTitle(t);
    }


}


@NgModule({
    providers: [FriendlyTitleService]
})
export class FriendlyTitleModule { }


