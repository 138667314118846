import { Component, Input, OnInit, ElementRef, ViewEncapsulation, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
//import 'rxjs/Rx';


@Component({
    
    selector: 'dashboard-panel-box',
    templateUrl: 'dashboard-panel-box.tmpl.html',
    // styles: ['panel-box.css'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardPanelBoxComponent implements AfterViewInit {
    constructor(private el: ElementRef) { }

    @Input() title: string;
    @Input() showCollapse: boolean = true;
    @Input() backgroundColor: string = '#26c281';
    @Input() color: string = 'white';



    @Output() onSelect = new EventEmitter<number>();
    expended = false;

    public headerSelect(e) {
        if (e) {
            e.preventDefault();
        }
        this.onSelect.emit(1);
    }


    ngAfterViewInit() {
        // this.showCollapseBtn.nativeElement.click();
    }

    collapse() {
        let el = (<any>event).target
        let button = el;
        let ibox = el.closest('div.ibox')
        let content = $(ibox).find('div.ibox-content');
        content.slideToggle(200);
        $(button).toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        $(ibox).toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            $(ibox).resize();
            $(ibox).find('[id^=map-]').resize();
        }, 50);

        this.expended = !this.expended;

        if (this.expended) {
            this.onSelect.emit(1);
        }
    }

    close() {
        var el = (<any>event).target
        this.el.nativeElement.remove();
        //  this.expended = true;
    }
}
