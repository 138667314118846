import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { ModalService } from './../components/modal/modal.svc';
//import { UpdateCashRegisterDlg } from './ivrDonationDetails-updateCashRegister-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
//import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { IvrDonationDetailsData } from './ivrDonationDetails.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
  selector: 'ivrDonationDetails-list',
  templateUrl: 'ivrDonationDetails-list.tmpl.html'
})

export class IvrDonationDetailsList implements OnInit {
  constructor(
    private ivrDonationDetailsData: IvrDonationDetailsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private modal: ModalService,
    private router: Router,
    public _matDialog: MatDialog,
  ) {
  }

  displayedColumns: string[] = ['buttons', 'ivrDonationDetailID', 'lastName', 'shtibelName', 'phoneNumber', 'title', 'createDate', 'amount', 'qty','statusPayment', 'methodTitle', 'commitmentID'];
  data: any[] = [];

  resultsLength = 0;
  isRateLimitReached = false;
  commitmentID;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('ivrDonationDetailEventID') ivrDonationDetailEventID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;

  @Input('contactID') public contactID?: number | null = null;
  @Input('ivrDonationDetailID') public ivrDonationDetailID?: number | null = null;

  // private openUpdateCashRegisterDlg(): void {

  //   this.modal.show({
  //     Header: 'עדכון תשלומי קופה רושמת',
  //     BodyComponentType: UpdateCashRegisterDlg,
  //     // Width : '40%',
  //     Actions: [
  //       { Text: 'יציאה', CssClass: 'btn-default', Action: () => true },
  //     ],
  //     Data: {}
  //   });
  // }



  searchIvrDonationDetailsListForm: FormGroup;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;

  public clerFilter() {
    this.initForm();
    this.getIvrDonationDetails();
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ['add'] } }]);
  }

  openSelectedRow(ivrDonationDetailID) {
    this.router.navigate(['', { outlets: { popup: ['ivrDonationDetails', ivrDonationDetailID] } }]);
  }


  public downloadExcel() {
    this.ivrDonationDetailsData.exportExcel(this.searchIvrDonationDetailsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `סטנציא.xlsx`;
        link.click();
      });
  }


  private getIvrDonationDetails() {
    this.searchIvrDonationDetailsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

    if (this.contactID && this.contactID > 0) {
      this.searchIvrDonationDetailsListForm.controls['contactID'].setValue(this.contactID);
    }
    if (this.ivrDonationDetailID && this.ivrDonationDetailID > 0) {
      this.searchIvrDonationDetailsListForm.controls['ivrDonationDetailID'].setValue(this.ivrDonationDetailID);
    }


    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchIvrDonationDetailsListForm.value));

    return this.ivrDonationDetailsData!.getList(this.searchIvrDonationDetailsListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;


        this.isRateLimitReached = false;

      });
  }

  ivrDonationDetailsDebitBalance = 0;
  getIvrDonationDetailsDebitBalance() {
    if (this.contactID && this.contactID > 0) {
      this.ivrDonationDetailsData.getIvrDonationDetailsDebitBalance(this.contactID).subscribe(
        (r: any) => {
          this.ivrDonationDetailsDebitBalance = r;
        },
        error => { });
    }
  }
  private initForm() {
    this.searchIvrDonationDetailsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      ivrDonationDetailID: [],
      statusPayment: [],
      donationCategoryID: [],
      donationCategoryTitle: [],
      registeringPhone: [],
      callerNumber: [],
      contactID: [],
      contactName: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
      updateCashRegisterModel: this.formBuilder.array([this.initUpdateCashRegisterModel()])
    });
  }

  initUpdateCashRegisterModel(): FormGroup {
    return this.formBuilder.group({
      contactID: [, Validators.required],
      commitmentID: [, Validators.required],
      amount: [, Validators.required]
    });
  }


  panelOpenState;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;

  ngOnInit() {
    this.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchIvrDonationDetailsListForm.patchValue(frm);
    }
    this.getIvrDonationDetails();
    this.getIvrDonationDetailsDebitBalance();
  }

  pageChange(e) {
    this.searchIvrDonationDetailsListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getIvrDonationDetails();
  }


  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getIvrDonationDetails();
  }
  public search() {
  }
  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchIvrDonationDetailsListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }
  pageEvent: PageEvent;
  pageSizeOptions;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchIvrDonationDetailsListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}
