<form [formGroup]="searchHoldingsListForm">
    <div fxLayout="row" fxLayoutAlign="end end">
        <div fxLayout="column" style="padding-left: 6px;" *ngIf="contactID > 0">
            הצג קשורים<br>
            <mat-slide-toggle (toggleChange)="includeRelatedHoldingsChanged($event)"
                formControlName="includeRelatedHoldings">
            </mat-slide-toggle>
        </div>
    </div>
    <div *ngIf="!contactID && !commitmentID && !paymentsPlanID" class="example-container"
        style="background-color: white">
        <div class="column">
            <div fxLayout="row" class="scrolling-wrapper">
                <auto-complete apply-enter [placeholder]="'שם איש...'" (onSelect)="selectionChanged($event)"
                    [controllerName]="'Contacts'" [formGroupModel]="searchHoldingsListForm"
                    [idControl]="searchHoldingsListForm.controls.contactID"
                    [nameControl]="searchHoldingsListForm.controls.contactName"></auto-complete>
                &nbsp;&nbsp;
                <auto-complete apply-enter [placeholder]="'שטיבל...'" (onSelect)="selectionChanged($event)" #shtibelID
                    [controllerName]="'Shtibels'" [formGroupModel]="searchHoldingsListForm"
                    [idControl]="searchHoldingsListForm.controls.shtibelID"
                    [nameControl]="searchHoldingsListForm.controls.shtibelName"></auto-complete>
                &nbsp;&nbsp;
                <auto-complete apply-enter [placeholder]="'שם אחראי...'" (onSelect)="selectionChanged($event)"
                    #responsibleID [controllerName]="'Contacts'" [formGroupModel]="searchHoldingsListForm"
                    [idControl]="searchHoldingsListForm.controls.responsibleID"
                    [nameControl]="searchHoldingsListForm.controls.responsibleName"></auto-complete>
                &nbsp;&nbsp;
                <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                    *ngIf="servicesData.StatusPayments" (selectChange)="selectionChanged($event)"
                    [listControl]="searchHoldingsListForm.controls.statusPayments" [placeholder]="'סטטוס...'"
                    [list]="servicesData.StatusPayments">
                </mat-select-multiple-dropdown>
                &nbsp;&nbsp;
                <div class="input-group mb-3">
                    <label>הודפס...</label>
                    <mat-select class="form-control" (selectChange)="selectionChanged($event)" placeholder="הודפס..."
                        formControlName="isPrint">
                        <mat-option [value]="null">...</mat-option>
                        <mat-option [value]="true">הודפס</mat-option>
                        <mat-option [value]="false">לא הודפס</mat-option>
                    </mat-select>
                </div>
                &nbsp;&nbsp;
                <div class="input-group mb-3">
                    <label>שנה...</label>
                    <mat-select class="form-control" (selectChange)="selectionChanged($event)" placeholder="שנה..."
                        formControlName="jewishYear">
                        <mat-option *ngFor="let y of servicesData.JewishYearOptions" [value]="y.value">{{y.label}}</mat-option>
                    </mat-select>
                </div>

                <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
                    <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן"
                        (click)="selectionChanged($event)"><i class="fa fa-filter"></i></button>
                    <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים"
                        (click)="clerFilter()"><i class="material-icons">clear</i></button>
                    <button style="margin: 3px;" mat-mini-fab color="primary" title="Pivot"
                        (click)="downloadHoldingsPivotReportExls(searchHoldingsListForm.controls.jewishYear.value, searchHoldingsListForm.controls.responsibleID.value)">
                        <i class="fa fa-file-excel-o"></i></button>
                </div>

            </div>
        </div>
    </div>
    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                            <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-checkbox apply-enter (click)="$event.stopPropagation()"
                                (selectChange)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>



                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="איש">
                            {{row.firstName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="motherName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> אם
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="אם">
                            {{row.motherName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> משפחה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="משפחה">
                            {{row.lastName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> סכום
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סכום">
                            {{row.amount}} {{row.currencyIcon}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="month">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>חודש
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="חודש">
                            {{row.monthName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="year">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שנה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="שנה">
                            {{row.yearName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="shtibelName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שטיבל
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="שטיבל">
                            {{row.shtibelName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="statusPayment">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> סטטוס
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סטטוס">
                            <span customToolTip [contentTemplate]="statusTemplate">
                                <i *ngIf="row.statusPayment == 1" style="color: rgb(240, 206, 14)"
                                    class="fa fa-warning"></i>
                                <i *ngIf="row.statusPayment == 2" style="color: green" class="fa fa-check"></i>
                                <i *ngIf="row.statusPayment == 3" style="color: red" class="fa fa-close"></i>
                            </span>
                            <ng-template #statusTemplate>
                                <div class="well">{{row.statusPaymentName}} </div>
                            </ng-template>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="paymentIDs">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> תשלומים
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תשלומים">
                            <span *ngFor="let pId of row.paymentIDs?.split(',')">
                                <a *ngIf="pId" [routerLink]="['', {outlets: {popup: ['payments', pId]}}]"
                                >{{ pId }}&nbsp;&nbsp;</a>
                            </span>
                        </mat-cell>


                    </ng-container>
                    <ng-container matColumnDef="numberOfPrintSeries">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> הודפס
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="הודפס">
                            <i customToolTip [contentTemplate]="template" *ngIf="row.isPrint" class="fa fa-check"></i>
                            <ng-template #template>
                                <div class="well"> סדרת הדפסה&nbsp;{{row.numberOfPrintSeries}} </div>
                            </ng-template>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>
            </div>
        </div>
        <h1 *ngIf="!sourceList && (!contactID && !commitmentID && !paymentsPlanID)"
            style="color: gray; text-align: center;">
            בחר סינון כדי להציג רשומות...
        </h1>
        <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
            [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
    </div>

</form>