import { RegistrationEventDetailsDlg } from './registrationEvent/registrationEvent-details-dlg.cmp';
import { ModalService } from '../components/modal/modal.svc';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { RegistrationEventsData } from './registrationEvents.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
//import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { ToraxHttp } from '../utils/custom-http.svc';
import { RegistrationEvent } from './registrationEvent/registrationEvent';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';

@Component({
	selector: 'registrationEvents-list',
	templateUrl: 'registrationEvents-list.tmpl.html'
})

export class RegistrationEventsList implements OnInit {
	constructor(
		private registrationEventsData: RegistrationEventsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		public _matDialog: MatDialog,
	) {
	}

	displayedColumns: string[] = ['buttons', 'registrationEventID', 'title', 'registrationEventType', 'date', 'price', 'sumDoses', 'sumRegistrations', 'sumPrice', 'sumAmountPaid', 'sumAmountToPay']; //, 'sumRegistrations', 'sumDoses', 'sumAmount', 'sumAmountPaid', 'sumAmountToPay'
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;

	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('registrationEventID') registrationEventID: AutoCompleteComponent;


	searchRegistrationEventsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
		this.getRegistrationEvents();
	}

	// addNew() {
	// 	this.router.navigate([{ outlets: { popup: ['add'] } }]);
	// }

	openSelectedRow(registrationEventID) {
		this.router.navigate(['', { outlets: { popup: ['registrationEvents', registrationEventID] } }]);
	}
	openDetails(row: RegistrationEvent) {
		this._matDialog.open(RegistrationEventDetailsDlg, {
			data: row
		});
	}


	public downloadExcel() {
		if(!this.searchRegistrationEventsListForm.controls.registrationEventTypes.value){
			alert('יש לבחור סוג רשומה לפני ייצוא');
			return;
		}
			this.registrationEventsData.downloadExcel(this.searchRegistrationEventsListForm.value)
			.subscribe(blob => {
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `אירועים סטנציה.xlsx`;
				link.click();
			});
	}

	public downloadHeavyExcel() {
		if(!this.searchRegistrationEventsListForm.controls.registrationEventTypes.value){
			alert('יש לבחור סוג רשומה לפני ייצוא');
			return;
		}
		this.registrationEventsData.downloadHeavyExcel(this.searchRegistrationEventsListForm.value)
			.subscribe(blob => {
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `אירועים סטנציה מלא.xlsx`;
				link.click();
			});
	}

	public getRegistrationEvents() {
		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchRegistrationEventsListForm.value));

		return this.registrationEventsData!.getList(this.searchRegistrationEventsListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;


				this.isRateLimitReached = false;

			});
	}

	registrationEventsDebitBalance = 0;

	private initForm() {
		this.searchRegistrationEventsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			registrationEventID: [],
			registrationEventTitle: [],
			registrationEventTypes: [],
			selectedRows: [],
			orders: this.formBuilder.array([])
		});
	}

	initUpdateCashRegisterModel(): FormGroup {
		return this.formBuilder.group({
			contactID: [, Validators.required],
			commitmentID: [, Validators.required],
			amount: [, Validators.required]
		});
	}


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;

	ngOnInit() {
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchRegistrationEventsListForm.patchValue(frm);
		}
		this.getRegistrationEvents();
	}

	pageChange(e) {
		this.searchRegistrationEventsListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}

	selectionChanged(e) {
		this.getRegistrationEvents();
	}


	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getRegistrationEvents();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchRegistrationEventsListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}

	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchRegistrationEventsListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}

	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}