import { DashboardGroupsDataService } from './dashboard-groups.data';
import * as shape from 'd3-shape';
import { Params, ActivatedRoute } from '@angular/router';
import { PaymentsViewDlg } from './../dashboard-payments-view-dlg.cmp';
import { ServicesData } from './../../services/services.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { ModalService } from './../../components/modal/modal.svc';
import { Component, OnInit, AfterViewInit } from '@angular/core';


@Component({
    selector: 'dashboard-groups',
    templateUrl: './dashboard-groups.tmpl.html'    
})
export class DashboardGroupsComponent implements OnInit {
    userName: string;
    model: any = {};  
   //  widget5: any = {};

    constructor(
        private dashboardGroupsDataService: DashboardGroupsDataService,
        public servicesData: ServicesData,
        private toraxHttp: ToraxHttp,
        private alert: AlertService,
    ) {        
        this.toraxHttp.updatedEvent.subscribe((id) => {
            this.load();

        });
    }
   


      
    // printWaitingILReceipts(): void {
    //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    //     this.dashboardGroupsDataService.printWaitingILReceipts()
    //         .subscribe(data => {
    //                 // tslint:disable-next-line:max-line-length
    //                 let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    //                 popupWinindow.document.open();
    //                 // tslint:disable-next-line:max-line-length
    //                 popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
    //                 popupWinindow.document.close();
    //         });
    // }

    // printWaitingUSAReceipts() {
    //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    //     this.dashboardGroupsDataService.printWaitingUSAReceipts()
    //         .subscribe(data => {
    //                 // tslint:disable-next-line:max-line-length
    //                 let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    //                 popupWinindow.document.open();
    //                 // tslint:disable-next-line:max-line-length
    //                 popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
    //                 popupWinindow.document.close();
    //         });
    // }

    // printWaitingUKReceipts() {
    //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    //     this.dashboardGroupsDataService.printWaitingUKReceipts()
    //         .subscribe(data => {
    //                 // tslint:disable-next-line:max-line-length
    //                 let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    //                 popupWinindow.document.open();
    //                 // tslint:disable-next-line:max-line-length
    //                 popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
    //                 popupWinindow.document.close();
    //         });
    // }

    // cancelILReceiptsPrint(receiptsIdsStr: string) {
    //     console.log(receiptsIdsStr);
    //     let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
    //         return parseInt(item, 10);
    //     });
    //     this.alert.info('המתן בבקשה,\nמבטל קבלות...');
    //     this.dashboardGroupsDataService.cancelILReceiptsPrint(receiptsIdsList);
    // }

    // cancelUSAReceiptsPrint(receiptsIdsStr: string) {
    //     console.log(receiptsIdsStr);
    //     let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
    //         return parseInt(item, 10);
    //     });
    //     this.alert.info('המתן בבקשה,\nמבטל קבלות...');
    //     this.dashboardGroupsDataService.cancelUSAReceiptsPrint(receiptsIdsList);
    // }

    // cancelUKReceiptsPrint(receiptsIdsStr: string) {
    //     console.log(receiptsIdsStr);
    //     let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
    //         return parseInt(item, 10);
    //     });
    //     this.alert.info('המתן בבקשה,\nמבטל קבלות...');
    //     this.dashboardGroupsDataService.cancelUKReceiptsPrint(receiptsIdsList);
    // }
  
    load() {       
        this.dashboardGroupsDataService.get().subscribe(db => {
           this.fillValues(db);            
        });
      }

    fillValues(db:any){
        this.model = db;        
    }
     topNewCommitments: any[];
    // groupID;
    ngOnInit() {
        
            this.load();
  
     }
}
