import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CommitmentReasonsData } from './commitmentReasons.data';
import { CommitmentReason } from './commitmentReason/commitmentReason';
import { ObjectsListResult, ObjectListItem } from '../services/objects-list-results';
import { Router } from '@angular/router';

@Component({
	
	selector: 'commitmentReasons-list',
	templateUrl: './commitmentReasons-list.tmpl.html'
})

export class CommitmentReasonsList implements OnInit {
	constructor(
		private commitmentReasonsData: CommitmentReasonsData,
		private formBuilder: FormBuilder,
		private router: Router,
	) {
	}
	public objectsListResult: ObjectsListResult;
	// public commitmentReasons: any[];
	searchCommitmentReasonsListForm: FormGroup;

	public query: string | null = null;
	public freeTextQuery: string;

	addNew() {
		this.router.navigate(['', { outlets: { popup: ['commitmentReasons', 'add'] } }]);
	  }	
	  public getCommitmentReasons(): void {
		this.commitmentReasonsData.getList(this.searchCommitmentReasonsListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			error => { });
	}

	public changePage(page: number) {
		this.searchCommitmentReasonsListForm.controls['page'].setValue(page);
		this.getCommitmentReasons();
	}

	public search() {
		this.getCommitmentReasons();
	}

	private initForm() {
		this.searchCommitmentReasonsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			commitmentReasonID: [],
		});
	}
	ngOnInit(): void {
		this.initForm();
		this.getCommitmentReasons();
	}
}
