<form [formGroup]="assistanceForm" novalidate (ngSubmit)="save()" *ngIf="assistance">
  <div class="modal-header">
    <h1>עריכת פרטי תמיכה [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <div *ngIf="assistance" class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי תמיכה</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <!-- <label>שם איש:</label> -->
              <input style="font-weight: bold;" class="form-control"  disabled [value]="assistance.contactName">
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>סכום:</label>
              <input class="form-control" apply-enter formControlName="amount" placeholder="סכום:">
            </div>&nbsp;
            <div class="input-group mb-3">
              <label>מטבע:</label>
              <select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע">
                <option [value]="">...</option>
                <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                  {{c.name}}
                </option>
              </select>
            </div>
          </div>

          <auto-complete apply-enter [controllerName]="'DonationCategories'" [placeholder]="'קטגוריה...'"
            [formGroupModel]="assistanceForm" [idControl]="assistanceForm.controls.donationCategoryID"
            [nameControl]="assistanceForm.controls.donationCategoryTitle">
          </auto-complete>
          <date-input [placeholder]="'תאריך'" [dateControl]="assistanceForm.controls.assistanceDate">Loading...
          </date-input>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מס' שיק:</label>
              <input apply-enter class="form-control" formControlName="chickNumber">
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>פרטי השיק:</label>
              <input apply-enter class="form-control" formControlName="chickDetails">
            </div>
          </div>


          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>סיבה:</label>
              <input apply-enter class="form-control" formControlName="reason">
            </div>
          </div>
          <div class="input-group mb-3" style="width: 100%;">
            <textarea class="form-control comment" style="min-width: 100%" rows="2" placeholder="הערה..."
              formControlName="comment"></textarea>
          </div>

        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [dataProvider]="assistancesData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
    [modelFormGroup]="assistanceForm"></footer-edit-buttons>

</form>
