<div >
    <div class="modal-action-header" style="min-width: 350px !important;">
        <button (click)="switch()" class="switch" [ngClass]="{ 'checked': value }" [attr.title]="'עריכה ועדכון'">
            <mat-icon *ngIf="!value">edit</mat-icon>
            <mat-icon *ngIf="value">pageview</mat-icon>
        </button>
        <button (click)="exitModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <ng-container *ngTemplateOutlet="customHtmlElements">
    </ng-container>
</div>
