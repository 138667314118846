import { HonorTypeMain } from './honor-type/honor-type-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';

import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { HonorTypesData } from './honor-types.data';
import { HonorTypesList } from './honor-types-list.cmp';
import { HonorTypeEdit } from './honor-type/honor-type-edit.cmp';
import { HonorTypeDetails } from './honor-type/honor-type-details.cmp';
import { HonorTypesRoutes } from './honor-types.routing';
import { MaterialModule } from '../material.module';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		RouterModule.forChild(HonorTypesRoutes),
		MainFormModalModule,
		MaterialModule
	],
	declarations: [
		HonorTypesList,
		HonorTypeEdit,
		HonorTypeDetails,
		HonorTypeMain
	],
	exports: [
		HonorTypesList,
		HonorTypeEdit,
		HonorTypeDetails,
		HonorTypeMain
	],
	providers: [
		HonorTypesData
	]
})
export class HonorTypesMdl { }

