<div *ngIf="honor">
  <div class="modal-header">
    <div class="row">
      <div class="col-sm-4">
        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', honor.contactID]}}]">
          <h2>{{ honor.contactName }}</h2>
        </a>
      </div>

      <div class="col-sm-8 text-left">
        <a class="btn btn-primary" (click)="createAndPrintHonorLetter()"><i class="fa fa-print"></i>&nbsp;{{
          (honor.contactLetterID > 0 ? 'הדפס מכתב' : 'צור מכתב') }} </a>
      </div>

    </div>
  </div>
  <div class="modal-body" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div fxLayout="row" style="overflow: auto;" fxLayout.xs="row wrap">
      <mat-card *ngIf="honor" fxLayout="column" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3 style="font-weight: bold; font-size: 120%; text-decoration: underline;"> {{ honor.honorTypeTitle }} </h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%;">
          <dl class="dl-horizontal" style="white-space: nowrap;">

            <dt *ngIf="honor.receivingContactID">ניתן ל</dt>
            <dd *ngIf="honor.receivingContactID"> {{ honor.receivingRelationTypeName }}: <a
                [routerLink]="[ '', {outlets: {popup: [ 'contacts', honor.receivingContactID]}}]"><b>{{
                  honor.receivingContactName }} </b></a></dd>
            <hr *ngIf="honor.receivingContactID">
            <dt *ngIf="honor.honorsSetTitle">שבת/אירוע</dt>
            <dd *ngIf="honor.honorsSetTitle"> {{ honor.honorsSetTitle}} </dd>
            <hr *ngIf="honor.honorsSetTitle">
            <!-- <dt>כיבוד</dt>
            <dd> {{ honor.honorTypeTitle }} </dd>
            <hr> -->
            <dt>תאריך</dt>
            <dd>{{ honor.honorParasha }} {{ honor.honorJewishDate }} ({{ honor.honorDate | date:'dd/MM/yyyy' }})
            </dd>
            <hr>
            <dt>סכום התחייבות במי-שברך</dt>
            <dd>{{ honor.contributionAmount | number}}{{ honor.currencyIcon }} </dd>
            <hr>
            <dt>סיבה/הערה</dt>
            <dd>{{ honor.reasonComment }} </dd>
            <hr>
            <dt>התחייבות</dt>
            <dd> <a *ngIf="honor.commitmentID"
                [routerLink]="[ '', {outlets: {popup: [ 'commitments', honor.commitmentID]}}]">{{
                honor.commitmentID }}</a> </dd>
            <hr>
            <dt>מכתב</dt>
            <dd *ngIf="honor.contactLetterID > 0">{{ honor.contactLetterID }}
              <br><b>תאריך הדפסה:</b>
              <br>
              {{honor.datePrintContactLetter | date:'dd/MM/yyyy' }}
              ({{honor.jewishDatePrintContactLetter}})
            </dd>
            <hr>
          </dl>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
