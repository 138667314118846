import { TishTable } from 'src/app/tishTables/tishTable/tishTable';
import { TishInvitedDlg } from './../../tishInviteds/tishInvited/tishInvited-edit-dlg.cmp';
import { TishGroupDlg } from './../../tishGroups/tishGroup/tishGroup-edit-dlg.cmp';
import { TishGroup } from './../../tishGroups/tishGroup/tishGroup';
import { TishInvited } from './../../tishInviteds/tishInvited/tishInvited';
import { TishTablesData } from './../../tishTables/tishTables.data';
import { Location } from '@angular/common';
import { TishTableDlg } from './../../tishTables/tishTable/tishTable-edit-dlg.cmp';
import { TishGroupsData } from './../../tishGroups/tishGroups.data';
import { TishInvitedsData } from './../../tishInviteds/tishInviteds.data';
import { TishPlacesData } from './../../tishPlaces/tishPlaces.data';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { TishEventsData } from './../tishEvents.data';
import { AlertService } from './../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { CloneTishEventModel, TishEvent } from './tishEvent';
import { TishArea } from 'src/app/tishAreas/tishArea/tishArea';
import { TishAreaDlg } from 'src/app/tishAreas/tishArea/tishArea-edit-dlg.cmp';
import { TishAreasData } from 'src/app/tishAreas/tishAreas.data';

@Component({

	selector: 'tishEvent-details',
	templateUrl: './tishEvent-details.tmpl.html'
})

export class TishEventDetails implements OnInit {
	constructor(
		private tishEventsData: TishEventsData,
		private activatedRoute: ActivatedRoute,
		public router: Router,
		private alertSvc: AlertService,
        private location: Location,
		private cdref: ChangeDetectorRef,
		private toraxHttp: ToraxHttp,
        private tishPlacesData: TishPlacesData,
        private tishInvitedsData: TishInvitedsData,
        private tishGroupsData: TishGroupsData,
        private tishAreasData: TishAreasData,
        public dialog: MatDialog,
        private tishTablesData: TishTablesData,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.tishEvent && id === this.tishEvent.tishEventID) {
				this.loadTishEvent();
			}
		});
	}

	public tishEvent: TishEvent;
	private id: number;
    tishEventForm: FormGroup;
    hideMenubtns: boolean = true;

	public loadTishEvent(): void {
		this.tishEventsData.get(this.id)
			.subscribe((tg:any) => {
				this.tishEvent = tg;
			});
	}

    get pathStr() {
        return `${this.perfix}${this.location.path()}`;
    }

    activeTabIndex;
    perfix = "tabs_";
    public itemSelect(event) {
        this.activeTabIndex = event.index;
        sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
        this.cdref.detectChanges();
    }

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['tishEventID'];
			this.loadTishEvent();
            this.activeTabIndex = sessionStorage.getItem(`${this.pathStr}`) || 0;
		});
	}

    public downloadExls(): void {

        this.tishEventsData.downloadExls(this.id)
            .subscribe(blob => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `דוח מוזמנים לאירוע ${this.tishEvent.tishEventTitle} - ${this.tishEvent.description}.xlsx`;
                link.click();
            });
    }

    public downloadTicketsExls(): void {

        this.tishEventsData.downloadTicketsExls(this.id)
            .subscribe(blob => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `רשימת כרטיסים ${this.tishEvent.tishEventTitle} - ${this.tishEvent.description}.xlsx`;
                link.click();
            });
    }

    public downloadTishEventTicketsForLettersExls(): void {

        this.tishEventsData.downloadTishEventTicketsForLettersExls(this.id)
            .subscribe(blob => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `יצוא למיזוג מכתבים עם נתוני כרטיסים ${this.tishEvent.tishEventTitle} - ${this.tishEvent.description}.xlsx`;
                link.click();
            });
    }

    public downloadExlsForMerge(): void {

        this.tishEventsData.downloadExlsForMerge(this.id)
            .subscribe(blob => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `יצוא למיזוג מדבקות ${this.tishEvent.tishEventTitle} - ${this.tishEvent.description}.xlsx`;
                link.click();
            });
    }
    addTableDlg() {
        this.hideMenubtns = true;
        let tt = new TishTable();
        tt.tishEventID = this.tishEvent.tishEventID;
        tt.tishEventTitle = this.tishEvent.tishEventTitle;
        let e = (<any>event);
        tt.tishTableLeft = $(e.target).closest('.cntnr').css('left');
        tt.tishTableTop = $(e.target).closest('.cntnr').css('top');

        setTimeout(() => {
            const dialogRef = this.dialog.open(TishTableDlg, {
                data: tt
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    this.createTishTable(result);
                }
            });
        }, 200);
    }

    createTishTable = (ttForm: FormGroup): void => {
        if (ttForm && ttForm.valid) {
            let ttData = this.tishTablesData;
            let te = this.tishEvent;
            this.tishTablesData.update(<any>ttForm.value)
                .subscribe((x: any) => {
                    this.tishTablesData.getMap(x.id)
                        .subscribe((tt: TishTable) => {
                            return true;
                            // let tFrm = ttData.initForm();
                            // tFrm.patchValue(tt);
                            // let tid = tt.tishTableID;
                            // te.tishTablesIds.push(tid);
                        });
                });
        }
    }

    addInvitedDlg() {
        let ti = new TishInvited();
        ti.tishEventID = this.tishEvent.tishEventID;
        ti.tishEventTitle = this.tishEvent.tishEventTitle;

        setTimeout(() => {
            const dialogRef = this.dialog.open(TishInvitedDlg, {
                data: ti
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    this.createTishInvited(result);
                }
            });
        }, 200);
    }

    createTishInvited = (formData: FormGroup): void => {
        if (formData.valid) {
            this.tishInvitedsData.update(<any>formData.value)
                .subscribe((c: any) => {
                    return true;
                })
        }
    }

    cloneTishEvent() {
        let cte = new CloneTishEventModel();
        cte.oldTishEventID = this.tishEvent.tishEventID;
        cte.tishEventTitle = this.tishEvent.tishEventTitle;

               this.tishEventsData.cloneTishEvent(cte)
               .subscribe((x: any) => {
                   console.log(cte);
               });
    }

    addGroupDlg() {
        let tg = new TishGroup();
        tg.tishEventID = this.tishEvent.tishEventID;
        tg.tishEventTitle = this.tishEvent.tishEventTitle;

        setTimeout(() => {
            const dialogRef = this.dialog.open(TishGroupDlg, {
                data: tg
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    this.createTishGroup(result);
                }
            });
        }, 200);
    }

    createTishGroup = (formData: FormGroup): void => {
        if (formData.valid) {
            this.tishGroupsData.update(<any>formData.value)
                .subscribe((c: any) => {
                    return true;
                })
        }
    }



    addAreaDlg() {
      let ta = new TishArea();
      ta.tishEventID = this.tishEvent.tishEventID;
      ta.tishEventTitle = this.tishEvent.tishEventTitle;

      setTimeout(() => {
          const dialogRef = this.dialog.open(TishAreaDlg, {
              data: ta
          });
          dialogRef.afterClosed().subscribe(result => {
              if (result && result.valid) {
                  this.createTishArea(result);
              }
          });
      }, 200);
  }
  createTishArea = (formData: FormGroup): void => {
      if (formData.valid) {
          this.tishAreasData.update(<any>formData.value)
              .subscribe((c: any) => {
                  return true;
              })
      }
  }

}

