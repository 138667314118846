import { AlertService } from '../../components/alert/alert.svc';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { HonorsSetsData } from './../honors-sets.data';
import { HonorsSet } from './honors-set';

@Component({

	selector: 'honors-set-details',
	templateUrl: './honors-set-details.tmpl.html'
})

export class HonorsSetDetails implements OnInit {
	constructor(
		private honorsSetsData: HonorsSetsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private alert: AlertService,

	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.honorsSet && id === this.honorsSet.honorsSetID) {
				this.loadHonorsSet();
			}
		});
	}

	public honorsSet: HonorsSet;
	private id: number;


	public createAndPrintHonorsSetLetters(): void {
		this.alert.info("מדפיס מכתבים לרשימת המוגדרים להדפסה, ניתן לשנות את ההגדרה בעריכת פרטי הכרטיס");
		this.honorsSetsData.createAndPrintHonorsSetLetters(this.id)
		.subscribe(data => {
			let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');			
		   popupWinindow.document.open();		   
		   popupWinindow.document.write('<html><head><style type="text/css">.MsoNormal { margin-top: 0px; margin-bottom: 0px;} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { page-break-after: always; } }  </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
		//    popupWinindow.document.write('<html><head><style type="text/css"> @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
		   popupWinindow.document.close();
	   });
	};


	public loadHonorsSet(): void {
		this.honorsSetsData.get(this.id)
			.subscribe((c: any) => this.honorsSet = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['honorsSetID'];
			this.loadHonorsSet();
		});
	}
}
