import { DashboardDirectorDataService } from './dashboard-director/dashboard-director.data';
import { ModalContainerComponent } from './../components/modal/modal-container.cmp';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './dashboard-payments-view-dlg.tmpl.html'
})
export class PaymentsViewDlg implements OnInit {
    public data: any;

    constructor(
        private dashboardDirectorDataService: DashboardDirectorDataService,
        private modalContainerComponent: ModalContainerComponent

    ) { }
    paymentCommitmentsList;

    ngOnInit(): void {
        // this.dashboardDirectorDataService.getPaymentCommitmentsInfo(this.data)
        //     .subscribe(x => {
        //         this.paymentCommitmentsList = x;
        //     });
    }
}
