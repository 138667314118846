
import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({

    selector: 'dateMonth-input',
    templateUrl: 'dateMonth-input.tmpl.html',
})



export class DateMonthInput implements OnInit {
    constructor(
        private cdref: ChangeDetectorRef
    ) {

    }

    @Input('placeholder') placeholder?: string = 'שנה וחודש...';
    @Input('monthInputControl') monthInputControl: FormControl;
    @Input('yearInputControl') yearInputControl: FormControl;
    @Input('isRequired') isRequired: boolean = false;
    @Input('withLabel') public withLabel: boolean = true;
    month;
    year;
    monthsList;


    isLapYear(jewishYear: number) {
        if ((((jewishYear * 7) + 1) % 19) < 7) {
            return true;
        }
        else {
            return false;
        }
        //this.cdref.detectChanges();
    }

    fillMonths(yearValue) {
        if (this.isLapYear(yearValue)) {
            this.monthsList = [
                { value: 8, label: 'ניסן' , order: 8 },
                { value: 9, label: 'אייר' , order: 9 },
                { value: 10, label: 'סיון' , order: 10 },
                { value: 11, label: 'תמוז' , order: 11 },
                { value: 12, label: 'אב' , order: 12 },
                { value: 13, label: 'אלול' , order: 13 },
                { value: 1, label: 'תשרי' , order: 1 },
                { value: 2, label: 'חשון' , order: 2 },
                { value: 3, label: 'כסלו' , order: 3 },
                { value: 4, label: 'טבת' , order: 4 },
                { value: 5, label: 'שבט' , order: 5 },
                { value: 6, label: 'אדר א' , order: 6 },
                { value: 7, label: 'אדר ב' , order: 7 },
            ];
        } else {
            this.monthsList = [
                { value: 7, label: 'ניסן' , order: 7 },
                { value: 8, label: 'אייר' , order: 8 },
                { value: 9, label: 'סיון' , order: 9 },
                { value: 10, label: 'תמוז' , order: 10 },
                { value: 11, label: 'אב' , order: 11 },
                { value: 12, label: 'אלול' , order: 12 },
                { value: 1, label: 'תשרי' , order: 1 },
                { value: 2, label: 'חשון' , order: 2 },
                { value: 3, label: 'כסלו' , order: 3 },
                { value: 4, label: 'טבת' , order: 4 },
                { value: 5, label: 'שבט' , order: 5 },
                { value: 6, label: 'אדר' , order: 6 },
            ];

        }
    }


    years = [
        { value: null, label: '...' },
        { value: 5776, label: 'תשעו' },
        { value: 5777, label: 'תשעז' },
        { value: 5778, label: 'תשעח' },
        { value: 5779, label: 'תשעט' },
        { value: 5780, label: 'תשפ' },
        { value: 5781, label: 'תשפא' },
        { value: 5782, label: 'תשפב' },
        { value: 5783, label: 'תשפג' },
        { value: 5784, label: 'תשפד' },
        { value: 5785, label: 'תשפה' },
        { value: 5786, label: 'תשפו' },
        { value: 5787, label: 'תשפז' },
        { value: 5788, label: 'תשפח' },
        { value: 5789, label: 'תשפט' },
        { value: 5790, label: 'תשצ' },
    ];

    // yearSelect(e) {
    //      this.fillMonths(+e.target.value); 
    // }

    ngOnInit() {
 
        if (this.yearInputControl.value != null) {
            this.year = +this.yearInputControl.value;
        }
        if (this.monthInputControl.value != null) {
            this.month = +this.monthInputControl.value;
        }
        this.yearInputControl.valueChanges.subscribe(x => {
            this.fillMonths(x);      
        })


        if (this.isRequired) {
            this.yearInputControl.setValidators(Validators.required);
            this.monthInputControl.setValidators(Validators.required);
          }
        this.cdref.detectChanges();
     
    }

}

