import { Observable, throwError } from 'rxjs';
import { tap, filter, pairwise } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './../components/alert/alert.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, RoutesRecognized } from '@angular/router';
import { I } from '@angular/cdk/keycodes';



@Injectable({
  providedIn: 'root',
})


export class ToraxHttp {


  constructor(
    private http: HttpClient,
    private alert: AlertService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(),
    ).
      subscribe((event: any[]) => {
        //      console.log(event[0].urlAfterRedirects);
        this.previousUrl = event[0].urlAfterRedirects;
      });
  }



  previousUrl: string;
  public inReload: boolean = false;


  getNull() {
    return "";
  }

  // private hideProgressBar() {
  //     this.inReload = false;
  //     // this.cdRef.detectChanges();
  // }
  // private showProgressBar() {
  //     this.inReload = true;
  //     // this.cdRef.detectChanges();
  // }
  getFile(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  head(url: string) {
    return this.http.head(`${environment.apiUrl}/${url}`);
  }

  get(url: string) {
    return this.http.get(`${environment.apiUrl}/${url}`, { withCredentials: true });
  }

  recIn(url: string, id: number) {
    return this.http.get(`${environment.apiUrl}/${url}/recordInfo/${id}`, { withCredentials: true });
  }

  post(url: string, body: any) {
    return this.http.post(`${environment.apiUrl}/${url}`, body, { withCredentials: true });
  }

  update(url: string, body: any) {
    return this.http.post(`${environment.apiUrl}/${url}`, body, { withCredentials: true })
      .pipe(
        tap(
          data => {
            let ret = this.extractData(data);
            this.onUpdated((<any>data).id, url);
            this.showSuccessMessage(ret && ret.message ? ret.message : "המידע נשמר!");
          },
          error => {
            this.handleError(error);
          }
        ));
  }

  postWithResponce(url: string, body: any) {
    //this.showProgressBar();
    return this.http.post(`${environment.apiUrl}/${url}`, body, { withCredentials: true })
      .pipe(
        tap(
          data => {
            //this.hideProgressBar();
            this.extractCustomData(data);
          },
          error => {
            this.handleError(error);
          }
        ));
  }

  postWithResponceAudio(url: string, body: any) {
    //this.showProgressBar();
    return this.http.post(`${environment.apiUrl}/${url}`, body, { withCredentials: true })
      .pipe(
        tap(
          data => {
            //this.hideProgressBar();
            this.extractCustomData(data);
          },
          //error => this.handleError(error)
        ));
  }

  postAndDownload(url: string, body: any) {
    //this.showProgressBar();
    return this.http.post(`${environment.apiUrl}/${url}`, body, { withCredentials: true, responseType: 'blob' })
      .pipe(
        tap(
          data => {
            //this.hideProgressBar();
            this.extractCustomData(data);
          }, //error => this.handleError(error)
        ));
  }

  getWithResponceToDownload(url: string) {
    //this.showProgressBar();
    return this.http.get(`${environment.apiUrl}/${url}`, { withCredentials: true, responseType: 'blob' })
      .pipe(
        tap(
          data => {
            //this.hideProgressBar();
            this.extractCustomData(data);
          },
          //error => this.handleError(error)
        ));
  }

  getWithResponce(url: string) {
    //this.showProgressBar();
    return this.http.get(`${environment.apiUrl}/${url}`, { withCredentials: true })
      .pipe(
        tap(
          data => {
            //this.hideProgressBar();
            this.extractData(data);
          },
          //error => this.handleError(error)
        ))
  }
  getObjectReponse(url: string) {
    //this.showProgressBar();
    return this.http.get(`${environment.apiUrl}/${url}`, { withCredentials: true })
      .pipe(
        tap(
          data => {
            //this.hideProgressBar();
            this.extractData(data);
          },   //error => this.handleError(error)
        ));
  }

  getDeleteResponse(url: string) {
    //this.showProgressBar();
    return this.http.get(`${environment.apiUrl}/${url}`, { withCredentials: true })
      .pipe(
        tap(
          (data: any) => {
            //this.hideProgressBar();
            this.extractCustomData(data);
            if (data && data.message) {
              this.alert.success(data.message);
            }
          },
          //error => this.handleError(error)
        ));
  }


  // getWithBlobResponce<T>(url: string) {
  //     return this.http.get(`${environment.apiUrl}/${url}`, { withCredentials: true, responseType: 'blob', observe: 'response' });
  // }


  // postToStringResponse<Response>(url: string, body: any) {
  //     return this.http.post(url, body, options)
  //         .map(r => this.extractString(r))
  //         .catch(r => this.handleError(r));
  // }

  // postPromise<T>(url: string, body: any, options?: RequestOptionsArgs) {
  //     return this.http.post(url, body, options)
  //         .toPromise()
  //         .then((res) => {
  //             if (res && res.status == 204) {
  //                 this.handleError(res);
  //             }
  //             let ret = this.extractReponse(res);
  //             this.onUpdated(ret.id);
  //             this.showSuccessMessage(ret.message ? ret.message : "המידע נשמר!");
  //             return ret;
  //         })
  //         .catch(r => {
  //             this.handleError(r);
  //         }
  //         );
  // }

  // postPromiseNonMessage<T>(url: string, body: any, options?: RequestOptionsArgs) {
  //     return this.http.post(url, body, options)
  //         .toPromise()
  //         .then((res) => {
  //             if (res && res.status == 204) {
  //                 this.handleError(res);
  //             }
  //             let ret = this.extractReponse(res);
  //             this.onUpdated(ret.id);
  //             //  this.showSuccessMessage(ret.message ? ret.message : "המידע נשמר!");
  //             return ret;
  //         })
  //         .catch(r => {
  //             this.handleError(r);
  //         }
  //         );
  // }


  public updatedEvent: EventEmitter<any> = new EventEmitter<any>();


  private onUpdated(id: any, type:string = ''): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit({ id: id || null || undefined, type: type }); //this.previousUrl
    }
  }

  private extractData(res: any) {
    let body = res;
    if (res.item1 && res.item2 && res.item3) {
      body = res.item2;
      if (res.item1 == true) {
        this.showInfoMessage(res.item3);
      } else if (res.item1 == false) {
        this.showErrorMessage(res.item3);
      }
    }
    return body || {};
  }



  private extractCustomData(res: any) {
    //  console.log("typeof res", typeof res);
    return res;
    if (typeof res === "string") {
      return res;
    }
    else {
      let body = res;
      return body || {};
    }
  }


  public handleError(error) {
    console.log("error", error);
    let errorMessage = "Error: ";

    if (error != undefined) {
      if (error.error) {  // instance of ErrorEvent
        errorMessage = error.error.message ? error.error.message : error.message; //JSON.parse(error.error);
      } else if (typeof error === "string") {
        errorMessage += error;
      } else if (error && error.status || error && error.message) {
        errorMessage += `Code: [${error.status}], Message: [${error.message}]`;
      } else {
        errorMessage += error.toString();
      }
    }

    this.showErrorMessage(errorMessage);


    return throwError(error);
  }

  private showSuccessMessage(body: any): void {
    var successMsg = "";
    let successInfo: any;
    try {
      successInfo = JSON.parse(body);
    } catch (e) {
      if (body != undefined) {
        if (typeof body === "string") {
          successMsg = body;
        } else {
          successMsg = body.toString();
        }
      }
    }
    if (successInfo) {
      this.alert.success(successInfo);
    }
    else {
      this.alert.success(successMsg);
    }
  }

  private showInfoMessage(body: any): void {
    var successMsg = "";
    let successInfo: any;
    try {
      successInfo = JSON.parse(body);
    } catch (e) {
      if (body != undefined) {
        if (typeof body === "string") {
          successMsg = body;
        } else {
          successMsg = body.toString();
        }
      }
    }
    if (successInfo) {
      this.alert.info(successInfo);
    }
    else {
      this.alert.info(successMsg);
    }
  }


  private showErrorMessage(body: any): void {

    if (body && (body.Message || body.message || typeof body === "string")) {
      this.alert.error(body);
    } else {
      this.alert.error('אירעה שגיאה בעת ביצוע הפעולה');
    }
  }





}

export class PagedList {
  public list: any[];
  public page: number;
  public pagesCount: number;
  public rowsCount: number;
}
