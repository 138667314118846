import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertService, Alert } from './alert.svc';

@Component({
    
    selector: 'alert-global',
    templateUrl: 'alert.tmpl.html',
    styleUrls: ['./alert.css'],
    encapsulation: ViewEncapsulation.None

})
export class AlertComponent implements OnInit {
    constructor(private svc: AlertService) {
        this.alertList = this.svc.alertList;
    }

    public alertList: Alert[] = []
    ngOnInit() { }
} 