
import { Component, OnInit, Inject, Input } from '@angular/core';
import { DonationCategory } from './donationCategory';


@Component({
    selector: 'donationCategory-short-details',
    templateUrl: './donationCategory-short-details.tmpl.html'
})
export class DonationCategoryShortDetails    {
    @Input() donationCategory: DonationCategory;



}

