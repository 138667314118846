import { MatDialog } from '@angular/material/dialog';
import { LoaderBarService } from './../../components/loader-bar/loader-bar.service';

import { CcChargeDlg } from '../../cc-charge/cc-charge-dlg.cmp';
import { CcChargeModel } from '../../cc-charge/cc-charge-model';
import { PaymentsCancellationReceiptDlg } from '../payments-cancellationReceipt-dlg.cmp';
import { ModalService } from '../../components/modal/modal.svc';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { AlertService } from '../../components/alert/alert.svc';
import { PaymentsData } from '../payments.data';
import { Payment, CancellationReceiptModel } from './payment';
import { ServicesData } from '../../services/services.data';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { saveAs } from 'file-saver';

@Component({
  selector: 'payment-details',
  templateUrl: './payment-details.tmpl.html'
})

export class PaymentDetails implements OnInit {
  constructor(
    public paymentsData: PaymentsData,
    private activatedRoute: ActivatedRoute,
    private alertSvc: AlertService,
    private formBuilder: FormBuilder,
    private toraxHttp: ToraxHttp,
    private _matDialog: MatDialog,
    private modal: ModalService,
    public servicesData: ServicesData,
    private sanitizer: DomSanitizer,
    private loaderBarService: LoaderBarService
  ) {

    this.toraxHttp.updatedEvent.subscribe((id) => {
      if (id > 0 && this.payment && id === this.payment.paymentID) {
        this.loadPayment();
      }
    });
  }

  showPayments: boolean = false;

  payment: Payment;
  id: number;
  paymentForm: FormGroup;

  viwPayments() {
    this.showPayments = !this.showPayments;
  }

  releventReceiptNumber: number;
  public loadPayment(): void {
    if (this.id > 0) {
      this.paymentsData.get(this.id)
        .subscribe((p: any) => {
          this.payment = p;
          this.fillForm(p);
          this.releventReceiptNumber = p.receiptNumber || p.usaReceiptNumber || p.ukReceiptNumber || p.ichudILReceiptNumber;
          console.log("releventReceiptNumber", this.releventReceiptNumber);
        });
    }

  }




  private openCcChargeDlg(): void {
    let ccChargeModel = new CcChargeModel();
    ccChargeModel.amount = this.payment.amount;
    ccChargeModel.currency = this.payment.currency;
    ccChargeModel.currencyIcon = this.payment.currencyIcon;
    ccChargeModel.contactID = this.payment.contactID;
    ccChargeModel.contactName = this.payment.contactName;
    ccChargeModel.paymentID = this.payment.paymentID;
    ccChargeModel.payMethodID = this.payment.payMethodID;
    ccChargeModel.paymentOption = this.payment.paymentOption;
    ccChargeModel.payMethodTitle = this.payment.payMethodTitle;
    ccChargeModel.termID = this.payment.termID;
    ccChargeModel.termName = this.payment.termName;
    ccChargeModel.paymentCommitments = this.payment.paymentCommitments;


    // Actions: [
    //     { Text: 'ביטול', CssClass: 'btn-default', Action: () => true },
    //     { Text: 'אישור', CssClass: 'btn-primary', Action: this.publish, Validate: true }
    // ],

    this._matDialog.open(CcChargeDlg, {
      data: ccChargeModel
    });

    // this.modal.show({
    //     Header: 'סליקת אשראי',
    //     Width: '50%',
    //     BodyComponentType: CcChargeDlg,
    //     Actions: [
    //         { Text: 'חזור', CssClass: 'btn-default', Action: () => true }
    //     ],
    //     Data: {
    //         ccChargeModel
    //     }
    // });
  }

  // private openCcChargeDlg(): void {
  // 	let cc = new CcChargeModel();
  // 	cc.paymentID = this.id;
  // 	cc.amount = 15555;

  // 	this.modal.show({
  // 		Header: 'סליקת אשראי',
  // 		BodyComponentType: CcChargeDlg,
  // 		Actions: [
  // 			{ Text: 'ביטול', CssClass: 'btn-default', Action: () => true },
  // 			{ Text: 'אישור', CssClass: 'btn-primary', Action: () => false, Validate: true }
  // 		],
  // 		Data: {
  // 			cc
  // 		}
  // 	});
  // }



  private fillForm(p: Payment): void {
    this.paymentForm.patchValue(p);
  }

  public publish(): void {
    console.log('publish run');
    this.paymentsData.chargePayment(this.paymentForm.value)
      .subscribe((c: any) => {
        let debitApproveNumber = +c.debitApproveNumber;
        // tslint:disable-next-line:curly
        if (debitApproveNumber > 1)
          this.alertSvc.success(`תגובת אשראי: \nמס' אישור${debitApproveNumber} \n${c.message} \n${c.error}`);
        else {
          this.alertSvc.error(`תגובת אשראי: \n${c.message} \n${c.error}`);
        }
      },
        error => {
          console.log(error);
        });
  };

  public refundPayment(): void {
    console.log('refundPayment run');
    this.paymentsData.refundPayment(this.paymentForm.value)
      .subscribe((c: any) => {
        let debitApproveNumber = +c.debitApproveNumber;
        // tslint:disable-next-line:curly
        if (debitApproveNumber > 1)
          this.alertSvc.success(`תגובת אשראי: \nמס' אישור${debitApproveNumber} \n${c.message} \n${c.error}`);
        else {
          this.alertSvc.error(`תגובת אשראי: \n${c.message} \n${c.error}`);
        }
      },
        error => { });
  };

  public createAndPrintReceipt(): void {
    let confirmMargeReceipt = true;
    if (!this.releventReceiptNumber && this.payment.paymentGroupID && this.payment.paymentGroupID > 0) {
      confirmMargeReceipt = confirm("תשלום זה הינו חלק מסידרת תשלומים\nהאם ברצונך להפיק קבלה מרוכזת לכל הסידרה?")
    }

    let doDownload = false;
    this.paymentsData.createAndPrintReceipt(this.id, doDownload, (confirmMargeReceipt ? this.payment.paymentGroupID : null))
      .subscribe(blob => {
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html style="background-image: url(\'usaReceiptBackround.jpg\');" ><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { } </style></head><body onload="window.print()"><div>' + (<any>blob).value + '</div></body></html>');
        popupWinindow.document.close();
        this.toraxHttp.updatedEvent.emit(this.id);
      });
  };

  public createAndDownloadReceipt(): void {
    let confirmMargeReceipt = true;
    if (!this.releventReceiptNumber && this.payment.paymentGroupID && this.payment.paymentGroupID > 0) {
      confirmMargeReceipt = confirm("תשלום זה הינו חלק מסידרת תשלומים\nהאם ברצונך להפיק קבלה מרוכזת לכל הסידרה?")
    }

    let doDownload = true;


    this.paymentsData.createAndPrintReceipt(this.id, doDownload, (confirmMargeReceipt ? this.payment.paymentGroupID : null))
      .subscribe((blob: any) => {
        var blb = new Blob([blob], { type: "application/pdf;charset=utf-8" });
        saveAs(blb, `קבלה תשלום מס' ${this.id}.pdf`);
      });
  };


  //@ViewChild('contentToConvert') reportContent: ElementRef;




  public createAndPrintReceiptWithTemplate(templateId): void {
    this.paymentsData.createAndPrintReceiptWithTemplate(this.id, templateId)
      .subscribe(blob => {
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { } </style></head><body  onload="window.print()"><div>' + (<any>blob).value + '</div></body></html>');
        popupWinindow.document.close();
        this.toraxHttp.updatedEvent.emit(this.id);
      });
  };


  private initForm() {
    this.paymentForm = this.formBuilder.group({
      paymentID: [this.id || 0],
      canMakeClearing: [],
    });
  }

  private cancellationReceipt = (paymentsCancellationReceiptDlg: PaymentsCancellationReceiptDlg): void => {
    if (paymentsCancellationReceiptDlg.formData.valid) {
      this.paymentsData.cancellationReceipt(<CancellationReceiptModel>paymentsCancellationReceiptDlg.formData.value)
        .subscribe(data => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          // tslint:disable-next-line:max-line-length
          popupWinindow.document.write('<html dir="rtl"><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; page-break-inside: avoid; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
          popupWinindow.document.close();
          this.toraxHttp.updatedEvent.emit(this.id);
          return true;
        });
    }
  }




  private openCancellationReceiptDlg(): void {
    let cancellationReceiptModel = new CancellationReceiptModel();
    cancellationReceiptModel.paymentID = this.paymentForm.controls['paymentID'].value;

    this.modal.show({
      Header: 'פרטי ביטול קבלה',
      BodyComponentType: PaymentsCancellationReceiptDlg,
      Actions: [
        { Text: 'ביטול', CssClass: 'btn-default', Action: () => true },
        { Text: 'אישור', CssClass: 'btn-primary', Action: this.cancellationReceipt, Validate: true }
      ],
      Data: {
        cancellationReceiptModel
      }
    });
  }


  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = + params['paymentID'];
    });
    this.initForm();
    this.loadPayment();
  }
}
