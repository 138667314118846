<div *ngIf="tishInvited">
    <div class="modal-header">
        <h2>פרטי מוזמן [{{ (id > 0 ? id : 'חדש') }}] </h2>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
            <dt>כותרת אירוע</dt>
            <dd>{{ tishInvited.tishEventTitle }}</dd>
            <hr>
            <dt>מוזמן</dt>
            <dd>{{ tishInvited.tishInvitedName }}</dd>
            <hr>
            <dt>מספר מקום:</dt>
            <dd>{{ tishInvited.placeNumber }}</dd>
            <hr>
            <dt>שולחן</dt>
            <dd>{{ tishInvited.tishTableName }}</dd>
            <hr>
            <dt>איזור</dt>
            <dd>{{ tishInvited.tishAreaName }}</dd>
            <hr>
            <dt>קבוצה</dt>
            <dd>{{ tishInvited.tishGroupName }}</dd>
            <hr>
            <dt>כרטיסי כניסה נוספים</dt>
            <dd>{{ tishInvited.quantityAdditionalTickets }}</dd>
            <hr>
        </dl>
    </div>
</div>