import { ContactDetailsDlg } from './contacts-details-dlg.cmp';
import { Contact } from './contact/contact';

import { AuthenticationService } from './../_guards/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactsSearchInput } from '../components/contacts-search-input/contacts-search-input.component';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';

import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';

import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ContactsData } from './contacts.data';

import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, HostListener } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AlertService } from '../components/alert/alert.svc';
import { MatTable } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ContactGroupListDlg } from './contactGroups/contactGroups-list-dlg.cmp';
import { ContactAppointmentListDlg } from './contactAppointments/contactAppointments-list-dlg.cmp';
//import * as signalR from '@aspnet/signalr';

@Component({
  selector: 'contacts-group-list',
  templateUrl: 'contacts-group-list.tmpl.html',
  styleUrls: ['./contacts-list.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ContactsGroupList implements OnInit, AfterViewInit {
  constructor(
    public contactsData: ContactsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private alert: AlertService,
    private cdref: ChangeDetectorRef) {
  }


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInp') searchInp: ContactsSearchInput;
  @ViewChild('cityID') cityID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;
  @ViewChild('table') table: MatTable<any>;



  @Output() selectedRowsChange = new EventEmitter<any>();
  orderButtonIsChicked: boolean = false;
  selection = new SelectionModel<number>(true, []);
  resultsLength = 0;
  stopLoadingPoints: boolean = false;
  isLoadingResults = false;
  isRateLimitReached = false;
  searchContactsListForm: FormGroup;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  pageEvent: PageEvent;
  pageSizeOptions;
  displayedColumns: string[] = ['select', 'buttons', 'contactID', 'perfix', 'lastName', 'firstName', 'streetLine', 'cityName', 'phoneNumber1', 'phoneNumber2', 'shtibelName', 'codeIchud'];
  data: any[] = [];
  contactGroupStatuses: any[];

  openContactGroupsPanel(contactID:number){
      setTimeout(() => {

        const dialogRef = this._matDialog.open(ContactGroupListDlg, {
          data: contactID
        });

        dialogRef.afterClosed().subscribe(result => {
          this.getContacts();
        });
      }, 200);

  }



  openContactAppointmentsPanel(contactID:number){
    setTimeout(() => {

      const dialogRef = this._matDialog.open(ContactAppointmentListDlg, {
        data: contactID
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getContacts();
      });
    }, 200);

}
  addNew() {
    this.router.navigate(['', { outlets: { popup: ['contacts', 'add'] } }]);
  }
  onlySortRecentlyOpenedByCurUser = false;
  topCurUserOpenings() {
    this.onlySortRecentlyOpenedByCurUser = !this.onlySortRecentlyOpenedByCurUser;
    this.searchContactsListForm.controls['onlySortRecentlyOpenedByCurUser'].setValue(this.onlySortRecentlyOpenedByCurUser);

    this.getContacts();

  }
  topOpenings() {
    this.orderButtonIsChicked = !this.orderButtonIsChicked;
    if (this.orderButtonIsChicked) {
      this.addOrder('openingDateTime', true, 1);
    } else {
      this.removeOrder('openingDateTime');
    }

    this.getContacts();
  }

  toogleContactFavorite(row: Contact) {
    this.contactsData.toogleContactFavorite(row.contactID)
      .subscribe((x: boolean) => {
        row.isFavorite = x;
        if (row.isFavorite) {
          this.alert.success(`${row.firstName} ${row.lastName} נוסף בהצלחה למועדפים`);
        } else {
          this.alert.info(`${row.firstName} ${row.lastName} הוסר מרשימת המועדפים`);
        }
      }
      )
  }

  openSelectedRow(contactID) {
    this.router.navigate(['contacts', contactID]);
  }

  openDetails(row) {
    this._matDialog.open(ContactDetailsDlg, {
      // panelClass: 'contact-details',
      data: row,
      width: '90%'
    });
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.contactID === row.contactID);
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  pageChange(e) {
    this.searchContactsListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getContacts();
  }

  public clerFilter() {
    this.initForm();
    this.getContacts();
  }

  public getContacts() {
    if (this.paginator) {
      this.searchContactsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    //this.searchContactsListForm.controls['pageRows'].setValue(this.paginator.pageSize);
    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchContactsListForm.value));
    return this.contactsData!.getList(this.searchContactsListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;

        if (this.orderButtonIsChicked) {
          this.buildDataSource();
        }

        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;

        //trick
        this.stopLoadingPoints = false;


        this.selection.isSelected = this.isChecked.bind(this);
      });
  }

  initForm() {
    this.searchContactsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      cityID: [],
      cityTitle: [],
      shtibelID: [],
      shtibelName: [],
      groupID: [],
      groupIDs: [],
      groupPriorities: [],
      groupStatuses: [],
      onlySortRecentlyOpenedByCurUser: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchContactsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.contactID));
      this.selectedRowsChange.emit(x.source.selected);
    });

    this.searchContactsListForm.controls['groupIDs'].valueChanges.subscribe(x => {
      this.servicesData.getList(`ContactGroupStatuses/${x}`)
        .subscribe(
          (c: any) => {
            this.contactGroupStatuses = c;
          },
          (x) => {
            //console.log("xxxxxxxx", x);
          });
    });
  }

  ngOnInit() {
    let groupID: number = null;
    let groupStatusID: number = null;
    this.activatedRoute.params.forEach((params: Params) => {
      groupID = +params['groupID'] || 0;
      groupStatusID = +params['groupStatusID'] || 0;
    });

    this.stopLoadingPoints = true;

    this.initForm();
    if (groupID && groupID > 0) {
      this.searchContactsListForm.controls['groupIDs'].setValue([groupID]);
    }

    if (groupStatusID && groupStatusID > 0) {
      this.searchContactsListForm.controls['groupStatuses'].setValue([groupStatusID]);
    }

    // let item = sessionStorage.getItem(this.router.url);
    // if (item) {
    //   let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
    //   this.searchContactsListForm.patchValue(frm);
    //   let qur = this.searchContactsListForm.controls['query'].value;
    //   if (frm.orders) {
    //     frm.orders.forEach(x => { this.addOrder(x.key, x.value) });
    //     if (this.searchContactsListForm.controls['orders'].value.at('openingDateTime')) {
    //       this.topOpenings();
    //     } else if (qur != null && qur != '') {
    //       this.getContacts();
    //     }
    //   }
    // }

   // if (groupID && groupID > 0 ) {
      this.getContacts();
    // }
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  groupingColumn = 'openingJewishDate';
  reducedGroups = [];

  /**
   * Rebuilds the datasource after any change to the criterions
   */
  buildDataSource() {
    if (this.sourceList) {
      this.sourceList = this.groupBy(this.groupingColumn, this.sourceList, this.reducedGroups);
    }
  }

  /**
   * Groups the @param data by distinct values of a @param column
   * This adds group lines to the dataSource
   * @param reducedGroups is used localy to keep track of the colapsed groups
   */
  groupBy(column: string, data: any[], reducedGroups?: any[]) {
    if (!column) return data;
    let collapsedGroups = reducedGroups;
    if (!reducedGroups) collapsedGroups = [];
    const customReducer = (accumulator, currentValue) => {
      let currentGroup = currentValue[column];
      if (!accumulator[currentGroup])
        accumulator[currentGroup] = [{
          groupName: `${column} ${currentValue[column]}`,
          value: currentValue[column],
          isGroup: true,
          reduced: collapsedGroups.some((group) => group.value == currentValue[column])
        }];

      accumulator[currentGroup].sort(x => x).push(currentValue);

      return accumulator;
    }
    let groups = data.reduce(customReducer, {});
    let groupArray = Object.keys(groups).map(key => groups[key]);
    let flatList = groupArray.reduce((a, c) => { return a.concat(c); }, []);

    return flatList.filter((rawLine) => {
      return rawLine.isGroup ||
        collapsedGroups.every((group) => rawLine[column] != group.value);
    });
  }

  /**
   * Since groups are on the same level as the data,
   * this function is used by @input(matRowDefWhen)
   */
  isGroup(index, item): boolean {
    return item.isGroup;
  }

  /**
   * Used in the view to collapse a group
   * Effectively removing it from the displayed datasource
   */
  reduceGroup(row) {
    row.reduced = !row.reduced;
    if (row.reduced)
      this.reducedGroups.sort(x => x).push(row);
    else
      this.reducedGroups = this.reducedGroups.filter((el) => el.value != row.value);

    this.buildDataSource();
  }


  printSticks(state: string = "") {
    //console.log(this.selection);
    let contactIDs = this.searchContactsListForm.controls['selectedRows'].value.map(x => x).join();

    this.contactsData.printSticks(contactIDs, state)
      .subscribe(
        data => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write('<html dir="rtl"><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; margin: 0px } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; page-break-inside: avoid; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
          popupWinindow.document.close();
          return true;
        }
      )
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getContacts();
  }

  public search() {
  }

  removeOrder(propertyName: string) {
    const ordersControl = <FormArray>this.searchContactsListForm.controls['orders'];
    let i = null;
    ordersControl.controls.forEach((ctrl, ix) => {
      if ((<any>ctrl).value.key == propertyName) {
        i = ix;
      }
    });
    if (i >= 0) {
      ordersControl.removeAt(i);
    }
  }

  addOrder(propertyName: string, desc: boolean = false, priorityOrder: number = null) {
    const ordersControl = <FormArray>this.searchContactsListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      if (priorityOrder >= 1) {
        ordersControl.insert(priorityOrder - 1, ord);
      } else {

        ordersControl.push(ord);
      }
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchContactsListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}
