import { Process } from './process/process';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core'; 


@Injectable()

export class ProcessesData {
    private url = 'api/processes';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }



    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(process: Process) {
        return this.toraxHttp.update(this.url, process);
    }

    getAdd(contactID: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactID || 0}`);
    }
}