<div *ngIf="collectionBuilder">
  <div class="modal-header">
    <div class="row">
      <div class="col-sm-4">
        <span *ngIf="(collectionBuilder.plannedCount - collectionBuilder.exludesCount) < 0"
          style="color: red; font-size: 125%;"><i class="fa fa-warning"></i>רשומה בעייתית, אל תבצע גביה,
          <br><small>דווח למנהל המערכת</small>
        </span>
      </div>
      <div class="col-sm-8 text-left" [formGroup]="collectionBuilderForm" novalidate>
        <a class="btn btn-primary" style="background-color: red;" *ngIf="collectionBuilder.termID == 28"
          (click)="openMasavRefundDlg()">
          <i class="fa fa-download">&nbsp;הורד קובץ לזיכויי מס"ב</i>
        </a>
        <a class="btn btn-primary" *ngIf="collectionBuilder.termID == 8 || collectionBuilder.termID == 30"
          (click)="openMasavDlg()">
          <i class="fa fa-download">&nbsp;הורד קובץ מס"ב</i>
        </a>
        <button [disabled]="disableBtn"
          *ngIf="collectionBuilder.termID != null && collectionBuilder.termID != 8 && collectionBuilder.termID != 28"
          class="btn btn-primary" (click)="publish()">
          <i class="fa fa-save"></i>&nbsp;סליקת אשראי</button>
      </div>
    </div>


  </div>
  <div class="modal-body" style="resize: both; overflow: auto;" fxLayout="row" fxLayout.sm="row wrap"
  fxLayoutAlign="start start" fxLayoutGap="15px">
  <div  class="example-card">
    <mat-card fxLayout="column"  class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>פרטי גביה: [{{ id }}]</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content style="width: 100%;">
          <dl class="dl-horizontal">
            <dt>תאריך לגביה</dt>
            <dd>{{ collectionBuilder.collectionDate | date:'dd/MM/yyyy' }}
              <br>{{ collectionBuilder.jewishCollectionDate }}
            </dd>
            <hr>
            <dt>כלול תאריכים מוקדמים בחודש זה</dt>
            <dd>
              &nbsp;<i *ngIf="collectionBuilder.collectionDateIsTill" class="fa fa-check"></i>
            </dd>
            <hr>
            <dt>איש לגביה</dt>
            <dd>{{ collectionBuilder.contactName }}</dd>
            <hr>
            <dt>מזהה תשלום לגביה</dt>
            <dd>{{ collectionBuilder.paymentID }}</dd>
            <hr>
            <dt>סה"כ סכום כולל</dt>
            <dd>{{ collectionBuilder.transactionsSum }}</dd>
            <hr>
            <dt>כמות פעולות כולל</dt>
            <dd>{{ collectionBuilder.transactionsCount }}</dd>
            <hr>
            <dt>כמות פעולות לגביה</dt>
            <dd>{{ (collectionBuilder.plannedCount - collectionBuilder.exludesCount)}}</dd>
            <hr>
            <dt>סה"כ סכום לגביה</dt>
            <dd>{{ (collectionBuilder.plannedSum - collectionBuilder.exludesSum) }}</dd>
            <hr>
            <dt>שם מסוף</dt>
            <dd>{{ collectionBuilder.termName }}</dd>
            <hr>
            <dt>סה"כ סכום שנגבה</dt>
            <dd>{{ collectionBuilder.succeededSum }}</dd>
            <hr>
            <dt>כמות פעולות שהצליחו</dt>
            <dd>{{ collectionBuilder.succeededCount }}</dd>
            <hr>

          </dl>
        </mat-card-content>
      </mat-card>
    </div>
    <div style="width: 100%">
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>תנועות</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%; padding: 0 !important;">
          <transactions-list [collectionBuilderID]="collectionBuilder.collectionBuilderID">
            Loading...</transactions-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
