<div *ngIf="letterSetting">
    <div class="modal-header">
        <h2>פרטי מכתב [{{ (id > 0 ? id : 'חדש') }}] </h2>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
            <dt>שם</dt>
            <dd>{{ letterSetting.letterName }}</dd>
            <hr>
            <dt>תבנית כתובת</dt>
            <dd>
                <div innerHTML="{{letterSetting.titleAndShippingAddressTemplate}}"></div>
            </dd>
            <hr>
            <dt>תבנית מכתב</dt>
            <dd>
                <div [innerHTML]="letterSetting.mainTemplate"></div>
            </dd>
            <hr>
            <dt>תבנית תשלום</dt>
            <dd>
                <div [innerHTML]="letterSetting.paymentTemplate"></div>
            </dd>
            <hr>
            <dt>שוליים</dt>
            <dd>ימין:{{ letterSetting.marginR }}px שמאל:{{ letterSetting.marginL }}px למעלה:{{ letterSetting.marginT
                }}px למטה:{{ letterSetting.marginB }}px</dd>
            <hr>
            <dt>פורמט תאריך</dt>
            <dd>{{ letterSetting.letterDateFormat }}</dd>
            <hr>
            <dt>גירסת עיצוב</dt>
            <dd>{{ letterSetting.templateVertion }}</dd>
            <hr>
            <dt>גודל דף</dt>
            <dd>{{ letterSetting.pageSize }}</dd>
            <hr>
            <dt>פונט ב"מ</dt>
            <dd>{{ letterSetting.fontName }}</dd>
            <hr>

        </dl>
    </div>
</div>