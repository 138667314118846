import { ActivatedRoute } from '@angular/router';
import { ServicesData } from 'src/app/services/services.data';
import { ContactsData } from 'src/app/contacts/contacts.data';
import { ContactsListDashboard } from '../contacts-list-dashboard.cmp';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation, Input, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { Contact } from './contact';

@Component({
    selector: 'contact-dashboard-report',
    templateUrl: './contact-dashboard-report.tmpl.html',
    styleUrls: ['./../contacts-list.scss'],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDashboardReportComponent implements AfterViewInit, OnChanges {
    @Input('contact') public contact: Contact;
    @Input('groupSubjects') public groupSubjects: string;

    selectedSubjects: number[];

    contacts: Contact;
    enabled: boolean;

    /**
     * Constructor
     */
    constructor(
        private _contactsListComponent: ContactsListDashboard,
        private contactsData: ContactsData,
        private changeDetector: ChangeDetectorRef,
        private servicesData: ServicesData, 
    ) {
    }
    ngAfterViewInit(): void {
        console.log("groupSubjects",this.groupSubjects);
        if (this.groupSubjects) {
            this.selectedSubjects = this.groupSubjects.split(',').map(s => +s);
        }
    }
    loadData() {
        this.enabled = false;
        if (this.contact?.contactID) {
            this.contact.commitments = null;
            if (this.contact?.contactID) { // let subjectsStr = this.selectedSubjects
                this.contactsData!.getContactReport(this.contact.contactID, (this.selectedSubjects ? this.selectedSubjects.join(',') : ''))
                    .subscribe((data: any) => {
                        this.contact.commitments = data;
                        this.changeDetector.detectChanges();
                        this.enabled = true;
                    });
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {  

        this.loadData();
    }



    closeDrawer(): Promise<MatDrawerToggleResult> {
        return this._contactsListComponent.matDrawer.close();
    }
}
