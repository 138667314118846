export class Commitment {
    commitmentID: number;
    honorID: any;
    registrationID?:number;
    contactID: number;
    contactName: string;
    payMethodID: number;
    commitmentSubject: number;
    commitmentReasonID: number;
    pulseAmount: number;
    specialtyMonthsAmount?: number
    globalAmount: number;
    ilsRate: number;
    globalAmountValueInILS: number;
    pulseAmountValueInILS: number;
    donationType: number;
    fromDate: Date;
    quantityCommitments: number;
    lastPayRecordOn: boolean;
    closedDate: Date;
    currency: number; 
    // holdingFromMonth: number;
    // holdingFromYear: number;
    // holdingAmount: number;
    // holdingSpecialtyMonthsAmount: number;
    hebrewDate: boolean;
    payMethodTitle: string;
    dayOfMonth: number;
    donationTypeName: string;
    defaultReceiptAddress: string;
    isHoldingCommitment: boolean;
    commitmentFundraisingID?:number;
    commitmentTitle: string;
}




export class ClosedCommitmentMdl {
    commitmentID: number
    closedComment: string;
    closedDate;
    closedStatus: number;
}

// export class CommitmentReciptsMdl {
//     commitmentID: number
//     paymentsIds;
//     mergePayments: boolean
// }

