import { AlertService } from './../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ServicesData } from './../../services/services.data';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'data-collection',
  styleUrls: ['./data-table.component.scss'],
  templateUrl: './data-table.component.html',
})
export class DataCollectionComponent implements AfterViewInit {

  constructor(
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private alert: AlertService,
    private cdref: ChangeDetectorRef) {
    }
  @Input() path: string="test-local";
  @Input() displayedColumns: any[]=[];
  @Input() controllerName: string;
  @Input() dataProvider: any;


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() selectedRowsChange = new EventEmitter<any>();
  selection = new SelectionModel<number>(true, []);
   resultsLength = 0;
  stopLoadingPoints: boolean = false;
isLoadingResults = false;
isRateLimitReached = false;
searchContactsListForm: FormGroup;
sourceList;
pageRows = 20;
rowsCount;
page;
panelOpenState: boolean = false;
rows: any[];
loadingIndicator: boolean;
reorderable: boolean;
pageEvent: PageEvent;
pageSizeOptions;

  dataSource: MyDataSource;
  dataSubject = new BehaviorSubject<any[]>([]);
  data: any[] = [];

  // ngOnInit() {
  //   this.dataSource =  new MyDataSource(this.dataSubject);

  //   this.dataSubject.next([{name:'simon'}, {name:'mark'}, {name:'matthew'}]);
  // }


  public getList() {
    if (this.paginator) {
      this.searchContactsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    //this.searchContactsListForm.controls['pageRows'].setValue(this.paginator.pageSize);
    //this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchContactsListForm.value));
    return this.dataProvider!.getList(this.searchContactsListForm.value)
      .subscribe((data: any) => {
      //  this.dataSource =  new MyDataSource(data.list);

    // this.dataSubject.next(data.list);

        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;

        //trick
        this.stopLoadingPoints = false;
      });
  }



  initForm() {
    this.searchContactsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      cityID: [],
      cityTitle: [],
      shtibelID: [],
      shtibelName: [],
   //   responsibleID: [],
    //  responsibleName: [],
      groupID: [],
      groupIDs: [],
      //comments: [],
      groupPriorities: [],
      // contactAffiliations: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchContactsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.contactID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.contactID === row.contactID);
    if (found) {
      return true;
    }
    return false;
  }
  ngAfterViewInit() {
    this.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchContactsListForm.patchValue(frm);
    }
  this.getList();

    this.selection.isSelected = this.isChecked.bind(this);


  }
}
export class MyDataSource extends DataSource<any[]> {
  constructor(private subject: BehaviorSubject<any[]>) {
    super ();
  }
  connect (): Observable<any[]> {
    return this.subject.asObservable();
  }
  disconnect (): void {}
}

