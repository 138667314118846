<div class="row" style="background-color: white;">
    <div style="padding:12px;" class="col-sm-12">
        <div class="col-sm-5">
            <h1 style="margin-top: 0;">
                החזקת בית המדרש
            </h1>
        </div>
        <div class="col-sm-7 text-left">
            <button style="background-color: navy;" class="btn btn-primary" (click)="openHoldingsPrintDlg()">
                הדפס רשימה</button>
            <button style="background-color: navy;" class="btn btn-primary"
                (click)="viwHoldingsList = !viwHoldingsList; viwHistoryList = false">
                {{(viwHoldingsList? 'הסתר רשומות' : 'הצג רשומות')}} </button>
            <button style="background-color: navy;" class="btn btn-primary" (click)="viwHistoryList = !viwHistoryList; viwHoldingsList = false">
                {{(viwHistoryList? 'הסתר הסטוריית הדפסות' : 'הצג הסטוריית הדפסות')}} </button>
        </div>
    </div>
</div>
<div style="padding-top: 15px;" >
    <holdings-list *ngIf="viwHoldingsList"></holdings-list>
</div>
<div style="padding-top: 15px;">
     <holdings-prints-list *ngIf="viwHistoryList"></holdings-prints-list> 
</div> 