<div cdkDrag *ngIf="tishEventID && tishEvent" class="example-box" fxLayout="column"
  style="z-index: 10000; width: min-content; border: solid; border-color: #c5aa65; position: absolute; padding: 0;">
  <div fxLayout="row" fxFlex>
    <div fxFlex="8px" fxLayout="column" cdkDragHandle style="background-color: white;">
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="row" class="well" style="width: 100%;" cdkDragHandle>
        <div class="column" title="גרור מוזמן לכאן כדי לבטל את שיבוצו"
          style="display: flex; justify-content: end; align-items: flex-end;  max-width: min-content;"
          class="ui-widget-header invitedsGabch" #invitedsGabch>
          <i style="background: rgb(194, 190, 190); color: red;" class="fa fa-trash-o"></i>
        </div>
      </div>

      <div *ngIf="sourceList" fxLayout="row" fxFlex>
        <div class="row">
          <form fxLayout="column" [formGroup]="searchTishInvitedsListForm" novalidate>
            <div fxLayout="column" class="row" style="margin-bottom: 10px">

              <div fxLayout="column">

                <div fxLayout="row">
                  <div style="white-space: nowrap; display: inline-flex;">
                    <auto-complete fxLayout="column" apply-enter #groupCmp [smallTemplate]="true"
                      [placeholder]="'קבוצה...'" [controllerName]="('TishGroups/' + tishEventID)"
                      (onSelect)="itemSelect($event)" [formGroupModel]="searchTishInvitedsListForm"
                      [idControl]="searchTishInvitedsListForm.controls.groupID"
                      [nameControl]="searchTishInvitedsListForm.controls.tishGroupName">
                    </auto-complete>
                      <button class="btn btn-circle" (click)="getTishInviteds()" title="סינון">
                        <i class="fa fa-filter"></i>
                      </button>
                      &nbsp;
                      <button class="btn btn-circle" (click)="clerFilter()" title="נקה סינונים">
                        <i class="material-icons">clear</i>
                      </button>
                  </div>
                </div>
                <div style="white-space: nowrap; display: inline-flex;">
                  <contacts-search-input #searchInp fxLayout="row" [query]="searchTishInvitedsListForm.controls.query"
                    (onChangeTxt)="searchTxt($event)"></contacts-search-input>
                  <i class="fa fa-eraser" (click)="clearSerch()" style="margin-top: 0;"></i>
                </div>
              </div>

            </div>

            <div fxLayout="column" class="row">
              <h4
                style="vertical-align: middle; text-align: center; font-weight: bold; width: 100%; text-decoration: underline; background-color: #c5aa65;">
                רשימת ממתינים לשיבוץ</h4>
              <div class="content">
                <cdk-virtual-scroll-viewport itemSize="10" style="height:calc(100vh - 530px); width: 100%;">

                  <div *ngFor="let ti of sourceList" style="line-height: 12px;padding:3px" class="even-odd">
                    <tishInvited-map (dropedInvited)="onDropInvited($event)" (dragInvited)="onDragInvited($event)"
                      [tishInvited]="ti" [rowTemplate]="true"></tishInvited-map>
                  </div>
                </cdk-virtual-scroll-viewport>
                <mat-paginator style="z-index: 10001" (page)="pageChange($event)" #paginator
                  [showFirstLastButtons]="true" [hidePageSize]="false" [length]="rowsCount" [pageSize]="pageRows"
                  [pageSizeOptions]="[5, 10, 25, 30, 50, 100, 150, 200]">
                </mat-paginator>
              </div>
              <!-- <pagination-mini [currentPage]="page"   [pagesCount]="rowsCount"
              (change)="pageChange($event)"></pagination-mini> -->

            </div>
          </form>

        </div>

      </div>
      <div fxLayout="row" fxFlex="8px" style="background-color: white; width: 100%;" cdkDragHandle></div>
    </div>
    <div fxFlex="8px" fxLayout="column" style="background-color: white;" cdkDragHandle>
    </div>
  </div>
</div>
