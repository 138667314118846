import { PayMethodEdit } from './payMethod-edit.cmp';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ServicesData } from '../../services/services.data';

import { PayMethodsData } from '../payMethods.data';
import { PayMethod } from './payMethod';

export class PaymentOption { }
export class Api { }



@Component({
    selector: 'payMethod-edit-win',
    templateUrl: './payMethod-edit-win.tmpl.html'
})

export class PayMethodEditWin implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private payMethodsData: PayMethodsData,
        public servicesData: ServicesData
    ) {
    }

    contactId: number;
    id: number;
    payMethod :PayMethod;
    @ViewChild('payMethodEdit', {static: true}) payMethodEdit: PayMethodEdit;

    public delete(): void {
        this.payMethodsData.delete(this.id).subscribe(p => {
            let params = (<any>this.activatedRoute.params).value;
            let urlRet = new Array<string>();
            if (params['contactID'] != null) {
                urlRet.push(`contacts/${params['contactID']}`);
            }
            if (params['payMethodID'] != null) {
                urlRet.push(`payMethods/${params['payMethodID']}`);
            }
            if (urlRet.length === 0) {
                urlRet.push('payMethods');
            }
            this.router.navigateByUrl(`/${urlRet.join("/")}`);
        });
    }




    ngOnInit() {
        //this.payMethodForm = this.formBuilder.group({});
        this.payMethod = new PayMethod(); //treck
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['payMethodID'] | 0;
            this.contactId = +params['contactID'];
        });
    }
}





