import { VouchersOrgPaymentPack } from './vouchersOrgPaymentPack/vouchersOrgPaymentPack';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable } from '@angular/core'; 

@Injectable()

export class VouchersOrgPaymentPacksData {

    private url = 'api/vouchersOrgPaymentPacks';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }



    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    loadRelevantPayments(vouchersOrganizationID: number) {
        return this.toraxHttp.get(`${this.url}/loadRelevantPayments/${vouchersOrganizationID || 0}`);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(vouchersOrgPaymentPack: VouchersOrgPaymentPack) {
        return this.toraxHttp.update(this.url, vouchersOrgPaymentPack);
    }

    getAdd(vouchersOrganizationID: number) {
        return this.toraxHttp.get(`${this.url}/add/${vouchersOrganizationID || 0}`);
    }

    exportExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
    }


}