<a mat-list-item  (click)="onItemSelected(item)"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}" class="menu-list-item">
   <i class="fa {{item.iconName}}"></i>  &nbsp;
   {{item.displayName}}  
  <span *ngIf="item.children && item.children.length"> 
    <i style="margin-right: 5px;" [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="fa arrow"></i> 
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
