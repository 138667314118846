import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountriesData } from '../countries.data';
import { ServicesData } from '../../services/services.data';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Country, DefaultTerm } from './country';

@Component({
    selector: 'country-edit-dlg',
    templateUrl: './country-edit-dlg.tmpl.html'
})

export class CountryEditDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<CountryEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public servicesData: ServicesData,
        public countriesData: CountriesData,
        private formBuilder: FormBuilder,
    ) {
    }

    country: Country;
    id: number;
    countryForm: FormGroup;
    terms;
    public load(): void {
        if (this.id) {
            this.countriesData.getEdit(this.id)
                .subscribe((c: any) => {
                    this.country = c;
                    this.fillForm(c);
                });
        }
    }

    fillForm(c: any): any {
        this.countryForm.patchValue(c);
        this.fillDefaultTerms(c.defaultTerms);
    }

    private fillDefaultTerms(defaultTerms: DefaultTerm[]) {
        const defaultTermsArray = <FormArray>this.countryForm.controls['defaultTerms'];
        defaultTerms.forEach((cg, i) => {
            let cgFrm = this.initDefaultTerm();
            cgFrm.patchValue(cg);
            defaultTermsArray.push(cgFrm);
        });
        //this.commitmentForm.controls['payments'].setValue(paymentsArray);
    }


    initDefaultTerm() {
        let fb = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            id: [0, Validators.required],
            termID: [, Validators.required],
            countryID: [this.id || 0, Validators.required],
            commitmentSubject: [, Validators.required],
            isDeleted: [false, Validators.required],
        });
        return fb;
    }

    addDefaultTerm() {
        let dt = new DefaultTerm();
        dt.countryID = this.id;
        let dtFrm = this.initDefaultTerm()
        const defaultTermsArray = <FormArray>this.countryForm.controls['defaultTerms'];
        defaultTermsArray.push(dtFrm);
        dtFrm.patchValue(dt);
    }

 
    
    get fcDefaultTerms(): FormGroup {
        return this.countryForm.controls['defaultTerms'] as FormGroup;
    }

    private initForm() {
        this.countryForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            countryID: [0, Validators.required],
            countryName: [, Validators.required],
            countryNameEn: [, Validators.required],
            region: [],
            defaultTerms: this.formBuilder.array([])
        });
    }

    ngOnInit() {
        this.id = this.data;
        this.initForm();
        this.load();
    }
}



