<div [formGroup]="searchTishInvitedsListForm">
    <div class="example-button-row" style="white-space: nowrap;" fxLayout="row"
      style="position: sticky; float: left; left: 5px;  z-index: 9999;">
      <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן" (click)="selectionChanged($event)"><i
          class="fa fa-filter"></i></button>
      <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
          class="material-icons">clear</i></button>
    </div>
    <div class="column" style="padding-top: 12px; overflow: auto">
      <div fxLayout="row" class="scrolling-wrapper">

        <contacts-search-input style="background-color: white" fxFlex fxLayout="row"
          (onChangeTxt)="selectionChanged($event)" matInput #searchInp
          [query]="searchTishInvitedsListForm.controls.query">
        </contacts-search-input>
        &nbsp;&nbsp;

        <auto-complete apply-enter [placeholder]="'קבוצה באירוע...'" (onSelect)="selectionChanged($event)" [smallTemplate]="true" fxLayout="row"
          [controllerName]="'TishGroups/' + eventID" [formGroupModel]="searchTishInvitedsListForm"
          [idControl]="searchTishInvitedsListForm.controls.groupID"
          [nameControl]="searchTishInvitedsListForm.controls.tishGroupName"></auto-complete>
        &nbsp;&nbsp;
        <!-- <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'" [smallTemplate]="true" fxLayout="row"
          [formGroupModel]="searchTishInvitedsListForm" [idControl]="searchTishInvitedsListForm.controls.contactID"
          [nameControl]="searchTishInvitedsListForm.controls.contactName"></auto-complete>
        &nbsp;&nbsp; -->
          <!-- <label>סטטוס...</label> -->
          <mat-select  class="form-control small-input" apply-enter formControlName="notEmbedded"
            (selectionChange)="selectionChanged($event)" placeholder="סטטוס...">
            <mat-option [value]="null">הכל</mat-option>
            <mat-option [value]="false">משובצים</mat-option>
            <mat-option [value]="true">לא-משובצים</mat-option>

          </mat-select>

      </div>
    </div>

    <div class="example-container">
      <div class="bounds">
        <div class="content" style="height: 70%; overflow: auto" loading-status
          [stopLoadingWhen]="stopLoadingPoints || sourceList">
          <cdk-virtual-scroll-viewport itemSize="10" style="height:calc(100vh - 330px); width: 100%;" >
            <mat-table [dataSource]="sourceList"  style="overflow-x: auto; width: 100%;" (matSortChange)="sortData($event)"
            class="example-table" matSort matSortDisableClear matSortDirection="asc">

            <ng-container matColumnDef="buttons">
              <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <a mat-menu-item [routerLink]="['', {outlets: {popup: ['tishInviteds', row.tishInvitedID]}}]">
                    <mat-icon>pageview</mat-icon>
                    <span>פרטים מלאים</span>
                  </a>

                  <a mat-menu-item [routerLink]="['', {outlets: {popup: ['tishInviteds', row.tishInvitedID, 'edit']}}]">
                    <mat-icon>edit</mat-icon>
                    <span>עריכה</span>
                  </a>
                  <a mat-menu-item>
                    <mat-icon>information</mat-icon>
                    <model-info style="padding: 4px" [dataProvider]="tishInvitedsData" [id]="row.tishInvitedID"
                      [title]="'מידע רשומה'">
                    </model-info>
                  </a>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tishInvitedID">
              <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="מזהה">
                <a [routerLink]="['', {outlets: {popup: ['tishInviteds', row.tishInvitedID]}}]">
                  {{ row.tishInvitedID }}
                </a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tishEventTitle">
              <mat-header-cell *matHeaderCellDef mat-sort-header> אירוע
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="אירוע"> {{row.tishEventTitle}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tableName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> שולחן </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="שולחן">
                {{row.tableName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="placeNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header> מקום </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="מקום">
                {{row.placeNumber}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> משפחה
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="משפחה"> {{row.lastName}}
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="firstName">
              <mat-header-cell *matHeaderCellDef> פרטי </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="פרטי">
                {{row.firstName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ticketName">
              <mat-header-cell *matHeaderCellDef> שם כרטיס </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="שם כרטיס">
                {{row.ticketName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="comment">
              <mat-header-cell *matHeaderCellDef> הערה </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="הערה"><span>
                  {{row.comment}}</span> </mat-cell>
            </ng-container>



            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.tishInvitedID)">
            </mat-row>
          </mat-table>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>

      <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100, 200, 300]"></mat-paginator>
    </div>
</div>
