<mat-card *ngIf="registrationEvent" fxLayout="column" class="example-card">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            <h3>פרטי אירוע: [{{ registrationEvent.registrationEventID }}] </h3>
            <span class="example-fill-remaining-space"></span>
            <a [routerLink]="['/registrationEvents', {outlets: {popup: [ id, 'edit']}}]">
                <i class="fa fa-pencil"></i>
            </a>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="width: 100%;">
        <dl class="dl-horizontal">
            <dt>תאריך</dt>
            <dd>{{ registrationEvent.date | date:'dd/MM/yyyy' }}</dd>
            <hr>
            <dt>סוג</dt>
            <dd>{{ registrationEvent.registrationEventTypeName  }}</dd>
            <hr>
            <dt>מחיר</dt>
            <dd> {{ registrationEvent.price | number }} {{ registrationEvent.currency | currency  }}</dd>
            <hr>
            <dt>מזהה מסוף אשראי</dt>
            <dd> {{ registrationEvent.termID }}</dd>
            <hr>
            <dt>סה"כ לתשלום</dt>
            <dd> {{ registrationEvent.sumPrice | number }}</dd>
            <hr>
            <dt>סה"כ שולם</dt>
            <dd>
                <div *ngFor="let po  of registrationEvent.paymentOptionsSums">
                    {{po.key}} - {{po.value | number }}
                </div>
                <div style="font-weight: bold">{{ registrationEvent.sumAmountPaid | number }}</div>
            </dd>
            <hr>
            <dt>יתרות לתשלום</dt>
            <dd> {{ registrationEvent.sumAmountToPay | number }}</dd>
            <hr>
            <dt>סה"כ רשומים</dt>
            <dd> {{ registrationEvent.sumRegistrations }}</dd>
            <hr>
            <dt>סה"כ מנות</dt>
            <dd> {{ registrationEvent.sumDoses }}</dd>
            <hr>
        </dl>
    </mat-card-content>
</mat-card>