
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HoldingsData } from './holdings.data';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AlertService } from '../components/alert/alert.svc';

@Component({
  selector: 'holdings-prints-list',
  templateUrl: 'holdings-prints-list.tmpl.html',
})

export class HoldingsPrintsList implements OnInit {
  constructor(
    private holdingsData: HoldingsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private alert: AlertService
  ) {
  }

  displayedColumns: string[] = ['buttons', 'shtibelName','period','includeOldRecords', 'printDate', 'holdingsSeriePrintID', 'count'];
  data: any[] = [];

  resultsLength = 0;
  isRateLimitReached = false;
  searchPrintsListForm: FormGroup;
  selectionAmount;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  selection = new SelectionModel<number>(true, []);

  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('shtibelID') shtibelID?: number;

  @Output() selectedRowsChange = new EventEmitter<any>();


  defaultFormValues = {
    page: 1,
    pageRows: this.pageRows,
  }


  canceloldingsPrint(holdingsSeriePrintID: number) {
    let cnfrm = confirm("האם לבטל הדפסה?\nלא ניתן לשחזר את הפעולה");
    if (cnfrm) {
      this.alert.info("המתן בבקשה,\nמבטל הדפסת רשימות...");
      this.holdingsData.cancelHoldingsPrint(holdingsSeriePrintID)
        .subscribe(data => {
          this.alert.success("הדפסת רשימות בוטלה");
          this.getHoldingsPrintedGroup();
        });
    }
  }
  public clerFilter() {
    this.searchPrintsListForm.reset(this.defaultFormValues);
    this.getHoldingsPrintedGroup();
  }


  public getHoldingsPrintedGroup() {
    this.searchPrintsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    this.searchPrintsListForm.controls['pageRows'].setValue(this.paginator.pageSize);

    if (this.shtibelID && this.shtibelID > 0) {
      this.searchPrintsListForm.controls['shtibelID'].setValue(this.shtibelID);
    }



    return this.holdingsData!.getHoldingsPrintedGroup(this.searchPrintsListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isRateLimitReached = false;

      });
  }

  private initForm() {
    this.searchPrintsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      shtibelID: [],
      shtibelName: [],
      // responsibleID: [],
      // responsibleName: [],
      statusPayments: [],
      isPrint: [],
      commitmentID: [],
      dateMonthsRange: [null],
      includeOldRecords: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchPrintsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.holdingID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }


  panelOpenState;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  public referrerCmpUrl = "";

  ngOnInit() {
    this.initForm();
    this.getHoldingsPrintedGroup();
  }

  pageChange(e) {
    this.searchPrintsListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged();
  }

  selectionChanged() {
    this.getHoldingsPrintedGroup();
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getHoldingsPrintedGroup();
  }

  public search() {
    this.getHoldingsPrintedGroup();
  }

  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchPrintsListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }

  pageEvent: PageEvent;
  pageSizeOptions;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchPrintsListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}