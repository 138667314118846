import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, AfterViewInit, ElementRef, QueryList, ViewChildren, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TishInvitedsData } from './../tishInviteds.data';
import { TishInvited } from './tishInvited';
import { MatDialog } from '@angular/material/dialog';
import { TishInvitedDlg } from './tishInvited-edit-dlg.cmp';
declare var jQuery: any;



@Component({
    selector: 'tishInvited-map',
    templateUrl: './tishInvited-map.tmpl.html',
    styleUrls: ['./../tishInviteds.scss']
})

export class TishInvitedMap implements OnInit, AfterViewInit {
    constructor(
        private tishInvitedsData: TishInvitedsData,
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
    ) {
    }



    @Input('tishInvitedForm') public tishInvitedForm: FormGroup;
    @Input('tishPlaceForm') public tishPlaceForm: FormGroup;
    @Input('transform') public transform: string = 'none';
    @Input('rowTemplate') public rowTemplate: boolean = false;

    //למקרה שמגיע מהאירוע
    @Input('tishInvited') public tishInvited: TishInvited;


    @Output() dragInvited = new EventEmitter<boolean>();
    @Output() dropedInvited = new EventEmitter<boolean>();




    @ViewChildren('invited') inviteds: QueryList<ElementRef>;

    private id: number;

    editInvited() {
      let tt = this.tishInvited;

      setTimeout(() => {
          const dialogRef = this.dialog.open(TishInvitedDlg, {
              data: tt
          });
          dialogRef.afterClosed().subscribe(result => {
              if (result && result.valid) {
                  this.saveTishInvited(result);
              }
          });
      }, 200);
  }



  saveTishInvited = (formData: FormGroup): void => {
      if (formData.valid) {
          this.tishInvited = formData.value;
          this.tishInvitedForm = formData;
          this.save();
      }
  }


  public save() {
    if (this.tishInvitedForm.valid) {
        this.tishInvitedsData.update(this.tishInvitedForm.value)
            .subscribe((c: any) => {
                this.id = c.id;
            });
    } else {
        console.log('not valid');
        console.log(this.tishInvitedForm.errors);
    }
}
    // editInvited(tishInvitedID){


    //     this.router.navigate(['', { outlets: { popup: ['tishInviteds', tishInvitedID, 'edit']}}]);
    // }
    ngOnInit(): void {
        if (this.tishInvitedForm) {
            this.tishInvited = this.tishInvitedForm.value;
        }
        else if (this.tishInvited) {
            this.tishInvitedForm = this.tishInvitedsData.initForm();
            this.tishInvitedForm.patchValue(this.tishInvited);
        }
    }




    ngAfterViewInit(): void {
        let tishInvitedForm = this.tishInvitedForm;
        let tishPlaceForm = this.tishPlaceForm;

        let tishInvited = tishInvitedForm ? tishInvitedForm.value : null;
        let drgInvtd = this.dragInvited;
        let drpInvtd = this.dropedInvited;

        this.inviteds.forEach(element => {
            let invited = jQuery(element.nativeElement);

            invited.draggable({
                connectToSortable: ".invitedsGabch",
                containment: ".map-wrapper",
                scroll: true,
                revert: true,
                //revert: "invalid",
                // opacity: .4,
                // cursor: "crosshair",


                start: function (e, ui) {
                    drgInvtd.emit(true);

                    $(this).addClass('moveable');
                    //$(this).height(175).width(175);
                    $(this).stop(true, true);
                    let jsnTishInvited = JSON.stringify(tishInvited);
                    let thisPlaceForm = this.tishPlaceForm;
                    let srcObj: any = {
                        "tishInvited": jsnTishInvited,
                        "tishPlaceForm": tishPlaceForm
                    };
                    $(this).data('srcObj', srcObj);
                    // $(this).height(25).width(25);
                },
                stop: function (e, ui) {
                    drpInvtd.emit(true);
                    $(this).removeClass('moveable');
                    $(this).removeClass('list-row-invited');

                }
            });
        });
    }
}
