import { VouchersOrgPaymentPackMain } from './vouchersOrgPaymentPack/vouchersOrgPaymentPack-main.cmp';
import { VouchersOrgPaymentPacksRoutes } from './vouchersOrgPaymentPacks.routing';
import { VouchersOrgPaymentPackDetails } from './vouchersOrgPaymentPack/vouchersOrgPaymentPack-details.cmp';
import { VouchersOrgPaymentPackEdit } from './vouchersOrgPaymentPack/vouchersOrgPaymentPack-edit.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { MaterialModule } from './../material.module';
import { AmountInputRangeModule } from './../components/amountInput-range/amount-input-range.mdl';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';
import { VouchersOrgPaymentPacksList } from './vouchersOrgPaymentPacks-list.cmp'; 
import { TabsMdl } from './../components/tabs/tabs.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl'; 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { VouchersOrgPaymentPacksData } from './vouchersOrgPaymentPacks.data'; 
import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { CurrencyPipeMdl } from '../components/pipes/currency-pipe';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';



@NgModule({
	imports: [
		CommonModule,  
		FormsModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule,  
		FooterEditButtonsMdl,
		TabsMdl,
		DateInputModule,
		CurrencyPipeMdl,
		RouterModule.forChild(VouchersOrgPaymentPacksRoutes),
		DateInputRangeModule,
		AmountInputRangeModule,
		MaterialModule,
MainFormModalModule
	],
	declarations: [
		VouchersOrgPaymentPacksList,
		VouchersOrgPaymentPackEdit,
		VouchersOrgPaymentPackDetails,
		VouchersOrgPaymentPackMain
	],
	exports: [
		VouchersOrgPaymentPacksList,
		VouchersOrgPaymentPackEdit,
		VouchersOrgPaymentPackDetails,
		VouchersOrgPaymentPackMain
	],
	providers: [
		VouchersOrgPaymentPacksData
	]
})  
export class VouchersOrgPaymentPacksMdl { }

