import { ServicesData } from './../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { HonorTypesData } from './../honor-types.data';
import { HonorType } from './honor-type';

@Component({

    selector: 'honor-type-edit',
    templateUrl: './honor-type-edit.tmpl.html'
})

export class HonorTypeEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public honorTypesData: HonorTypesData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData
    ) {
    }

    honorType: HonorType;
    id: number;
    honorTypeForm: FormGroup;

    public load(): void {
        if (this.id) {
            this.honorTypesData.getEdit(this.id)
                .subscribe((c: any) => {
                    this.honorType = c;
                    this.honorTypeForm.patchValue(c);
                });
        } else {

            this.honorType = new HonorType();
        }
    }

    private initForm() {
        this.honorTypeForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            honorTypeID: [this.id || 0, Validators.required],
            honorDepartmentID: [, Validators.required],
            honorTypeDescription: [, Validators.required],
            letterText: [, Validators.required],
            letterSettingID: [, Validators.required]
        });
    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['honorTypeID'];
        });
        this.initForm();
        this.load();
    }
}



