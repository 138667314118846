<form  [formGroup]="searchCampaignsListForm">
    <div class="ibox-content m-b-sm border-bottom">

        <div class="example-container">

        </div>
        <div class="example-container">
            <div class="bounds">
                <div class="content" style="height: 70%; overflow: auto">
                    <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">

                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">


                                    <a mat-menu-item
                                        [routerLink]="['', {outlets: {popup: ['campaigns', row.campaignID, 'edit']}}]">
                                        <mat-icon>edit</mat-icon>
                                        <span>עריכה</span>
                                    </a>
                                    <a mat-menu-item>
                                        <mat-icon>information</mat-icon>
                                        <model-info style="padding: 4px" [dataProvider]="campaignsData" [id]="row.campaignID" [title]="'מידע רשומה'">
                                        </model-info>
                        </a>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="campaignName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>שם פרוייקט</mat-header-cell>
                            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם פרוייקט">
                                {{ row.campaignName }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="destination">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>יעד</mat-header-cell>
                            <mat-cell  *matCellDef="let row" data-label="יעד">
                                {{ row.destination }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="visibleDestination">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>תצוגת יעד</mat-header-cell>
                            <mat-cell  *matCellDef="let row" data-label="תצוגת יעד">
                                {{ row.visibleDestination }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="campaignStartDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>תאריך פתיחת הפרוייקט</mat-header-cell>
                            <mat-cell  *matCellDef="let row" data-label="תאריך פתיחת הפרוייקט">
                                {{ row.campaignStartDate | date:'dd/MM/yyyy' }}</mat-cell>
                        </ng-container>


                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="openSelectedRow(row.campaignID)">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </div>

</form>
