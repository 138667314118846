import { ServicesData } from 'src/app/services/services.data';
//import { AutoCompleteComponent } from '../../../components/auto-complete/auto-complete.cmp';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'payment-chic-details',
  templateUrl: './payment-chic-details.tmpl.html',
  styleUrls: ['./../../payments.scss']
})


export class PaymentChicDetails implements OnInit, AfterViewInit {

  control: FormArray;
  mode: boolean;
  touchedRows: any;
  constructor(private fb: FormBuilder, private servicesData: ServicesData) { }
 
  @Input('paymentForm') public paymentForm: FormGroup;
  @Input('chicDetails') public chicDetails: any[];
  @Input('paymentID') public paymentID: number;



  ngOnInit(): void {
    this.touchedRows = [];
    if (this.paymentID == 0 && this.chicDetails.length == 0) {
      this.addRow();
    }
  }

  ngAfterViewInit(): void {
    if (this.chicDetails && this.paymentID > 0) {
      const arr = <FormArray>this.paymentForm.controls['chicDetails'];
      this.chicDetails.forEach(chicDetail => {
        let chickForm = this.initialForm(chicDetail);
        arr.push(chickForm);
      });
    } 
  }

  initialForm(row: any = null): FormGroup {
    return this.fb.group({
      paymentID: [row?.paymentID | 0, Validators.required],
      checkDate: [row?.checkDate, Validators.required],
      bankNumber: [row?.bankNumber],
      bankBranchNumber: [row?.bankBranchNumber],
      bankAcountNumber: [row?.bankAcountNumber],
      chicNumber: [row?.chicNumber, Validators.required],
      nameOfChic: [row?.nameOfChic],
      statusPayment: [row?.statusPayment ? row.statusPayment : 1, Validators.required],
      doneOn: [row?.doneOn],
      amount: [row?.amount, Validators.required],
      calculatedAmountInILS: [], 
      //iEditable: [true]
    });


  }

  addRow() {
    const arr = this.paymentForm.get('chicDetails') as FormArray;
    let controls = arr.controls;
    let lastIx = +controls.length;

    if (lastIx > 0) {

      let rowControl = controls[lastIx - 1];
      let rowControlValue = rowControl.value;

      let d = new Date(rowControlValue.checkDate);
      d.setMonth(d.getMonth() + 1); //הוספת חודש לשיק


      let nextChick = {
        paymentID: 0,
        checkDate: rowControlValue.checkDate ? d : null,
        bankNumber: rowControlValue?.bankNumber,
        bankBranchNumber: rowControlValue?.bankBranchNumber,
        bankAcountNumber: rowControlValue?.bankAcountNumber,
        chicNumber: rowControlValue.chicNumber ? (+rowControlValue?.chicNumber + 1) : null,
        nameOfChic: rowControlValue?.nameOfChic,
        statusPayment: rowControlValue?.statusPayment,
        doneOn: rowControlValue?.doneOn,
        bankAcountOwner: rowControlValue?.bankAcountOwner,
        amount: rowControlValue?.amount,
      }

      arr.push(this.initialForm(nextChick));
    } else {
      arr.push(this.initialForm());
    }
  }

  deleteRow(index: number) {
    const control = this.paymentForm.get('chicDetails') as FormArray;
    control.removeAt(index);
  }

  editRow(group: FormGroup) {
    group.get('iEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('iEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.paymentForm.value);
  }

  get getFormControls() {
    const control = this.paymentForm.get('chicDetails') as FormArray;
    return control;
  }

  submitForm() {
    const control = this.paymentForm.get('chicDetails') as FormArray;
    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    //  console.log(this.touchedRows);
  }

  toggleTheme() {
    this.mode = !this.mode;
  }

}
