import { AutoComplete } from './auto-complete.class';
import { AutoCompleteDataService } from './auto-complete.data';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { map, debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Component, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";


@Component({
    selector: "auto-complete",
    templateUrl: "auto-complete.tmpl.html",
    styleUrls: ["./auto-complete.scss"]
})
export class AutoCompleteComponent implements AfterViewInit {
    noResults: boolean;
    loading: boolean;
 
    constructor(
        private data: AutoCompleteDataService
    ) {
    }

    public model: any;

    formatter = (x: { name: string }) => x.name;

    sourceList: string;
    filterValues: Observable<any>;
    public searchResults: AutoComplete[] = [];

    @ViewChild('txtSearch') public txtSearch: ElementRef;
    @ViewChild('matAutocomplete', { static: true }) matAutocomplete: MatAutocomplete;
    @Input("appearance") public appearance: string = "outline";
    @Input("formGroupModel") public formGroupModel: FormGroup;
    @Input('idControl') public idControl: FormControl;
    @Input('nameControl') public nameControl: FormControl;
    @Input('controllerName') public controllerName: string;  
    @Input('placeholder') placeholder?: string = '...';
    @Input('smallTemplate') smallTemplate?: boolean = false;
    @Input('firstFocus') public firstFocus: boolean = false;
    @Input('withLabel') public withLabel: boolean = true;
    @Input('emitFullObject') public emitFullObject: boolean = false;
    @Input('withSubTitle') public withSubTitle: boolean = false;
    
    @Output() onSelect = new EventEmitter<number>();
    @Output() onSelectObj = new EventEmitter<any>();

    public itemSelect(item) {
        if (item) {
            //console.log(item);           
        }

        if (item) {
            this.activeItem = new AutoComplete(+item.id, item.title);
            this.idControl.setValue(+this.activeItem.id);
            this.nameControl.setValue(this.activeItem.title);

            this.onSelect.emit(+this.activeItem.id);
            if (this.emitFullObject === true) {
                this.onSelectObj.emit(item);
            }
        } else {
            this.activeItem = null;
            this.idControl.setValue(null);
            this.onSelect.emit(null);
        }
        return false;
    }

    isAutofilled;
    autofilled() {
        //console.log("autofilled",e);
    }
    public activeItem: AutoComplete;
    public isActive(value): boolean {
        return (value === this.activeItem);
    }
    public setActive($event, item): void {
        this.itemSelect(item);
    }

    ngAfterViewInit() {
        this.sourceList = this.controllerName;
        const typeahead = this.nameControl.valueChanges.pipe(
            map((e: any) => e),
            filter(text => {
                if (text) {
                    let txt = text.trim();
                    return txt && txt.length >= 2;
                } else {
                    this.itemSelect(null);
                }
                return false;
            }),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(() => {
                let queryText = (<any>this.txtSearch.nativeElement).value;
                if (queryText) {
                    return this.data.search(this.sourceList, queryText.trim());
                } else {
                    return of(null);
                }
            }
            )
        );

        typeahead.subscribe(data => {
            this.searchResults = <AutoComplete[]>data;
        });

    }

    minWidth: number = 180;
    width: number = this.minWidth;

    resize() {
        setTimeout(() => this.width = Math.max(this.minWidth, this.txtSearch.nativeElement.offsetWidth));
    }
    // private _filterList(list: string[], value: string): string[] {
    //     const filterValue = value.toLowerCase();
    //     return list.filter(x => x.toLowerCase().indexOf(filterValue) === 0);
    // }


}

