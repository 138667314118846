<div>
    <form [formGroup]="searchContactsLotteryListForm">
        <div class="example-container" style="background-color: white; border-bottom: 10px solid rgb(243, 243, 244)">
            <div style="z-index: 10; position: relative; left: 0;  top:0px;" class="example-button-row">
                <h3 style="color: blue; position: absolute; width: 100%; text-align: left; top: -7px;" class="label">
                    <span *ngIf="searchContactsLotteryListForm.controls.cityTitle.value"> עיר:{{
                                searchContactsLotteryListForm.controls.cityTitle.value }}&nbsp;</span>
                    <span *ngIf="searchContactsLotteryListForm.controls.shtibelName.value"> שטיבל:{{
                                searchContactsLotteryListForm.controls.shtibelName.value }}&nbsp;</span>
                </h3>
            </div>

            <div class="example-button-row" style="white-space: nowrap;" fxLayout="row"
                style="position: absolute; left: 5px; top: -30px; z-index: 9999;">
                <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן"
                    (click)="selectionChanged($event)"><i class="fa fa-filter"></i></button>
                <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                        class="material-icons">clear</i></button>


            </div>
            <div class="column" style="padding-top: 12px; overflow: auto;">
                <div fxLayout="row" class="scrolling-wrapper">
                    <contacts-search-input style="background-color: white; " fxFlex fxLayout="row"
                        (onChangeTxt)="selectionChanged($event)" matInput apply-enter #searchInp
                        [query]="searchContactsLotteryListForm.controls.query">
                    </contacts-search-input>
                    &nbsp;&nbsp;
                    <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" apply-enter
                        #cityID [placeholder]="'עיר...'" (onSelect)="selectionChanged($event)"
                        [controllerName]="'Cities'" [formGroupModel]="searchContactsLotteryListForm"
                        [idControl]="searchContactsLotteryListForm.controls.cityID"
                        [nameControl]="searchContactsLotteryListForm.controls.cityTitle">
                    </auto-complete>
                    &nbsp;&nbsp;
                    <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" apply-enter
                        #shtibelID [placeholder]="'שטיבל...'" (onSelect)="selectionChanged($event)"
                        [controllerName]="'Shtibels'" [formGroupModel]="searchContactsLotteryListForm"
                        [idControl]="searchContactsLotteryListForm.controls.shtibelID"
                        [nameControl]="searchContactsLotteryListForm.controls.shtibelName">
                    </auto-complete>
                </div>
            </div>
        </div>

        <div class="example-container">
            <div class="bounds">
                <div class="content" style="height: 70%; overflow: auto">
                    <mat-table [dataSource]="sourceList" style="overflow-x: scroll;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">

                        <ng-container matColumnDef="select">
                            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                                <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                                    *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox apply-enter (click)="$event.stopPropagation()"
                                    (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button title="הצג עוד..." style="max-height: 21px;" mat-icon-button
                                    (click)="openDetails(row);" aria-label="הצג עוד...">
                                    <mat-icon style="color: gray;">remove_red_eye</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> סוג
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="סוג" >
                                {{ row.type }}
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="codeIchud">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> קוד
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="קוד" >
                                {{ row.codeIchud }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="codeMatrim">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> קוד מתרים
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="קוד מתרים" >
                                {{ row.codeMatrim }}
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="perfix">
                            <mat-header-cell *matHeaderCellDef mat-sort-header > תואר
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תואר" > {{row.perfix}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="personTitle">
                            <mat-header-cell *matHeaderCellDef> שם </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="שם">
                                <span style="font-weight: 500;"> {{ row.personTitle }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="suffix">
                            <mat-header-cell *matHeaderCellDef mat-sort-header > סיומת
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="סיומת" > {{row.suffix}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="cityName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> עיר </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="עיר"> {{row.cityName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="shtibelName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> שטיבל </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="שטיבל"> {{row.shtibelName}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="countTickets">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> סך כרטיסים </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="סך כרטיסים">
                                {{ row.countTickets }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="sumAmount">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="סכום">
                                {{ row.sumAmount  | currency:(row.currency == 1 ? 'USD': (row.currency == 2 ? 'GBP': 'ILS')):true:'1.0-0'  }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="qty">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> תשלומים </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תשלומים">
                                {{ row.qty }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="amountOk">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום ששולם </mat-header-cell>
                            <mat-cell style="color: green;" *matCellDef="let row" data-label="סכום ששולם">
                                {{ row.amountOk | number }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="amountReady">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> תשלומים ממתינים </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תשלומים ממתינים">
                                {{ row.amountReady | number }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="overPaid">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> תשלומים נוספים </mat-header-cell>
                            <mat-cell style="color: green;" *matCellDef="let row" data-label="יתרה לטיפול">
                                {{ row.overPaid | number }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="balance">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> יתרה לטיפול </mat-header-cell>
                            <mat-cell [ngStyle]="{'color': row.balance > 0 ? 'red' : 'green'}" *matCellDef="let row"
                                data-label="יתרה לטיפול">
                                {{ row.balance | number }} </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <h1 *ngIf="!sourceList && !isLoadingResults" style="color: gray; text-align: center;">
                הקלד בשורת החיפוש כדי להציג רשומות...
            </h1>
            <h1 *ngIf="isLoadingResults" style="color: gray; text-align: center;">
                <i class="fa fa-refresh  fa-spin"></i>
            </h1>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </form>
</div>
