import { CollectionBuildersData } from './../collection-builders.data';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { ServicesData } from 'src/app/services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 
import { CollectionBuilder } from './collection-builder';

@Component({
    selector: 'collection-builder-edit',
    templateUrl: './collection-builder-edit.tmpl.html'
})

export class CollectionBuilderEdit implements OnInit {

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private collectionBuildersData: CollectionBuildersData,
        public servicesData: ServicesData,
        private formBuilder: FormBuilder,
        private location: Location,
        private toraxHttp: ToraxHttp,
    ) {
    }
    paymentID?: number;
    termID?: number;
    collectionBuilder: CollectionBuilder;
    id: number;
    collectionBuilderForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.collectionBuildersData.getEdit(this.id)
                .subscribe((cb: any) => {
                    this.collectionBuilder = cb;
                    this.fillForm(cb);
                });
        } else {
            this.collectionBuildersData.getAdd(this.termID, this.paymentID)
                .subscribe((cb: any) => {
                    this.collectionBuilder = cb;
                    this.fillForm(cb);
                });
        }
    }

    private fillForm(c: CollectionBuilder): void {
        this.collectionBuilderForm.patchValue(c);
    }

    private initForm() {
        this.collectionBuilderForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            collectionBuilderID: [this.id || 0, Validators.required],
            collectionDate: [],
            collectionDateIsTill: [false, Validators.required],
            contactName: [],
            contactID: [],
            paymentID: [],
            termID: [, Validators.required],
            dollar: [true, Validators.required],
            cadDollar: [true, Validators.required],
            euro: [true, Validators.required],
            franc: [true, Validators.required],
        });
    }


    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['collectionBuilderID'];
            this.paymentID = +params['paymentID'];
            this.termID = +params['termID'];
        });
        this.initForm();
        this.load();
    }
}




