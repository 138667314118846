<form  *ngIf="objectsListResult" [formGroup]="searchLetterSettingsListForm" novalidate>
    <panel-box title="תבניות מכתבים" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools">
            <panel-box-btn (btnClick)="getLetterSettings()" icon="fa-refresh"></panel-box-btn>
        <a [routerLink]="[{outlets: {popup: ['add']}}]"><i class="fa fa-plus"></i></a>
        </span>
        <div class="panel-content">
            <div class="row">
                <table class="table table-striped no-margins">
                    <thead>
                        <tr>
                            <th>...</th>
                            <th>שם</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ls of objectsListResult.list">
                            <td>
                                <a   [routerLink]="[ '', {outlets: {popup: [ 'letterSettings', ls.letterSettingID]}}]"><i class="fa fa-folder-open"></i></a>&nbsp;&nbsp;{{ ls.letterSettingID }}
                            </td>
                            <td>{{ls.letterName}}</td>
                        </tr>
                    </tbody>
                </table>
                <pagination [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount" (change)="changePage($event)"></pagination>
            </div>
        </div>
    </panel-box>
</form>