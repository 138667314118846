import { MaterialModule } from './../material.module';
import { AssistanceMain } from './assistance/assistance-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';

import { AssistancesRoutes } from './assistances.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { AssistancesData } from './assistances.data';
import { AssistanceEdit } from './assistance/assistance-edit.cmp';
import { AssistanceDetails } from './assistance/assistance-details.cmp';
import { AssistancesList } from './assistances-list.cmp';
import { AmountAndCurrencyInputModule } from '../components/amount-and-currency-input/amount-and-currency-input.module';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		AutoCompleteModule,
		RouterModule.forChild(AssistancesRoutes),
		MaterialModule,
		MainFormModalModule,
		AmountAndCurrencyInputModule,
    ModelInfoModule

	],
	declarations: [
		AssistanceMain,
		AssistancesList,
		AssistanceEdit,
		AssistanceDetails,
	],
	exports: [
		AssistancesList,
		AssistanceMain,
		AssistanceEdit,
		AssistanceDetails,
	],
	providers: [
		AssistancesData
	]
})
export class AssistancesMdl { }

