import { saveAs } from 'file-saver';
import { LoaderBarService } from '../../components/loader-bar/loader-bar.service';
import { CommitmentsData } from './../commitments.data';
import { Component, AfterViewInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'commitment-details-view',
    templateUrl: './commitment-details-view.tmpl.html',
    styleUrls: ['./../commitments.scss']
})
export class CommitmentDetailsView implements AfterViewInit {
    constructor(
        private commitmentsData: CommitmentsData,
        private loaderBarService: LoaderBarService
    ) { }


    startedClass = false;
    completedClass = false;
    preventAbuse = false;
    @Output() downloadPdf = new EventEmitter<number>();
    @Input('commitment') public commitment: any;
    @ViewChild('progressBar') progressBar: any;
    isOpen = false;

    downloadOneCommitmentReport(contactID, commitmentID) {
        this.commitmentsData.downloadOneCommitmentReport(contactID, commitmentID).subscribe((blob: any) => {
            var blb = new Blob([blob], { type: "application/pdf;charset=utf-8" });
            saveAs(blb, `דוח התחייבות מס' ${this.commitment.commitmentID} - ${this.commitment.commitmentSubjectName}.pdf`);
        });
    }

    ngAfterViewInit(): void {
        // console.log("progressBar", this.progressBar);
        // this.progressBar.start();
    }

    extendedView: boolean = false;
    showPayments: boolean = false;

    viwPayments() {

        this.loaderBarService.show();

        this.showPayments = !this.showPayments;

        //טוען רק פעם כשעדיין לא נטען
        if (this.commitment.paymentCommitments && this.commitment.paymentCommitments.length > 0) {
            this.loaderBarService.hide();
            return;
        } else {
            //this.progress.start();
            this.commitmentsData.getPaymentCommitmentsVM(this.commitment.commitmentID).subscribe(x => {
                // this.progress.complete();
                this.loaderBarService.hide();
                this.commitment.paymentCommitments = x;
                //this.loaderBarService.show();
            });
        }
    }
}

