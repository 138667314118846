import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardGroupsDataService {
    private url = 'api/dashboardGroups';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }

    getContactsByGroup(groupID:number){
        return this.toraxHttp.get(`${this.url}/getContactsByGroup/${groupID}`);
    }
    
    printWaitingILReceipts() {
        return this.toraxHttp.getWithResponce(`${this.url}/printWaitingILReceipts`);
    }
    printWaitingUSAReceipts() {
        return this.toraxHttp.getWithResponce(`${this.url}/printWaitingUSAReceipts`);
    }
    printWaitingUKReceipts() {
        return this.toraxHttp.getWithResponce(`${this.url}/printWaitingUKReceipts`);
    }


    // cancelILReceiptsPrint(receiptsIdsList: number[]) {
    //     return this.toraxHttp.postWithResponce(`${this.url}/cancelILReceiptsPrint`, receiptsIdsList);
    // }
    // cancelUSAReceiptsPrint(receiptsIdsList: number[]) {
    //     return this.toraxHttp.postWithResponce(`${this.url}/cancelUSAReceiptsPrint`, receiptsIdsList);
    // }
    // cancelUKReceiptsPrint(receiptsIdsList: number[]) {
    //     return this.toraxHttp.postWithResponce(`${this.url}/cancelUKReceiptsPrint`, receiptsIdsList);
    // }

    // getPaymentCommitmentsInfo(paymentCommitmentsIdsList: number[]) {
    //     return this.toraxHttp.post(`${this.url}/getPaymentCommitmentsInfo`, paymentCommitmentsIdsList);
    // }


    get()  {
        return this.toraxHttp.get(`${this.url}`);
    }
}
