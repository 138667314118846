import { DonationCategoriesData } from './../donationCategories.data';
import { ServicesData } from 'src/app/services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DonationCategory } from './donationCategory';

@Component({
  selector: 'donationCategory-edit',
  templateUrl: './donationCategory-edit.tmpl.html'
})

export class DonationCategoryEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public donationCategoriesData: DonationCategoriesData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,

  ) {
  }

  donationCategory: DonationCategory;
  id: number;
  donationCategoryForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.donationCategoriesData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.donationCategory = c;
          this.donationCategoryForm.patchValue(c);
        });
    }
    else {
      this.donationCategoriesData.getAdd()
        .subscribe((c: any) => {
          this.donationCategory = c;
          this.donationCategoryForm.patchValue(c);
        });
    }
  }

  private initForm() {
    this.donationCategoryForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      donationCategoryID: [this.id || 0, Validators.required],
      title: [, Validators.required],
      openDate: [, Validators.required],
      keyNumber: [],
      currency: [, Validators.required],
      il_TermID: [, Validators.required],
      usa_TermID: [, Validators.required],
      isIncludeRecord: [, Validators.required],
      //lastClearingDateOn:[]
    });
    //  this.donationCategoryForm.controls['price'].disable();
  }



  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['donationCategoryID'];
    });
    this.initForm();
    this.load();
  }
}



