import { ServicesData } from './../../services/services.data';
import { TishEventsData } from './../tishEvents.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';  
import { TishEvent } from './tishEvent';
export class PaymentOption { }
export class Api { }

@Component({
    
    selector: 'tishEvent-edit',
    templateUrl: './tishEvent-edit.tmpl.html'
})

export class TishEventEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private tishEventsData: TishEventsData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {
    }


    tishEvent: TishEvent;
    id: number = 0;
    tishEventForm: FormGroup;


    public load(): void {

        if (this.id > 0) {
            this.tishEventsData.getEdit(this.id)
                .subscribe((tg:any) => {
                    this.tishEvent = tg;
                    this.fillForm(tg);
                });
        }
        // else {
        //     // this.tishEventsData.getAdd()
        //     //     .subscribe(tg => {
        //     //         this.tishEvent = tg;
        //     //         this.fillForm(tg);
        //     //         // this.tishEventForm.controls['eventDate'].setValue(this.tishEvent.eventDate);

        //     //     });
        // }
    }

    private fillForm(tg: TishEvent): void {
        this.tishEventForm.patchValue(tg);
    }

    public save(): void {
        console.log('save run');
        if (this.tishEventForm.valid) {
            this.tishEventsData.update(this.tishEventForm.value)
                .subscribe((c: any) => {
                    this.location.back();
                    //this.router.navigate(['tishEvents', c.id], { relativeTo: this.activatedRoute });
                });
        } else {
            console.log('not valid');
            console.log(this.tishEventForm.errors);
        }
    }

    public cancel(): void {
        this.location.back();
    }

    private initForm() {
        this.tishEventForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            tishEventID: [this.id || 0, Validators.required],
            tishEventTitle: [],
            description: [],
            eventDate: [, Validators.required],
            // eventJewishDay: [],
            // eventJewishMonth: [],
            // eventJewishYear: [],
            comment: [],
        });

    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['tishEventID'];
        });
        this.initForm();
        if (this.id > 0) {
            this.load();
        } else {
            this.tishEvent = new TishEvent();
            this.tishEvent.tishEventID = 0;
        }
    }
}





