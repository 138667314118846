import { ToraxHttp } from './../../utils/custom-http.svc';
import { TishTablesData } from './../tishTables.data';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { TishTable } from './tishTable'; 

@Component({
	selector: 'tishTable-details',
	templateUrl: './tishTable-details.tmpl.html'
})

export class TishTableDetails implements OnInit {
	constructor(
		private TishTablesData: TishTablesData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.tishTable && id === this.tishTable.tishTableID) {
				this.loadTishTable();
			}
		});
	}

	public tishTable: TishTable;
	private id: number;

	public loadTishTable(): void {
		this.TishTablesData.get(this.id)
			.subscribe((tg:any) => this.tishTable = tg);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['tishTableID'];
			this.loadTishTable();
		});
	}
}

