import { Router } from '@angular/router';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, Input, AfterViewInit, TemplateRef, ContentChild } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Location } from '@angular/common';
import { PreviousRouteService } from 'src/app/_helpers/previous-route.service';

@Component({
  selector: 'footer-edit-buttons',
  templateUrl: 'footer-edit-buttons.tmpl.html',
  styleUrls: ['./footer-edit-buttons.scss']
})

export class FooterEditButtons implements AfterViewInit {
  constructor(
    private toraxHttp: ToraxHttp,
    private location: Location,
    private previousRouteService: PreviousRouteService
  ) {
  }

  @Input('modelFormGroup') public modelFormGroup: FormGroup;
  @Input('disabledDeleteButton') public disabledDeleteButton?: boolean = true;
  @Input('navigateAfterSaving') public navigateAfterSaving: string;
  @Input('onSaveGoToBack') public onSaveGoToBack?: boolean = true;
  @Input('dataProvider') public dataProvider?: any;
  @Input('postMethod') public postMethod?: string = null;
  @Input('errorMsg') public errorMsg?: string;
  @Input('confirmationMessageBeforeSaving') public confirmationMessageBeforeSaving?: string = "";
  @Input('id') public id: number = 0;
  @Input('router') public router: Router;
  @Input('displaySaveButton') public displaySaveButton: boolean = true;
  @Input('displayDeleteButton') public displayDeleteButton: boolean = true;
  @ContentChild('customHtmlButtons') customHtmlButtons: TemplateRef<any>;

  disableSave: boolean = false;
  urlToNavigateAfterSaving: string;

  getFormValidationErrors() {
    Object.keys(this.modelFormGroup.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.modelFormGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  public save(): void {
    let confirmSave = true;
    if (this.confirmationMessageBeforeSaving && this.confirmationMessageBeforeSaving != "") {
      confirmSave = confirm(this.confirmationMessageBeforeSaving)
    }
    if (!confirmSave)
      return;

    this.getFormValidationErrors();
    //console.log(this.modelFormGroup);

    this.disableSave = true;
    if (this.modelFormGroup.valid) {
      if (!this.postMethod || this.postMethod == null) {
        this.postMethod = "update";
      }

      this.dataProvider[this.postMethod](this.modelFormGroup.value).subscribe(
        (r: any) => {
          if (this.onSaveGoToBack) {
            this.location.back();
          } else if (r) {
            this.router.navigateByUrl(`/${this.urlToNavigateAfterSaving}${r.id != undefined ? `/${r.id}` : ``}`);
          }
        },
        () => {
          // this.toraxHttp.handleError(error);
          this.location.back();
        });
    } else {
      this.disableSave = false;
    }
  }

  public cancel(): void {
    this.previousRouteService.goToPrev();
  }

  public delete(): void {
    this.dataProvider.delete(this.id).subscribe(
      () => {
        this.previousRouteService.goToPrev();
      },
      error => {
        this.toraxHttp.handleError(error);
        this.previousRouteService.goToPrev();

      });
  }

  ngAfterViewInit() {
    if (this.navigateAfterSaving != '')
      this.urlToNavigateAfterSaving = this.navigateAfterSaving;
    else if (!this.onSaveGoToBack) {
      this.urlToNavigateAfterSaving = this.dataProvider.url.split('/')[1];
    }
  }
}

