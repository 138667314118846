import { CreateMasavFileDlg } from '../createMasavFileDlg.cmp';
import { ModalService } from '../../components/modal/modal.svc';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from '../../components/alert/alert.svc';
import { CollectionBuildersData } from '../collection-builders.data';
import { CollectionBuilder, CreateMasavFileModel } from './collection-builder';
import { DatePipe } from '@angular/common';

import { saveAs } from 'file-saver';
@Component({
    selector: 'collection-builder-details',
    templateUrl: './collection-builder-details.tmpl.html'
})

export class CollectionBuilderDetails implements OnInit {
    constructor(
        private collectionBuildersData: CollectionBuildersData,
        private activatedRoute: ActivatedRoute,
        private alertSvc: AlertService,
        private formBuilder: FormBuilder,
        private toraxHttp: ToraxHttp,
        private modal: ModalService,
        private datePipe: DatePipe
    ) {
        this.toraxHttp.updatedEvent.subscribe((id) => {
            if (this.collectionBuilder && id === this.collectionBuilder.collectionBuilderID) {
                this.load();
            }
        });
    }


    collectionBuilder: CollectionBuilder;
    id: number;
    collectionBuilderForm: FormGroup;
    disableBtn = false;


    private downloadMasavFile = (createMasavFileDlg: CreateMasavFileDlg): void => {
      if (createMasavFileDlg.formData.valid) {
          let billingDate = createMasavFileDlg.formData.controls['billingDate'].value;
          let makeAsSucceeded = createMasavFileDlg.formData.controls['makeAsSucceeded'].value;
          this.collectionBuildersData.publishMasavCollection(this.id, billingDate, makeAsSucceeded)
              .subscribe((txt:any) => {
                let byteNumbers = new Array(txt.value.length);
                for (var i = 0; i < txt.value.length; i++) {
                  byteNumbers[i] = txt.value.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                var data = new Blob([byteArray], {type: "application/octet-stream"});


                // let blob = new Blob([txt.value], { type: 'text/plain' });
                //   var url = window.URL.createObjectURL(blob);
                //   var anchor = document.createElement("a");
                //   anchor.href = url;
                //   anchor.setAttribute('href', 'data:text/plain,' +  txt.value);

                //   let dt = this.datePipe.transform( new Date(), 'ddmmyy');
                //   anchor.download = `${this.id}.mv`;
                //   anchor.click();
                saveAs(data, `${this.id}.mv`);
                this.alertSvc.success(`הורדה הסתיימה, טען את הקובץ למערכת מס"ב ובצע חיוב`, 15);
                this.load();
                return true;
              });

      }
  }


  private downloadMasavRefundFile = (createMasavFileDlg: CreateMasavFileDlg): void => {
    if (createMasavFileDlg.formData.valid) {
        let billingDate = createMasavFileDlg.formData.controls['billingDate'].value;
        let makeAsSucceeded = createMasavFileDlg.formData.controls['makeAsSucceeded'].value;
        this.collectionBuildersData.publishMasavRefundCollection(this.id, billingDate, makeAsSucceeded)
            .subscribe((txt:any) => {
                let blob = new Blob([txt.value], { type: 'text/plain;charset=windows-1255' });
                var url = window.URL.createObjectURL(blob);
                var anchor = document.createElement("a");
                anchor.href = url;
                anchor.setAttribute('href', 'data:text/plain;charset=ansi,' +  txt.value);

                let dt = this.datePipe.transform( new Date(), 'ddmmyy');
                anchor.download = `${dt}.msv`;
                anchor.click();
                this.alertSvc.success(`הורדה הסתיימה, טען את הקובץ למערכת מס"ב ובצע חיוב`, 15);
                this.load();
                return true;
            });

    }
}


private openMasavDlg(): void {
  let createMasavFileModel = new CreateMasavFileModel();
  this.modal.show({
      Header: 'יצירת קובץ חיובים מסב',
      BodyComponentType: CreateMasavFileDlg,
      Actions: [
          { Text: 'ביטול', CssClass: 'btn-default', Action: () => true },
          { Text: 'אישור', CssClass: 'btn-primary', Action: this.downloadMasavFile, Validate: true }
      ],
      Data: {
          createMasavFileModel
      }
  });
}

private openMasavRefundDlg(): void {
    let createMasavFileModel = new CreateMasavFileModel();
    this.modal.show({
        Header: 'יצירת קובץ זיכויים מסב',
        BodyComponentType: CreateMasavFileDlg,
        Actions: [
            { Text: 'ביטול', CssClass: 'btn-default', Action: () => true },
            { Text: 'אישור', CssClass: 'btn-primary', Action: this.downloadMasavRefundFile, Validate: true }
        ],
        Data: {
            createMasavFileModel
        }
    });
}



    public load(): void {
        if (this.id) {
            this.collectionBuildersData.get(this.id)
                .subscribe((cb:any) => {
                    this.collectionBuilder = cb;
                    this.fillForm(cb);
                });
        }
    }

    private fillForm(c: CollectionBuilder): void {
        this.collectionBuilderForm.patchValue(c);
    }



    public publish(): void {
        this.disableBtn = true;
        if (this.collectionBuilderForm.valid) {
            this.collectionBuildersData.publishCreditCardsCollection(this.id)
                .subscribe((c: any) => {
                    this.alertSvc.success(`פעולה הסתיימה`);
                });
            //}
        } else {
            console.log('not valid');
            console.log(this.collectionBuilderForm.errors);
        }

    }


    private initForm() {
        this.collectionBuilderForm = this.formBuilder.group({
            collectionBuilderID: [],
            collectionDate: [null],
            contactID: [],
            termID: []
        });
    }


    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['collectionBuilderID'];
        });
        this.initForm();
        this.load();
    }
}

