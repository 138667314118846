<div class="row" *ngIf="contact" style="padding-right: 10px;">
    <div class="col-lg-12" style="padding: 0 !important;">
        <div class="gray-bg p-lg text-right border-l" style="padding: 10px; margin: 0;">
            <div class="m-b-md">
                <div fxFlex="100" class="feed-element">
                    <div fxLayoutAlign="start start" fxFlex="10">
                        <button (click)="closeDrawer()" class="btn btn-circle">
                            <i matTooltip="חזור" title="חזור" class="fa fa-arrow-right" style="font-size: large;"></i>
                        </button>
                    </div>
                    <div  fxLayoutAlign="start center" fxFlex>
                        <h2 class="font-bold no-margins">
                            <small>{{contact.perfix}}</small>
                            <br *ngIf="contact.perfix">
                            {{contact.firstName}} {{contact.lastName}}
                       <br>
                        <small>
                            {{contact.streetLine}} {{contact.cityName}}
                            <br *ngIf="contact.streetLine">
                            {{contact.phoneNumber1 | phone}} | {{contact.phoneNumber2 | phone}}
                        </small>
                    </h2>
                    </div>
                    <div fxLayoutAlign="start start" fxFlex="12">
                        <contact-report-action [contact]="contact"></contact-report-action>
                    </div>
                </div>
               
            </div> 
        </div>
        <div class="row" style="text-align: center; padding:12px 0; margin: 0; white-space: nowrap; overflow: auto;">
            <mat-button-toggle-group (change)="loadData()" [(ngModel)]="selectedSubjects" multiple>
                <mat-button-toggle style="padding: 5px; font-weight: bold;"
                    *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                    <i *ngIf="selectedSubjects.indexOf(cs.value) > -1" class="fa fa-check"></i>
                    {{cs.name}}
                </mat-button-toggle>
            </mat-button-toggle-group>

        </div>
        <div loading-status [stopLoadingWhen]="contact.commitments != null">
            <div *ngFor="let item of contact.commitments">
                <commitment-details-view [groupSubjects]="groupSubjectsAsStr" [commitment]="item">
                </commitment-details-view>
            </div>
        </div> 
    </div>
</div>