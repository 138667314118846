import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesMdl } from './../directives/directives-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmountAndCurrencyInputComponent } from './amount-and-currency-input.component';

@NgModule({
    imports: [
        CommonModule,
		FormsModule,
		ReactiveFormsModule,
        DirectivesMdl,
        MatSelectModule,
        MatInputModule,
        MatOptionModule
    ],
    declarations: [
        AmountAndCurrencyInputComponent,
    ],
    exports: [
        AmountAndCurrencyInputComponent,
    ]
})


export class AmountAndCurrencyInputModule {

}
