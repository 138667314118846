import { CcChargeModel } from './cc-charge-model';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { ObjectsListResult } from '../services/objects-list-results';
import { Payment } from '../payments/payment/payment';

@Injectable()

export class CcChargeData {

    public url = 'api/ccCharges';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    chargePayment(ccChargeModel: CcChargeModel) {
        return this.toraxHttp.update(`${this.url}/chargePayment`, ccChargeModel);
    }

}
