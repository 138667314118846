import { AfterViewInit } from '@angular/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'commitment-title',
    templateUrl: 'commitment-title.component.html',
    styleUrls: ['commitment-title.component.scss']
})
export class CommitmentTitleComponent    { 
  
    @Input('pc') public pc: any;
  //  @Input('onlyIdView') public onlyIdView: boolean = false;
  
}
