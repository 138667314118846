import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TishInvitedsData } from './../tishInviteds.data';
import { ServicesData } from './../../services/services.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

const dropDownRequired = c => {
  if (!c.value || c.value === 'undefined') {
    return { required: true };
  }
  return null;
};

@Component({

  templateUrl: './tishInvited-edit-dlg.tmpl.html'
})
export class TishInvitedDlg implements OnInit {
  public formData: FormGroup;


  constructor(
    public dialogRef: MatDialogRef<TishInvitedDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private tishInvitedsData: TishInvitedsData
  ) { }

  ngOnInit(): void {
    if(this.data.tishInvitedID > 0){
      this.load();
    } else{
      this.formData = this.tishInvitedsData.initForm();
           this.formData.patchValue(this.data);
    }
  }

  load() {
    if (this.data.tishInvitedID) {
      this.tishInvitedsData.getEdit(this.data.tishInvitedID)
        .subscribe((c: any) => {
          this.formData = this.tishInvitedsData.initForm();
          this.data = c;
          this.formData.patchValue(this.data);
        });
    }
  }



  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }
}
