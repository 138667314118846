import { Contact } from './../contact';

import { Component, Input } from '@angular/core';
                     
@Component({
    selector: 'contact-short-details',
    templateUrl: 'contact-short-details.tmpl.html'
})

export class ContactShortDetails {
    @Input('contact') public contact: Contact;   
}
