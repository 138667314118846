<div loading-status [stopLoadingWhen]="honorsSet != null">
    <div *ngIf="honorsSet">
        <div class="modal-header" fxLayoutAlign="start start" fxLayout="row" fxLayout.xs="column">
            <div fxFlex="35" style="white-space: nowrap;">
                <h1 #hebTitle style="width: fit-content;">
                    [{{ honorsSet.honorsSetID }}] {{ honorsSet.description}}
                </h1>
                <h3>{{ honorsSet.honorsSetParash }},
                    {{ honorsSet.honorsSetJewishDate }}
                </h3>
            </div>
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="end start">
                <a class="btn btn-primary" (click)="createAndPrintHonorsSetLetters()"><i
                        class="fa fa-print"></i>&nbsp;הדפס מכתבים</a>

            </div>
        </div>
        <div class="row modal-body" style="height: auto;">
            <honors-list [honorsSetID]="honorsSet.honorsSetID">Loading...</honors-list>
        </div>
    </div>
</div>