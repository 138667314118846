<div>
    <form [formGroup]="searchIvrAssignmentUserTasksListForm">
        <div class="example-container" style="background-color: white; border-bottom: 10px solid rgb(243, 243, 244)">
 

            <div fxLayoutAlign="start center" style="text-align:left; position: absolute; left: 10px;" class="example-button-row">
                <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן"
                    (click)="selectionChanged($event)"><i class="fa fa-filter"></i></button>
                    <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                        class="material-icons">clear</i></button>
                        <button style="margin: 3px;" mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="exportToExcel()"><i
                            class="fa fa-file-excel-o"></i></button>
                            <button style="margin: 3px;" mat-mini-fab color="primary" title="הורדה" (click)="downloadPdf()"><i
                                class="fa fa-file-pdf-o"></i></button>
                        <button style="margin: 3px;" mat-mini-fab color="primary" title="הוסף" (click)="addNew()"
                    aria-label="הוסף חדש">
                    <i class="material-icons">add</i>
                </button>
            </div>

            <div class="column" style="padding-top: 12px; overflow: auto;">
                <div fxLayout="row" class="scrolling-wrapper">
                    <div class="input-group mb-3">
                        <label>סטטוס...</label>
                        <mat-select class="form-control" (selectChange)="selectionChanged($event)"
                            placeholder="סטטוס..." formControlName="hasDone">
                            <mat-option [value]="null">הכל</mat-option>
                            <mat-option [value]="true">בוצע</mat-option>
                            <mat-option [value]="false">לא-בוצע</mat-option>
                        </mat-select>
                    </div>
                    &nbsp;&nbsp;
                    <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                        (selectChange)="selectionChanged($event)"
                        [listControl]="searchIvrAssignmentUserTasksListForm.controls.userNames"
                        [placeholder]="'אחראים...'" [list]="servicesData.AssignmentUserTasksUserNamesVms">
                    </mat-select-multiple-dropdown>
                    &nbsp;&nbsp;
                    <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                        (selectChange)="selectionChanged($event)"
                        [listControl]="searchIvrAssignmentUserTasksListForm.controls.subjects" [placeholder]="'נושא...'"
                        [list]="servicesData.AssignmentUserTasksSubjectsVms">
                    </mat-select-multiple-dropdown> 
                </div>
            </div>  
        </div>


        <div class="example-container">
            <!-- <div fxLayout="row">
            <span class="example-fill-remaining-space"></span>
            <button  class="btn btn-outline btn-success  dim" type="button"
                style="width: min-content; font-weight: 500; color:#ff4081" *ngIf="selection && selection.selected.length >= 1"
                (click)="printSticks()">
                הדפס מדבקות ל-{{selection.selected.length}} רשומות נבחרות
            </button>
        </div> -->
            <div class="bounds">
                <div class="content" style="height: 70%; overflow: auto">
                    <mat-table [dataSource]="sourceList" style="overflow-x: scroll;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">
                        <ng-container matColumnDef="select">
                            <mat-header-cell style="padding-right: 0;" *matHeaderCellDef>
                                <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                                    *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox apply-enter (click)="$event.stopPropagation()"
                                    (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                             </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>...</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <a [routerLink]="['', {outlets: {popup: ['ivrAssignmentUserTasks', row.ivrAssignmentUserTaskID, 'edit']}}]">
                                    <i style="color: gray;" matTooltip="עריכה" class="fa fa-pencil"></i> &nbsp;
                                    {{ row.ivrAssignmentUserTaskID }}
                                </a>
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="createDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תאריך">
                                {{ row.createDate | date: 'dd/MM/yyyy hh:mm' }} <br>{{ row.jewishCreateDate }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="subject">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> נושא </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="נושא">
                                <span style="font-weight: bold;"> {{ row.subject }} </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="userName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> אחראי ביצוע </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="אחראי ביצוע">
                                {{ row.userName }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="contactName">
                            <mat-header-cell *matHeaderCellDef> שם מתקשר </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="שם מתקשר">
                                {{ (row.contactName ? row.contactName : row.identifiedContactName) }}<br>
                                {{row.phoneNumber | phone}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="doneByUserName">
                            <mat-header-cell *matHeaderCellDef> טופל ע"י </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="טופל ע''י">
                                {{ row.doneByUserName }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="doneDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך טיפול </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תאריך טיפול">
                                {{ row.doneDate | date: 'dd/MM/yyyy hh:mm' }} <br>{{ row.jewishDoneDate }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="comment">
                            <mat-header-cell *matHeaderCellDef> תיאור </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תיאור">
                                {{row.comment}}
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="openSelectedRow(row.ivrAssignmentUserTaskID)">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <h1 *ngIf="isLoadingResults" style="color: gray; text-align: center;">
                <i class="fa fa-refresh  fa-spin"></i>
            </h1>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </form>
</div>