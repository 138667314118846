<form [formGroup]="searchCampaignAttachedsListForm">

  <div class="ibox-content m-b-sm border-bottom">


        <div class="example-container" *ngIf="indexService">
            <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test
                itemSize="10" style="height:calc(100vh - 320px)">
                <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
                    [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">

                    <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <a mat-menu-item
                                    [routerLink]="[ '', {outlets: {popup: [ 'campaignAttacheds', row.campaignAttachedID]}}]">
                                    <mat-icon>pageview</mat-icon>
                                    <span>פרטים מלאים</span>
                                </a>

                                <a mat-menu-item
                                    [routerLink]="['', {outlets: {popup: ['campaignAttacheds', row.campaignAttachedID, 'edit']}}]">
                                    <mat-icon>edit</mat-icon>
                                    <span>עריכה</span>
                                </a>

                                <a mat-menu-item>
                                    <mat-icon>information</mat-icon>
                                    <model-info style="padding: 4px" [dataProvider]="campaignAttachedsData" [id]="row.campaignAttachedID" [title]="'מידע רשומה'">
                                    </model-info>
                    </a>


                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachedName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>מצטרף</mat-header-cell>
                        <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="מצטרף">
                            {{ row.attachedName }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="comment">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>הערה</mat-header-cell>
                        <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="הערה">
                            {{ row.comment }}</mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="count">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>כמות</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="כמות">
                            {{ row.count }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="contactID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>מזהה איש</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מזהה איש">
                            {{ row.contactID }}</mat-cell>
                    </ng-container>



                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                        (dblclick)="openSelectedRow(row.campaignAttachedID)">
                    </mat-row>
                </mat-table>
            </cdk-virtual-scroll-viewport>

            <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="indexService.rowsCount" [pageSize]="indexService.pageRows"
                [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </div>
</form>

<ng-template #loading>
    <h3 style="text-align: center;">
        טוען נתונים...
    </h3>
</ng-template>
