import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { RegistrationsData } from './../registrations.data';
import { Registration } from './registration';

@Component({

	selector: 'registration-details',
	templateUrl: './registration-details.tmpl.html'
})

export class RegistrationDetails implements OnInit {
	constructor(
		private registrationsData: RegistrationsData,
		private toraxHttp: ToraxHttp,
		private activatedRoute: ActivatedRoute
	) {

		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.registration && id === this.registration.registrationID) {
				this.loadRegistration();
			}
		});
	}

	public registration: Registration;
	private id: number;

	public loadRegistration(): void {
		this.registrationsData.get(this.id)
			.subscribe((c:any) => this.registration = c);
	}
	exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];
	isLink(pmId) {
		return this.exludePayMethodsIDs.indexOf(pmId) < 0;
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['registrationID'];
		});
		this.loadRegistration();
	}
}
