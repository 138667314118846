import { ToraxHttp } from './../../utils/custom-http.svc';
import { ContactEvent } from './contactEvent/contactEvent';

import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";

@Injectable()

export class ContactEventsData {

    private url = 'api/contactEvents';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    getContactEvents(contactID: number) {
        return this.toraxHttp.get(`${this.url}/getContactEvents/${contactID}`);
    }

    get(contactEventID: number) {
        return this.toraxHttp.get(`${this.url}/${contactEventID || 0}`);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    update(contactEvent: ContactEvent) {
        return this.toraxHttp.update(this.url, contactEvent);
    }

    setContactEventPriority(contactID: number, prevContactEventID: number, curContactEventID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setContactEventPriority?contactID=${contactID}&prevContactEventID=${prevContactEventID}&curContactEventID=${curContactEventID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultContactEventID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultContactEventID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

