<div *ngIf="tishGroup" class="row">
    <div class="col-sm-6">
        <panel-box title="פרטי קבוצה: [{{ id }}]" [showClose]="false" [showCollapse]="false">
            <span class="panel-tools">
                    <a [routerLink]="['/tishGroups', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
                </span>
            <div class="row">
                <dl class="dl-horizontal">
                    <dt>שם קבוצה</dt>
                    <dd>{{ tishGroup.tishGroupName }}</dd>
                    <dt>אירוע</dt>
                    <dd>{{ tishGroup.tishEventTitle }}</dd>
                    <dt>תיאור</dt>
                    <dd>{{ tishGroup.description }}</dd>
                    <dt>הערה</dt>
                    <dd>{{ tishGroup.comment }}</dd>
                </dl>
            </div>
        </panel-box>
    </div>


</div>