<mat-card fxLayout="column" class="example-card">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            <h3 style="TEXT-DECORATION: UNDERLINE;">אמצעי קשר</h3>
            <span class="example-fill-remaining-space"></span>
            <button #matMenuTrigger fxLayout="column"
                style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px"
                matTooltip="הוסף אמצעי קשר" mat-raised-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                (mouseover)="menuTrigger.openMenu()">
                <mat-icon style="color: GRAY;">add_box</mat-icon>
            </button>

            <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <span (mouseleave)="menuTrigger.closeMenu()">
                    <button mat-menu-item (click)="openAddOrEditPhone(tempNewPhone)">
                        <mat-icon>phone</mat-icon>
                        <span>הוסף טלפון</span>
                    </button>
                    <button mat-menu-item (click)="openAddOrEditPhone(tempNewEmail)">
                        <mat-icon>email</mat-icon>
                        <span>הוסף דוא"ל</span>
                    </button>
                </span>
            </mat-menu>

            <button fxLayout="column" (click)="loadAll()"
                style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px"
                matTooltip="הצג גם הסטוריה" mat-stroked-button>
                <mat-icon>history</mat-icon>
            </button>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="width: 100%;">


        <div [formGroup]="searchPhonesListForm" class="example-full-width">
            <div cdkDropList class="example-list" *ngIf="phones" (cdkDropListDropped)="drop($event)"
                class="example-full-width">
                <div fxLayoutAlign="start center" cdkDrag class="example-box" *ngFor="let p of phones"
                    [cdkDragData]="p">
                    <mat-icon matTooltip="גרור כדי לתעדף" class="example-handle" cdkDragHandle fxLayout="column">
                        unfold_more</mat-icon>
                    <phone-details fxLayout="column" fxFlex (onEditClick)="openAddOrEditPhone(p)"
                        (phoneChange)="phoneChangeEvent(p.phoneID)" [phone]="p"></phone-details>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
