import { DateMonthInput } from './../dateMonth-input/dateMonth-input.cmp';

import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { DateMonthRangeClass } from './dateMonth-range.class';

@Component({
    
    selector: 'dateMonth-range',
    templateUrl: 'dateMonth-range.tmpl.html',
})



export class DateMonthRange implements OnInit {
    constructor(
        private formBuilder: FormBuilder
    ) { }

    @Input('dateMonthRangeCtrl') dateMonthRangeCtrl: FormControl;
    @Input('fromPlaceholder') fromPlaceholder: string;
    @Input('tillPlaceholder') tillPlaceholder: string;
    @Input('fromIsRequired') fromIsRequired: boolean;
    @Input('tillIsRequired') tillIsRequired: boolean;
    

    dateMonthRangeForm: FormGroup;
    dateMonthRangeClass = new DateMonthRangeClass();


    private initForm() {
        let startMonth = this.dateMonthRangeCtrl.value ? this.dateMonthRangeCtrl.value.startMonth : null;
        let startYear = this.dateMonthRangeCtrl.value ? this.dateMonthRangeCtrl.value.startYear : null;
        let endMonth = this.dateMonthRangeCtrl.value ? this.dateMonthRangeCtrl.value.endMonth : null;
        let endYear = this.dateMonthRangeCtrl.value ? this.dateMonthRangeCtrl.value.endYear : null;
        this.dateMonthRangeForm = this.formBuilder.group({
            startMonth: [startMonth],
            startYear: [startYear],
            endMonth: [endMonth],
            endYear: [endYear]
        });

        //this.dateRangeForm.patchValue(this.dateRangeCtrl);

        this.dateMonthRangeForm.valueChanges.subscribe(arg => {
            this.dateMonthRangeClass.startMonth = arg.startMonth;
            this.dateMonthRangeClass.startYear = arg.startYear;
            this.dateMonthRangeClass.endMonth = arg.endMonth;
            this.dateMonthRangeClass.endYear = arg.endYear;
            this.dateMonthRangeCtrl.setValue(this.dateMonthRangeClass);
        }
        );

    }

    ngOnInit() {
        this.initForm();
    }

}

