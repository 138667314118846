import {  CurrencyPipeMdl } from './../../components/pipes/currency-pipe';
import { AuthGuardMdl } from './../../_guards/auth.guard.mdl';
import { MaterialModule } from './../../material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Angular Imports
import { NgModule } from '@angular/core';
import { DashboardDirectorReportsComponent } from './dashboard-director-reports.cmp';
import { DashboardDirectorReportsDataService } from './dashboard-director-reports.data';
import { DashboardDirectorReportsRouting } from './dashboard-director-reports.routing';
import { CommitmentsMdl } from 'src/app/commitments/commitments.mdl';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(DashboardDirectorReportsRouting),
        AuthGuardMdl,
        MaterialModule,
        CurrencyPipeMdl,
        CommitmentsMdl
    ],
    exports:[DashboardDirectorReportsComponent],
    declarations: [
      DashboardDirectorReportsComponent
    ],
    providers: [DashboardDirectorReportsDataService]
})
export class DashboardDirectorReportsModule {

}
