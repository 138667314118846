import { ServicesData } from './../../services/services.data';
 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms'; 
import { ProcessesTypesData } from '../processes-types.data';
import { ProcessesType } from './processes-type';
@Component({
    
    selector: 'processes-type-edit',
    templateUrl: './processes-type-edit.tmpl.html'
})

export class ProcessesTypeEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private processesTypesData: ProcessesTypesData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
    ) {

    }

    contactID: number;
    processType: ProcessesType;
    id: number;
    processForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.processesTypesData.getEdit(this.id)
                .subscribe((p:any) => {
                    this.processType = p;
                    this.processForm.patchValue(p);
                });
        }
        else {
            this.processType = new ProcessesType();
        }
    }

    private initForm() {
        this.processForm = this.formBuilder.group({ createBy:[], createDate:[], changeBy:[], changeDate:[],            
            processTypeID: [this.id, Validators.required],
            title: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['processTypeID'] || 0;
        });
        this.initForm();
        this.load();

    }
}