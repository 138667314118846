import { AuthenticationService } from '../../_guards/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServicesData } from './../../services/services.data';
import { NavbarTopData } from './navbar-top.data';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AutoCompleteComponent } from '../‏‏auto-complete/auto-complete.cmp';

@Component({
    selector: 'navbar-top',
    templateUrl: './navbar-top.tmpl.html'
})

export class NavbarTopComponent {

    constructor(
        private cdRef: ChangeDetectorRef,
        public router: Router,
        public titleService: Title,
        private NavbarTopData: NavbarTopData,
        public servicesData: ServicesData,
        public spinnerService: SpinnerService,
        public formBuilder: FormBuilder) {
    }

    @Output() toggleNavigation = new EventEmitter();
    @Output() reLoadAllLists = new EventEmitter();
    @Output() backClicked = new EventEmitter();
    @Output() logout = new EventEmitter();
    @Input() title = "";
    @ViewChild("searcAc") searcAc: AutoCompleteComponent;


    toggleNavigationE(): void {
        this.toggleNavigation.emit();
    }
    reLoadAllListsE(): void {
        this.reLoadAllLists.emit();
    }
    backClickedE(): void {
        this.backClicked.emit();
    }
    logoutE(): void {
        this.logout.emit();
    }

    showProgressBar: boolean = false;
    userName: string;
    public pendingUsaReceipts;
    public pendingUkReceipts;
    public pendingReceipts;
    public waitingActivities;
    public pendingPaymentsForReceipts;
    mainSearchForm: FormGroup;


    public getPendingUsaReceipts(): void {
        this.NavbarTopData.getPendingUsaReceipts().subscribe(
            (r: any) => {
                this.pendingUsaReceipts = r;
            },
            () => { });
    }

    public getPendingUkReceipts(): void {
        this.NavbarTopData.getPendingUkReceipts().subscribe(
            (r: any) => {
                this.pendingUkReceipts = r;
            },
            () => { });
    }

    public getPendingReceipts(): void {
        this.NavbarTopData.getPendingReceipts().subscribe(
            (r: any) => {
                this.pendingReceipts = r;
            },
            () => { });
    }

    public getWaitingActivities(uid: string): void {
        this.NavbarTopData.getWaitingActivities(uid).subscribe(
            (r: any) => {
                this.waitingActivities = r;
            },
            () => { });
    }

    // public getPending_IL_PaymentsForReceipts(): void {
    //     this.NavbarTopData.getPending_IL_PaymentsForReceipts().subscribe(
    //         (r: any) => {
    //             this.pending_IL_PaymentsForReceipts = r;
    //         },
    //         () => { });
    // }

    // public getPending_USA_PaymentsForReceipts(): void {
    //     this.NavbarTopData.getPending_USA_PaymentsForReceipts().subscribe(
    //         (r: any) => {
    //             this.pending_USA_PaymentsForReceipts = r;
    //         },
    //         () => { });
    // }

    // public getPending_UK_PaymentsForReceipts(): void {
    //     this.NavbarTopData.getPending_UK_PaymentsForReceipts().subscribe(
    //         (r: any) => {
    //             this.pending_UK_PaymentsForReceipts = r;
    //         },
    //         () => { });
    // }

    searchContactsListForm: FormGroup;
    initForm() {
        this.searchContactsListForm = this.formBuilder.group({
            page: [1],
            pageRows: [20],
            query: [],
            contactID: [],
            contactName: [],
            cityID: [],
            cityTitle: [],
            shtibelID: [],
            shtibelName: [],
            groupID: [],
            groupIDs: [],
            groupPriorities: [],
            selectedRows: [],
            orders: this.formBuilder.array([])
        });
    }

    ngAfterViewInit() {

        if (!this.searchContactsListForm) {
            this.initForm();
        }
        this.showProgressBar = true;
        this.spinnerService.showProgressBarEvent.subscribe(x => {
            this.spinnerService.visibility.next(x);
            this.cdRef.detectChanges();
        });       //this.getPending_IL_PaymentsForReceipts();
    }

    searchSelect(id: number) {
        if (id) {
            this.router.navigate(['contacts', id]);
        }
        setTimeout(() => {
            this.searcAc.txtSearch.nativeElement.value = "";
        }, 500);
    }

}
