import { LoaderBarService } from '../../components/loader-bar/loader-bar.service';
import { Observable, Observer } from 'rxjs';
import { Location } from '@angular/common';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { PaymentsList } from '../../payments/payments-list.cmp';
import { CommitmentsData } from './../commitments.data';
import { MatDialog } from '@angular/material/dialog';

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { ClosedCommitmentMdl, Commitment } from './commitment';
import { PaymentsPlansList } from 'src/app/paymentsPlans/paymentsPlans-list.cmp';
import { CommitmentClosedDlg } from './commitment-closed-dlg.cmp';
import { FormGroup } from '@angular/forms';


@Component({
	selector: 'commitment-details',
	templateUrl: './commitment-details.tmpl.html',
	styleUrls:['./../commitments.scss']
})

export class CommitmentDetails implements OnInit {
	constructor(
		private commitmentsData: CommitmentsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private cdref: ChangeDetectorRef,
		private location: Location,
		public dialog: MatDialog,
		private router: Router,
		private loaderBarService: LoaderBarService
	) {

		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.commitment && id === this.commitment.commitmentID) {
				this.loadCommitment();
			}
		});
		this.asyncTabs = Observable.create((observer: Observer<any[]>) => {
			setTimeout(() => {
				observer.next([
					{ content: 'First', label: 'תוכניות תשלום' },
					{ content: 'Second', label: 'תשלומים' },
					// { content: 'Third', label: 'החזקת ביהמד' },
				]);
			}, 1000);
		});
	}
 	public commitment: Commitment;
	private id: number;
	asyncTabs
	expendPayments = false;
	expendPaymentsPlans = false;
	expendHoldings = false;
	@ViewChild('paymentsTab') public paymentsTab: PaymentsList;
	@ViewChild('paymentsPlansTab') public paymentsPlansTab: PaymentsPlansList;

	isView() {
		return this.commitment.isHoldingCommitment;
	}

	viwHoldings() {
		this.loaderBarService.show();
	 	}

	activeTabIndex = 1;
	perfix = "tabs_";
	public itemSelect(event) {
		this.activeTabIndex = event.index;
		sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
		this.cdref.detectChanges();
	}

	//בינתיים פונקציה זו מבוטלת כי אין סיבה לאפשר יצירה אוטומטית של קבלות
	//וכעת החזרתיה
	// public createAndPrintReceiptsForCommitment(): void {
	// 	this.commitmentsData.createAndPrintReceiptsForCommitment(this.id)
	// 		.subscribe(data => {
	// 			let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no, onload="window.print()"');
	// 			popupWinindow.document.open();
	// 			popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: A4; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
	// 			popupWinindow.document.close();
	// 		},
	// 			error => { });
	// };





	openClosedCommitmentDlg(): void {
		setTimeout(() => {
			let closedCommitmentMdl = new ClosedCommitmentMdl();
			closedCommitmentMdl.commitmentID = this.id;


			const dialogRef = this.dialog.open(CommitmentClosedDlg, {
				data: closedCommitmentMdl
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.closeCommitment(result);
				}
			});
		}, 200);
	}

	private closeCommitment(CommitmentClosedForm: FormGroup) {
		this.commitmentsData.closeCommitment(CommitmentClosedForm.value)
			.subscribe((x: any) => {
				this.loadCommitment()
				return true;
			})
	}



	public loadCommitment(): void {
		this.commitmentsData.get(this.id)
			.subscribe((c: any) => {
				this.commitment = c
				if (this.paymentsTab) {
					this.paymentsTab.indexService.getList();
				}
				if (this.paymentsPlansTab) {
					this.paymentsPlansTab.indexService.getList();
				}


			});
	}

	get pathStr() {
		return `${this.perfix}${this.location.path()}`;
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['commitmentID'];
			this.loadCommitment();
		});
		this.activeTabIndex = +sessionStorage.getItem(`${this.pathStr}`) || 0;

	}
}


