import { DonationCategoryMain } from './donationCategory/donationCategory-main.cmp';
import { DonationCategoryDetails } from './donationCategory/donationCategory-details.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { DonationCategoriesList } from './donationCategories-list.cmp';

export const DonationCategoriesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'donationCategories', component: DonationCategoriesList, data: {  roles: "donationCategories",  title: 'רשימת קטגוריות' } },
    { canActivate: [AuthGuard], path: 'donationCategories/add', component: DonationCategoryMain, data: { mode: 'edit', roles: "donationCategories", title: 'הוסף קטגוריה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'donationCategories/:donationCategoryID', component: DonationCategoryMain, data: {  roles: "donationCategories",  title: 'פרטי קטגוריה' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'donationCategories/:donationCategoryID/edit', component: DonationCategoryMain, data: { mode: 'edit', roles: "donationCategories",  title: 'עריכת פרטי קטגוריה' }, outlet: 'popup' }
];
