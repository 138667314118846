import { CommitmentsData } from './commitments.data';
import { ServicesData } from './../services/services.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';


@Component({

    templateUrl: './commitments-closeCommitment-dlg.tmpl.html'
})
export class CloseCommitmentDlg implements OnInit {
    public formData: FormGroup;

    public data: any;
    closedDetails: any;

    constructor(
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private commitmentsData: CommitmentsData
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.loadClosedDetails();
    }

    loadClosedDetails() {
        this.commitmentsData.getCommitmentClosedDetails(this.data.closedCommitmentMdl.commitmentID)
            .subscribe((c: any) => {
                this.closedDetails = c;
                this.formData.patchValue(this.closedDetails);
            });
    }

    validations = c => {
        // tslint:disable-next-line:max-line-length
        if (this.formData != null && this.formData.controls.closedDate.value != "" && this.formData.controls.closedDate.value != null && (!c.value || c.value=== 'undefined')) {
            return { required: true };
        }
        return null;
    };


    private initForm(): void {
        this.formData = this.formBuilder.group({
            commitmentID: [, this.validations],
            closedComment: [null, this.validations],
            closedDate: [ , this.validations],
            closedStatus: [, this.validations]
        });
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}

