import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { IvrAssignmentUserTaskEditWin } from './ivr-assignment-user-task/ivr-assignment-user-task-edit-win.cmp';
import { IvrAssignmentUserTaskEdit } from './ivr-assignment-user-task/ivr-assignment-user-task-edit.cmp'; 
import { AutoCompleteInputModule } from './../components/auto-complete-input/auto-complete-input.mdl'; 
import { MaterialModule } from './../material.module';
import { ivrAssignmentUserTasksList } from './ivr-assignment-user-tasks-list.cmp';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TransactionsMdl } from './../transactions/transactions.mdl'; 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl'; 
import { IvrAssignmentUserTasksData } from './ivr-assignment-user-tasks.data';  
import { IvrAssignmentUserTasksRoutes } from './ivr-assignment-user-tasks.routing'; 
import { IvrAssignmentUserTaskMain } from './ivr-assignment-user-task/ivr-assignment-user-task-main.cmp';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(IvrAssignmentUserTasksRoutes),		 
		AutoCompleteModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule, 
		FooterEditButtonsMdl,
		PhonePipeMdl, 
		DateInputModule,
		MaterialModule, 
		AutoCompleteInputModule, 
MainFormModalModule
	],
	declarations: [ 
		ivrAssignmentUserTasksList,
		IvrAssignmentUserTaskEdit, 
		IvrAssignmentUserTaskEditWin, 
		IvrAssignmentUserTaskMain
	],
	exports: [ 
		ivrAssignmentUserTasksList, 
		IvrAssignmentUserTaskMain
	//	IvrAssignmentUserTasksUpladFileDlg
	],
	entryComponents:[],
	providers: [
		IvrAssignmentUserTasksData
	]
})
export class IvrAssignmentUserTasksMdl { }

