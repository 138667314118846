import { GroupsData } from './../groups.data';
import { ServicesData } from 'src/app/services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 
import { Group, GroupUser as GroupUserVM } from './group'; 

@Component({

    selector: 'group-edit',
    templateUrl: './group-edit.tmpl.html'
})

export class GroupEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public servicesData: ServicesData,
        public groupsData: GroupsData,
        private formBuilder: FormBuilder,
        private location: Location,
    ) {
    }

    group: Group;
    id: number;
    groupForm: FormGroup;

    subjectsSelectionChange(event) {
        const groupSubjectsArray = <FormArray>this.groupForm.controls['groupSubjectsVM'];
        let str = groupSubjectsArray.value.join();
        this.groupForm.controls['groupSubjectsAsStr'].setValue(str);
    }

    public load(): void {
        if (this.id) {
            this.groupsData.getEdit(this.id)
                .subscribe((c: any) => {
                    this.fillForm(c);
                });
        }
    }

    fillForm(c: any): any {
        this.groupForm.patchValue(c);
        this.fillGroupUsers(c.groupUsers);
        // const arrGroupSubjectsVM = this.groupForm.controls['groupSubjectsAsStr'];
        // if(arrGroupSubjectsVM.value)    
        // {
        //     this.groupForm.controls['groupSubjectsVM'].setValue(arrGroupSubjectsVM.value.split(","));
        // }        
    }

    private fillGroupUsers(groupUsers: GroupUserVM[]) {
        const groupUsersArray = <FormArray>this.groupForm.controls['groupUsers'];
        groupUsers.forEach((cg, i) => {
            let cgFrm = this.initGroupUser();
            cgFrm.patchValue(cg);
            groupUsersArray.push(cgFrm);
        });
        //this.commitmentForm.controls['payments'].setValue(paymentsArray);
    }


    initGroupUser() {
        let fb = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            groupUserID: [0, Validators.required],
            groupID: [this.id || 0, Validators.required],
            gxUserName: ['', Validators.required],
            isDeleted: [false, Validators.required],
        });
        return fb;
    }

    addGroupUser() {
        let cg = new GroupUserVM();
        cg.groupID = this.id;
        let cgFrm = this.initGroupUser()
        const groupUsersArray = <FormArray>this.groupForm.controls['groupUsers'];
        groupUsersArray.push(cgFrm);
        cgFrm.patchValue(cg);
    }

    get fcGroupUsers(): FormGroup {
        return this.groupForm.controls['groupUsers'] as FormGroup;
    }

    deleteItem(ix: number) {
        let cgFrm = <FormGroup>(<FormArray>this.groupForm.controls['groupUsers']).controls[ix];
        Object.keys(cgFrm.controls)
            .filter(key => key != 'isDeleted' && key != 'groupUserID')
            .forEach(key => {
                cgFrm.controls[key].disable();
            });
        cgFrm.controls['isDeleted'].enable();
        cgFrm.controls['isDeleted'].setValue(true);
    }

    unDeleteItem(ix: number) {
        let cgFrm = <FormGroup>(<FormArray>this.groupForm.controls['groupUsers']).controls[ix];

        Object.keys(cgFrm.controls)
            .filter(key => key != 'isDeleted' && key != 'groupUserID')
            .forEach(key => {
                cgFrm.controls[key].enable();
            });
        cgFrm.controls['isDeleted'].setValue(false);
    }

    private initForm() {
        this.groupForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            groupID: [0, Validators.required],
            groupName: [, Validators.required],
            groupColor: [, Validators.required],
            //    groupDepartment: [, Validators.required],
            showOnDashboard: [false, Validators.required],
            groupUsers: this.formBuilder.array([]),
            groupSubjectsAsStr: [],
            groupSubjectsVM: [],
        });
    }


    setColor(e) {
        if (e && e.target) {
            this.groupForm.controls.groupColor.setValue(e.target.value);
        }
    }

    public cancel(): void {
        this.location.back();
    }


    get groupColorValue(): any {
        let a = this.groupForm.controls['groupColor'] as FormGroup;
        return a.value as any

    }



    ngOnInit() {
        this.initForm();
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['groupID'] || 0;
        });
        if (this.id > 0) {
            this.load();
        } else {
            this.group = new Group();
        }


    }
}



