import { Modal } from './modal.class';
import { ModalService } from './modal.svc';
import {
	Component, OnInit,
	ElementRef,
	ViewChild,
	ViewContainerRef, ComponentFactoryResolver, ComponentRef, Renderer2
} from '@angular/core';

@Component({
	
	selector: 'modal-container',
	templateUrl: './modal-container.tmpl.html',
	styles: [
		`
		modal {
			z-index: 2100;
			overflow: scroll;
		}
		`
	]
})
export class ModalContainerComponent implements OnInit {
	@ViewChild('modal', {static: true}) private modalEl: ElementRef;
	@ViewChild('modalBody', {static: true}) private bodyEl: ElementRef;
	@ViewChild('bodyCmp', { read: ViewContainerRef, static: true }) private bodyVcr: ViewContainerRef;


	private windowResizeEv: Function;
	public modalData: Modal;

	private componentReference: ComponentRef<any>;

	constructor(
		private el: ElementRef,
		private modalSvc: ModalService,
		private renderer: Renderer2,
		private resolver: ComponentFactoryResolver
	) { }

	ngOnInit(): void {
		this.modalSvc.OpenModel.subscribe(this.openModal);
	}    

	private openModal = (m: Modal) => {
		this.modalData = m;

		this.createBodyComponent();

		this.renderer.setStyle(this.modalEl.nativeElement, 'display', 'block');
		this.handleHeight();

		return (this.componentReference ? this.componentReference.instance : null);
	};

	private createBodyComponent(): void {
		this.bodyVcr.clear();

		if (this.modalData.BodyComponentType) {
			let componentFactory = this.resolver.resolveComponentFactory(this.modalData.BodyComponentType);
			this.componentReference = this.bodyVcr.createComponent(componentFactory);
			if (this.modalData.Data) {
				this.componentReference.instance.data = this.modalData.Data;
			}
		}
	}

	private handleHeight() {
		let f = () => {
			let bodyHeight = (window.innerHeight - 220);

			if (this.bodyEl) {
				this.renderer.setStyle(this.bodyEl.nativeElement, 'max-height', (bodyHeight) + 'px');
			}
		};
		f();
		this.windowResizeEv = this.renderer.listen('window', 'resize', f);
	}

	closeModal(): void {
		this.renderer.setStyle(this.modalEl.nativeElement, 'display', '');
		if (this.windowResizeEv) {
			this.windowResizeEv();
		}
	}

	public onAction(actionFn: (cmp: any) => boolean, validate: boolean) {

		let cmp: any;
		if (this.componentReference) {
			cmp = this.componentReference.instance;
		}

		if (validate && cmp && cmp.isValid && !cmp.isValid()) {
			return;
		}

		let result = actionFn(cmp);

		if (result === null || result === undefined || result) {
			this.closeModal();
		}
	}
}
