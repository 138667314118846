import { AuthenticationService } from '../../_guards/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServicesData } from 'src/app/services/services.data';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardLocatorDataService } from './dashboard-locator.data';
import { ChartOptions } from 'chart.js';
import { DateRangeClass } from 'src/app/components/dateInput-range/date-input-range.class';
import { DateInputRange } from 'src/app/components/dateInput-range/date-input-range.cmp';


@Component({
  templateUrl: './dashboard-locator.tmpl.html',
  styleUrls: ['./../dashboard.scss']
})
export class DashboardLocatorComponent implements OnInit {
  userName: string;
  model: any;
  centeralForm: FormGroup;
  public centeralDataObj: any = {};

  constructor(
    public servicesData: ServicesData,
    private authService: AuthenticationService,
    private dashboardLocatorDataService: DashboardLocatorDataService,
    private formBuilder: FormBuilder
  ) {
  }
  @ViewChild('releventDateRangeCom') releventDateRangeCom: DateInputRange;

  subjectCommitmentReasons: [];

  ngOnInit() {
    if (this.authService.isAuthorized("SystemAdministrator,dashboardLocator")) {
      this.dashboardLocatorDataService.get().subscribe(db => {
        this.fillValues(db);
      });
      this.initCenteralForm();
      this.loadCenteralData();
    }
  }

  loadCenteralData() {
    this.dashboardLocatorDataService.centeralData(this.centeralForm.value).subscribe((x: any) => {
      this.centeralDataObj = x;
      this.pieChartLabels = x.donationDetailsGroup.map((y: any) => y.item1);
      this.pieChartDatasets = [{
        data: x.donationDetailsGroup.map((y: any) => y.item2)
      }];
      if (x.donationsLineChart) {
        this.lineChartLabels = x.donationsLineChart.map((y: any) => y.item2);
        this.lineChartDatasets = [{
          data: x.donationsLineChart.map((y: any) => y.item3),

          fill: false,
          backgroundColor: '#2b75d2',
         // borderColor: 'blue',
          tension: 0.1
        }];
      }

    });
  }

  designationDetailsList: any[];
  loadDesignationDetails(e) {
    if (e) {
      this.servicesData.getList(`DesignationDetails//${e}`).subscribe((x: any[]) => {
        this.designationDetailsList = x;
      });
    } else {
      this.designationDetailsList = [];

    }
  }
  initCenteralForm() {
    let fd = new Date();
    fd.setDate(fd.getDate() - 7);
    let td = new Date();  //  today

    let dr = new DateRangeClass();
    dr.start = fd;
    dr.end = td;



    this.centeralForm = this.formBuilder.group({
      contactID: [],
      contactName: [],
      relevantDateRange: [new DateRangeClass(fd, td)],
      fromFromDateRange: [], //לא פעיל כרגע
      statusPayment: [],
      commitmentSubject: [],
      commitmentReasonID: [],
      accountLocation: [],
      designation: [],
      designationDetails: [],
      donationCategoryID: [],
      donationCategoryTitle: [],
    });



    this.centeralForm.controls['commitmentSubject'].valueChanges.subscribe(x => {
      this.servicesData.getList(`CommitmentReasons/${x}`)
        .subscribe(
          (r: any) => {
            this.subjectCommitmentReasons = r;
          },
          error => { });
    });

  }
  setPriod(days: number) {
    let fd = new Date();
    fd.setDate(fd.getDate() - days);
    let td = new Date();  //  today

    let dr = new DateRangeClass();
    dr.start = fd;
    dr.end = td;

    this.releventDateRangeCom.startInput.dateControl.setValue(fd);
    this.releventDateRangeCom.endInput.dateControl.setValue(td);
    this.releventDateRangeCom.startInput.dt = fd;
    this.releventDateRangeCom.endInput.dt = td;
    this.releventDateRangeCom.startInput.ngAfterViewInit();
    this.releventDateRangeCom.endInput.ngAfterViewInit();

    this.centeralForm.controls['relevantDateRange'].setValue(dr);
    this.loadCenteralData();

  }

  // Line
  lineChartOptions = {
    responsive: true,
    color: 'blue',
  };
  lineChartLabels: any[];
  lineChartDatasets: any[];
  lineChartLegend = true;
  lineChartPlugins = [];

  // Pie
  pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  pieChartLabels: any[];
  pieChartDatasets: any[];
  pieChartLegend = true;
  pieChartPlugins = [];
  // events
  chartClicked(e: any): void {
    console.log(e);
  }
  chartHovered(e: any): void {
    console.log(e);
  }
  public clerFilter() {
    this.centeralForm.reset();
    this.initCenteralForm();
    this.loadCenteralData();
  }
  fillValues(db: any) {
    this.model = db;
  }
}
