
<main-form-modal (toggleChange)="setVal($event)">
  <ng-template #customHtmlElements>
      <div [ngSwitch]="editMode">

          <assistance-edit *ngSwitchCase="true"></assistance-edit>
          <assistance-details *ngSwitchCase="false"></assistance-details>
      </div>
  </ng-template>
</main-form-modal>
