import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateMonthInput } from '../../components/dateMonth-input/dateMonth-input.cmp';
import { ServicesData } from '../../services/services.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { PaymentsPlansData } from '../paymentsPlans.data';
import { HoldingsSetting } from './paymentsPlan';


@Component({
    templateUrl: './paymentsPlan-payments-changes-dlg.tmpl.html'
})
export class PaymentsPlanPaymentsChangesDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PaymentsPlanPaymentsChangesDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private paymentsPlansData: PaymentsPlansData

    ) { }

    public formData: FormGroup;
    holdingsSetting: HoldingsSetting;


    ngOnInit(): void {
        this.initForm();
    }


    private initForm(): void {
        this.formData = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
             paymentsPlanID: [this.data.paymentsPlanID, Validators.required],
            reOldHoldings: [false, Validators.required],
        });
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}

