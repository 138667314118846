import { SelectionModel } from '@angular/cdk/collections';
import { C } from '@angular/cdk/keycodes';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Inject, Injectable, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class IndexService {
  constructor(
    @Inject('formGroup') private searchForm: FormGroup,
    @Inject('params') private params: any[],
    @Inject('dataService') public dataService: any,
    @Inject('router') private router: Router,
    @Inject('defaultSearchValues') private defaultSearchValues: any = null,
    @Inject('specificTab') private specificTab: string = "",
    @Inject('groupingColumn') private groupingColumn: string = "",
    @Inject('groupingOrderColumn') private groupingOrderColumn: string = "",
  ) {
    if (defaultSearchValues) {
      this.defaultFormValues = defaultSearchValues;
    }
    if (specificTab) {
      this.specificTab = specificTab;
    }

  }
  @ViewChild('table') public table: MatTable<any> = null;

  @Output() public OnLoaddedEnd = new EventEmitter<boolean>();

  // @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);
  reducedGroups = [];
  pageEvent: PageEvent;
  pageSizeOptions;
  data: any[] = [];
  isRateLimitReached = false;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  defaultFormValues = {
    page: 1,
    pageRows: this.pageRows,
  }


  public clerFilter() {
    this.searchForm.removeControl('orders');
    this.searchForm.addControl('orders', new FormBuilder().array([]));
    this.searchForm.reset(this.defaultFormValues, { onlySelf: true });
    this.getList();
  }

  changed(e) {
  }

  /**
   * Rebuilds the datasource after any change to the criterions
   */
  buildDataSource() {
    if (this.sourceList) {
      this.sourceList = this.groupBy(this.groupingColumn, this.sourceList, this.reducedGroups);
    }
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.contactID === row.contactID);
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  /**
   * Groups the @param data by distinct values of a @param column
   * This adds group lines to the dataSource
   * @param reducedGroups is used localy to keep track of the colapsed groups
   */
  groupBy(column: string, data: any[], reducedGroups?: any[]) {
    if (!column) return data;
    let collapsedGroups = reducedGroups;
    if (!reducedGroups) collapsedGroups = [];
    const customReducer = (accumulator, currentValue) => {
      let currentGroup = currentValue[column];
      if (!accumulator[currentGroup])
        accumulator[currentGroup] = [{
          groupName: `${column} ${currentValue[column]}`,
          value: currentValue[column],
          isGroup: true,
          reduced: collapsedGroups.some((group) => group.value == currentValue[column])
        }];

      accumulator[currentGroup].sort(x => x).push(currentValue);

      return accumulator;
    }
    let groups = data.reduce(customReducer, {});
    let groupArray = Object.keys(groups).map(key => groups[key]);
    let flatList = groupArray.reduce((a, c) => { return a.concat(c); }, []);

    return flatList.filter((rawLine) => {
      return rawLine.isGroup ||
        collapsedGroups.every((group) => rawLine[column] != group.value);
    });
  }

  /**
   * Since groups are on the same level as the data,
   * this function is used by @input(matRowDefWhen)
   */
  isGroup(index, item): boolean {
    return item.isGroup;
  }

  /**
   * Used in the view to collapse a group
   * Effectively removing it from the displayed datasource
   */
  reduceGroup(row: any) {
    row.reduced = !row.reduced;
    if (row.reduced)
      this.reducedGroups.sort(x => x).push(row);
    else
      this.reducedGroups = this.reducedGroups.filter((el) => el.value != row.value);

    this.buildDataSource();
  }

  public getList() {

    this.params.forEach(x => {
      if (x && +x.value > 0) {
        let ctrl = this.searchForm.controls[x.key];
        if (ctrl) {
          ctrl.setValue(x.value);
        }
      }
    });

    sessionStorage.setItem(`${this.router.url}${(this.specificTab ? `#${this.specificTab}` : ``)}`, JSON.stringify(this.searchForm.value));

    return this.dataService!.getList(this.searchForm.value)
      .subscribe((data: any) => {

        this.data = data;
        this.sourceList = data.list;

        if (this.searchForm.controls['orders'].value.at(this.groupingOrderColumn) && this.sourceList) {
          this.buildDataSource();
        }

        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isRateLimitReached = false;

        this.selection.isSelected = this.isChecked.bind(this);

        this.OnLoaddedEnd.emit(true);
      });
  }


  public currentIndex(e): void {
    // console.log(e);
  }

  public changePage(page: number) {
    this.searchForm.controls['page'].setValue(page);
    this.getList();
  }

  public selectionChanged(e) {
    this.getList();
  }

  pageChange(e) {
    //console.log(e);
    this.searchForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.searchForm.controls['page'].setValue(e.pageIndex + 1);
    this.selectionChanged(e);
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getList();
  }

  search() {
    this.getList();
  }

  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }

  removeOrder(propertyName: string) {
    const ordersControl = <FormArray>this.searchForm.controls['orders'];
    let i = null;
    ordersControl.controls.forEach((ctrl, ix) => {
      if ((<any>ctrl).value.key == propertyName) {
        i = ix;
      }
    });
    if (i >= 0) {
      ordersControl.removeAt(i);
    }
  }


  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return new FormBuilder().group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }

}
