import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "mat-select-multiple-dropdown",
    templateUrl: "./mat-select-multiple-dropdown.component.html",
    styleUrls: ["./mat-select-multiple-dropdown.component.css"]
})
export class MatSelectMultipleDropdownComponent implements AfterViewInit {

    showDropDown = false;
    @Input() list: any[];

    @Output() shareCheckedList = new EventEmitter();
    @Output() shareIndividualCheckedList = new EventEmitter();

    @Input("listControl") listControl: FormControl;
    @Input("placeholder") placeholder?: string = "בחר...";

    @Input("keyProperty") keyProperty: string = "key";
    @Input("titleProperty") titleProperty: string = "title";
    @Input("subTitleProperty") subTitleProperty: string = "subTitle";
    @Input("colorProperty") colorProperty: string = "color";

    @Input('withLabel') public withLabel: boolean = true;


      @Output() public selectChangeEmt = new EventEmitter<any>();
    checkedValueList: KeyValuePair[];
    checkedNameList: any[];
    currentSelected: {};

    constructor() {
        this.checkedValueList = [];
        this.checkedNameList = [];
    }

    titleOfitem(item) {
        return item[this.titleProperty];
    }

    // isColorOfitem(item) {
    //     return item[this.colorProperty] != undefined;
    // }

    colorOfitem(item) {
        return item[this.colorProperty];
    }

   public  selectionChanged(e){
        console.log(e);
        this.selectChangeEmt.emit(e);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.listControl.value && this.list) {
                this.checkedValueList = this.list.filter(x => this.listControl.value.indexOf(x[this.keyProperty]) > -1);
                this.checkedNameList = this.checkedValueList.map(x => x[this.titleProperty]);
            }
        }, 150);
    }

    getSelectedValue(item) {
        var index = this.checkedValueList.indexOf(item);
        if (index > -1) {
            this.checkedValueList.splice(index, 1);
        } else {
            this.checkedValueList.push(item);
        }

        this.currentSelected = { key: item.key, title: item.title, subTitle: item.subTitle, color: item.color };

        //share checked list
        this.shareCheckedlist();

        //share individual selected item
        this.shareIndividualStatus();
        this.listControl.setValue(this.checkedValueList.map(x => x[this.keyProperty]));
        this.checkedNameList = this.checkedValueList.map(x => x[this.titleProperty]);
    }

    shareCheckedlist() {
        this.shareCheckedList.emit(this.checkedValueList);
    }
    shareIndividualStatus() {
        this.shareIndividualCheckedList.emit(this.currentSelected);
    }
}


export class KeyValuePair {
    key: number;
    title: string;
    subTitle: string;
    color: string;
}
