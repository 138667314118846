import { TishPlace } from './../../tishPlaces/tishPlace/tishPlace'; 

export class TishTable {
    tishTableID: number;
    tishPlaces: TishPlace[];
    chainingLocation: string;
    chainingRoundAndSize: string;
    placeNumber: number;
    tishEventID;
    tishEventTitle;
    tishTableName;
    tishTableLeft;
    tishTableTop;
    transformRotate;
    narrowTable;
    countPlaces;
}

