<div *ngIf="commitmentReason">
    <div class="col-sm-6">
        <panel-box title="פרטי סיבת התחייבות: {{ id }}" [showClose]="false" [showCollapse]="false">
            <span class="panel-tools">
                <a [routerLink]="['/commitmentReasons', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
            </span>
            <div class="panel-content">
                <dl class="dl-horizontal">
                    <dt>סיבה</dt>
                    <dd> {{ commitmentReason.reason  }}</dd>
                    <hr>
                    <dt>משוייך לנושא</dt>
                    <dd> {{ commitmentReason.commitmentSubjectName  }}</dd>
                    <hr>
                </dl>
            </div>
        </panel-box>
    </div>
</div>