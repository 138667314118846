import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { IvrDonationDetailsData } from './../ivrDonationDetails.data';
import { IvrDonationDetail } from './ivrDonationDetail';

@Component({

  selector: 'ivrDonationDetail-details',
  templateUrl: './ivrDonationDetail-details.tmpl.html'
})

export class IvrDonationDetailDetails implements OnInit {
  constructor(
    private ivrDonationDetailsData: IvrDonationDetailsData,
    private toraxHttp: ToraxHttp,
    private activatedRoute: ActivatedRoute
  ) {

    this.toraxHttp.updatedEvent.subscribe((id) => {
      if (id > 0 && this.ivrDonationDetail && id === this.ivrDonationDetail.ivrDonationDetailID) {
        this.loadIvrDonationDetail();
      }
    });
  }

  public ivrDonationDetail: IvrDonationDetail;
  private id: number;

  public loadIvrDonationDetail(): void {
    this.ivrDonationDetailsData.get(this.id)
      .subscribe((c: any) => {
        this.ivrDonationDetail = c;
        setTimeout(() => {
          this.getAudioByts();
          this.getTargetAudioByts();
        }, 150);
      });
  }




  getTargetAudioByts(): void {
    if (this.id > 0) {
      this.ivrDonationDetailsData.getTargetAudioByts(this.id)
        .subscribe(blob => {
          let elmnt = (<any>document.getElementById("targetAudioBytes"));
          if (elmnt) {
            elmnt.src = window.URL.createObjectURL(blob);
          }
        }),
        error => { };
    }
  }



  getAudioByts(): void {
    if (this.id > 0) {
      this.ivrDonationDetailsData.getAudioByts(this.id)
        .subscribe(blob => {
          let elmnt = (<any>document.getElementById("recordFileBytes"));
          if (elmnt) {
            elmnt.src = window.URL.createObjectURL(blob);
          }
        }),
        error => { };
    }
  }
  exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];
  isLink(pmId) {
    return this.exludePayMethodsIDs.indexOf(pmId) < 0;
  }

  ngOnInit(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = + params['ivrDonationDetailID'];
    });
    this.loadIvrDonationDetail();
  }
}
