import { ToraxHttp } from './../utils/custom-http.svc';
import { ObjectsListResult } from './../services/objects-list-results';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";
import { Honor } from './honor/honor';

@Injectable()

export class HonorsData {
  private url = 'api/honors';


  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  createAndPrintHonorsLetters(honorIDs) {
    return this.toraxHttp.get(`${this.url}/createAndPrintHonorsLetters/${honorIDs}`);
  }

  createAndPrintHonorLetter(honorID: number) {
    return this.toraxHttp.get(`${this.url}/createAndPrintHonorLetter?honorID=${honorID}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(honor: Honor) {
    return this.toraxHttp.update(this.url, honor);
  }

  getAdd(contactId: number) {
    return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
