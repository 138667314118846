<form [formGroup]="tishInvitedForm" novalidate (ngSubmit)="save()" *ngIf="tishInvited">
    <div class="modal-header">
        <h1>עריכת פרטי מוזמן [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי מוזמן</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3" style="white-space: nowrap;">
                            <label> אירוע:</label>
                            <h3><b> <u>{{ tishInvited.tishEventTitle }}</u></b></h3>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                      <auto-complete apply-enter [controllerName]="'Contacts'" [formGroupModel]="tishInvitedForm"
                          [placeholder]="'שם מוזמן'" [idControl]="tishInvitedForm.controls.contactID"
                          [nameControl]="tishInvitedForm.controls.tishInvitedName"></auto-complete>



                     <div *ngIf="tishInvited" style="font-size:70%">
                      <br>
                      <br>
                      <mat-icon style="font-size:70%" (click)="openConactDetails(tishInvited.contactID);">remove_red_eye</mat-icon>
   איחוד: {{tishInvited.contact.codeIchud}}
                     </div>

                </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">

                            <label>שם לכרטיס:</label>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                                <input class="form-control narrow" placeholder="תיאור ותואר" style="text-align: right;"
                                    apply-enter formControlName="specialInvitedPerfix" />
                                <input class="form-control" placeholder="שם פרטי" style="text-align: right;" apply-enter
                                    formControlName="specialInvitedFirstName" />
                                <input class="form-control" placeholder="שם משפחה" style="text-align: right;"
                                    apply-enter formControlName="specialInvitedLastName" />
                                <input class="form-control narrow" placeholder="סיומת" style="text-align: right;"
                                    apply-enter formControlName="specialInvitedSuffix" />
                            </div>
                            <h3 fxLayout="row" class="label">יש להכניס נתונים מסודרים בשדות המתאימים</h3>
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <auto-complete apply-enter [controllerName]="'TishGroups/' + tishEventID"
                            [placeholder]="'קבוצה'" [formGroupModel]="tishInvitedForm"
                            [idControl]="tishInvitedForm.controls.tishGroupID"
                            [nameControl]="tishInvitedForm.controls.tishGroupName"></auto-complete>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">

                            <label for="quantityAdditionalTickets">מס' כרטיסי כניסה
                                נוספים:</label>
                            <input type="text" class="form-control" formControlName="quantityAdditionalTickets" />
                            <h3 class="label">לצורך הדפסת כרטיסי כניסה נוספים למוזמן נוכחי - לכניסה בלבד</h3>

                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label for="comment">הערה:</label>
                            <input type="text" class="form-control" formControlName="comment" />
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="tishInvitedsData" [displayDeleteButton]="false" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="tishInvitedForm">
        <ng-template #customHtmlButtons>
          <button type="button" [disabled]="id <= 0" class="btn btn-danger navbar-btn" (click)="delete()">
            <i class="fa fa-ban"></i> מחק
        </button>

        </ng-template>
    </footer-edit-buttons>
</form>
