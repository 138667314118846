<form  class="example-form" [formGroup]="formData" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>פרטי סגירת התחייבות</small>
            <!-- <br>
            {{ closedDetails.contactName }} -->
        </h2>
    </div>
    <mat-dialog-content  style="padding:0px 24px 24px 24px">
        <div fxLayout="column" style="display: block;">
            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <date-input  [placeholder]="'תאריך סגירה'" [dateControl]="formData.controls.closedDate">Loading...
                </date-input>
            </div>
            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                    <label>סטטוס:</label>
                    <select class="form-control" formControlName="closedStatus">
                        <option value="">...</option>
                        <option *ngFor="let cs of servicesData.ClosedStatuses" [value]="cs.value">
                            {{cs.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <textarea rows="4" class="comment" placeholder="הערת סגירת התחייבות..."
                    formControlName="closedComment"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="formData"><i class="fa fa-save"></i> שמור </button>
    </mat-dialog-actions>

</form>