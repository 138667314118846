<panel-box title="משימות" [showClose]="false" [showCollapse]="false">
    <span class="panel-tools">
		<panel-box-btn (btnClick)="getTasks()" icon="fa-refresh"></panel-box-btn>
		
	</span>
    <div *ngIf="objectsListResult" class="panel-content">
        <table class="table table-striped no-margins">
            <thead>
                <tr>
                    <th>...</th>
                    <th [hidden]="contactID > 0">שם איש</th>
                    <th>כותרת</th>
                    <th>תאריך הדפסה</th>
                </tr>
            </thead>
            <tbody >
                <tr  *ngFor="let t of objectsListResult.list">
                    <!--<td> <a [routerLink]="['tasks', t.taskID]"><i class="fa fa-folder-open-o"></i></a></td>-->
                    <td>
                        <a (click)="printReceiptHtml(t.taskID)"><i class="fa fa-print"></i></a>
                    </td>
                    <td [hidden]="contactID > 0">{{ t.contactName }}</td>
                    <td>{{ t.title }}</td>
                    <td>{{ t.doneOn }}</td>
                </tr>
            </tbody>
        </table>
         <pagination [rowsCount]="objectsListResult.rowsCount" [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount"
        (change)="changePage($event)"></pagination>

    </div>
</panel-box>