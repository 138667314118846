import { Location } from '@angular/common';
import { ControlValueAccessor } from '@angular/forms';
import { Input, TemplateRef, ContentChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { PreviousRouteService } from 'src/app/_helpers/previous-route.service';

@Component({
    selector: 'main-form-modal',
    templateUrl: 'main-form-modal.tmpl.html',
    styleUrls: ['./main-form-modal.scss']
})



export class MainFormModal implements ControlValueAccessor {

    constructor(
        private activatedRoute: ActivatedRoute,
         private router: Router,
        private previousRouteService: PreviousRouteService
    ) {
        this.activatedRoute.data.subscribe(data => {
            setTimeout(() => {
                if (data.mode == 'edit') {
                    this.value = true;
                } else {
                    this.value = false;
                }
                this.toggleChange.emit(this.value);
            }, 300);
        });

    }
   public set value(val) {
        this._value = val;
        this.onChange(val);
        // this.onTouched();
    }

    @Output('toggleChange') public toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ContentChild('customHtmlElements') customHtmlElements: TemplateRef<any>;

    @Input() label = 'עבור בין תצוגות';
    @Input('value') _value: boolean = null;

ngAfterViewInit(){
    var child = document.querySelector("main-container");
//    console.log("child", child);


}

    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this._value;
    }

    exitModal() {
        this.previousRouteService.goToPrev();
    }

    //   editModal() {
    //     let path = this.location.path();

    //     if (path.indexOf('popup') > -1 && path.endsWith(')')) {
    //       if (!path.endsWith('/edit)')) {

    //         let ptrn = /\((.*)\)/i;
    //         let res = path.match(ptrn)[1];
    //         let ur = path.replace(res, res + '/edit');
    //         this.location.go(ur);
    //       } else if (path.endsWith('/edit)')) {

    //         let ur = path.replace('/edit', '');
    //         this.location.go(ur);

    //       }
    //     }
    //   }



    registerOnChange(fn) {
        this.onChange = fn;
    }

    writeValue(value) {
        console.log(value);
        if (value) {
            this.value = value;
        }
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    switch() {
        this.value = !this.value;
        this.toggleChange.emit(this.value);
    }
}

