import { PayMethod } from '../../payMethods/payMethod/payMethod';
export class Payment {
    contactName: string;
    defaultReceiptAddress: string;
    paymentID: number;
    contactID: number;
    commitmentID: number;
    payMethodID: number;
    active: boolean;
    doneOn: Date;
    amount: number;
    billingDate: Date;
    collectionOn: Date;
    statusPayment: number;
    currency: number;
    deleted: boolean;
    paymentOption: number;
    payMethodTitle: string;
    holdings: Holding[];
    commitmentSubject: number;
    payMethod: PayMethod;
    currencyIcon: string;
    contactTitle: string;
    termID: number;
    termName: string;
    vouchersOrgPaymentID: number;
    paymentCommitments: PaymentCommitment[];
    voucherPaymentIDs: number[];
    lastName: string;
    isOpenOtherHoldingCommitmentsForContact: boolean = false;
    paymentGroupID?: number;
    paymentOptionName: string;
}
export class PaymentCommitment {
    paymentCommitmentID;
    paymentID;
    commitmentID;
    commitmentTitle;
    partialAmount;
    designation;
    designationDetails;
    contactID;
    registrationID;
    honorID;
    commitmentReasonID;
    commitmentReasonName;
    commitmentSubject;
    commitmentSubjectName;
    hebYear;
    operation;
}

export class Holding {
    holdingID: number;
    paymentID: number;
    contactID: number;
    contactName: string;
    HoldingMonthName: string;;
    HoldingYearName: string;
    Deleted: boolean;
}

export class CancellationReceiptModel {
    paymentID: number;
    CancellationReason: string;
}
