import { Component} from '@angular/core';

@Component({
    selector: 'honor-main',
    templateUrl: './honor-main.tmpl.html'
})

export class HonorMain {
    editMode: boolean = null;

    public setVal(val:boolean) {
        this.editMode = val;
    }
}
