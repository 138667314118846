<div class="modal-header" *ngIf="commitmentEdit &&  commitmentEdit.commitment">
    <h1>
        {{ commitmentEdit.commitment.contactName }}
        <br>
        <small>עריכת פרטי התחייבות [{{ (commitmentEdit.id > 0 ? commitmentEdit.id : 'חדש') }}] </small>
    </h1>
    <div style="background-color: #337ab7; float: left;  position: absolute; left: 15px;  top: 50px;">
        <span style="background-color: inherit;" class="label label-primary"> {{ commitmentEdit.donationTypeName
            }}</span>
    </div>
</div>
<div class="modal-body"   style="resize: both; overflow: auto;">
    <commitment-edit [contactId]="contactId" [id]="id" #commitmentEdit></commitment-edit>
</div>
<footer-edit-buttons *ngIf="commitmentEdit && commitmentEdit.commitment && commitmentEdit.commitmentForm"
    [onSaveGoToBack]="true" [navigateAfterSaving]="'commitments'" [dataProvider]="commitmentEdit.commitmentsData"
    [router]="commitmentEdit.router" [id]="commitmentEdit.id" [disabledDeleteButton]="false"
    [modelFormGroup]="commitmentEdit.commitmentForm">
</footer-edit-buttons>