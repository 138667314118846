<div *ngIf="model">
  <div class="row">
    <div *authGuardDirective="'contacts'" class="col-lg-2">
      <a [routerLink]="['/contacts']">
        <div class="widget style1 navy-bg">
          <div class="row vertical-align">
            <div class="col-3">
              <i class="fa fa-user fa-3x"></i>
            </div>
            <div class="col-9 text-right">
              <h2 class="font-bold" style="font-size: 28px">כרטסת אנשי קשר</h2>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div *authGuardDirective="'ivrDonationDetails'" class="col-lg-2">
      <a [routerLink]="['/ivrDonationDetails']">
        <div class="widget style1 lazur-bg">
          <div class="row vertical-align">
            <div class="col-3">
              <i class="fa fa-phone fa-3x"></i>
            </div>
            <div class="col-9 text-right">
              <h2 class="font-bold" style="font-size: 28px">תרומות טלפוניות</h2>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div *authGuardDirective="'ivrDonationDetails'" class="col-lg-2">
      <a [routerLink]="['/donationCategories']">
        <div class="widget style1 lazur-bg primary">
          <div class="row vertical-align">
            <div class="col-3">
              <i class="fa fa-circle fa-3x"></i>
            </div>
            <div class="col-9 text-right">
              <h2 class="font-bold" style="font-size: 28px">הגדרות ושלוחות</h2>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-2">
      <a>
        <div class="widget">
          <div class="row vertical-align">

          </div>
        </div>
      </a>
    </div>


    <div class="col-lg-2">
      <div class="widget" style=" border: solid; padding: 0px; border-color: gray;">
        <div class="ibox-title" style="background: #a7b1c21c;">
          <div class="ibox-tools">
            <span class="label float-right"
              style="border: solid; border-width: 1px; background: WHITE; color: BLACK; font-size: small;">{{model.yomiJewishDate}}</span>
          </div>
          <h5>תרומות יומי</h5>
        </div>
        <h1 class="no-margins" style="padding: 10px 7px 12px;background: WHITE"
          *ngFor="let item of model.sumTrumotYomi">
          {{item.value|number}}<small style="color: black;">{{item.key}}</small></h1>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="widget" style=" border: solid; padding: 0px; border-color: gray;">
        <div class="ibox-title" style="background: #a7b1c21c;">
          <div class="ibox-tools">
            <span class="label float-right"
              style="border: solid; border-width: 1px; background: WHITE; color: BLACK; font-size: small;">{{model.yomiJewishDate}}</span>
          </div>
          <h5>כניסות יומי</h5>
        </div>
        <h1 class="no-margins" style="padding: 10px 7px 12px;background: WHITE">{{model.countCnisotYomi}} <small
            style="color: black;">
            שיחות </small></h1>
      </div>
    </div>
  </div>
  <div *ngIf="model" class="row" style="margin-top: 40px;">
    <!-- <fieldset style="border: 3px solid #ddd !important;
      border-radius: 4px;
      padding: 40px 10px 0; ">
      <legend style="    border-radius: 5px; background: #a7b1c21c; border-bottom: solid; ">מעקב פעילות</legend>
      <div class="row">
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title" style="background: #a7b1c21c;">
              <div class="ibox-tools">
                <span class="label float-right"
                  style="border: solid; border-width: 1px; background: WHITE; color: BLACK; font-size: small;">{{model.yomiJewishDate}}</span>
              </div>
              <h5>תרומות יומי</h5>
            </div>
            <div class="ibox-content">
              <h1 class="no-margins" style="padding: 30px 7px 12px;" *ngFor="let item of model.sumTrumotYomi">
                {{item.value|number}}<small style="color: black;">{{item.key}}</small></h1>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title" style="background: #a7b1c21c;">
              <div class="ibox-tools">
                <span class="label float-right"
                  style="border: solid; border-width: 1px; background: WHITE; color: BLACK; font-size: small;">{{model.yomiJewishDate}}</span>
              </div>
              <h5>כניסות יומי</h5>
            </div>
            <div class="ibox-content">
              <h1 class="no-margins" style="padding: 30px 7px 12px;">{{model.countCnisotYomi}} <small
                  style="color: black;"> שיחות </small></h1>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title" style="background: #a7b1c21c;">
              <h5>ממתינים לקבלות</h5>
            </div>
            <div class="ibox-content">
              <h1 class="no-margins" style="padding: 30px 7px 12px;">{{model.countMamtinimLetipul}} <small
                  style="color: black;">תשלומים</small></h1>
            </div>
          </div>
        </div>

      </div>
    </fieldset>
    <fieldset *ngIf="centeralDataObj?.mainReport" style="    border: 3px solid #ddd !important;
      border-radius: 4px;
      padding: 40px 10px 0; ">
      <legend style="    border-radius: 5px; background: #a7b1c21c; border-bottom: solid; ">סיכום לתקופה</legend>
      <div class="row">
        <div class="col-lg-2">
          7 ימים אחרונים
        </div>
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title" style="background: #a7b1c21c;">
              <div class="ibox-tools">
                <span class="label float-right"
                  style="border: solid; border-width: 1px; background: WHITE; color: BLACK; font-size: small;">{{centeralDataObj.mainReport.countCommitments
                  || 0}} רשומות</span>
              </div>
              <h5>התחייבויות</h5>
            </div>
            <div class="ibox-content">
              <h1 class="no-margins" style="padding: 10px 7px 12px; direction: ltr">
                {{centeralDataObj.mainReport.sumGlobalCommitmentAmount|number}}<small style="color: black;">₪</small></h1>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title" style="background: #a7b1c21c;">
              <div class="ibox-tools">
                <span class="label float-right"
                  style="border: solid; border-width: 1px; background: WHITE; color: BLACK; font-size: small;">{{centeralDataObj.mainReport.countPaymentsPlans
                  || 0}}</span>
              </div>
              <h5>תוכניות תשלום (הו"ק)</h5>
            </div>
            <div class="ibox-content">
              <h1 class="no-margins" style="padding: 30px 7px 12px; direction: ltr;">
                {{centeralDataObj.mainReport.pulseAmount|number}} <small style="color: black;"> ₪ </small></h1>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title" style="background: #a7b1c21c;">
              <h5>ממתינים לקבלות</h5>
            </div>
            <div class="ibox-content">
              <h1 class="no-margins" style="padding: 30px 7px 12px;">{{model.countMamtinimLetipul}} <small
                  style="color: black;">תשלומים</small></h1>
            </div>
          </div>
        </div>
      </div>
    </fieldset> -->
  </div>
  <hr style="margin-top: 50px;">
  <h2 style="text-align: center;">מעקב פעילות</h2>
  <div class="row" [formGroup]="centeralForm">
    <!-- <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title" style="padding: 0;">
          <div class="col-lg-12"> -->
            <div class="row col-lg-4">

              <!-- <mat-select class="form-control" placeholder="נושא..." formControlName="commitmentSubject">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let c of servicesData.CommitmentSubjects" [value]="c.value">
                {{c.name}}
              </mat-option>
            </mat-select>

            &nbsp;&nbsp;
            <mat-select *ngIf="subjectCommitmentReasons" class="form-control" placeholder="סיבה..."
              formControlName="commitmentReasonID">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let c of subjectCommitmentReasons" [value]="c.commitmentReasonID">
                {{c.name}}
              </mat-option>
            </mat-select>
            &nbsp;&nbsp;

            <mat-select class="form-control" placeholder="סטטוס..." formControlName="statusPayment">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let c of  servicesData.StatusPayments" [value]="c.value">
                {{c.name}}
              </mat-option>
            </mat-select>
            &nbsp;&nbsp;
            <auto-complete-input apply-enter [withLabel]="false" [placeholder]="'ייעוד תרומה...'"
              (onSelect)="loadDesignationDetails(₪event)" [controllerName]="'Designations'"
              [stringValueControl]="centeralForm.controls.designation" [formGroupModel]="centeralForm">
            </auto-complete-input>

            &nbsp;&nbsp;
            <mat-select class="form-control" placeholder="תיאור ייעוד" formControlName="designationDetails">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let c of designationDetailsList" [value]="c">
                {{c}}
              </mat-option>
            </mat-select> -->
            </div>
            <!-- <div class="row col-lg-8">
              <button type="button" class="btn btn-xs btn-white" (click)="setPriod(30)">30 יום</button>
              <button type="button" class="btn btn-xs btn-white" (click)="setPriod(7)">7 ימים</button>
              &nbsp;&nbsp;
              <date-input-range #releventDateRangeCom [placeHolderStart]="'מתאריך'" [withLabel]="false"
                [placeHolderEnd]="'עד לתאריך'" [dateRangeCtrl]="centeralForm.controls.relevantDateRange">Loading...
              </date-input-range>
              &nbsp;&nbsp;


              <mat-select class="form-control" placeholder="קטגוריה..." formControlName="donationCategoryID">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let c of  servicesData.DonationCategories" [value]="c.id">
                  {{c.title}}
                </mat-option>
              </mat-select>
              &nbsp;&nbsp;
              <span class="example-fill-remaining-space"></span>
              <div class="btn-group" style="position: absolute; left:10px; top:-30px;z-index: 999;">
                <button style="margin: 3px; width: 30px; height: 30px; background-color: #65cc6bb8;" mat-mini-fab
                  color="primary" title="סנן" (click)="loadCenteralData()"><i class="fa fa-filter"></i></button>
                <button style="margin: 3px; width: 30px; height: 30px; background-color: #65cc6bb8;" mat-mini-fab
                  color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                    class="material-icons">clear</i></button>
              </div>
            </div> -->
          <!-- </div>

        </div>
      </div>
    </div> -->
    <div class="row" *ngIf="centeralDataObj">
      <div class="col-lg-12">
        <div class="row col-lg-4" style="    background-color: #f1f2f499;">
          <div class="col-lg-12" >

            <h2 style="text-align: center; margin-bottom: 10px; font-weight: bold; text-decoration: underline;">  תשלומים והוראות קבע</h2>
            <div class="col-lg-4" *ngIf="centeralDataObj?.mainReport">
              <div class="ibox">
                <div class="ibox-title">
                  <div class="ibox-tools">
                  </div>
                  <h5>ס"ה התחייבויות</h5>
                </div>
                <div class="ibox-content">
                  <h1 class="no-margins">0</h1>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="centeralDataObj?.mainReport">
              <div class="ibox ">
                <div class="ibox-title">
                  <div class="ibox-tools">
                  </div>
                  <h5>תוכניות פעילות</h5>
                </div>
                <div class="ibox-content">
                  <h1 class="no-margins">0</h1>
                </div>
              </div>
            </div>

            <div class="col-lg-4" *ngIf="centeralDataObj?.mainReport">
              <div class="ibox ">
                <div class="ibox-title">
                  <div class="ibox-tools">
                  </div>
                  <h5>תשלומים</h5>
                </div>
                <div class="ibox-content">
                  <ul class="stat-list">
                    <li>
                      <br>
                      <small>סכום שנגבה</small>
                      <h2 class="no-margins">{{centeralDataObj.mainReport.sumSucceededAmount|number}}</h2>
                      <div class="stat-percent">{{centeralDataObj.mainReport.countSucceededPayments || 0}} רשומות</div>
                      <hr style="background-color: green; height: 3px;" />
                    </li>
                    <li>
                      <br>
                      <small>סכום לגביה / (טיפול)</small>
                      <h2 class="no-margins ">{{centeralDataObj.mainReport.sumWaitingAmount|number}}</h2>
                      <div class="stat-percent">{{centeralDataObj.mainReport.countWaitingPayments || 0}} רשומות</div>
                      <hr style="background-color: chocolate; height: 3px;" />
                    </li>

                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-lg-8" style="border-right: solid; background-color: #f1f2f4; border-width: 1px;">
          <h2
              style="text-align: center; margin-bottom: 10px; font-weight: bold; text-decoration: underline; padding: 0">
              מערכת טלפונית</h2>
          <div class="ibox-title row col-lg-12" style="padding: 0; display: inline-flex;">
            <button type="button" class="btn btn-xs btn-white" (click)="setPriod(30)">30 יום</button>
            <button type="button" class="btn btn-xs btn-white" (click)="setPriod(7)">7 ימים</button>
            &nbsp;&nbsp;
            <date-input-range #releventDateRangeCom [placeHolderStart]="'מתאריך'" [withLabel]="false"
              [placeHolderEnd]="'עד לתאריך'" [dateRangeCtrl]="centeralForm.controls.relevantDateRange">Loading...
            </date-input-range>
            &nbsp;&nbsp;
            <mat-select class="form-control" placeholder="קטגוריה..." formControlName="donationCategoryID">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let c of  servicesData.DonationCategories" [value]="c.id">
                {{c.title}}
              </mat-option>
            </mat-select>
            &nbsp;&nbsp;
            <span class="example-fill-remaining-space"></span>
            <div class="btn-group">
              <button style="margin: 3px; width: 30px; height: 30px; background-color: #65cc6bb8;" mat-mini-fab
                color="primary" title="סנן" (click)="loadCenteralData()"><i class="fa fa-filter"></i></button>
              <button style="margin: 3px; width: 30px; height: 30px; background-color: #65cc6bb8;" mat-mini-fab
                color="primary" title="נקה סינונים" (click)="clerFilter()"><i class="material-icons">clear</i></button>
            </div>
          </div>

          <div class="col-lg-12" style="padding: 0;">
            <div class="col-lg-4" style="padding-right: 0;">
              <div class="ibox">
                <div class="ibox-title tabs-container">
                  <ul class="nav nav-tabs">
                    <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> <i class="fa fa-pie-chart"></i></a>
                    </li>
                    <li><a class="nav-link" data-toggle="tab" href="#tab-2"><i class="fa fa-table"></i></a></li>
                  </ul>
                  <div class="tab-content">
                    <div id="tab-1" class="tab-pane active">
                      <div class="panel-body" style="padding: 0;">
                        <div style="display: block" *ngIf="pieChartDatasets">
                          <canvas baseChart [type]="'pie'" [datasets]="pieChartDatasets" [labels]="pieChartLabels"
                            [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                          </canvas>

                        </div>
                      </div>
                    </div>
                    <div id="tab-2" class="tab-pane">
                      <div class="panel-body">
                        <table class="table table-hover no-margins">
                          <thead>
                            <tr>
                              <th>קטגוריה</th>
                              <th>שיחות</th>
                              <th>ס"ה</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of centeralDataObj?.donationDetailsGroup">
                              <td><small>{{item.item1}}</small></td>
                              <td> {{item.item2}}</td>
                              <td>{{item.item3 |number}}</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-8"  style="padding-left: 0;">
              <div style="display: block;padding: 0; max-height:370px" *ngIf="lineChartDatasets">
                <canvas baseChart style="max-height:370px" [type]="'bar'" [legend]="false"
                  [datasets]="lineChartDatasets" [labels]="lineChartLabels" [options]="lineChartOptions"
                  [plugins]="lineChartPlugins">
                </canvas>

              </div>
            </div>
            <div class="col-lg-12"  style="padding-right: 0;">
              <div class="col-lg-2"  style="padding-right: 0; ">
                <div class="ibox">
                  <div class="ibox-title">
                    <div class="ibox-tools">
                    </div>
                    <h5>סכום כולל</h5>
                  </div>
                  <div class="ibox-content">
                    <h1 class="no-margins" style="direction: ltr; text-align: center;font-weight: 700;">
                      <small>₪</small>
                      {{centeralDataObj?.sumSucceededIvrDonationts|number}}
                    </h1>
                  </div>
                </div>
              </div>
              <div class="col-lg-2" >
                <div class="ibox" >
                  <div class="ibox-title">
                    <div class="ibox-tools">
                    </div>
                    <h5>סכום שנכשל</h5>
                  </div>
                  <div class="ibox-content" style="color:#ff08006e">
                    <h1 class="no-margins" style="direction: ltr; text-align: center;">
                      <small  style="color:#ff08006e">₪</small>
                      {{centeralDataObj?.sumFailedIvrDonations|number:'1.0-0'}}
                    </h1>
                  </div>

                </div>
              </div>
              <div class="col-lg-2">
                <div class="ibox">
                  <div class="ibox-title">
                    <div class="ibox-tools">
                    </div>
                    <h5>שיחות מוצלחות</h5>
                  </div>
                  <div class="ibox-content">
                    <h1 class="no-margins" style="direction: ltr; text-align: center;font-weight: 700;">
                      {{centeralDataObj?.countSucceededIvrDonationts}}
                    </h1>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="ibox">
                  <div class="ibox-title">
                    <div class="ibox-tools">
                    </div>
                    <h5>שיחות שנכשלו</h5>
                  </div>
                  <div class="ibox-content"  style="color:#ff08006e">
                    <h1 class="no-margins" style="direction: ltr; text-align: center;">
                      {{centeralDataObj?.countFailedIvrDonations}}
                    </h1>
                  </div>
                </div>
              </div>


              <div class="col-lg-2">
                <div class="ibox ">
                  <div class="ibox-title">
                    <div class="ibox-tools">
                    </div>
                    <h5>
                      ממוצע יומי
                    </h5>
                  </div>
                  <div class="ibox-content">
                    <h1 class="no-margins" style="direction: ltr; text-align: center;font-weight: 700;">
                      {{centeralDataObj?.averageDayCountSucceededIvrDonationts}}
                    </h1>
                   </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="ibox ">
                  <div class="ibox-title">
                    <div class="ibox-tools">
                    </div>
                    <h5>
                      ממוצע לתרומה
                    </h5>
                  </div>
                  <div class="ibox-content">
                    <h1 class="no-margins" style="direction: ltr; text-align: center;font-weight: 700;">
                      <small style="color: black;">₪</small>
                      {{centeralDataObj?.averageUnitDonationSumSucceededIvrDonationts|number:'1.0-0'}}
                    </h1>
                </div>
                </div>
              </div>
            </div>
          </div>


        </div>



      </div>
      <!-- <div class="ibox-content" *ngIf="centeralDataObj?.mainReport">
      <div class="row">


      </div>
    </div> -->

    </div>

    <!-- <div class="row" style="margin-top: 40px;">
    <fieldset>
      <legend style="    border-radius: 5px;
  background: #FFF;
  border-bottom: solid; ">פילוח תרומות לקטגוריה</legend>
      <div class="row">
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title">
              <div class="ibox-tools" style="background: #a7b1c21c;">
                <span class="label label-info float-right">החל מ-</span>
              </div>
              <h5>סה"כ תרומות לקטגוריה</h5>
            </div>
            <div class="ibox-content">
               <h1 class="no-margins" style="padding: 30px 7px 12px;">180 ₪</h1>
              <small>ל-100 שיחות נכנסות</small>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="ibox ">
            <div class="ibox-title">
              <div class="ibox-tools" style="background: #a7b1c21c;">
                <span class="label label-info float-right">כ"א אדר ב'</span>
              </div>
              <h5>תנועות לטיפול</h5>
            </div>
            <div class="ibox-content">
               <h1 class="no-margins" style="padding: 30px 7px 12px;">180 ₪</h1>
              <small>ל-100 שיחות נכנסות</small>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div> -->
    <!-- <div *ngIf="model" fxLayout="row wrap" style="align-content: flex-start" fxLayout.md="column" fxLayout.sm="column"
  fxLayout.xs="column">
  <div fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxFlex.gt-md="0 1 75" fxLayoutGap="10px"
    fxLayout="row wrap" style="align-content: flex-start">


    <mat-card *authGuardDirective="'ivrDonationUserTasks'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: rgb(37 90 30);">
      <mat-card-content>
        <a [routerLink]="['/ivrDonationUserTasks']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              משימות תרומה
            </h1>
            <span>
              <small>
                {{ model.countIvrDonationUserTask }} משימות ממתינות
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>


    <mat-card *authGuardDirective="'contacts'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: rgb(31 24 31);">
      <mat-card-content>
        <a [routerLink]="['/contacts']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              אנשי קשר
            </h1>
            <span>
              <small>

              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>
    <mat-card *authGuardDirective="'contacts'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: rgb(31 24 31);">
      <mat-card-content>
        <a [routerLink]="['/ivrDonationDetails']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              תרומות טלפוניות
            </h1>
            <span>
              <small>

              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>

  </div>

</div> -->
