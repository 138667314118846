import { MaterialModule } from '../../material.module';
import { AuthGuardMdl } from '../../_guards/auth.guard.mdl';
import { DirectivesMdl } from '../directives/directives-module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutoCompleteDataService } from './auto-complete.data';
import { AutoCompleteComponent } from './auto-complete.cmp';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ObserversModule } from '@angular/cdk/observers';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({ 
    imports: [
        
        CommonModule, 
        FormsModule,  
        ReactiveFormsModule, 
        AuthGuardMdl,
        TextFieldModule,
        ObserversModule,
        ScrollingModule,
        MatAutocompleteModule,
        DirectivesMdl, 
        MaterialModule,
    ],
    exports: [AutoCompleteComponent],
    declarations: [AutoCompleteComponent],
    providers: [AutoCompleteDataService],
})
export class AutoCompleteModule { }
