import { Commitment } from './../../commitments/commitment/commitment';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { Component, OnInit, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ServicesData } from '../../services/services.data';
import { PaymentsData } from '../payments.data';
import { Payment, PaymentCommitment } from './payment';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';


declare const Hebcal: any;

@Component({
  selector: 'payment-edit',
  templateUrl: './payment-edit.tmpl.html',
  styleUrls: ['./../payments.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class PaymentEdit implements OnInit {
  chicsSubscription: any;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public paymentsData: PaymentsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private toraxHttp: ToraxHttp,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private location: Location,
  ) {
    this.toraxHttp.updatedEvent.subscribe((id) => {
      if (id > 0 && this.payment && id === this.payment.paymentID) {
        //  console.log(133);
      }
    });
  }

  //desktopModalWidth = 60;
  terms;
  isForCommitments = true;
  payment: Payment;
  id: number;
  contactId: number;
  commitmentID: number;
  paymentsPlanID: number;
  paymentForm: FormGroup;
  subjects;
  unequalAmount = true; // מיועד לצורך עדכון ולידציה שהסכום אינו שווה
  unequalChicDetailsAmount = true; // מיועד לצורך עדכון ולידציה שסכום השיקים אינו שווה
  doneOnLabel = '';
  showAddButoons = false;
  statuPayment: number | null = 1;
  payOption = '';
  payMethodID;
  contactCommitments;
  includeIsFundraisings = false;


  @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;


  amountChangeEvent(e) {
    this.updatePartialAmount();
  }

  paymentRadioSelect(value: boolean) {
    this.isForCommitments = value;
  }

  public itemSelect(id: number) {
    this.bankBranchesCmp.sourceList = `BankBranches/${id}`;
  }

  addressAddEvent(address) {
    console.log("s", address);
    this.paymentForm.controls['receiptAddressID'].setValue(address.addressID);
    console.log("val", this.paymentForm.controls['receiptAddressID'].value);
  }

  addressChangeEvent(address) {
    this.paymentForm.controls['receiptAddressID'].setValue(address.addressID);
  }

  payMethodAddEvent(payMethod) {
    this.paymentForm.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
  }

  payMethodChangeEvent(payMethod) {
    this.paymentForm.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
    setTimeout(() => {
      this.loadTerms();
    },500);
  }

  payMethodSelection(payMethod) {
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
  }

  disableSave: boolean = false;
  saveAndClaring(paymentForm: FormGroup) {
    this.disableSave = true;
    if (paymentForm.valid) {
      paymentForm.controls['clearNow'].setValue(true);
      paymentForm.controls['printReceiptNow'].setValue(true);
      return this.paymentsData.update(paymentForm.value).subscribe(
        (blob: any) => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write('<html style="background-image: url(\'usaReceiptBackround.jpg\');" ><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { } </style></head><body onload="window.print()"><div>' + (<any>blob).value + '</div></body></html>');
          popupWinindow.document.close();
          this.toraxHttp.updatedEvent.emit(this.id);
          this.location.back();

        },
        error => {
          this.toraxHttp.handleError(error);

        });
    }
    else {
      this.disableSave = false;

    }
  }


  loadTerms() {

    const paymentCommitmentsControl = <FormArray>this.paymentForm.controls['paymentCommitments'];
    this.subjects = <any>paymentCommitmentsControl.controls.map(x => (<any>x).controls.commitmentSubject.value).join();

    if (this.contactId && this.subjects && this.subjects != '') {

      //רק באשראי
      if ([0, 88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(this.payMethodID | 0) == -1) {
        let po = 0;
        if (this.payOption == 'cc') {
          po = 1;
        } else if (this.payOption == 'hok') {
          po = 4;
        }
        this.servicesData.getList(`SubjectAndOptionTermsAndDefault/${this.contactId}/${this.subjects}/${po}`)
          .subscribe((x: any) => {

            let tid = this.paymentForm.controls['termID'].value;
            if (this.id == 0 || !tid) {
              if (x.value.length == 1) {
                this.paymentForm.controls['termID'].setValue(x.value[0].id);
              } else {
                this.paymentForm.controls['termID'].setValue(x.key);
              }
            }
            this.terms = x.value;
            if (tid && x.value && x.value.length > 0 && x.value.map(y => y.id).indexOf(tid) == -1) {
              this.paymentForm.controls['termID'].setValue(null);
            }
          });
      }
    }
  }

  ccPayment() {
    return [0, 88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(this.payMethodID | 0) == -1
      && this.payOption != 'hok';
  }

  setPayOption(payMethodID: number, payMethodOption: string = null) {
    if ([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(payMethodID) == -1) {
      switch (payMethodOption) {
        case 'אשראי':
          this.payOption = 'cc';
          this.doneOnLabel = 'תאריך תשלום (ביצוע)';
          break;
        case 'הוראת קבע':
          this.payOption = 'hok';
          this.doneOnLabel = 'תאריך תשלום (ביצוע)'
          break;
        default:
          this.terms = null;
          break;
      }
    }
    else if (payMethodID == 88) {
      this.payOption = 'Cash';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)';
      //    this.checkValidation;
    }
    else if (payMethodID == 89) {
      this.payOption = 'Check';
      this.doneOnLabel = 'תאריך הפקדת השיק בבנק בפועל'
    }
    else if (payMethodID == 420) {
      this.payOption = 'BankTransfer';
      this.doneOnLabel = 'בהעברה בנקאית יש להזין את תאריך ההעברה בפועל'
    }
    else if (payMethodID == 1310) {
      this.payOption = 'cashRegister';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)'
    }
    else if (payMethodID == 3580) {
      this.payOption = 'voucher';
      this.doneOnLabel = 'תאריך הפקדה מחברת הוורצ\'רס'
    }
    else if (payMethodID == 6232) {
      this.payOption = 'externalSoftware';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)'
    }
    else if (payMethodID == 6990) {
      this.payOption = 'moneyValue';
      this.doneOnLabel = 'בתשלום ע"י שווי כסף יש להכניס תאריך ביצוע'
    }
    else if (!payMethodID || payMethodID == 0) {
      this.payOption = '';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)'
    }
  }





  load(): void {
    if (this.id > 0) {
      this.paymentsData.getEdit(this.id || 0)
        .subscribe((p: Payment) => {
          this.fillForm(p);
        });
    } else {
      this.paymentsData.getAdd(this.commitmentID, this.contactId, this.paymentsPlanID)
        .subscribe((p: Payment) => {
          this.fillForm(p);
        })
    }
  }

  loadContactCommitments = false;
  toogleAddButtons() {
    this.showAddButoons = !this.showAddButoons;
    if (this.toogleAddButtons) {
      this.contactCommitments = null;
    }

  }

  private fillForm(p: Payment): void {
    this.payment = p;
    this.paymentForm.patchValue(p);
    this.payMethodID = p.payMethodID;


    if (p.amount === 0) { //כדי להוריד מספר 0 בעת הכנסת סכום חדש
      this.paymentForm.controls['amount'].setValue(null);
    }

    this.paymentForm.controls['billingDate'].setValue(p.billingDate);

    if (!this.contactId) {
      this.contactId = p.contactID || 0;
    }

    this.selectPayOption();

    const paymentCommitmentsControl = <FormArray>this.paymentForm.controls['paymentCommitments'];
    if ((<any>p).paymentCommitments) {
      let openNewPaymentCommitment = this.id == 0 && (<any>p).paymentCommitments.length == 1 && (<any>p).paymentCommitments[0].commitmentID == 0; //כאשר התשלום חדש ההתחייבות האוטומטית היא כללי
      (<any>p).paymentCommitments.forEach(element => {
        let pcForm = this.initPaymentCommitment(element, openNewPaymentCommitment);
        paymentCommitmentsControl.push(pcForm);
      });
    }

    // לאחר טעינת המודל
    const chicDetailsControl = <FormArray>this.paymentForm.controls['chicDetails'];
    this.paymentForm.controls['payMethodID'].valueChanges.subscribe(x => {
      this.payMethodID = x;
      if (this.payMethodID != 89) {
        chicDetailsControl.clear();
      }
      this.loadTerms();
    });

  }


  public selectPayOption(event?: any) {
    let fpmID = +this.paymentForm.controls['payMethodID'].value;
    this.setPayOption(fpmID, this.payment.paymentOptionName);
    if (event == null || event === undefined) {
      // if (this.id === 0) {
      //     this.paymentForm.controls['accountLocationVM'].setValue(null);
      // }
    } else {
      if ([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(fpmID) > -1) {
        //  this.paymentForm.controls['accountLocationVM'].setValue(null);
      }
    }
    if (this.payOption === 'Check') {
      this.statuPayment = null;
    }
    this.cdRef.detectChanges();
    //this.paymentForm.controls['transferReference'].setValue(null);
  }


  payMethodValidation = c => {
    if ((!c.value || c.value === 0 || c.value === 'undefined') && [88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(this.payMethodID) == -1) {
      return { required: true };
    }
    return null;
  };

  termIDValidation = c => {
    if ((!c.value || c.value === 0 || c.value === 'undefined') && this.payOption === 'cc') {
      return { required: true };
    }
    return null;
  };

  amountValidation = c => {
    if (!c.value || c.value === 0 || c.value === 'undefined') {
      return { required: true };
    }
    return null;
  };

  externalSoftwareValidation = c => {
    if (this.payMethodID && (this.payMethodID == 6232) && (!c.value || c.value == '' || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  doneOnValidation = c => {
    if (this.payMethodID && ((this.payMethodID == 420) || (this.payMethodID == 6232) || (this.payMethodID == 6990)) && (!c.value || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  paymentFormValidation = c => {
    this.unequalAmount = true;
    let sumAmount = 0;
    const paymentCommitmentsControl = <FormArray>this.paymentForm.controls['paymentCommitments'];
    paymentCommitmentsControl.controls.forEach((x: FormGroup) => {
      sumAmount += parseFloat(x.controls['partialAmount'].value || 0);
    });

    if (paymentCommitmentsControl.length > 0 && (+c.controls['amount'].value !== sumAmount)) {
      this.unequalAmount = false;
      return { required: true };
    }

    if (paymentCommitmentsControl.length === 0) {
      return { required: true };
    }

    paymentCommitmentsControl.controls.forEach((x: FormGroup) => {
      let testAmount = parseFloat(x.controls['partialAmount'].value || 0);
      if (testAmount <= 0 && this.paymentForm.controls['amount'].value) {
        this.unequalAmount = false; // כאשר הסכום באחד הרשומות = 0
        return { required: true };
      }
    });

    this.unequalChicDetailsAmount = true;
    let sumChicDetailsAmount = 0;
    const chicDetailsControl = <FormArray>this.paymentForm.controls['chicDetails'];
    chicDetailsControl.controls.forEach((x: FormGroup) => {
      sumChicDetailsAmount += parseFloat(x.controls['amount'].value || 0);
    });
    if (chicDetailsControl.length > 0 && (+c.controls['amount'].value !== sumChicDetailsAmount)) {
      this.unequalChicDetailsAmount = false;
      this.cdRef.detectChanges();
      return { required: true };
    }
    return null;
  };


  voucherValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && ((this.payMethodID == 3580)) && (!c.va) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };


  checkValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && ((this.payMethodID == 89)) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  bankDetailsValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && ((this.payMethodID == 420)) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  accountLocationValidation = c => {
    // tslint:disable-next-line:max-line-length
    if ((!c.value || c.value === 'undefined') && (this.payMethodID && [88, 89, 420, 6232, 6990, 42340].indexOf(this.payMethodID) > -1)) {
      return { required: true };
    }
    return null;
  };

  transferReferenceValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && (this.payMethodID == 420) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  moneyValueValidation = c => {
    if ((!c.value || c.value === 0 || c.value === 'undefined') && (this.payMethodID && this.payMethodID == 6990)) {
      return { required: true };
    }
    return null;
  };

  currencyValidation = c => {
    if (!c.value || c.value === 0 || c.value === 'undefined') {
      return { required: true };
    }
    return null;
  };

  // addChicDetailControl(){
  //     const chicDetailControl = (<FormArray>this.paymentForm.controls['chicDetails']);
  //     let cdForm = this.addChicDetailFormGroup();
  //     chicDetailControl.push(cdForm);

  // }
  // addChicDetailFormGroup() {
  //     let chicDetailFormGroup = this.formBuilder.group({
  //         checkDate: [],
  //         bankNumber: [],
  //         bankName:[],
  //         bankBranchNumber: [],
  //         bankBranchName:[],
  //         bankAcountNumber: [],
  //         chicNumber: [],
  //         nameOfChic: []
  //     });
  //     return chicDetailFormGroup;
  // }


  addPaymentCommitmentControl(id: number, openNewPaymentCommitment: boolean = false, commitment: any = null) {
    let commitmentID = id || 0;
    const paymentCommitmentsControl = (<FormArray>this.paymentForm.controls['paymentCommitments']);
    let amount = +this.paymentForm.controls['amount'].value || 0;
    let sumAmount = 0;
    paymentCommitmentsControl.controls.forEach((x: FormGroup) => {
      sumAmount += parseFloat(x.controls['partialAmount'].value);
    });
    let partialAmount = (amount - sumAmount);
    partialAmount = partialAmount > 0 ? partialAmount : 0;
    let pc = new PaymentCommitment();
    pc.commitmentID = commitmentID;
    pc.partialAmount = partialAmount;
    //  pc.commitmentTitle = commitment.commitmentTitle;
    pc.paymentID = this.id;
    pc.contactID = this.contactId;

    if (!commitment) {
      commitment = new Commitment();
    }
    pc.commitmentReasonID = commitment.commitmentReasonID;
    pc.commitmentReasonName = commitment.commitmentReasonName;
    pc.commitmentSubject = commitment.commitmentSubject;
    pc.commitmentSubjectName = commitment.subjectName;
    pc.designation = commitment.designation;
    pc.designationDetails = commitment.designationDetails;

    let pcForm = this.initPaymentCommitment(pc, openNewPaymentCommitment);
    paymentCommitmentsControl.push(pcForm);
  }

  initPaymentCommitment(pc: any, openNewPaymentCommitment: boolean = false) {

    if (openNewPaymentCommitment && !pc.hebYear) {
      let dt = new Date();
      let hebcal = Hebcal;
      pc.hebYear = hebcal.gematriya(hebcal.HDate(dt.getFullYear() - 1, dt.getMonth()).year - 1, 3).replace('"', ''); //TODO minus 1 ?????
    }

    let paymentCommitmentFormGroup = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      paymentCommitmentID: [pc.paymentCommitmentID || 0, Validators.required],
      paymentID: [pc.paymentID || 0, Validators.required],
      partialAmount: [pc.partialAmount || null, Validators.required],
      partialValueInCommitment: [pc.partialValueInCommitment],
      lockPartialValueInCommitment: [pc.lockPartialValueInCommitment || false, Validators.required],
      contactID: [pc.contactID || this.contactId, Validators.required],
      commitmentID: [pc.commitmentID, Validators.required],
      commitmentSubject: [pc.commitmentSubject, openNewPaymentCommitment ? Validators.required : null],
      commitmentReasonID: [pc.commitmentReasonID], //, openNewPaymentCommitment ? Validators.required : null
      designation: [pc.designation],
      designationDetails: [pc.designationDetails],
      hebYear: [pc.hebYear],

      //trick
      openNewPaymentCommitment: [openNewPaymentCommitment]

    });

    paymentCommitmentFormGroup.controls['commitmentSubject'].valueChanges.subscribe(x => {
      this.loadTerms();
    });
    paymentCommitmentFormGroup.controls['partialValueInCommitment'].disable();
    paymentCommitmentFormGroup.controls['lockPartialValueInCommitment'].valueChanges.subscribe(x => {
      if (x) {
        paymentCommitmentFormGroup.controls['partialValueInCommitment'].enable();
      }
      else {
        paymentCommitmentFormGroup.controls['partialValueInCommitment'].disable();
      }
    });

    return paymentCommitmentFormGroup;
  }

  newCommitmentSubjectUpdate(subject) {
    if (event.type != "load" && +subject == 1 && this.payment.isOpenOtherHoldingCommitmentsForContact) {
      alert('שים לב! לאיש זה קיימת התחייבות פתוחה להחזקת ביהמ"ד\nניתן לבחור התחייבות זו ע"י מחיקת השורה ולחיצה על "הוסף מתוך התחייבויות פתוחות"');
    }
  }
  reloadSubjectTermsEvent(obj) {
    //this.paymentForm.controls['needRefreshFuturePayments'].setValue(true);
    const pcControl = <FormGroup>(<FormArray>this.paymentForm.controls['paymentCommitments']).controls[obj.ix];
    pcControl.controls['commitmentSubject'].setValue(obj.subject);

    this.loadTerms();
  }
  calculatePaymentCommitmentsAmounts() {

    const paymentCommitmentsControl = (<FormArray>this.paymentForm.controls['paymentCommitments']);
    let amount = +this.paymentForm.controls['amount'].value || 0;

    let cnt = paymentCommitmentsControl.controls.length;

    if (amount > 0 && cnt >= 1) {
      paymentCommitmentsControl.controls.forEach((x: FormGroup) => {
        x.controls['partialAmount'].setValue(amount / cnt);
      });
    }
  }


  updatePartialAmount() {
    const paymentCommitmentsControl = <FormArray>this.paymentForm.controls['paymentCommitments'];
    if (paymentCommitmentsControl.length === 1 && this.id == 0) {
      let val = +this.paymentForm.controls['amount'].value;
      (<any>paymentCommitmentsControl.controls[0]).controls['partialAmount'].setValue(val);
    }
  }

  private initForm() {
    this.paymentForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      ilsRate: [],
      calculatedAmountInILS: [],
      paymentID: [this.id, Validators.required],
      contactID: [this.contactId, Validators.required],
      payMethodID: [null, this.payMethodValidation],
      externalSoftwarePayMethod: [, this.externalSoftwareValidation],
      isActive: [true, Validators.required],
      doneOn: [, this.doneOnValidation],
      transactinID: [],
      amount: [, this.amountValidation],
      bankAcountOwner: [],
      billingDate: [, Validators.required],
      checkDate: [, this.payMethodID == 89 && this.id > 0 ? this.checkValidation : null],
      statusPayment: [this.statuPayment, Validators.required],
      chicNumber: [, this.payMethodID == 89 && this.id > 0 ? this.checkValidation : null],
      voucherNumber: [, this.voucherValidation],
      vouchersOrganizationID: [, this.voucherValidation],
      vouchersOrganizationName: [],
      vouchersOrgPaymentID: [],
      bankNumber: [], // , this.bankDetailsValidation הוסר בגלל בנקים בחו"ל
      bankName: [],
      bankBranchNumber: [], // , this.bankDetailsValidation הוסר בגלל בנקים בחו"ל
      bankBranchName: [],
      bankAcountNumber: [], // , this.bankDetailsValidation הוסר בגלל בנקים בחו"ל
      nameOfChic: [],
      currency: [null, this.currencyValidation],
      paymentOption: [1, Validators.required],
      receiptAddressID: [],
      comment: [],
      receiptComment: [],
      contactTitleForReceipts: [],
      detailsLetterAsReceipt: [false, Validators.required],
      accountLocation: [null, this.accountLocationValidation],
      transferReference: ['', this.transferReferenceValidation],
      moneyValueDescription: ['', this.moneyValueValidation],
      invoiceNumberForMoneyValue: ['', this.moneyValueValidation],
      invoiceDateForMoneyValue: [, this.moneyValueValidation],
      termID: [null, this.termIDValidation],
      paymentsPlanID: [],
      hashReceiptNumber: [],
      voucherPaymentIDs: [],
      paymentGroupID: [],
      pladaPaymentID: [],
      //only on save props vm's
      clearNow: [false, Validators.required],
      printReceiptNow: [true, Validators.required], // <<== לא תקף כאשר הפרמטר  CleargNow = False

      paymentCommitments: this.formBuilder.array([]),
      chicDetails: this.formBuilder.array([]),
      //trick
      // contactCommitmentsCtrl: []
    });

    this.paymentForm.controls['ilsRate'].valueChanges.subscribe(x => {
      this.setChicIlsAmount();
    });
    this.chicsSubscription = this.createChicsSubscription();
    this.paymentForm.setValidators(this.paymentFormValidation);

  }

  createChicsSubscription(): any {
    return this.paymentForm.controls['chicDetails'].valueChanges.subscribe(x => {
      this.setChicIlsAmount();
    });
  }

  shekelValueChange(e) {
    this.setChicIlsAmount();
  }


  private setChicIlsAmount() {
    let rt = this.paymentForm.controls['ilsRate'].value;
    const chicDetailsControl = <FormArray>this.paymentForm.controls['chicDetails'];
    this.chicsSubscription.unsubscribe();
    chicDetailsControl.controls.forEach((cd: FormGroup) => {
      let amnt = parseFloat(cd.controls['amount'].value || 0);
      let ilsAmnt = (amnt * rt);
      cd.controls['calculatedAmountInILS'].setValue(ilsAmnt);
    });
    this.chicsSubscription = this.createChicsSubscription();
  }

  // onAmountChange() {
  //     let pcs = (<FormArray>this.paymentForm.controls['paymentCommitments']).controls;
  //     if (this.id == 0 && pcs.length == 1 && ((<FormArray>pcs[0]).controls['partialAmount'].value == 0 || (<FormArray>pcs[0]).controls['partialAmount'].value == null)) {
  //         (<FormArray>pcs[0]).controls['partialAmount'].setValue(+this.paymentForm.controls['amount'].value);
  //     }
  // }

  showContactCommitmentsMenue = false;
  showContactCommitments() {
    this.showContactCommitmentsMenue = !this.showContactCommitmentsMenue;
    // this.getContactCommitments();
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['paymentID'] || 0;
      this.commitmentID = +params['commitmentID'] || 0;
      this.contactId = +params['contactID'];
      this.paymentsPlanID = +params['paymentsPlanID'] || null;
    });
    this.initForm();
    this.load();



  }
}



