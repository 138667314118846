<form  *ngIf="objectsListResult" [formGroup]="searchTishEventsListForm" novalidate>
    <panel-box title="אירועים" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools"> 
		<panel-box-btn (btnClick)="getTishEvents()" icon="fa-refresh"></panel-box-btn>
		  <a [routerLink]="[{outlets: {popup: ['add']}}]"><i class="fa fa-plus"></i></a>
	</span>
        <div class="panel-content">
            <table class="table table-striped no-margins">
                <thead>
                    <tr>
                        <th>...</th> 
                        <th>כותרת אירוע</th>
                        <th>תיאור</th>
                        <th>תאריך</th>
                        <th>הערה</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tg of objectsListResult.list">
                        <td style="white-space: nowrap;">
                            <a  [routerLink]="['/tishEvents', tg.tishEventID]">
                                <i class="fa fa-folder-open-o"></i>
                            </a>
                            &nbsp; 
                            <a   [routerLink]="['/tishEvents', tg.tishEventID, 'map']">
                                <i class="fa fa-sitemap"></i>
                            </a> 
                            <!--<a [routerLink]="[tg.tishEventID, 'map']"><i class="fa fa-map-marker"></i></a>-->
                        </td>
                        <td>{{ tg.tishEventTitle }}</td>
                        <td>{{ tg.description }}</td>
                        <td>{{ tg.eventJewishDate }} [{{ tg.eventDate | date:'dd/MM/yyyy' }}]</td>
                        <td>{{ tg.comment }}</td>
                    </tr>
                </tbody>
            </table>
            <pagination [rowsCount]="objectsListResult.rowsCount" [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount" (change)="changePage($event)"></pagination>

        </div>
    </panel-box>
</form>
 