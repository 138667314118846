<form style="min-width: 850px;" *ngIf="commitment" [formGroup]="commitmentForm" novalidate>
    <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
            <legend>פרטי התחייבות</legend>
            <label class="header-label">תקופה:</label>
            <div fxLayout="column">
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                    <!-- (dateChange)="setTillDateMsg($event)" בוטל היות וגרם להרבה בעיות -->
                    <date-input apply-enter  [placeholder]="'תאריך התחייבות'"
                        [dateControl]="commitmentForm.controls.fromDate">Loading...</date-input> &nbsp;
                    &nbsp;
                    <div class="input-group mb-3">
                        <label>מבצע:</label>
                        <input class="form-control" apply-enter formControlName="operation">
                    </div>
                </div>
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                    <date-input apply-enter (dateChange)="deleteTillDateMsg($event)" [placeholder]="'תאריך סיום מתוכנן'"
                        [dateControl]="commitmentForm.controls.tillDate">
                        Loading...</date-input> 
                </div> 
                <h4 #tillDateMsg style="color:blue"></h4>
            </div>
            <hr> 
            <div fxLayout="column">
                <label class="header-label">עבור:</label>
                <div style="margin-top: -30px; left: 25px;" class="form-row" fxLayout="row" fxLayout.xs="column">
                    <span class="example-fill-remaining-space"></span>
                    <label
                        style="float: left; width: fit-content; cursor: alias;white-space: nowrap; text-align: end; vertical-align:bottom;"
                        (click)="openFundraisingCommitments()"
                        class="label inline">{{ !isFundraisingCommitment ? 'בחירה וקישור מתוך התחייבות לגיוס' : 'עבור להתחייבות רגילה'}}:</label>
                </div>
                <div fxLayout="column" *ngIf="!isFundraisingCommitment">
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>נושא:</label>
                            <select class="form-control" apply-enter (change)="selectSubject($event)"
                                placeholder="נושא:" [formControl]="commitmentForm.controls.commitmentSubject">
                                <option [value]="">...</option>
                                <option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                                    {{cs.name}}</option>
                            </select>
                        </div> &nbsp;
                        <div class="input-group mb-3">
                            <label>סיבה:</label>
                            <select class="form-control" apply-enter placeholder="סיבה:"
                                [formControl]="commitmentForm.controls.commitmentReasonID">
                                <option [value]="">...</option>
                                <option *ngFor="let cr of commitmentReasons" [value]="cr.commitmentReasonID">
                                    {{cr.name}}</option>
                            </select>
                        </div>&nbsp;
                        <div class="input-group mb-3" *ngIf="subject == 5">
                            <label>כיבוד:</label>
                            <select class="form-control" (change)="selectHonor()" apply-enter placeholder="בחר כיבוד..."
                                [formControl]="commitmentForm.controls.honorID">
                                <option [value]="">...</option>
                                <option *ngFor="let h of honors" [value]="h.honorID">
                                    {{h.honorsSetTitle}} {{h.honorJewishDate}} {{h.honorTypeTitle}}
                                    {{h.reasonComment}}
                                </option>
                            </select>
                        </div>
                        &nbsp;
                        <div class="input-group mb-3" *ngIf="subject == 6">
                            <label>רישום:</label>
                            <select class="form-control" apply-enter style="width: -webkit-fill-available !IMPORTANT;"
                                *ngIf="subject == 6" formControlName="registrationID">
                                <option [value]="">בחר רישום...</option>
                                <option *ngFor="let r of registrations" [value]="r.id">
                                    {{r.title}} יתרה לתשלום: {{r.amountToPay | number}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">

                        <div class="input-group mb-3" style="width: min-content;">
                            <label>שנה:</label>
                            <select apply-enter style="width: 84px; min-width: 84px;" class="form-control"
                                formControlName="hebYear">
                                <option value="''">...</option>
                                <option value="תשעו">תשעו</option>
                                <option value="תשעז">תשעז</option>
                                <option value="תשעח">תשעח</option>
                                <option value="תשעט">תשעט</option>
                                <option value="תשפ">תשפ</option>
                                <option value="תשפא">תשפא</option>
                                <option value="תשפב">תשפב</option>
                                <option value="תשפג">תשפג</option>
                                <option value="תשפד">תשפד</option>
                                <option value="תשפה">תשפה</option>
                                <option value="תשפו">תשפו</option>
                                <option value="תשפז">תשפז</option>
                                <option value="תשפח">תשפח</option>
                                <option value="תשפט">תשפט</option>
                                <option value="תשצ">תשצ</option>
                                <option value="תשצא">תשצא</option>
                                <option value="תשצב">תשצב</option>
                                <option value="תשצג">תשצג</option>
                            </select>
                        </div>&nbsp;
                        <div class="input-group mb-3">
                            <label>ייעוד מיוחד:</label>
                            <input apply-enter type="text" class="form-control" formControlName="designation">
                        </div>&nbsp;
                        <div class="input-group mb-3" *ngIf="commitmentForm.controls.designation.value">
                            <label>פירוט ייעוד:</label>
                            <input apply-enter type="text" class="form-control" formControlName="designationDetails">
                        </div>
                    </div>

                    <h3 style="color:red" class="label" *ngIf="currencyWarning">
                        מטבע התחייבות שונה ממטבע רשימות החזקת ביהמ"ד
                    </h3>
                </div>
                <div class="form-row" *ngIf="isFundraisingCommitment">
                    <auto-complete apply-enter [placeholder]="'קישור להתחייבות ראשית...'"
                        (onSelect)="fundraisingCommitmentSelect($event)" [controllerName]="'ViwFundraisingCommitments'"
                        [formGroupModel]="commitmentForm" [idControl]="commitmentForm.controls.commitmentFundraisingID"
                        [nameControl]="commitmentForm.controls.commitmentFundraisingTitle">
                    </auto-complete>
                </div>
            </div>
            <hr>
            <div fxLayout="column">
                <label class="header-label">סכום:</label>
                <p>
                    <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style"
                        [(value)]="amountWay">
                        <mat-button-toggle apply-enter value="globalAmount">
                            <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'globalAmount'">radio_button_checked</mat-icon>
                            <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'pulseAmount'">radio_button_unchecked</mat-icon>
                            סכום כללי
                        </mat-button-toggle>
                        <mat-button-toggle apply-enter value="pulseAmount">
                            <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'pulseAmount'">radio_button_checked</mat-icon>
                            <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'globalAmount'">radio_button_unchecked</mat-icon>
                            סכום לחיוב בודד
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <span class="example-fill-remaining-space"></span>
                    <span
                        style="white-space: nowrap; float: left;color: blue; position: absolute; left:15px; text-align: end; vertical-align:bottom;"
                        *ngIf="qtyDescription && amountWay == 'globalAmount'  && commitmentForm.controls.globalAmount.value">
                        {{qtyDescription}} {{lastQtyDescription}}
                    </span>
                </p>
                <div class="form-row" *ngIf="amountWay == 'globalAmount'" fxLayout="row" fxLayout.xs="column">
                    <div class="input-group mb-3">
                        <label>סכום כללי:</label>
                        <input apply-enter type="text" class="form-control" formControlName="globalAmount">
                    </div> &nbsp;
                    <div class="input-group mb-3">
                        <label>מטבע:</label>
                        <select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע">
                            <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                                {{c.name}}
                            </option>
                        </select>
                    </div>&nbsp;
                    <div class="input-group mb-3">
                        <label>פיצול לתשלומים:</label>
                        <input apply-enter type="text" class="form-control" formControlName="qty">
                    </div>
                </div>
                <div class="form-row" *ngIf="amountWay == 'pulseAmount'" fxLayout="row" fxLayout.xs="column">
                    <div class="input-group mb-3">
                        <label>סכום לחודש:</label>
                        <input apply-enter type="text" class="form-control" formControlName="pulseAmount">
                    </div> &nbsp;
                    <div class="input-group mb-3">
                        <label>מטבע:</label>
                        <select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע">
                            <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                                {{c.name}}
                            </option>
                        </select>
                    </div>&nbsp;
                    <div class="input-group mb-3">
                        <label>מס' תשלומים:</label>
                        <input apply-enter type="text" class="form-control" formControlName="qty">
                    </div>&nbsp;
                    <div class="input-group mb-3">
                        <label>תוספת אדר אלול:</label>
                        <input apply-enter type="text" class="form-control" formControlName="specialtyMonthsAmount">
                    </div>
                </div>
                <h4 style="color:blue; text-align: left;" *ngIf="commitmentForm.controls.tillDate.value">
                    שים לב למוקדם מביניהם {{commitmentForm.controls.qty.value}} או
                    {{commitmentForm.controls.tillDate.value}}</h4>
            </div>
        </fieldset>
       
        <div class="col-sm-12" style="padding: 0; margin-top: 20px;">
            <div class="col-sm-7" style="padding-right: 0;">
                <fieldset class="form-group" fxLayout="column">
                    <legend>מידע נוסף</legend>
                    <div fxLayout="column">
                        <div fxLayout="row" style="text-align: right; white-space: nowrap"
                            class="custom-control custom-checkbox">
                            <input apply-enter type="checkbox" class="custom-control-input"
                                formControlName="isFundraising">
                            &nbsp;
                            <label class="custom-control-label" for="isFundraising">התחייבות לגיוס:</label>
                            <span class="example-full-width"></span>
                            <h4 fxLayout="row" style="color: blue; text-align: left;">
                                ☜ כאשר ההתחייבות היא לגיוס תרומות חיצוניות
                            </h4>
                        </div>
                    </div>
                    <hr>
                    <div fxLayout="column">
                        <label class="header-label">פרטי קבלות בהתחייבות זו <small>(ברירת מחדל)</small>:</label>
                        <div class="form-row" fxLayout="row" fxLayout.xs="column">
                            <div class="input-group mb-3">
                                <label>שם לקבלות:</label>
                                <input type="text" class="form-control" apply-enter
                                    formControlName="defaultContactTitleForReceipts" placeholder="שם לקבלות...">
                            </div>&nbsp;
                            <addresses-select-pick *ngIf="commitmentForm.controls.contactID.value > 0" fxLayout="row"
                                fxLayout.xs="column" (addressAdd)="addressAddEvent($event)"
                                (addressChange)="addressChangeEvent($event)"
                                [contactID]="commitmentForm.controls.contactID.value" [placeHolder]="'כתובת לקבלה'"
                                [addresIdControl]="commitmentForm.controls.defaultReceiptAddressID">
                            </addresses-select-pick>&nbsp;
                        </div>
                        <div fxLayout="row" fxLayout.xs="column">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input"
                                    formControlName="defaultDetailsLetterAsReceipt">
                                <label class="custom-control-label" for="defaultDetailsLetterAsReceipt">הצג שם זה גם
                                    במכתב תודה:</label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="input-group mb-3" style="width: 100%;">
                        <label>הערה:</label>
                        <textarea class="form-control comment" style="min-width: 100%" apply-enter rows="2"
                            placeholder="הערה..." formControlName="comment"></textarea>
                    </div>
                </fieldset>
            </div>
            <div class="col-sm-5" style="padding-left: 0;">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי סגירת התחייבות</legend>
                    <div fxLayout="column">
                        <div class="form-row" fxLayout="row" fxLayout.xs="column">
                            <date-input [placeholder]="'תאריך סגירת התחייבות'"
                                [dateControl]="commitmentForm.controls.closedDate">Loading...
                            </date-input>
                        </div>
                        <div class="form-row" fxLayout="row" fxLayout.xs="column">
                            <div class="input-group mb-3">
                                <label>סטטוס:</label>
                                <select class="form-control" apply-enter placeholder="סטטוס:"
                                    [formControl]="commitmentForm.controls.closedStatus">
                                    <option value="">...</option>
                                    <option *ngFor="let cs of servicesData.ClosedStatuses" [value]="cs.value">
                                        {{cs.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row" fxLayout="row" fxLayout.xs="column">
                            <div class="input-group mb-3">
                                <label>הערת סגירה:</label>
                                <textarea class="form-control comment" apply-enter rows="1" placeholder="הערת סגירה..."
                                    formControlName="closedComment"></textarea>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

        </div>

    </div>

</form>