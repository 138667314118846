import { Component } from '@angular/core';

@Component({ 
    selector: 'dashboard-chapak',
    templateUrl: 'dashboard-chapak.component.html',
    styleUrls: ['dashboard-chapak.component.scss']
})
export class DashboardChapakComponent {

}
