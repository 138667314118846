<form *ngIf="data && formData" class="example-form" [formGroup]="formData" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            {{ (data && data.tishInvitedID > 0 ? 'עריכת פרטי' : 'הוספת') }} מוזמן
            <br>
            <small>אירוע: <b>{{ data.tishEventTitle }}</b></small>
        </h2>
    </div>
    <mat-dialog-content>
        <div fxLayout="column" style="display: block;">
            <div [formGroup]="formData" *ngIf="data" class="panel-content">
                <div class="form-row" fxLayout="column">
                    <div class="input-group mb-3">
                        <auto-complete apply-enter [placeholder]="'שם מוזמן'" [controllerName]="'Contacts'"
                            [formGroupModel]="formData" [idControl]="formData.controls.contactID"
                            [nameControl]="formData.controls.tishInvitedName">
                        </auto-complete>
                    </div>
                    <hr>
                      <div class="form-row" fxLayout="column">
                        <div class="input-group mb-3">
                            <label>שם לכרטיס:</label>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                                    <input type="text" fxFlex="20" class="form-control" placeholder="תיאור ותואר"
                                        style="text-align: right;" apply-enter formControlName="specialInvitedPerfix" />
                                    <input type="text" fxFlex="30" class="form-control" placeholder="שם פרטי"
                                        style="text-align: right;" apply-enter
                                        formControlName="specialInvitedFirstName" />
                                    <input type="text" fxFlex="30" class="form-control" placeholder="שם משפחה"
                                        style="text-align: right;" apply-enter
                                        formControlName="specialInvitedLastName" />
                                    <input type="text" fxFlex="20" class="form-control" placeholder="סיומת"
                                        style="text-align: right;" apply-enter formControlName="specialInvitedSuffix" />
                                </div>
                                <h3 fxLayout="row" class="label">יש להכניס נתונים מסודרים בשדות המתאימים</h3>

                        </div>
                        <!--  <label>שם לכרטיס:</label>
                               <div class="col-sm-10">
                                   <input type="text" class="form-control" formControlName="specialInvitedName" />
                             </div> -->
                    </div>
                    <hr> <div class="form-row" fxLayout="column">
                        <div class="input-group mb-3">
                            <auto-complete apply-enter [placeholder]="'קבוצה'"
                                [controllerName]="'TishGroups/' + data.tishEventID" [formGroupModel]="formData"
                                [idControl]="formData.controls.tishGroupID"
                                [nameControl]="formData.controls.tishGroupName">
                            </auto-complete>
                        </div>
                    </div>
                    <hr> <div class="input-group mb-3">
                        <label for="quantityAdditionalTickets">מס' כרטיסי כניסה נוספים:</label>
                        <input type="text" class="form-control" formControlName="quantityAdditionalTickets" />
                        <h3 class="label">לצורך הדפסת כרטיסי כניסה נוספים למוזמן נוכחי - לכניסה בלבד</h3>
                    </div>
                    <hr>
                    <div class="input-group mb-3">
                        <label for="comment">הערה:</label>
                        <textarea class="form-control comment" apply-enter rows="1"  style="width: 100%;" placeholder="הערה..."
                        formControlName="comment"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="float: left; padding: 20px;">
      <button apply-enter  [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="formData"><i class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="data.tishInvitedID > 0" mat-dialog-close><i
                class="fa fa-warning"></i> ביטול
        </button>

    </mat-dialog-actions>

</form>
