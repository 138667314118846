import { IvrDonationDetailMain } from './ivrDonationDetail/ivrDonationDetail-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { IvrDonationDetailsGroupList } from './ivrDonationDetails-group-list.cmp';
import { Routes } from '@angular/router';
import { IvrDonationDetailsList } from './ivrDonationDetails-list.cmp';

export const IvrDonationDetailsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'ivrDonationDetails', component: IvrDonationDetailsList, data: { roles: "ivrDonationDetails", title: 'תרומות' } },
    { canActivate: [AuthGuard], path: 'ivrDonationDetails/:contactID/ivrDonationDetails/add', component: IvrDonationDetailMain, data: { mode: "edit", roles: "ivrDonationDetails_add", title: 'הוסף תרומה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'ivrDonationDetails/:ivrDonationDetailID', component: IvrDonationDetailMain, data: { roles: "ivrDonationDetails", title: 'פרטי תרומה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'ivrDonationDetails/:ivrDonationDetailID/edit', component: IvrDonationDetailMain, data: { mode: "edit", roles: "ivrDonationDetails", title: 'עריכת פרטי תרומה' }, outlet: 'popup' },

    { canActivate: [AuthGuard], path: 'ivrDonationDetails/groupIvrDonationDetails', component: IvrDonationDetailsGroupList, data: { title: 'רשימות מקובץ לאנשים וסוגים' } },
];

