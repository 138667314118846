import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { PaymentsPlansMdl } from './../paymentsPlans/paymentsPlans.mdl';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { PaymentMain } from './payment/payment-main.cmp';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { CurrencyPipeMdl } from './../components/pipes/currency-pipe';
import { CommitmentTitleModule } from './../commitment-title/commitment-title.module';
import { PaymentBankTransferDetails } from './payment/payment-bankTransfer-details/payment-bankTransfer-details.cmp';

import { AmountAndCurrencyInputModule } from './../components/amount-and-currency-input/amount-and-currency-input.module';
import { CommitmentsSelectPickMdl } from '../commitments/commitments-select-pick/commitments-select-pick.mdl';
import { MaterialModule } from './../material.module';

import { PaymentCommitmentEditComponent } from './payment-commitment-edit/payment-commitment-edit.component';
import { PaymentsRoutes } from './payments.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { AmountInputRangeModule } from './../components/amountInput-range/amount-input-range.mdl';
import { CcChargeMdl } from './../cc-charge/cc-charge.mdl';
import { DateMonthInputModule } from './../components/dateMonth-input/dateMonth-input.mdl';
import { PaymentDetailsDlg } from './payment/payment-details-dlg.cmp';
import { PaymentsList } from './payments-list.cmp';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';
import { PaymentsCancellationReceiptDlg } from './payments-cancellationReceipt-dlg.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { PaymentsData } from './payments.data';
import { PaymentEdit } from './payment/payment-edit.cmp';
import { PaymentDetails } from './payment/payment-details.cmp';
import { CommitmentFundraisingInfo } from './payment-misc/commitment-fundraising-info.cmp';
import { PaymentChicDetails } from './payment/payment-chic-details/payment-chic-details.cmp';
import { PaymentExternalSoftwareDetails } from './payment/payment-externalSoftware-details/payment-externalSoftware-details.cmp';
import { PaymentsShortList } from './payments-short-list.cmp';
import { PaymentShortDetails } from './payment/payment-short-details.cmp';
import { AddressesMdl } from '../addresses/addresses.mdl';
import { HoldingsMdl } from '../holdings/holdings.mdl';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { TransactionsMdl } from '../transactions/transactions.mdl';
import { DirectivesMdl } from '../components/directives/directives-module';
import { AutoCompleteInputModule } from '../components/auto-complete-input/auto-complete-input.mdl';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(PaymentsRoutes),
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        DateInputModule,
        DateMonthInputModule,
        FooterEditButtonsMdl,
        DateInputRangeModule,
        CcChargeMdl,
        AmountInputRangeModule,
        AutoCompleteModule,
        MaterialModule,
        CommitmentsSelectPickMdl,
        AmountAndCurrencyInputModule,
        AutoCompleteInputModule,
        CommitmentTitleModule,
        CurrencyPipeMdl,
        ModelInfoModule,
        MainFormModalModule,
        PayMethodsMdl,
        AddressesMdl,
        HoldingsMdl,
        CustomToolTipModule,
        TransactionsMdl,
        DirectivesMdl

    ],
    exports: [
        PaymentMain,
        PaymentsList,
        PaymentsShortList,
        PaymentEdit,
        PaymentDetails,
        PaymentDetailsDlg,
        PaymentShortDetails
    ],
    declarations: [
        PaymentMain,
        PaymentsList,
        PaymentsShortList,
        PaymentEdit,
        PaymentDetails,
        PaymentsCancellationReceiptDlg,
        PaymentDetailsDlg,
        PaymentCommitmentEditComponent,
        CommitmentFundraisingInfo,
        PaymentChicDetails,
        PaymentBankTransferDetails,
        PaymentExternalSoftwareDetails,
        PaymentShortDetails
    ],
    providers: [
        PaymentsData
    ],
    entryComponents: [
        PaymentsCancellationReceiptDlg,
        PaymentDetailsDlg,
        CommitmentFundraisingInfo


    ]
})
export class PaymentsMdl { }

