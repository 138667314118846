import { CurrencyPipeMdl } from './../components/pipes/currency-pipe';
import { HoldingsPaymentEditDlg } from './holdings-payment-edit-dlg.cmp';
import { HoldingsPrintsList } from './holdings-prints-list.cmp';
import { HoldingsMain } from './holdings-main.cmp';
import { HoldingsList } from './holdings-list.cmp';
import { MaterialModule } from './../material.module';
import { HoldingsRoutes } from './holdings.routing';
import { DateMonthInputModule } from './../components/dateMonth-input/dateMonth-input.mdl';
import { DateMonthRangeModule } from './../components/dateMonth-range/dateMonth-range.mdl';
import { HoldingsPrintHtmlDlg } from './holdings-print-dlg.cmp';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { HoldingsListSmall } from './holdings-list-small.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl'; 
import { HoldingsData } from './holdings.data'; 
import { SortPipeMdl } from '../components/pipes/sort-pipe';


@NgModule({
    imports: [
        AutoCompleteModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        DateMonthRangeModule,
        DateMonthInputModule,
        MaterialModule,
        CurrencyPipeMdl,
         RouterModule.forChild(HoldingsRoutes),
     
    ],
    declarations: [
        HoldingsList,
        HoldingsListSmall,
        HoldingsPrintsList, 
        HoldingsPrintHtmlDlg,
        HoldingsMain,
        HoldingsPaymentEditDlg
    ],
    exports: [
        HoldingsList,
        HoldingsListSmall, 
        HoldingsPrintHtmlDlg,
        HoldingsPaymentEditDlg,
        HoldingsPrintsList,
    ],
    providers: [
        HoldingsData
    ],
    entryComponents: [
        HoldingsPrintHtmlDlg
    ]
})
export class HoldingsMdl { }



