<!--
<div [formGroup]="tishPlaceForm" *ngIf="tishPlace" style="z-index: 5;margin: 0 auto;color: black;font-size: 7px;line-height: 7px;"
    class="row text-center">
    <span class="place-number"  style="position: absolute; top: 13px; left: 28px;"

    [ngStyle]="{'left' : (tishPlaceForm.controls.placeNumber.value % 2) == 0 ? '-3px' : '26px', 'transform': (tishPlaceForm.controls.placeNumber.value % 2) == 0 ? 'rotate(300rad)' : 'rotate(-300rad)'}">
        <b>[{{tishPlace.placeNumber}}]</b>
    </span>
    <div class="innerContent ui-widget-header"  [ngStyle]="{'box-shadow': (tishPlaceForm.controls.placeNumber.value == searchTishPlaceNumber ? 'rgb(53 35 200) 1px 0px 0px 3px' : '')}" #placeForinvited  [ngStyle]="{'float': (tishPlaceForm.controls.placeNumber.value % 2) == 0 ? 'right' : 'left'}" >
        <tishInvited-map (dropedInvited)="onDropedElementFn($event)"  (dragInvited)="onDragInvited($event)" [transform]="(tishPlaceForm.controls.placeNumber.value % 2) == 0 ? 'rotate(300rad)' : 'rotate(-300rad)'" [tishPlaceForm]="tishPlaceForm" [tishInvitedForm]="tishPlaceForm.controls.tishInvited"></tishInvited-map>
    </div>
</div>   -->


<div [formGroup]="tishPlaceForm" *ngIf="tishPlace" [ngClass]="{'chair-left': (tishPlaceForm.controls.placeNumber.value % 2) != 0, 'chair-right': (tishPlaceForm.controls.placeNumber.value % 2) == 0}">
    <div class="innerContent ui-widget-header ui-droppable chair-outher"  [ngStyle]="{'box-shadow': (tishPlaceForm.controls.placeNumber.value == searchTishPlaceNumber ? 'rgb(53 35 200) 1px 0px 0px 3px' : ''), 'border-bottom-color': tishPlaceForm.controls.areaColor.value, 'border-bottom-width': '3px'}" #placeForinvited >

      <tishInvited-map *ngIf="rerender == false" #invitedMap (dropedInvited)="onDropedElementFn($event)"  (dragInvited)="onDragInvited($event)"  [tishPlaceForm]="tishPlaceForm" [tishInvitedForm]="tishPlaceForm.controls.tishInvited"></tishInvited-map>
              <!-- <tishInvited-map (dropedInvited)="onDropedElementFn($event)"  (dragInvited)="onDragInvited($event)" [transform]="(tishPlaceForm.controls.placeNumber.value % 2) == 0 ? 'rotate(300rad)' : 'rotate(-300rad)'" [tishPlaceForm]="tishPlaceForm" [tishInvitedForm]="tishPlaceForm.controls.tishInvited"></tishInvited-map>  -->

    </div>
    <div class="chair-mishenet" (click)="editPlace()">
        <span class="chair-number" (contextmenu)="onPlaceNumberRightClick($event)"> {{ tishPlace.placeNumber }} </span>
    </div>
</div>
<!-- border-bottom-color: red !IMPORTANT;
    border-bottom-width: 2px !IMPORTANT; -->
