import { Alert, AlertService } from './alert.svc';
import { Component, OnInit, Input } from '@angular/core';

@Component({

    selector: 'alert-item',
    templateUrl: './alert-item.tmpl.html'
})
export class AlertItemComponent implements OnInit {
    constructor(private svc: AlertService) { }

    @Input() public alert: Alert;

    public remove(): void {
        this.svc.removeByAlert(this.alert);
    }
    ngOnInit() { }
}
