import { Contact } from './../../contact/contact';


export class ContactCompensation {
    contactCompensationID: number;
    contactID: number;
    contactName: string;
    compensationDate:Date;
    compensationJewishDate: number;
    description: string;
    subject:string;  //הזדמנות

}

