import { Contact, ContactGroupsEditModel, ContactEventsEditModel } from './contact/contact';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContactReportModel } from './contactReports/contact-report-model';
import { ServicesData } from './../services/services.data';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";

import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class ContactsData {


    public url = 'api/contacts';

    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    activeContactSubjects(contactID: number) {
        return this.toraxHttp.get(`${this.url}/activeContactSubjects/${contactID}`);
    }

    createAndPrintReceiptsForAnyPayments(contactID: number, mergePayments: boolean, paymentsIds: string = "") {
        return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintReceiptsForAnyPayments?contactID=${contactID}&mergePayments=${mergePayments}&paymentsIds=${paymentsIds}`);
    }

    getContactPayments(contactId: number, withoutReceipts: boolean = null) {
        return this.toraxHttp.get(`${this.url}/getContactPayments/${contactId}/${withoutReceipts}`);
    }


    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    // getContactInfo(contactID: number) {
    //     return this.toraxHttp.get(`${this.url}/getContactInfo/${contactID || 0}`);
    // }

    toogleContactFavorite(contactID: number) {
      return this.toraxHttp.get(`${this.url}/toogleContactFavorite/${contactID}`);
  }
    // getContactGroups(contactID: number): any {
    //     return this.toraxHttp.get(`${this.url}/getContactGroups/${contactID}`);
    // }

    // getContactGroup(contactGroupID: number): any {
    //     return this.toraxHttp.get(`${this.url}/getContactGroup/${contactGroupID}`);
    // }

    // getContactGroupsToEdit(contactID: number): any {
    //     return this.toraxHttp.get(`${this.url}/getContactGroupsToEdit?contactID=${contactID}`);
    // }

    // getContactEventsToEdit(contactID: number): any {
    //     return this.toraxHttp.get(`${this.url}/getContactEventsToEdit?contactID=${contactID}`);
    // }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }
    // getDbList() {
    //     return this.toraxHttp.get(`${this.url}/getDbList`);
    // }

    getGroupContacts(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/getGroupContacts`, baseQuery);
    }

    // getGroupInfo(baseQuery: any) {
    //     return this.toraxHttp.post(`${this.url}/getGroupInfo`, baseQuery);
    // }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getByIchud(codeIchud: number) {
        return this.toraxHttp.get(`${this.url}/ichud/${codeIchud || 0}`);
    }

    printNewReceiptsForContact(contactID: number, andCreate: boolean = false) {
        return this.toraxHttp.getWithResponce(`${this.url}/printNewReceiptsForContact?contactID=${contactID}&andCreate=${andCreate}`);
    }

    printSticks(contactIDs, state) {
        return this.toraxHttp.getWithResponce(`${this.url}/printSticks/${contactIDs}/${state}`);
    }

    createAndPrintReceiptsForNonReceiptContact(contactID: number) {
        return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintReceiptsForNonReceiptContact?contactID=${contactID}`);
    }

    printEnvelopeorContact(contactID: number) {
        return this.toraxHttp.getWithResponce(`${this.url}/printEnvelopeorContact?contactID=${contactID}`);
    }

    printAllContactInformation(contactID: number) {
        return this.toraxHttp.getWithResponceToDownload(`${this.url}/printAllContactInformation?contactID=${contactID}`);
        //return this.toraxHttp.getWithResponce(`${this.url}/printAllContactInformation?contactID=${contactID}`);
    }

    getContactShortInformation(contactReportModel: ContactReportModel) {
        return this.toraxHttp.postAndDownload(`${this.url}/getContactShortInformation`, contactReportModel);
        //return this.toraxHttp.getWithResponce(`${this.url}/printAllContactInformation?contactID=${contactID}`);
    }

    getContactReport(contactID, groupSubjects) {
        return this.toraxHttp.get(`${this.url}/getContactReport/${contactID}${(groupSubjects ? `/${groupSubjects}` : '')}`);
       }


    getContactName(id: number) {
        return this.toraxHttp.get(`${this.url}/contactName/${id || 0}`);
    }

    getContactCommitments(id: number) {
        return this.toraxHttp.get(`${this.url}/contactCommitments/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd() {
        return this.toraxHttp.get(`${this.url}/add`);
    }

    update(contact: Contact) {
        return this.toraxHttp.update(this.url, contact);
    }

    updateContactGroups(contactGroupsEditModel: any) {
        return this.toraxHttp.update(`${this.url}/updateContactGroups`, contactGroupsEditModel);
    }

    updateContactEvents(contactEventsEditModel: any) {
        return this.toraxHttp.update(`${this.url}/updateContactEvents`, contactEventsEditModel);
    }



    saveContactGroupComment(contactGroupID: number, comment: string, priority: number): any {
        return this.toraxHttp.postWithResponce(`${this.url}/saveContactGroupComment?contactGroupID=${contactGroupID}&comment=${comment}&priority=${priority}`, null);
    }
    saveContactEventDescription(contactEventID: number, description: string): any {
        return this.toraxHttp.postWithResponce(`${this.url}/saveContactEventDescription?contactEventID=${contactEventID}&description=${description}`, null);
    }




    setDefaultAddressID(contactID: number, addressID: number) {
        return this.toraxHttp.postWithResponce(`${this.url}/setDefaultAddressID?ContactID=${contactID}&AddressID=${addressID}`, null);
    }

    removeDefaultAddressID(contactID: number) {
        return this.toraxHttp.postWithResponce(`${this.url}/removeDefaultAddressID?ContactID=${contactID}`, null);
    }



    setDefaultEmailID(contactID: number, emailID: number) {
        return this.toraxHttp.postWithResponce(`${this.url}/setDefaultEmailID?ContactID=${contactID}&EmailID=${emailID}`, null);
    }

    removeDefaultEmailID(contactID: number) {
        return this.toraxHttp.postWithResponce(`${this.url}/removeDefaultEmailID?ContactID=${contactID}`, null);
    }



    recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }
    public updatedEvent = new EventEmitter<any>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit({id:id,type:"Contacts"});
        }
    }
}
