import { PayMethodMain } from './payMethod/payMethod-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';

import { PayMethodEdit } from './payMethod/payMethod-edit.cmp';
import { PayMethodsList } from './payMethods-list.cmp';
import { MaterialModule } from './../material.module';
import { PayMethodAddAction } from './payMethod/payMethod-add-action.cmp';
import { AuthorizeTransactionsMdl } from './../authorizeTransactions/authorizeTransactions.mdl';
import { DashboardPanelBoxModule } from './../components/dashboard-panel-box/dashboard-panel-box.mdl';
import { PayMethodsRoutes } from './payMethods.routing';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TransactionsMdl } from './../transactions/transactions.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { PayMethodsData } from './payMethods.data';
import { PayMethodEditWin } from './payMethod/payMethod-edit-win.cmp';
import { PayMethodDetails } from './payMethod/payMethod-details.cmp';
import { PayMethodEditDlg } from './payMethod/payMethod-edit-dlg.cmp';
import { PayMethodsSelectPick } from './payMethods-select-pick.cmp';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { PeleCardTransactionsMdl } from '../pele-card-transactions/pele-card-transactions.mdl';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild(PayMethodsRoutes),
		PanelBoxModule,
		DashboardPanelBoxModule,
		PaginationModule,
		ReactiveFormsModule,
		PageHeadingModule,
		AutoCompleteModule,
		FooterEditButtonsMdl,
		MaterialModule,
		MainFormModalModule,
		TransactionsMdl,
		AuthorizeTransactionsMdl,
    PeleCardTransactionsMdl,
		CustomToolTipModule

	],
	exports: [
		PayMethodMain,
		PayMethodsList,
		PayMethodEdit,
		PayMethodEditDlg,
		PayMethodAddAction,
		PayMethodsSelectPick

	],
	declarations: [
		PayMethodMain,
		PayMethodsList,
		PayMethodEdit,
		PayMethodDetails,
		PayMethodEdit,
		PayMethodEditWin,
		PayMethodEditDlg,
		PayMethodAddAction,
		PayMethodsSelectPick
	],
	entryComponents: [
		PayMethodEditDlg,
		PayMethodAddAction,
		PayMethodsSelectPick
	],
	providers: [
		PayMethodsData
	]
})
export class PayMethodsMdl { }

