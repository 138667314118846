import { FormControl } from '@angular/forms';
import { Component, Input, AfterViewInit } from '@angular/core';

@Component({ 
    selector: 'input-info',
    templateUrl: 'input-info.component.html',
    styleUrls: ['input-info.component.scss']
})
export class InputInfoComponent  {
    @Input('control') public control : FormControl;
    @Input('alwaysShow') public alwaysShow : boolean = false;


    
     
}
