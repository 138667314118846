import { HonorMain } from './honor/honor-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { HonorsList } from './honors-list.cmp';

export const HonorsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'honors', component: HonorsList, data: {  roles: "honors",  title: 'רשימת כיבודים' } },
    { canActivate: [AuthGuard], path: 'honors/add', component: HonorMain, data: { mode: 'edit', roles: "honors", title: 'הוסף כיבוד' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'honors/:honorID', component: HonorMain, data: {  roles: "honors",  title: 'פרטי כיבוד' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'honors/:honorID/edit', component: HonorMain, data: { mode: 'edit', roles: "honors",  title: 'עריכת פרטי כיבוד' }, outlet: 'popup' }
];
