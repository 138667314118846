import { ServicesData } from './../../services/services.data';
import { PageHeadingService } from './../../components/page-heading/page-heading.svc';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';

import { IvrDonationDetailsData } from './../ivrDonationDetails.data';
import { IvrDonationDetail } from './ivrDonationDetail';


@Component({

  selector: 'ivrDonationDetail-edit',
  templateUrl: './ivrDonationDetail-edit.tmpl.html'
})


export class IvrDonationDetailEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private ivrDonationDetailsData: IvrDonationDetailsData,
    private formBuilder: FormBuilder,
    private location: Location,
    public servicesData: ServicesData
  ) {

  }

  isAronit: boolean = false;;
  ivrDonationDetail: IvrDonationDetail;
  id: number;
  contactId: number;
  payMethods: any;
  payOption = '';
  ivrDonationDetailForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.ivrDonationDetailsData.getEdit(this.id)
        .subscribe((p: any) => {
          this.ivrDonationDetail = p;
          this.loadEventType(this.ivrDonationDetail.ivrDonationDetailEventID)
          this.fillForm(p);
        });
    }
    else {
      this.ivrDonationDetailsData.getAdd(this.contactId)
        .subscribe((p: any) => {
          this.ivrDonationDetail = p;
          this.fillForm(p);
        });
    }
  }

  private fillForm(p: IvrDonationDetail): void {
    this.ivrDonationDetailForm.patchValue(p);
    this.getPayMethods(); //הועבר לכאן כדי שיטען לאחר טעינת ה-contactID
  }

  public save(): void {
    console.log('save run');
    if (this.ivrDonationDetailForm.valid) {
      this.ivrDonationDetailsData.update(this.ivrDonationDetailForm.value)
        .subscribe((c: any) => {
          this.location.back();
        },
          error => { });
    } else {
      console.log('not valid');
      console.log(this.ivrDonationDetailForm.errors);
    }
  }


  public cancel(): void {
    this.location.back();
  }


  payMethodsAddEvent(payMethod) {
    this.ivrDonationDetailForm.controls['commitmentPayMethodID'].setValue(payMethod.id);
    this.setPayOption(payMethod.id, payMethod.type);
  }
  payMethodsChangeEvent(payMethod) {
    this.ivrDonationDetailForm.controls['commitmentPayMethodID'].setValue(payMethod.id);
    this.setPayOption(payMethod.id, payMethod.type);
  }


  loadEventType(e) {
    this.ivrDonationDetailsData.getEventType(e).subscribe(x => {
      if (x == 4) {
        this.isAronit = true;
      } else {
        this.isAronit = false;
        this.ivrDonationDetailForm.controls['aronitNumber'].setValue(null);
      }
    }
    );
  }

  setPayOption(payMethodID: number, payMethodOption: string = null) {
    if ([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(payMethodID) == -1) {
      switch (payMethodOption) {
        case 'אשראי':
          this.payOption = 'cc';
          break;
        case 'הוראת קבע':
          this.payOption = 'hok';
          break;
        default:
          break;
      }
    }
    else if (payMethodID == 88) {
      this.payOption = 'Cash';
    }
    else if (payMethodID == 89) {
      this.payOption = 'Check';
    }
    else if (payMethodID == 420) {
      this.payOption = 'BankTransfer';
    }
    else if (payMethodID == 1310) {
      this.payOption = 'cashRegister';
    }
    else if (payMethodID == 3580) {
      this.payOption = 'voucher';
    }
    else if (payMethodID == 6232) {
      this.payOption = 'externalSoftware';
    }
    else if (payMethodID == 6990) {
      this.payOption = 'moneyValue';
    }
    else if (!payMethodID || payMethodID == 0) {
      this.payOption = '';
    }
  }






  private initForm() {
    this.ivrDonationDetailForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      ivrDonationDetailID: [this.id, Validators.required],
      contactID: [this.contactId, Validators.required],
      contactName: [],
      ivrDonationDetailEventID: [null, Validators.required],
      ivrDonationDetailEventTitle: [],
      quantity: [, Validators.required],
      payMethodID: [null],
      registeringPhone: [],
      callerNumber: [],
      aronitNumber: []

    });

  }



  public getPayMethods(): void {
    let cid = this.contactId > 0 ? this.contactId : this.ivrDonationDetail.contactID;
    let pmID = this.ivrDonationDetailForm.controls['payMethodID'].value;
    this.servicesData.getList(`PayMethods/${cid}/${false}/${pmID}`).subscribe(
      c => this.payMethods = c,
      error => { });
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['ivrDonationDetailID'] || 0;
      this.contactId = +params['contactID'] || 0;
    });

    console.log(this.activatedRoute.params);
    this.initForm();
    this.load();
  }
}



