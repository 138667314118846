<form [formGroup]="ivrDonationDetailForm" novalidate (ngSubmit)="save()" *ngIf="ivrDonationDetail">
    <div class="modal-header">
        <h1>עריכת פרטי תרומה [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
      עדיין לא פעיל!!
        <!-- <div *ngIf="ivrDonationDetail" class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי תרומה</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div style="width: 250px !important;" class="input-group mb-3">
                            <label>שם איש:</label>
                            <h3> {{ ivrDonationDetail.contactName }}</h3>
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <auto-complete apply-enter  (onSelect)="loadEventType($event)" [controllerName]="'IvrDonationDetailEvents'" [placeholder]="'אירוע'"
                            [formGroupModel]="ivrDonationDetailForm"
                            [idControl]="ivrDonationDetailForm.controls.ivrDonationDetailEventID"
                            [nameControl]="ivrDonationDetailForm.controls.ivrDonationDetailEventTitle">
                        </auto-complete>
                    </div>
                    <div *ngIf="isAronit == true" class="form-row" fxLayout="row" fxLayout.xs="column">
                      <div style="width: 250px !important;" class="input-group mb-3">
                          <label>מס' ארונית:</label>
                          <input type="text" class="form-control" formControlName="aronitNumber" />
                      </div>
                  </div>
                  <div class="form-row" fxLayout="row" fxLayout.xs="column">
                    <div style="width: 250px !important;" class="input-group mb-3">
                        <label>מס' מקומות:</label>
                        <input type="text" class="form-control" formControlName="quantity" />
                    </div>
                </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <payMethods-select-pick fxLayout="row"
                            [ngClass]="{'has-error': ivrDonationDetailForm.controls.payMethodID.invalid }"
                            (payMethodAdd)="payMethodsAddEvent($event)"
                            (payMethodChange)="payMethodsChangeEvent($event)" [contactID]="ivrDonationDetail.contactID"
                            [placeHolder]="'אמצעי תשלום'" [payMethodIdControl]="ivrDonationDetailForm.controls.payMethodID"
                            [fromRecordType]="'IvrDonationDetail'"  [fromRecordID]="ivrDonationDetailForm.controls.ivrDonationDetailID.value"
                            >
                        </payMethods-select-pick>
                    </div>
                    <input-info class="form-row" fxLayout="row" [alwaysShow]="true" [control]="ivrDonationDetailForm.controls.payMethodID">
                        אמצעי תשלום בתרומה *לא מחייב*
                    </input-info>
                </fieldset>
            </div>
        </div> -->
    </div>
    <!-- <footer-edit-buttons [dataProvider]="ivrDonationDetailsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="ivrDonationDetailForm"></footer-edit-buttons> -->

</form>
