export class User {
    id: number;
    userName: string;
    // password: string;
    firstName: string;
    lastName: string;
    // returnUrl:string;
    roles: string[];
    // token?: string;
}


export class LoginViewModel {
    constructor(
        public userName: string,
        public password: string,
        public returnUrl: string,
    ) {

    }
}

