import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertCenterService, AlertCenter } from './alert-center.svc';

@Component({

    selector: 'alert-center-global',
    templateUrl: 'alert-center.tmpl.html',
    styleUrls: ['./alert-center.css'],
    encapsulation: ViewEncapsulation.None

})
export class AlertCenterComponent implements OnInit {
    constructor(private svc: AlertCenterService) {
        this.alertCenterList = this.svc.alertCenterList;
    }

    public alertCenterList: AlertCenter[] = []
    ngOnInit() { }
}
