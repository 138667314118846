import { DonationCategory } from './donationCategory/donationCategory';
import { ToraxHttp } from '../utils/custom-http.svc';
import { FormGroup } from '@angular/forms';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";
import { ObjectsListResult, ObjectListItem } from '../services/objects-list-results';
import { AlertService } from '../components/alert/alert.svc';

@Injectable()

export class DonationCategoriesData {




  private url = 'api/donationCategories';

  constructor(
    private toraxHttp: ToraxHttp
  ) { }



  getAdd() {
    return this.toraxHttp.get(`${this.url}/add`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  downloadExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
  }

  downloadHeavyExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadHeavyExls`, baseQuery);
  }

  update(donationCategory: DonationCategory) {
    return this.toraxHttp.update(this.url, donationCategory);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}

