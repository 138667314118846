import { MaterialModule } from './../material.module';

import { TransactionsRoutes } from './transactions.routing';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { HoldingsMdl } from './../holdings/holdings.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { TransactionsData } from './transactions.data';
import { TransactionsList } from './transactions-list.cmp';
import { TransactionsLastStatusses } from './transactions-last-statusses.cmp';
//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeadingModule,
    PanelBoxModule,
    PaginationModule,
    DateInputModule,
    RouterModule.forChild(TransactionsRoutes),
    AutoCompleteModule,
    MaterialModule,
   // PerfectScrollbarModule
  ],
  declarations: [
    TransactionsList,
    TransactionsLastStatusses
  ],
  exports: [
    TransactionsList,
    TransactionsLastStatusses
  ],
  providers: [
    TransactionsData,

  ]
})
export class TransactionsMdl { }

