<form  *ngIf="address" class="example-form" [formGroup]="addressForm" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} כתובת</small>
            <br>
            {{ address.contactName }}
        </h2>
    </div>
    <mat-dialog-content style="padding:0px 24px 24px 24px">
        <div fxLayout="column" style="display: block;">
            <div fxLayout="row" class="example-margin">
                <span *ngIf="!address.isActive">
                    נמחק בתאריך {{ address.inactiveJewishDate }} ({{address.inactiveDateStr}})
                    <a (click)="clearInactiveDate()">לחץ כדי לשחזר</a> לאחר מכן שמור שינויים
                </span>
                <mat-icon style="float: left; color: rgb(255, 94, 0)" *ngIf="!address.isActive">cancel</mat-icon>
            </div>

            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <auto-complete apply-enter (onSelect)="countryIDItemSelect($event)" [placeholder]="'ארץ'"
                    [controllerName]="'Countries'" [formGroupModel]="addressForm"
                    [idControl]="addressForm.controls.countryID" [nameControl]="addressForm.controls.countryName">
                </auto-complete>&nbsp;
                <auto-complete #cityCmp apply-enter [firstFocus]="true" (onSelect)="cityIDItemSelect($event)"
                    [placeholder]="'עיר, מחוז'" [controllerName]="'Cities'" [formGroupModel]="addressForm"
                    [idControl]="addressForm.controls.cityID" [nameControl]="addressForm.controls.cityTitle">
                </auto-complete>
            </div>

            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <auto-complete-input #streetInput apply-enter [placeholder]="'רחוב'" [controllerName]="'Streets'"
                    [stringValueControl]="addressForm.controls.street" [formGroupModel]="addressForm">
                </auto-complete-input>
                &nbsp;
                <div class="input-group mb-3">
                    <label>בית:</label>
                    <input class="form-control narrow" apply-enter formControlName="houseNum" placeholder="בית..." />
                </div>&nbsp;
                <div class="input-group mb-3">
                    <label>דירה:</label>
                    <input class="form-control narrow" apply-enter formControlName="aptNum" placeholder="דירה..." />
                </div>

            </div>

            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                    <label>כניסה:</label>
                    <input class="form-control narrow" apply-enter formControlName="entry" placeholder="כניסה..." />
                </div>&nbsp;
                <div class="input-group mb-3">
                    <label>קומה:</label>
                    <input class="form-control narrow" apply-enter formControlName="floor" placeholder="קומה..." />
                </div>&nbsp;
                <div class="input-group mb-3">
                    <label>מיקוד:</label>
                    <input class="form-control narrow" apply-enter formControlName="zip" placeholder="מיקוד..." />
                </div>&nbsp;
                <div class="input-group mb-3">
                    <label>ת.ד.:</label>
                    <input class="form-control  narrow" apply-enter formControlName="pob" placeholder="ת.ד...." />
                </div>&nbsp;

            </div>

            <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div  class="input-group mb-3" style="width:140px;">
                    <label>מיקום כתובת:</label>
                    <select class="form-control" apply-enter placeholder="מיקום כתובת..." formControlName="locationType">
                        <option *ngFor="let lt of servicesData.LocationTypes" [value]="lt.value">
                            {{lt.name}}
                        </option>
                    </select>
                </div>&nbsp;
                <div class="input-group mb-3">
                    <label>שם נמען:</label>
                    <input class="form-control" apply-enter formControlName="recipientName" placeholder="שם נמען..." />
                </div>
            </div>
            <mat-checkbox apply-enter fxLayout="row" formControlName="forPost">
                כתובת למשלוח דואר
            </mat-checkbox>

            <mat-checkbox apply-enter fxLayout="row" formControlName="confirmed">
                כתובת אושרה (לא תיבדק בכתובות גוגל)
            </mat-checkbox>
            <div class="input-group mb-3">
                <label>הערה:</label>
                <textarea class="form-control comment" apply-enter rows="2" cols="4" placeholder="הערה..."
                    formControlName="comment"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button apply-enter  [disabled]="addressForm.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="addressForm"><i
                class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="address.addressID > 0 && address.isActive"
            [mat-dialog-close]="addressForm" (click)="setInactiveDate()"><i class="fa fa-warning"></i> מחק
        </button>
        <!-- <button mat-button mat-dialog-close [mat-dialog-close]="addressForm" class="btn btn-danger navbar-btn"><i
                class="fa fa-trash-o"></i> מחק לנצח
        </button> -->
    </mat-dialog-actions>

</form>