import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { InputInfoComponent } from './input-info.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule
    ],
    declarations: [
        InputInfoComponent,
    ],
    exports: [
        InputInfoComponent,
    ]
})
export class InputInfoModule {

}
