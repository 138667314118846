import { LetterSettingMain } from './letter-setting/letter-setting-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { LetterSettingEdit } from './letter-setting/letter-setting-edit.cmp';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { LetterSettingsRoutes } from './letter-settings.routing';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { LetterSettingsData } from './letter-settings.data';
import { LetterSettingsList } from './letter-settings-list.cmp'; 
import { LetterSettingDetails  } from './letter-setting/letter-setting-details.cmp';
import { ColorPickerModule } from 'ngx-color-picker'; 

@NgModule({
	imports: [
		CommonModule,		
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		RouterModule.forChild(LetterSettingsRoutes),
		FooterEditButtonsMdl,
		ColorPickerModule,
		MatFormFieldModule,
		MatSelectModule,
		MatOptionModule,
MainFormModalModule
	],
	declarations: [
		LetterSettingsList,
		LetterSettingEdit,
		LetterSettingDetails,
		LetterSettingMain
	],
	exports: [
		LetterSettingsList,
		LetterSettingEdit,
		LetterSettingDetails,
		LetterSettingMain
	],
	providers: [
		LetterSettingsData
	]
})
export class LetterSettingsMdl { }

