 
import { ServicesData } from './../../services/services.data';
import { VouchersOrgPaymentPacksData } from './../vouchersOrgPaymentPacks.data';
import { Payment } from './../../payments/payment/payment';
import { MatList } from '@angular/material/list'; 
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 
import { VouchersOrgPaymentPack } from './vouchersOrgPaymentPack'; 
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';

@Component({     
      
    selector: 'vouchersOrgPaymentPack-edit',
    templateUrl: './vouchersOrgPaymentPack-edit.tmpl.html',
    styleUrls: ['./../vouchersOrgPaymentPack.scss']

})


export class VouchersOrgPaymentPackEdit implements OnInit {

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private vouchersOrgPaymentPacksData: VouchersOrgPaymentPacksData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData,
    ) {

    }

    @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;

    id: number;
    vouchersOrganizationID: number;
    vouchersOrgPaymentPackForm: FormGroup;
    vouchersOrgPaymentPack: VouchersOrgPaymentPack;
    vouchersPayments: Payment[];

    // addPaymentControl(payment?: Payment) {
    //     //let vouchersOrgPaymentPackID = id || 0;
    //     const paymentsControl = <FormArray>this.vouchersOrgPaymentPackForm.controls['voucherPaymentsVM'];
    //     let paymentFormGroup = this.initPayment(payment);
    //     paymentsControl.push(paymentFormGroup);
    // }

    // initPayment(payment?: Payment): FormGroup {
    //     //let vouchersOrgPaymentPackID = this.id || 0;
    //     let paymentFormGroup = this.formBuilder.group({
    //         //אין צורך להתייחס כאן לכל הפרטים אלא רק ל-PaymentID
    //         //ול- vouchersOrgPaymentPackID
    //         // createBy: [], createDate: [], changeBy: [], changeDate: [],
    //         paymentID: [0, Validators.required],
    //         vouchersOrgPaymentPackID: [null],
    //         amount: [],
    //         voucherNumber: [],
    //         currency: [],
    //         payMethodTitle: [],
    //         contactName: []

    //     });

    //     if (payment != null) {
    //         paymentFormGroup.patchValue(payment);
    //     }

    //     return paymentFormGroup;
    // }

    // initExistingPayment(p: any) {
    //     let vouchersOrgPaymentPackID = this.id || 0;
    //     let paymentFormGroup = this.formBuilder.group({
    //         paymentID: [0, Validators.required],
    //         vouchersOrgPaymentPackID: [vouchersOrgPaymentPackID || 0, Validators.required]
    //     });
    //     return paymentFormGroup;
    // }

    // removePayment(i: number) {
    //     const paymentsControl = <FormArray>this.vouchersOrgPaymentPackForm.controls['voucherPaymentsVM'];
    //     paymentsControl.removeAt(i);
    // }

    public itemSelect(id: number) {
        this.bankBranchesCmp.sourceList = `BankBranches/${id}`;
    }

    public load(): void {
        if (this.id > 0) {
            this.vouchersOrgPaymentPacksData.getEdit(this.id)
                .subscribe((vo:any) => {
                    this.vouchersOrgPaymentPack = vo;
                    this.fillForm(vo);
                });
        } else {
            this.vouchersOrgPaymentPacksData.getAdd(this.vouchersOrganizationID)
                .subscribe((vo:any) => {
                    this.vouchersOrgPaymentPack = vo;
                    this.fillForm(vo);
                });
        }
    }

    private fillForm(vo: VouchersOrgPaymentPack): void {
        // let vouchersOrgPaymentPackID = this.id || 0;
        this.vouchersOrgPaymentPackForm.patchValue(vo);
        // vo.voucherPaymentsVM.forEach(p => {
        //     // p.vouchersOrgPaymentPackID = this.id;
        //     this.addPaymentControl(p);
        // });
    }

    private initForm() {
        this.vouchersOrgPaymentPackForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            vouchersOrgPaymentPackID: [this.id, Validators.required],
            vouchersOrganizationID: [this.vouchersOrganizationID, Validators.required],
            organizationName: [],
            amount: [, Validators.required],
            currency: [, Validators.required],
            paymentOption: [, Validators.required],
            bankNumber: [],
            bankName: [],
            bankBranchNumber: [],
            bankBranchName: [],
            bankAcountNumber: [],
            chicNumber: [],
            nameOfChic: [],
            bankAcountOwner: [],
            receiptID: [],
            receiptNumber: [],
            usaReceiptID: [],
            usaReceiptNumber: [],
            ukReceiptID: [],
            ukReceiptNumber: [],
            ichudIlReceiptID: [],
            ichudIlReceiptNumber: [], 
            accountLocation: [null, Validators.required],
            transferReference: [],
            comment: [],
            statusPayment: [],
            billingDate: [],
            VoucherPaymentIDs: [],
            // isChecked: [false, Validators.required], //רק לצורך טיפול בשמירת תשלומים לוורצ'רס
        });
        //  this.vouchersOrgPaymentPackForm.controls.Payments.valueChanges.forEach(x => alert(x));
    }
    @ViewChild('selectionList') private selectionList: MatList;


    selectAll() {
        (<any>this.selectionList).options.forEach(element => {
            element.selected = true;
        });
    }
    unSelectAll() {
        (<any>this.selectionList).options.forEach(element => {
            element.selected = false;
        });
    }
    refresh() {
        this.load();
    }

    

    selectionChange(e) {
        this.vouchersOrgPaymentPackForm.controls['roles'].setValue(e.source.options);
    }
    // loadRlevantePayments(): any {
    //     this.vouchersOrgPaymentPacksData.loadRelevantPayments(this.vouchersOrganizationID)
    //         .subscribe((pmnts: any) => {
    //             this.vouchersPayments = pmnts;
    //         });
    // }

    addprop(payment) {
        if (payment.controls["vouchersOrgPaymentPackID"].value == null) {
            payment.controls["vouchersOrgPaymentPackID"].setValue(this.id);
        } else {
            payment.controls["vouchersOrgPaymentPackID"].setValue(null);
        }
    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['vouchersOrgPaymentPack'] || 0;
            this.vouchersOrganizationID = +params['vouchersOrganizationID'];
        });
        this.initForm();
        this.load();
        // this.loadRlevantePayments();
    }
}





