import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';


@Injectable()

export class TasksData {
    public url = 'api/tasks';

    constructor(private toraxHttp: ToraxHttp)
    { }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }



    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }



    printReceiptHtml(taskID: number) {
        return this.toraxHttp.get(`${this.url}/printReceiptHtml?taskID=${taskID}`);
    }

    public downloadReceipt(taskID: number) {
        return this.toraxHttp.get(`${this.url}/printReceipt?taskID=${taskID || 0}`);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();



}
