
<main-form-modal (toggleChange)="setVal($event)">
  <ng-template #customHtmlElements>
      <div [ngSwitch]="editMode">

          <honor-edit *ngSwitchCase="true"></honor-edit>
          <honor-details *ngSwitchCase="false"></honor-details>
      </div>
  </ng-template>
</main-form-modal>
