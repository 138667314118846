<form *ngIf="data && formData" class="example-form" [formGroup]="formData" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            {{ ( data.tishTableID > 0 ? 'עריכת פרטי' : 'הוספת') }} שולחן
            <br>
            <small>אירוע: <b>{{ data.tishEventTitle }}</b></small> </h2>
    </div>
    <mat-dialog-content>
        <div fxLayout="column" style="display: block;">

            <div [formGroup]="formData" *ngIf="data" class="panel-content">
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                    <div class="input-group mb-3">
                        <label>שם שולחן:</label>
                        <input type="text" class="form-control" formControlName="tishTableName" />
                    </div>
                    <div class="input-group mb-3">
                        <label for="countPlaces">מס' מקומות:</label>
                        <input type="number" class="form-control" formControlName="countPlaces" />
                    </div>
                    <mat-checkbox apply-enter fxLayout="row" formControlName="narrowTable">
                        שולחן צר
                    </mat-checkbox>
                    <auto-complete apply-enter [placeholder]="'קבוצה'" [controllerName]="'TishGroups/' + tishEventID"
                        [formGroupModel]="formData" [idControl]="formData.controls.tishGroupID"
                        [nameControl]="formData.controls.tishGroupName"></auto-complete>
                    <auto-complete apply-enter [placeholder]="'איזור'" [controllerName]="'TishAreas/' + tishEventID"
                        [formGroupModel]="formData" [idControl]="formData.controls.tishAreaID"
                        [nameControl]="formData.controls.tishAreaName"></auto-complete>

                    <div class="input-group mb-3">
                        <label for="comment">הערה:</label>
                        <textarea class="form-control comment" apply-enter rows="1" style="width: 100%;"
                            placeholder="הערה..." formControlName="comment"></textarea>
                    </div>
                </div>

            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="float: left; padding: 20px;">
      <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="formData"><i class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="data.tishTableID > 0" mat-dialog-close><i
                class="fa fa-warning"></i> ביטול
        </button>

    </mat-dialog-actions>

</form>
