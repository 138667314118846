import { ServicesData } from '../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HonorsData } from '../honors.data';
import { Honor } from './honor';

@Component({
    selector: 'honor-edit',
    templateUrl: './honor-edit.tmpl.html'
})

export class HonorEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private honorsData: HonorsData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,

    ) {
    }

    honor: Honor;
    id: number;
    contactId: number;
    honorForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.honorsData.getEdit(this.id || 0)
                .subscribe((c:any)  => {
                    this.honor = c;
                    this.honorForm.patchValue(c);
                });
        } else {
            this.honorsData.getAdd(this.contactId)
                .subscribe((c:any)  => {
                    this.honor = c;
                    this.honorForm.patchValue(c);
                });
        }
    }


    payMethods;
    public getPayMethods(): void {
        this.servicesData.getList(`PayMethods/${this.contactId}/${false}`).subscribe(
            c => this.payMethods = c,
            error => { });
    }

    private initForm() {
        this.honorForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            honorID: [this.id || 0, Validators.required],
            honorsSetID: [],
            honorsSetTitle:[],
            contactID: [this.contactId, Validators.required],
            receivingContactID: [],
            receivingContactName: [],
            receivingRelationType: [],
            honorTypeID: [, Validators.required],
            honorTypeTitle: [],
            honorDate: [new Date().toJSON(), Validators.required],
            contributionAmount: [],
            currency: [],
            //payMethodID: [],
            doOpenCommitment: [false],
            commitmentID: [],
            doCreateLetter: [false],
            contactLetterID: [],
            reasonComment: []
        });
        this.honorForm.setValidators(this.receivingContactValidation);
    }

    public receivingContactValidation = c => {
        if (this.honorForm.valid) {
            let receivingContactID = this.honorForm.controls['receivingContactID'].value;
            let receivingRelationType = this.honorForm.controls['receivingRelationType'].value;
            let isValid = (receivingContactID == null && receivingRelationType == null) || (receivingContactID != null && receivingRelationType > 0);
            if (!isValid) {
                return { required: true };
            }
            return null;
        }
        return { required: true };
    };


    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['honorID'];
            this.contactId = +params['contactID'];
        });
        this.initForm();
        this.load();
        this.getPayMethods();
    }
}



