<form  [formGroup]="searchAuthorizeTransactionsListForm">
    <div *ngIf="!payMethodID && !collectionBuilderID" class="ibox-content m-b-sm border-bottom">
        <div class="row" style="padding: 10px 15px;">
            <div class="row">
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{panelOpenState ?  'לחץ לסגירת החיפוש...': 'לחץ להרחבת החיפוש...'}}
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="col-sm-12">

                            <mat-form-field>
                                <mat-select apply-enter (selectionChange)="selectionChanged($event)"
                                    placeholder="סטטוס גביה"
                                    [formControl]="searchAuthorizeTransactionsListForm.controls.statusResponses"
                                    multiple>
                                    <mat-option *ngFor="let sp of servicesData.StatusResponses" [value]="sp.value">
                                        {{sp.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div style="text-align:left" class="example-button-row">

                                <button mat-mini-fab color="primary" title="סנן" (click)="getAuthorizeTransactions()">
                                    <i class="fa fa-filter"></i></button>
                                &nbsp;&nbsp;<button mat-mini-fab color="primary" title="נקה סינונים"
                                    (click)="clerFilter()"><i class="material-icons">clear</i></button>

                            </div>
                        </div>
                    </mat-expansion-panel>

                </mat-accordion>

            </div>
        </div>
    </div>
    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">
                    <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="openDetails(row);" aria-label="הצג עוד...">
                                <mat-icon style="color: gray;" >remove_red_eye</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="toraxTermID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מסוף
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מסוף" fxHide fxShow.gt-xs>
                            {{ row.toraxTermID }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="transId">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>
                            {{ row.transId }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="transactionID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה
                            מקומי
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מזהה מקומי" fxHide fxShow.gt-xs>
                            {{ row.transactionID }} </mat-cell>
                    </ng-container>



                    <ng-container matColumnDef="settleAmount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> סכום
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סכום" fxHide fxShow.gt-xs>
                            {{ row.settleAmount }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="submitTimeLocal">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> תאריך
                            ושעת שליחה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תאריך ושעת שליחה" fxHide fxShow.gt-xs>
                            {{ row.submitTimeLocal | date: 'hh:mm dd-MM-yyyy'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="transactionStatus">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> סטטוס
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סטטוס" fxHide fxShow.gt-xs>
                            {{row.transactionStatus}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="accountNumber">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מס'
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מס'" fxHide fxShow.gt-xs>
                            {{ row.accountNumber }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="accountType">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> סוג
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סוג" fxHide fxShow.gt-xs>
                            {{ row.accountType }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="hasReturnedItemsSpecified">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> הוחזר
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="הוחזר" fxHide fxShow.gt-xs>
                            {{ row.hasReturnedItemsSpecified }} </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>
            </div>
        </div>
        <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
            [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
    </div>
</form>