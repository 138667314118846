import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { LetterSettingsData } from './letter-settings.data';
import { ObjectsListResult } from '../services/objects-list-results';
import { Router } from '@angular/router';

@Component({
    
    selector: 'letter-settings-list',
    templateUrl: './letter-settings-list.tmpl.html'
})

export class LetterSettingsList implements OnInit {
    constructor(
        private letterSettingsData: LetterSettingsData,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        // this.toraxHttp.updatedEvent.subscribe((id) => {
        // 	this.getLetterSettings();
        // });
    }
    public objectsListResult: ObjectsListResult;
    searchLetterSettingsListForm: FormGroup;

    public getLetterSettings(): void {
        this.letterSettingsData.getList(this.searchLetterSettingsListForm.value).subscribe(
            (r:any) => {
                this.objectsListResult = r;
            },
            error => { });
    }

    public changePage(page: number) {
        this.searchLetterSettingsListForm.controls['page'].setValue(page);
        this.search();
    }

    public clerFilter() {
        this.initForm();
        this.search();
    }

    public search() {
        sessionStorage.setItem(this.router.url, JSON.stringify(this.searchLetterSettingsListForm.value));
        this.getLetterSettings();
    }

    private initForm() {
        this.searchLetterSettingsListForm = this.formBuilder.group({
            page: [1],
            pageRows: [20],
            query: [],
            selectedRows:[],
			orders: this.formBuilder.array([])
        });
    }
    ngOnInit(): void {
        this.initForm();
        this.getLetterSettings();
    }

    addOrder(propertyName: string, desc = false) {
        const ordersControl = <FormArray>this.searchLetterSettingsListForm.controls['orders'];

        let ord = this.getOrder(propertyName);
        if (ord != null) {
            ord.controls['value'].setValue(desc);
        } else {
            ord = this.initOrder(propertyName, desc);
            ordersControl.push(ord);
        }
        this.search();
    }

    initOrder(propertyName: string, desc = false) {
        let propName = propertyName;
        let des = desc;
        return this.formBuilder.group({
            key: [propName],
            value: [des],
        });
    }

    getOrder(columnName: string): any {
        let lst = <FormArray>this.searchLetterSettingsListForm.controls['orders'];
        // tslint:disable-next-line:curly
        if (lst == null) return null;

        for (let i = 0; i < lst.length; i++) {
            if ((<FormGroup>lst.controls[i]).controls['key'].value === columnName) {
                return lst.controls[i];
            }
        }
        return null;
    }

    orderType(columnName: string) {
        let order = this.getOrder(columnName);
        return order != null ? order.controls['value'].value : null;
    }

}
