import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { TishPlacesData } from './../tishPlaces.data';
import { TishPlace } from './tishPlace';
import { ToraxHttp } from './../../utils/custom-http.svc';

@Component({
	
	selector: 'tishPlace-details',
	templateUrl: './tishPlace-details.tmpl.html'
})

export class TishPlaceDetails implements OnInit {
	constructor(
		private TishPlacesData: TishPlacesData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.tishPlace && id === this.tishPlace.tishPlaceID) {
				this.loadTishPlace();
			}
		});
	}

	public tishPlace: TishPlace;
	private id: number;

	public loadTishPlace(): void {
		this.TishPlacesData.get(this.id)
			.subscribe((tg:any) => this.tishPlace = tg);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['tishPlaceID'];
			this.loadTishPlace();
		});
	}
}

