import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RegistrationMain } from './registration/registration-main.cmp';

import { InputInfoModule } from './../components/input-info/input-info.module';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { MaterialModule } from './../material.module';
import { RegistrationsGroupList } from './registrations-group-list.cmp';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';

import { RegistrationsRoutes } from './registrations.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { RegistrationsData } from './registrations.data';
import { RegistrationEdit } from './registration/registration-edit.cmp';
import { RegistrationsList } from './registrations-list.cmp';
import { RegistrationDetails } from './registration/registration-details.cmp';
//import { UpdateCashRegisterDlg } from './registrations-updateCashRegister-dlg.cmp';
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';
import { AuthGuardMdl } from '../_guards/auth.guard.mdl';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        AutoCompleteModule,
         RouterModule.forChild(RegistrationsRoutes),
        MaterialModule,
        PhonePipeMdl,
        ModelInfoModule,
        InputInfoModule,
        MainFormModalModule,
        PayMethodsMdl,
        AuthGuardMdl
    ],
    declarations: [
        RegistrationEdit,
        RegistrationDetails,
        RegistrationsList,
        RegistrationsGroupList,
      //  UpdateCashRegisterDlg,
        RegistrationMain,


    ],
    exports: [
        RegistrationEdit,
        RegistrationDetails,
        RegistrationsList,
        RegistrationsGroupList,
        RegistrationMain

    ],
    providers: [
        RegistrationsData
    ],
    entryComponents: [
      //  UpdateCashRegisterDlg,

    ]
})
export class RegistrationsMdl { }



