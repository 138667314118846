import { Contact } from './contact/contact';
import { AlertService } from './../components/alert/alert.svc';
import { ContactsSearchInput } from './../components/contacts-search-input/contacts-search-input.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Country } from './../countries/country/country';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ContactsData } from './contacts.data';
import { ObjectsListResult } from '../services/objects-list-results';
import { ServicesData } from '../services/services.data';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav'; 
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';


@Component({
  selector: 'contacts-list-dashboard',
  templateUrl: './contacts-list-dashboard.tmpl.html',
  styleUrls: ['./contacts-list.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactsListDashboard implements OnInit {

  constructor(
    private contactsData: ContactsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private activatedRoute: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private alert: AlertService,
    private router: Router
  ) {
    this.selection = new SelectionModel<any>(true);
  }


  public selectionRow: SelectionModel<any>;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('searchInp') searchInp: ContactsSearchInput;
  @ViewChild('cityID') cityID: AutoCompleteComponent; 
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;
  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
  @Output() selectedRowsChange = new EventEmitter<any>();


  stopLoadingPoints: boolean = false;
  selection = new SelectionModel<number>(true, []);
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  searchContactsListForm: FormGroup;
  sourceList;
  page; //מס' עמוד נוכחי
  pageRows = 100; //שורות לעמוד
  rowsCount; //סך שורות כולל
  pagesCount; //מס' עמודים כולל
  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  pageEvent: PageEvent;
  pageSizeOptions;
  displayedColumns: string[] = ['lastName', 'phoneNumber1'];
  data: any[] = [];
  contactIdWhoIsOpen?: number = null;
  public objectsListResult: ObjectsListResult;
  posModeCommitmentsByGroup;
  contactsCount: number = 0;
  countries: Country[];
  drawerMode: 'side' | 'over';
  searchInputControl: FormControl = new FormControl();
  selectedContact: Contact;
  groupID;
  groupPriorities;
  groupSubjectsAsStr;
  selectedRowIndex: number;

  setContactIdWhoIsOpen(contactID) {
    this.contactIdWhoIsOpen = contactID;
  }


  attachToGroup() {
  }
  currentIndex(index) {
    console.log(index);
    if (index >= this.sourceList.length) {
      this.searchContactsListForm.controls['page'].setValue(this.page + 1);
      this.getContacts();
    } else if(index <= 0 && this.page > 1) {
      this.searchContactsListForm.controls['page'].setValue(this.page - 1);
      this.getContacts();
    }
  }
  
  public getContacts() {
    this.searchContactsListForm.controls['groupID'].setValue(this.groupID);
    this.searchContactsListForm.controls['groupPriorities'].setValue(this.groupPriorities);

    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchContactsListForm.value));
    return this.contactsData!.getGroupContacts(this.searchContactsListForm.value)
      .subscribe((data: any) => {

        this.data = data;
        this.sourceList = data.list;
        this.page = data.page;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;
        this.pagesCount = data.pagesCount;


        this.isLoadingResults = false;
        this.isRateLimitReached = false;

        //trick
        this.stopLoadingPoints = false;
      })
  } 

  pageChange(e) {
    console.log(e);
    this.page = e.pageIndex + 1;
    this.searchContactsListForm.controls['page'].setValue(this.page);
    this.searchContactsListForm.controls['pageRows'].setValue(e.pageSize || 100);
    this.selectionChanged(e);
  }

  public clerFilter() {
    this.initForm();
    this.search();
  }



  searchTxt(txt: string) {
    this.search();
  }

  selectionChanged(e) {
    this.getContacts();
  }

  private initForm() {
    this.searchContactsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [500],
      query: [],
      contactID: [],
      contactName: [],
      cityID: [],
      cityTitle: [],
      shtibelID: [],
      shtibelName: [],
   //   responsibleID: [],
     // responsibleName: [],
      groupID: [this.groupID],
      groupIDs: [],
      groupPriorities: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });
  }



  toogleContactFavorite(row: Contact) {
    this.contactsData.toogleContactFavorite(row.contactID)
      .subscribe((x: boolean) => {
        row.isFavorite = x;
        if (row.isFavorite) {
          this.alert.success(`${row.firstName} ${row.lastName} נוסף בהצלחה למועדפים`);
        } else {
          this.alert.info(`${row.firstName} ${row.lastName} הוסר מרשימת המועדפים`);
        }
      }
      )
  }


  ngOnInit() {
    this.stopLoadingPoints = true;


    this.initForm();
    this.activatedRoute.params.forEach((params: Params) => {
      this.groupID = +params['groupID'];
      this.groupSubjectsAsStr = params['groupSubjectsAsStr'];
    });

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchContactsListForm.patchValue(frm);
    }
    this.getContacts();

  }

  // highlight(row: any) {
  //   this.selectedContact = row;
  //   console.log(this.selectedContact);
  // }


  goToContact(row) {
    this.selectedRowIndex = this.sourceList.indexOf(row);
    this.selectedContact = row;
    this.matDrawer.toggle();
  }


  arrowDownEvent(row: any) {
    if (!this.selectedRowIndex) {
      this.selectedRowIndex = this.sourceList.indexOf(row);
    }
    let newRowIx = this.selectedRowIndex + 1;
    if (newRowIx >= this.sourceList.length) {
      if (this.page < this.pagesCount) {
        this.searchContactsListForm.controls['page'].setValue(this.page + 1);
        this.getContacts();
      }
      return;
    }
    var nextrow = this.sourceList[newRowIx];
    this.selectedContact = nextrow;
    this.selectedRowIndex = newRowIx;
    this.matDrawer.open();
  }

  arrowUpEvent(row: any) {
    if (!this.selectedRowIndex) {
      this.selectedRowIndex = this.sourceList.indexOf(row);
    }
    let newRowIx = this.selectedRowIndex - 1;
    if (newRowIx <= 0) {
      if (this.page >= 1) {
        this.searchContactsListForm.controls['page'].setValue(this.page - 1);
        this.getContacts();
      }
      return;
    }
    this.selectedRowIndex = this.sourceList.indexOf(row);
    var nextrow = this.sourceList[newRowIx];
    this.selectedContact = nextrow;
    this.selectedRowIndex = newRowIx;
    this.matDrawer.open();
  }


  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getContacts();
  }
  public search() {
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchContactsListForm.value));
    this.getContacts();
  }
  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchContactsListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchContactsListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}