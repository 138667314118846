import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GroupsData } from './groups.data'; 
import { ObjectsListResult } from '../services/objects-list-results';

@Component({
	
	selector: 'groups-list',
	templateUrl: './groups-list.tmpl.html'
})

export class GroupsList implements OnInit {
	constructor(
		private groupsData: GroupsData,
		private formBuilder: FormBuilder
	) {
	}
	public objectsListResult: ObjectsListResult;
	// public groups: any[];
	searchGroupsListForm: FormGroup;

	public query: string | null = null;
	public freeTextQuery: string;

	public getGroups(): void {
		this.groupsData.getList(this.searchGroupsListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			error => { });
	}

	public changePage(page: number) {
		this.searchGroupsListForm.controls['page'].setValue(page);
		this.getGroups();
	}

	public search() {
		this.getGroups();
	}

	private initForm() {
		this.searchGroupsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			groupID: [],
		});
	}
	ngOnInit(): void {
		this.initForm();
		this.getGroups();
	}
}
