<div class="modal-header" *ngIf="donationCategory">
    <div class="row">
        <div style="padding: 0px;" class="col-sm-12">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6 text-left">
            </div>
        </div>
    </div>
    <div class="modal-body" fxLayout.xs="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <donationCategory-short-details [donationCategory]="donationCategory"></donationCategory-short-details>
    </div>
</div>
