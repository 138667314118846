<mat-card *ngIf="donationCategory" fxLayout="column" class="example-card">
  <mat-card-header fxLayout="row">
    <mat-card-title fxLayout="row">
      <h3>פרטי קטגוריה: [{{ donationCategory.donationCategoryID }}] </h3>
      <span class="example-fill-remaining-space"></span>
      <a [routerLink]="['/donationCategories', {outlets: {popup: [ id, 'edit']}}]">
        <i class="fa fa-pencil"></i>
      </a>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="width: 100%;">
    <dl class="dl-horizontal">
      <dt>קטגוריה</dt>
      <dd><b>{{ donationCategory.title }}</b></dd>
      <hr>
      <dt>תאריך פתיחה</dt>
      <dd>{{ donationCategory.openDate | date:'dd/MM/yyyy' }}</dd>
      <hr>
      <dt>Key number</dt>
      <dd>{{ donationCategory.keyNumber }}</dd>
      <hr>
      <dt>מזהה מסוף אשראי</dt>
      <dd> {{ donationCategory.termID }}</dd>
      <hr>
    </dl>
  </mat-card-content>
</mat-card>
