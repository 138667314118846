<div loading-status [stopLoadingWhen]="tishEvent != null">
  <div *ngIf="tishEvent">
    <div class="modal-header" fxLayoutAlign="start start" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="35" style="white-space: nowrap;">
        <h1 #hebTitle style="width: fit-content;">
          [{{ tishEvent.tishEventID }}]{{ tishEvent.tishEventTitle }} &nbsp;&nbsp; <small> {{ tishEvent.eventJewishDate
            }}</small>
        </h1>
        <h3 style="border-top: 0.5px solid;">
          {{ tishEvent.description }}

          <small *ngIf=" tishEvent.comment"><br>
            {{ tishEvent.comment }}</small>
        </h3>
      </div>
      <div fxFlex fxLayout="row wrap" fxLayoutAlign="end start">
        <a class="btn btn-success" (click)="cloneTishEvent()">העתק אירוע</a>
        <a class="btn btn-success" (click)="addGroupDlg()">הוסף קבוצה</a>
        <a class="btn btn-success" (click)="addTableDlg()">הוסף שולחן</a>
        <a class="btn btn-success" (click)="addInvitedDlg()">הוסף מוזמן</a>
        <a class="btn btn-primary" (click)="addAreaDlg()">הוסף איזור</a>
        <a class="btn btn-success" [routerLink]="['/tishEvents', tishEvent.tishEventID, 'map']">הצג מפה</a>
        <br>
        <a class="btn btn-info" (click)="downloadExls()">יצוא רשימת מוזמנים</a>
        <a class="btn btn-info" (click)="downloadTicketsExls()">יצוא רשימת כרטיסים</a>
        <a class="btn btn-info" (click)="downloadTishEventTicketsForLettersExls()">יצוא למיזוג מכתבים עם
          נתוני
          כרטיסים</a>
        <a class="btn btn-info" (click)="downloadExlsForMerge()">יצוא למיזוג מדבקות</a>
      </div>
    </div>
    <div class="row modal-body" *ngIf="!rerender" style="height: auto;">

      <mat-tab-group style="height: calc(100vh - 125px);" [selectedIndex]="activeTabIndex"
        (selectedTabChange)="itemSelect($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            מוזמנים
          </ng-template>
          <tishInviteds-list *ngIf="activeTabIndex == 0" [eventID]="tishEvent.tishEventID">Loading...</tishInviteds-list>
       </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            שולחנות
          </ng-template>
          <tishTables-list *ngIf="activeTabIndex == 1" [eventID]="tishEvent.tishEventID">Loading...</tishTables-list>

        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            קבוצות
          </ng-template>
          <tishGroups-list *ngIf="activeTabIndex == 2" [eventID]="tishEvent.tishEventID">Loading...</tishGroups-list>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            איזורים
          </ng-template>
          <tishAreas-list *ngIf="activeTabIndex == 3" [eventID]="tishEvent.tishEventID">Loading...</tishAreas-list>

        </mat-tab>

      </mat-tab-group>



      <!-- <mat-tab-group [selectedIndex]="activeTabIndex" (selectedTabChange)="itemSelect($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            קבוצות
          </ng-template>
          <tishGroups-list *ngIf="activeTabIndex == 0" [eventID]="tishEvent.tishEventID">Loading...</tishGroups-list>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            שולחנות
          </ng-template>
          <tishTables-list *ngIf="activeTabIndex == 1" [eventID]="tishEvent.tishEventID">Loading...</tishTables-list>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            מוזמנים
          </ng-template>
          <tishInviteds-list *ngIf="activeTabIndex == 2" [eventID]="tishEvent.tishEventID">Loading...
          </tishInviteds-list>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            איזורים
          </ng-template>
          <tishAreas-list *ngIf="activeTabIndex == 3" [eventID]="tishEvent.tishEventID">Loading...</tishAreas-list>
        </mat-tab>

      </mat-tab-group> -->
    </div>
  </div>
</div>
