import { CountriesData } from './../countries.data';
import { ServicesData } from './../../services/services.data';
import { FormGroup, FormArray } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'defaultTerm-row',
    templateUrl: 'defaultTerm-row.tmpl.html',
})
export class DefaultTermRowComponent implements OnInit {
    terms;


    constructor(
        public servicesData: ServicesData,
        private countriesData: CountriesData
    ) { }

    @Input('defaultTermtFormGroup') defaultTermtFormGroup: FormGroup;
    @Input('countryForm') countryForm: FormGroup;
    @Input('i') i: number;

    deleteItem(ix: number) {
        let cgFrm = <FormGroup>(<FormArray>this.countryForm.controls['defaultTerms']).controls[ix];
        Object.keys(cgFrm.controls)
            .filter(key => key != 'isDeleted' && key != 'id')
            .forEach(key => {
                cgFrm.controls[key].disable();
            });
        cgFrm.controls['isDeleted'].enable();
        cgFrm.controls['isDeleted'].setValue(true);
    }

    unDeleteItem(ix: number) {
        let cgFrm = <FormGroup>(<FormArray>this.countryForm.controls['defaultTerms']).controls[ix];

        Object.keys(cgFrm.controls)
            .filter(key => key != 'isDeleted' && key != 'id')
            .forEach(key => {
                cgFrm.controls[key].enable();
            });
        cgFrm.controls['isDeleted'].setValue(false);
    }

    loadTerms(subject) {
        if (subject) {
            this.servicesData.getList(`SubjectTerms/${subject}`).subscribe(x => {
                this.terms = x;
            });
        }
    }

    ngOnInit(): void {
        let cgCtrl = this.defaultTermtFormGroup.controls['commitmentSubject'];
        if (cgCtrl.value) {
            this.loadTerms(cgCtrl.value);
        }
        cgCtrl.valueChanges.subscribe(val => {
            this.loadTerms(val);
        });
    }
}
