<form style="min-width: 650px;" *ngIf="payMethod" [formGroup]="payMethodForm" novalidate enter-tab>
  <div class="col-sm-12">
    <fieldset class="form-group" fxLayout="column">
      <legend>פרטי אמצעי תשלום</legend>
      <div fxLayout="column">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <mat-button-toggle-group name="fontStyle" multiple="false" formControlName="paymentOption"
            (change)="selectPayOption($event)">
            <mat-button-toggle [value]="1">
              <i *ngIf="payMethodForm.controls.paymentOption.value == 1" class="fa  fa-check-circle"></i>
              <i *ngIf="payMethodForm.controls.paymentOption.value != 1" class="fa  fa-circle-o"></i>
              &nbsp; &nbsp;אשראי
            </mat-button-toggle>
            <mat-button-toggle [value]="4">
              <i *ngIf="payMethodForm.controls.paymentOption.value == 4" class="fa  fa-check-circle"></i>
              <i *ngIf="payMethodForm.controls.paymentOption.value != 4" class="fa  fa-circle-o"></i>
              &nbsp;&nbsp;הוראת קבע (בנקאית)</mat-button-toggle>
          </mat-button-toggle-group>

          <!-- <label>סוג:</label>
                        <select class="form-control" (change)="selectPayOption($event)" formControlName="paymentOption">
                            <option [value]="4">הוראת קבע</option>
                            <option [value]="1">אשראי</option>
                        </select> -->
        </div>
      </div>
      &nbsp;
      <div *ngIf="payOption == 1" fxLayout="column">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מספר כרטיס:</label>
            <input apply-enter style="direction: ltr;" placeholder="מספר כרטיס..." class="form-control"
              formControlName="creditNumber" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>תוקף (MM/YY):</label>
            <input class="form-control" apply-enter mask="00/00" formControlName="expStr" type="text" />
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <label>ספרות בגב הכרטיס:</label>
            <input class="form-control" apply-enter  formControlName="cvv" type="text" />
          </div>
        </div>
      </div>
      <div *ngIf="payOption == 4" fxLayout="column">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>שם בעל החשבון:</label>
            <input type="text" class="form-control" formControlName="bankAcountOwner" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <auto-complete apply-enter [controllerName]="'Banks'" [placeholder]="'בנק'" (onSelect)="itemSelect($event)"
              [formGroupModel]="payMethodForm" [idControl]="payMethodForm.controls.bankNumber"
              [nameControl]="payMethodForm.controls.bankName"></auto-complete>
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <auto-complete apply-enter #bankBranchesCmp [controllerName]="'BankBranches'" [placeholder]="'סניף'"
              [formGroupModel]="payMethodForm" [idControl]="payMethodForm.controls.bankBranchNumber"
              [nameControl]="payMethodForm.controls.bankBranchName"></auto-complete>
          </div>

        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מס' חשבון:</label>
            <input type="text" class="form-control" formControlName="bankAcountNumber" />
          </div>
        </div>
      </div>
      <div fxLayout="column">

        <hr fxLayout="row">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מסוף:</label>
            <select apply-enter class="form-control" placeholder="מסוף" formControlName="termID">
              <option value="">...</option>
              <option *ngFor="let api of servicesData.Apis" [value]="api.value">
                {{api.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <mat-checkbox [checked]="isOneTime" (change)="selectionChanged($event)" apply-enter fxLayout="row">
            שמור במערכת לשימושים חוזרים
          </mat-checkbox>
        </div>
      </div>
    </fieldset>

  </div>
  <div class="col-sm-12">
    <div>
      <button mat-button *ngIf="!moreDetails" (click)="moreDetails=true">
        <span style="color: blue; text-decoration: underline ;"> פרטים נוספים (נדרש בלונדון) </span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <button mat-button *ngIf="moreDetails" (click)="moreDetails=false">
        <span style="color: blue; text-decoration: underline ;">הסתר </span>
        <mat-icon>expand_less</mat-icon>
      </button>
    </div>
    <fieldset class="form-group" *ngIf="moreDetails">
      <legend>פרטים נוספים (נדרש בלונדון)</legend>
      <div fxLayout="column">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">

          <div class="input-group mb-3">
            <label>שם פרטי:</label>
            <input type="text" class="form-control" formControlName="firstName" />
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <label>שם משפחה:</label>
            <input type="text" class="form-control" formControlName="lastName" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מיקוד (Zip):</label>
            <input type="text" class="form-control" formControlName="zip" />
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <label>חברה:</label>
            <input type="text" class="form-control" formControlName="company" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>כתובת (Address):</label>
            <input type="text" class="form-control" formControlName="address" />
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <label>עיר (City):</label>
            <input type="text" class="form-control" formControlName="city" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מדינה (State):</label>
            <input type="text" class="form-control" formControlName="state" />
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <label>ארץ (Country):</label>
            <input type="text" class="form-control" formControlName="country" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>אימייל:</label>
            <input type="text" class="form-control" formControlName="email" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="input-group mb-3">
            <label>מס' פקס:</label>
            <input type="text" class="form-control" formControlName="faxNumber" />
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <label>מס' טלפון:</label>
            <input type="text" class="form-control" formControlName="phoneNumber" />
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</form>
