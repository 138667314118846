import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';
import { IvrDonationUserTask } from './ivr-donation-user-task/ivr-donation-user-task';


@Injectable()

export class IvrDonationUserTasksData {

    private url = 'api/ivrDonationUserTasks';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }



    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getAudioByts(ivrDonationUserTaskID: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'audio/wav');
        return this.toraxHttp.postAndDownload(`${this.url}/getAudioByts/${ivrDonationUserTaskID}`,{});
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(ivrDonationUserTask: IvrDonationUserTask) {
        return this.toraxHttp.update(this.url, ivrDonationUserTask);
    }

}