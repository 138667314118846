<mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
        <h1 mat-dialog-title>הדפסת קבלות</h1>
    </mat-toolbar-row>
</mat-toolbar>
<div mat-dialog-content *ngIf="formData" [formGroup]="formData">
    <!-- withoutReceipts -->

    <div fxLayout="column" class="form-vertical">

        <mat-button-toggle-group fxLayoutAlign="center center" fxLayout="row" fxLayout.xs="column" name="fontStyle" formControlName="withoutReceipts" (change)="toggleChange($event)">
            <mat-button-toggle color="accent" fxFlex="50" fxFlexFill value="true">
                <h3>הצג תשלומים שלא הונפקו להם קבלות</h3>
            </mat-button-toggle>
            <mat-button-toggle color="primary" fxFlex="50" fxFlexFill value="false">
                <h3>הצג קבלות שלא הודפסו</h3>
            </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-selection-list #paymentsList *ngIf="payments">
            <mat-list-option *ngFor="let cs of payments" (click)="onAreaListControlChanged(list)" [value]="cs.id">
                {{cs.title}}
            </mat-list-option>
        </mat-selection-list>
        <mat-slide-toggle *ngIf="formData.controls.withoutReceipts.value" fxLayout="row" class="example-margin" [color]="blue" [checked]="mergePayments" (change)="selectionChanged($event)" [disabled]="disabled">צירוף תשלומים אפשריים לקבלה אחת
        </mat-slide-toggle>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="onNoClick()">ביטול</button>
        <button mat-button [mat-dialog-close]="formData" >צור</button>
    </div>
</div>