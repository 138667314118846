import { MaterialModule } from './../material.module';

import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { MatCheckboxModule } from '@angular/material/checkbox'; 

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CampaignsList } from './campaigns-list.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { CampaignsData } from './campaigns.data';
//import { TextMaskModule } from 'angular2-text-mask'; 
import { CampaignDetails } from './campaign/campaign-details.cmp';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { CampaignsRoutes } from './campaigns.routing';
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';
import { CampaignAttachedsMdl } from '../campaign-attached/campaign-attacheds.mdl';
import { CampaignMain } from './campaign/campaign-main.cmp';
import { DateInputModule } from '../components/dateInput/date-input.mdl';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(CampaignsRoutes),
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl, 
        ModelInfoModule, 
		MainFormModalModule,        
		CampaignAttachedsMdl,
        MaterialModule,
        DateInputModule
    ],
    declarations: [
        CampaignsList,
        CampaignMain,
        CampaignDetails, 
    ],
    exports: [
        CampaignsList
    ],
    providers: [
        CampaignsData
    ],
    entryComponents: [ 
    ]
})
export class CampaignsMdl { }



