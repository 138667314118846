import { ServicesData } from '../../services/services.data';
import {  FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'paymentsPlan-holdings-setting',
    templateUrl: './paymentsPlan-holdings-setting.tmpl.html'
})

export class PaymentsPlanHoldingsSetting {   
    constructor( 
        public servicesData: ServicesData, 
    ) { }

    @Input('formData') public formData : FormGroup;     
}

