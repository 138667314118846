import { ServicesData } from 'src/app/services/services.data';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { DateInput } from './../../components/dateInput/date-input.cmp';
import { LetterSettingsData } from './../letter-settings.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormsModule } from '@angular/forms';
import { Location } from '@angular/common'; 
import { LetterSetting } from './letter-setting'; 


@Component({
    
    selector: 'letter-setting-edit',
    templateUrl: './letter-setting-edit.tmpl.html'
})

export class LetterSettingEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public letterSettingsData: LetterSettingsData,
        private toraxHttp: ToraxHttp,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {
        this.toraxHttp.updatedEvent.subscribe((id) => {
            if (id > 0 && this.letterSetting && id === this.letterSetting.letterSettingID) {
                this.loadLetterSetting();
            }
        });
    }



    letterSetting: LetterSetting;
    id: number;
    letterSettingForm: FormGroup;

    public loadLetterSetting(): void {
        if (this.id) {
            this.letterSettingsData.getEdit(this.id)
                .subscribe((c:any) => {
                    this.letterSetting = c;
                    this.letterSettingForm.setValue(c);
                });
        }
    }

    // public save(): void {
    //     console.log('save run');
    //     if (this.letterSettingForm.valid) {
    //         this.letterSettingsData.update(this.letterSettingForm.value)
    //             .subscribe((c: any) => {
    //                 this.location.back();
    //                 // this.router.navigate(['payMethods', c.id], { relativeTo: this.activatedRoute });
    //             },
    //             error=>{});
    //     } else {
    //         console.log('not valid');
    //         console.log(this.letterSettingForm.errors);
    //     }
    // }

    private initForm() {
        this.letterSettingForm = this.formBuilder.group({
            createBy: [],
            createDate: [],
            changeBy: [],
            changeDate: [],
            letterSettingID: [this.id || 0, Validators.required],
            letterName: ['', Validators.required],
            titleAndShippingAddressTemplate: [, Validators.required],
            mainTemplate: [, Validators.required],
            paymentTemplate: [, Validators.required],
            // footerDateTemplate: [, Validators.required],
            rtl: [true, Validators.required],
            parentEntityID: [],
            marginL: [0, Validators.required],
            marginR: [0, Validators.required],
            marginT: [0, Validators.required],
            marginB: [0, Validators.required],
            fontName: [],
            pageSize: [],
            language: [1, Validators.required],
            saveAsReceiptTemplate:[false, Validators.required],

            templateVertion: [0, Validators.required],
            widthPercentage: [100, Validators.required],
            // signaturePath: ['', Validators.required],
            letterDateFormat: ['MMM.dd, yyyy', Validators.required],
        });
    }


    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = + params['letterSettingID'];
        });
        this.initForm();       
        this.loadLetterSetting();
    }
}



