<div [formGroup]="searchPaymentsListForm">
  <div class="multi-search" *ngIf="!contactID && !commitmentID && !paymentsPlanID && !vouchersOrganizationID"
    [hidden]="!panelOpenState">
    <div class="form-row" fxLayout="row">
      <div class="input-group mb-3">
        <label>מצב...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="מצב..."
          formControlName="isActive">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">פעיל</mat-option>
          <mat-option [value]="false">לא-פעיל</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>תוכניות תשלום...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="תוכניות תשלום..." formControlName="withPaymentsPlan">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">כולל</mat-option>
          <mat-option [value]="false">לא כולל</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsListForm.controls.relevantStatusPayments" [placeholder]="'סטטוס...'"
        [list]="servicesData.StatusPayments">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsListForm.controls.paymentOptions" [placeholder]="'אמצעי תשלום...'"
        [list]="paymentOptionsList">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>מסוף...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="מסוף..."
          formControlName="termID">
          <mat-option [value]="">...</mat-option>
          <mat-option *ngFor="let api of servicesData.Apis" [value]="api.value">
            {{ api.name }}
          </mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsListForm.controls.accountLocations" [placeholder]="'חשבון...'"
        [list]="servicesData.AccountLocations">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>מצב התחייבות...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="מצב התחייבות..." formControlName="isClosedCommitment">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">פתוח</mat-option>
          <mat-option [value]="false">סגור</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>מצב תוכנית...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="מצב תוכנית..." formControlName="isClosedPaymentsPlan">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">פתוח</mat-option>
          <mat-option [value]="false">סגור</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-row" fxLayout="row" [ngSwitch]="fieldForPaymentSearch">

      <div fxLayout="row" style="background-color: #ceced22e; border: dotted; display: inline-flex; border-width: 1px; padding: 0 6px;">
        <div class="input-group mb-3">
          <label>תאריך לסינון...</label>
          <mat-select class="form-control" (selectionChange)="updateDateControl($event)" placeholder="תאריך לסינון...">
            <mat-option [value]="'RelevantPaymentDate'">סוג תאריך...</mat-option>
            <mat-option [value]="'BillingDate'">תאריך תשלום</mat-option>
            <mat-option [value]="'DoneOn'">תאריך ביצוע</mat-option>
          </mat-select>
        </div>
        &nbsp;&nbsp;
        <date-input-range *ngSwitchCase="'RelevantPaymentDate'" [placeHolderStart]="'מתאריך:'"
          [placeHolderEnd]="'עד לתאריך:'" [dateRangeCtrl]="searchPaymentsListForm.controls.relevantPaymentDateRange">
          Loading...
        </date-input-range>
        &nbsp;&nbsp;
        <date-input-range *ngSwitchCase="'BillingDate'" [placeHolderStart]="'מתאריך תשלום:'"
          [placeHolderEnd]="'עד לתאריך:'" [dateRangeCtrl]="searchPaymentsListForm.controls.billingDateRange">
          Loading...
        </date-input-range>
        &nbsp;&nbsp;
        <date-input-range *ngSwitchCase="'DoneOn'" [placeHolderStart]="'מתאריך ביצוע:'" [placeHolderEnd]="'עד לתאריך:'"
          [dateRangeCtrl]="searchPaymentsListForm.controls.doneOnRange">
          Loading...
        </date-input-range>
      </div>

      &nbsp;&nbsp;
      <div  fxLayout="row" style="background-color: #ceced22e; border: dotted; display: inline-flex; border-width: 1px; padding: 0 6px;">
        <auto-complete-input apply-enter [placeholder]="'ייעוד תרומה...'" (onSelect)="loadDesignationDetails($event)"
          [controllerName]="'Designations'" [stringValueControl]="searchPaymentsListForm.controls.designation"
          [formGroupModel]="searchPaymentsListForm">
        </auto-complete-input>
        &nbsp;&nbsp;
        <div class="input-group mb-3">
          <label>תיאור ייעוד</label>
          <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
            placeholder="תיאור ייעוד" formControlName="designationDetails">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let c of designationDetailsList" [value]="c">
              {{c}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      &nbsp;&nbsp;

      <amount-input-range [placeHolderMin]="'מסכום...:'" [placeHolderMax]="'עד סכום...:'"
        [amountRangeCtrl]="searchPaymentsListForm.controls.amountRange">Loading...
      </amount-input-range>
      &nbsp;&nbsp;
      <div style="width:  80px !important; min-width: auto;" class="input-group mb-3">
        <label>מטבע...</label>
        <mat-select style="width:  80px !important; min-width: auto;" class="form-control"
          (selectChange)="indexService.selectionChanged($event)" placeholder="מטבע..." formControlName="currency">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let c of servicesData.Currencies" [value]="c.value">
            {{c.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-row" fxLayout="row">
      <auto-complete apply-enter [placeholder]="'שם איש...'" (onSelect)="indexService.selectionChanged($event)"
        [controllerName]="'Contacts'" [formGroupModel]="searchPaymentsListForm"
        [idControl]="searchPaymentsListForm.controls.contactID"
        [nameControl]="searchPaymentsListForm.controls.contactName"></auto-complete>
      &nbsp;&nbsp;
      <auto-complete apply-enter [placeholder]="'שטיבל...'" (onSelect)="indexService.selectionChanged($event)"
        #shtibelID [controllerName]="'Shtibels'" [formGroupModel]="searchPaymentsListForm"
        [idControl]="searchPaymentsListForm.controls.shtibelID"
        [nameControl]="searchPaymentsListForm.controls.shtibelName"></auto-complete>
      &nbsp;&nbsp;

      <auto-complete apply-enter [placeholder]="'ארגון שוברים...'" (onSelect)="indexService.selectionChanged($event)"
        #vouchersOrganizationID [controllerName]="'VouchersOrganizations'" [formGroupModel]="searchPaymentsListForm"
        [idControl]="searchPaymentsListForm.controls.vouchersOrganizationID"
        [nameControl]="searchPaymentsListForm.controls.organizationName">
      </auto-complete>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>מס שובר:</label>
        <input apply-enter type="text" class="form-control" formControlName="voucherNumber">
      </div>
      &nbsp;&nbsp;
      <mat-slide-toggle style="margin-top: 30px;" class="example-margin"
        (selectChange)="indexService.selectionChanged($event)" formControlName="paymentsForTreatment">הצג רק תשלומים
        לטיפול
      </mat-slide-toggle>

      &nbsp;&nbsp;

      <mat-select-multiple-dropdown [keyProperty]="'groupID'" [titleProperty]="'groupTitle'"
        [colorProperty]="'groupColor'" *ngIf="servicesData.Groups"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsListForm.controls.groupIdsToIncludeContacts" [placeholder]="'קבוצות...'"
        [list]="servicesData.Groups">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'groupID'" [titleProperty]="'groupTitle'"
        [colorProperty]="'groupColor'" *ngIf="servicesData.Groups"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsListForm.controls.groupIdsToExludeContacts" [placeholder]="'קבוצות לא מוכללות...'"
        [list]="servicesData.Groups">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
        <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="getPaymentsReport()">
          <i class="fa fa-file-excel-o"></i></button> &nbsp;
        <button mat-mini-fab color="primary" title="ייצוא לאקסל מלא" (click)="getPaymentsReport(true)">
          <i class="fa fa-file-excel-o"></i></button>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center" class="min-action-header">
    <span class="example-fill-remaining-space"></span>
    <div *ngIf="!contactID && !commitmentID && !paymentsPlanID && !vouchersOrganizationID">
      <button class="btn" (click)="toogleState()">
        {{(panelOpenState ? 'הסתר' : 'חיפוש מורחב')}}
        <i *ngIf="!panelOpenState" class="fa fa-arrow-up"></i>
        <i *ngIf="panelOpenState" class="fa fa-arrow-down"></i>
      </button>
    </div>

    <div>
      <mat-select class="form-control" placeholder="שנה..." formControlName="year">
        <mat-option [value]="">הכל</mat-option>
        <mat-option *ngFor="let y of yearsList" [value]="y">{{y}}</mat-option>
      </mat-select>


    </div>
    <div fxLayout="row">
      <mat-select-multiple-dropdown #subjectsSelect [withLabel]="false" [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsListForm.controls.commitmentSubjects" [placeholder]="'נושא...'"
        [list]="servicesData.CommitmentSubjects">
      </mat-select-multiple-dropdown>

      &nbsp;&nbsp;
      <div *ngIf="subjectCommitmentReasons" class="input-group mb-3">
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="סיבה..."
          formControlName="commitmentReasonID">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let c of subjectCommitmentReasons" [value]="c.commitmentReasonID">
            {{c.name}}
          </mat-option>
        </mat-select>


      </div>
    </div>
    <div *ngIf="contactID > 0 || commitmentID > 0 ">הצג קשורים:
      <mat-slide-toggle formControlName="includeRelatedPayments">
      </mat-slide-toggle>
    </div>
    <div>
      עמודות:<a matTooltip="הוסף עמודות" (click)="toogleFullView()">
        <mat-icon style="vertical-align: bottom;line-height: 28px;">view_column</mat-icon>
      </a>
    </div>

    <div style="text-align:left; white-space: nowrap;" class="example-button-row">
      <button mat-mini-fab class="mini" color="primary" style="margin-left:3px" title="סנן"
        (click)="indexService.getList()">
        <i class="fa fa-filter"></i></button>
      <button mat-mini-fab class="mini" color="primary" style="margin-left:3px" title="נקה סינונים"
        (click)="indexService.clerFilter()">
        <i class="material-icons">clear</i></button>
    </div>
  </div>

  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK
        [full-height]="!contactID && !commitmentID && !paymentsPlanID && !vouchersOrganizationID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="indexService.selection?.selected?.length > 0">{{ indexService.selection?.selected?.length
                }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                [checked]="indexService.selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item (click)="openDetails(row);">
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>תצוגה מהירה</span>
                </a>
                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['payments', row.paymentID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['payments', row.paymentID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>
                <a mat-menu-item [disabled]="!row.receiptNumber" (click)="createAndPrintReceipt(row)">
                  <mat-icon>print</mat-icon>
                  <span>הדפס קבלה</span>
                </a>

                <a mat-menu-item (click)="createAndPrintPaymentLetter(row)">
                  <mat-icon>print</mat-icon>
                  <span>הדפס מכתב לתשלום</span>
                </a>

                <a mat-menu-item [disabled]="!row.receiptNumber" (click)="createAndDownloadReceipt(row)">
                  <mat-icon>file_download</mat-icon>
                  <span>הורד קבלה</span>
                </a>
                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="paymentsData" [id]="row.paymentID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>

              &nbsp;&nbsp;
              <i class="fa fa-refresh" (click)="refreshPaymentCurrenciesRate(row.paymentID);"
                matTooltip="ריענון שערים..." style="color: gray;"></i>

            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="paymentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>
              <a [routerLink]="['', {outlets: {popup: ['payments', row.paymentID]}}]">
                <i matTooltip="תשלום לא פעיל" class="fa fa-window-close" style="float: left;color: red"
                  *ngIf="row.isActive != true && row.statusPayment != 2"></i>
                {{ row.paymentID }}
              </a>
              <br>
              <a *ngIf="row.paymentCommitments?.length > 1 && commitmentID > 1"
                (click)="toggleExpandableSymbol(row.paymentID || null)">
                <mat-icon *ngIf="expandedRowID != row.paymentID">more</mat-icon>
                <mat-icon *ngIf="expandedRowID == row.paymentID">expand_less</mat-icon>
              </a>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="billingDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך-ערך / תאריך-לגביה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך-ערך / תאריך-לגביה">
              {{ row.billingDate | date:'dd/MM/yyyy' }}<br>{{ row.jewishBillingDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="payMethodID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> באמצעות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="באמצעות">
              <div *ngIf="row.payMethodID">
                <a *ngIf="isLink(row.payMethodID)"
                  [routerLink]="['', {outlets: {popup: ['payMethods', row.payMethodID]}}]">
                  {{ row.payMethodTitle }}</a>
                <span style="white-space: normal;" *ngIf="!isLink(row.payMethodID)">
                  {{ row.payMethodTitle }}</span>
                <mat-icon *ngIf="row.paymentGroupID" matTooltip="קבוצת תשלומים"
                  [ngStyle]="{'color': servicesData.getRandomColor(row.paymentGroupID)}">
                  linear_scale</mat-icon>
              </div>
            </mat-cell>
          </ng-container>



          <ng-container matColumnDef="doneOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> תאריך ביצוע
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך ביצוע" fxHide fxShow.gt-xs>
              {{ row.doneOn | date:'dd/MM/yyyy' }}<br>{{ row.jewishDoneOn}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סכום">
              <span *ngIf="row.paymentCommitments?.length == 1; else pcsAmount">
                {{ row.amount | number}} {{ row.currencyIcon }}</span>
              <ng-template #pcsAmount>
                <span style="text-decoration: underline; font-weight: 700;">{{ row.amount |
                  number}}
                  {{ row.currencyIcon }}</span><br>

                <span style="font-size: 90%;" *ngFor="let pc of row.paymentCommitments">
                  <span
                    *ngIf="commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID || expandedRowID == row.paymentID"
                    [ngStyle]="{'background-color': (commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID ? 'yellow' : '')}">
                    ({{ pc.partialAmount | number }}{{ row.currencyIcon }})<br>
                  </span>
                </span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="calculatedAmountInILS">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ערכי מטבעות</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="ערך">
              <span style="text-decoration: underline; font-weight: 700;">{{ row.calculatedAmountInILS
                | number }} ש"ח</span>
              <span style="font-size: 90%;"> (שער:{{ row.paymentIlsRate | number }})</span><br>
              <span style="font-size: 90%;" *ngFor="let pc of row.paymentCommitments">
                <span
                  *ngIf="commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID || expandedRowID == row.paymentID">
                  ע' בהת': {{ pc.partialValueInCommitment | number }} {{pc.commitmentCurrency |
                  currency }}<br>
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="statusPayment">
            <mat-header-cell *matHeaderCellDef>סטטוס</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סטטוס" fxHide fxShow.gt-xs>
              <i class="fa fa-circle text-navtext-navy" style="font-size: 8px;"
                [ngStyle]="{'color': (row.statusPayment == 3 ? 'red' : ((row.statusPayment == 2) || (row.payMethodID == 89 && row.statusPayment == 1) ? 'green' : 'darkorange'))}"></i>
              {{ row.statusPaymentName }}
              <a customToolTip [contentTemplate]="template"
                *ngIf="row.doneOn && row.statusPayment != 2 && row.payMethodID && [88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(row.payMethodID) == -1">
                <mat-icon style="font-size: 10px; display: inline; color: gray;">
                  signal_cellular_4_bar
                </mat-icon>
              </a>
              <ng-template #template>
                <mat-card style="white-space: nowrap;">
                  <mat-card-content>
                    <!-- <transactions-last-statusses [payMethodID]="row.payMethodID">Loading...
                    </transactions-last-statusses> -->
                    <transactions-last-statusses [paymentID]="row.paymentID">Loading...
                    </transactions-last-statusses>
                  </mat-card-content>
                </mat-card>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef> שם איש </mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
              <a [routerLink]="['/contacts', row.contactID]">{{
                row.contactName }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="shtibelName">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> שטיבל </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שטיבל" fxHide fxShow.gt-xs>
              {{row.shtibelName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="responsible">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> אחראי
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="אחראי" fxHide fxShow.gt-xs>
              {{row.responsibleFirstName}}
              {{row.responsibleLastName}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="transactionID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> פעולת אשראי
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="פעולת אשראי" fxHide fxShow.gt-xs>
              {{ row.transactionID }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="receiptNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> קבלה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="קבלה" fxHide fxShow.gt-xs>
              {{ row.receiptNumber }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="designation">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> ייעוד תרומה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="ייעוד תרומה" fxHide fxShow.gt-xs>
              {{ row.designation }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="commitmentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> התחייבות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="התחייבות" fxHide fxShow.gt-xs>
              <div *ngIf="row.paymentCommitments && row.paymentCommitments?.length == 1; else pcsPayments">
                <a contacts
                  [ngStyle]="{'color': (row.paymentCommitments[0].isClosedCommitment == true ? '#f4433673;' : 'none' )}"
                  [routerLink]="['', {outlets: {popup: ['commitments', row.paymentCommitments[0].commitmentID]}}]">
                  {{ row.paymentCommitments[0].commitmentID }}
                  <!-- <i *ngIf="row.paymentCommitments[0].isClosedCommitment == true" [matTooltip]="'התחייבות סגורה'"
                    matSuffix style="color: #f4433673;" class="fa fa-times"></i> -->
                </a>
                <a style="display: inline; color: blue;" customToolTip [contentTemplate]="fundraisingTemplate"
                  *ngIf="row.paymentCommitments[0].commitmentFundraisingID > 0"
                  [routerLink]="['', {outlets: {popup: ['commitments', row.paymentCommitments[0].commitmentFundraisingID]}}]">
                  {{ row.paymentCommitments[0].commitmentFundraisingID }}
                  <i style="font-size: 12px;" class="fa fa fa-link"></i>
                </a>
              </div>
              <ng-template #pcsPayments>
                <br>
                <span *ngFor="let pc of row.paymentCommitments">
                  <span
                    *ngIf="commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID || expandedRowID == row.paymentID">
                    <a *ngIf="pc.commitmentID"
                      [ngStyle]="{'color': (pc.isClosedCommitment == true ? '#f4433673;' : 'none')}"
                      [routerLink]="['', {outlets: {popup: ['commitments', pc.commitmentID]}}]">{{
                      pc.commitmentID }}
                      <!-- <i *ngIf="pc.isClosedCommitment == true" [matTooltip]="'התחייבות סגורה'" matSuffix
                        style="color: #f4433673;" class="fa fa-times"></i> -->
                    </a>
                    <a style="display: inline; color: blue;" customToolTip [contentTemplate]="fundraisingTemplate"
                      *ngIf="pc.commitmentFundraisingID > 0"
                      [routerLink]="['', {outlets: {popup: ['commitments', pc.commitmentFundraisingID]}}]">
                      {{ pc.commitmentFundraisingID }}
                      <i style="font-size: 12px;" class="fa fa fa-link"></i>
                    </a>
                    <br>
                  </span>
                </span>
              </ng-template>
              <ng-template #fundraisingTemplate>
                <mat-card style="white-space: nowrap;">
                  <mat-card-content>
                    <commitment-fundraising-info [commitmentID]="row.commitmentFundraisingID">
                      Loading...
                    </commitment-fundraising-info>
                  </mat-card-content>
                </mat-card>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="commitmentSubject">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> תיאור התחיבות (הסכם)
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תיאור התחיבות (הסכם)" fxHide fxShow.gt-xs>
              <div *ngIf="row.paymentCommitments && row.paymentCommitments?.length == 1; else pcsDescription">
                <b>{{ row.paymentCommitments[0].subjectName }}</b>
                <span *ngIf="row.paymentCommitments[0].registrationEventTitle; else commtmtTtle">
                  <br>
                  {{ row.paymentCommitments[0].registrationEventTitle }}
                </span>
                <br *ngIf="row.paymentCommitments[0].commitmentReasonName">
                <span>{{ row.paymentCommitments[0].commitmentReasonName }}</span>
                &nbsp;
                <i style="color: black" *ngIf="row.isFundraising" class="fa fa fa-link"></i>
                &nbsp;
              </div>
              <ng-template #commtmtTtle>
                - {{ (row.paymentCommitments ? row.paymentCommitments[0].donationTypeName : '')}}
                {{ (row.paymentCommitments ? row.paymentCommitments[0].hebYear : '')}}
              </ng-template>
              <ng-template #pcsDescription>
                <b>{{ row.paymentCommitments[0].subjectName }}</b>
                <br>
                <span *ngFor="let pc of row.paymentCommitments">
                  <span
                    *ngIf="(commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID || expandedRowID == row.paymentID) ; else pcCommtmtTtle">
                    <span *ngIf="pc.registrationEventTitle">{{ pc.registrationEventTitle
                      }}</span>
                    <span *ngIf="pc.commitmentReasonName"> {{ pc.commitmentReasonName}} </span>
                    &nbsp;
                    <i style="color: black" *ngIf="row.isFundraising" class="fa fa fa-link"></i>
                    &nbsp;
                    <br>
                  </span>
                  <ng-template #pcCommtmtTtle>
                    <b>{{ pc.subjectName }}</b> - {{ pc.donationTypeName}} {{ pc.hebYear }}
                  </ng-template>
                </span>
              </ng-template>

            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="paymentsPlanID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> תוכנית תש'
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תוכנית תש'" fxHide fxShow.gt-xs>
              <a [ngStyle]="{'color': (row.isClosedPaymentsPlan == true ? '#f4433673;' : 'none')}"
                [routerLink]="['', {outlets: {popup: ['paymentsPlans', row.paymentsPlanID]}}]">{{row.paymentsPlanID}}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="vouchersOrgPaymentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> ע"י תש'
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="ע''י תש'" fxHide fxShow.gt-xs>
              <a style="color: blue" *ngIf="row.vouchersOrgPaymentID > 0"
                [routerLink]="['', {outlets: {popup: ['payments', row.vouchersOrgPaymentID]}}]">
                <i class="fa fa fa-link"></i> &nbsp;&nbsp; תשלום
                {{ row.vouchersOrgPaymentID }}
              </a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;let index = dataIndex;"
            [ngClass]="{alternate: index % 2 != 0 }" (dblclick)="openSelectedRow(row.paymentID)">
          </mat-row>

          <ng-container matColumnDef="groupHeader">
            <!-- <td colspan="999"  [ngStyle]="{'height': (!group.value) ? '0px' : 'height: 20px !IMPORTANT;'}" mat-cell *matCellDef="let group">
            <strong *ngIf="!group.value">{{(!group.value ? 'ממתין' : '')}}</strong>
          </td> -->
            <td colspan="999" style="height: 20px !IMPORTANT" mat-cell *matCellDef="let group">
              <strong>{{(group.value ? 'ממתין &#8681;' : 'תאריכים קודמים &#8681;' )}}</strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="groupReducer">
            <td colspan="1" [ngStyle]="{'height': (group.value) ? '0px' : 'height: 20px !IMPORTANT;'}" mat-cell
              *matCellDef="let group">

            </td>
          </ng-container>

          <tr mat-row class="clickable" style="background-color: #ceced2 !important;"
            [ngStyle]="{'height': ( indexService.isGroup) ? '0px' : 'height: 20px !IMPORTANT;'}"
            *matRowDef="let row; columns: ['groupReducer','groupHeader']; when: indexService.isGroup">
          </tr>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
