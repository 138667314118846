<div [formGroup]="searchIvrDonationUserTasksListForm">

  <div class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">

      <div class="input-group mb-3">
        <label class="control-label">סטטוס:</label>
        <mat-select class="form-control" formControlName="hasDone"  (selectChange)="indexService.selectionChanged($event)">
          <mat-option [value]="null">הכל</mat-option>
          <mat-option [value]="true">בוצע</mat-option>
          <mat-option [value]="false">לא-בוצע</mat-option>
        </mat-select>
      </div>
      <span fxFlex class="example-fill-remaining-space"></span>
      <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()">
          <i class="fa fa-filter"></i>
        </button>
        &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()">
          <i class="material-icons">clear</i>
        </button>
        &nbsp;

      </div>

    </div>
  </div>


  <div class="example-container" *ngIf="indexService">
    <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test
      (cdkObserveContent)="indexService?.changed($event)" itemSize="10" style="height:calc(100vh - 250px)">
      <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
        [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
        matSort matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef mat-sort-header>...</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a [routerLink]="['', {outlets: {popup: ['ivrDonationUserTasks', row.ivrDonationUserTaskID, 'edit']}}]">
              <i style="color: gray;" matTooltip="עריכה" class="fa fa-pencil"></i> &nbsp;
              {{ row.ivrDonationUserTaskID }}

            </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך">
            {{ row.createDate | date: 'dd/MM/yyyy hh:mm' }} <br>{{ row.jewishCreateDate }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contactName">
          <mat-header-cell *matHeaderCellDef> שם מתקשר </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="שם מתקשר">
            {{ (row.contactName ? row.contactName : row.identifiedContactName) }}<br>
            {{row.phoneNumber | phone}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef> הערה </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="הערה">
            {{row.comment}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef> סכום </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סכום">
            {{ row.amount |number }} {{row.currencyIcon}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="doneByUserName">
          <mat-header-cell *matHeaderCellDef> טופל ע"י </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="טופל ע''י">
            {{ row.doneByUserName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="doneDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך טיפול </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך טיפול">
            {{ row.doneDate | date: 'dd/MM/yyyy hh:mm' }} <br>{{ row.jewishDoneDate }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;"
          (dblclick)="openSelectedRow(row.ivrDonationUserTaskID)">
        </mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>

  <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
    [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
  </mat-paginator>
</div>
