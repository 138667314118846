import { CommitmentMain } from './commitment/commitment-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { CommitmentClosedDlg } from './commitment/commitment-closed-dlg.cmp';

import { LoaderBarService } from '../components/loader-bar/loader-bar.service';
import { HttpClientModule } from '@angular/common/http';
import { CommitmentDetailsView } from './commitment/commitment-details-view.cmp';
import { CurrencyPipeMdl } from './../components/pipes/currency-pipe';
import { PaymentsPlansMdl } from './../paymentsPlans/paymentsPlans.mdl';
import { CommitmentsSelectPickMdl } from './commitments-select-pick/commitments-select-pick.mdl';
import { CommitmentEditWin } from './commitment/commitment-edit-win.cmp';
import { CommitmentEditDlg } from './commitment/commitment-edit-dlg.cmp';
import { AuthGuardMdl } from './../_guards/auth.guard.mdl';
import { CommitmentDetails } from './commitment/commitment-details.cmp';
import { MaterialModule } from './../material.module';
import { CommitmentsDetailsToPrint } from './commitments-details-toPrint.cmp';
import { AmountInputRangeModule } from './../components/amountInput-range/amount-input-range.mdl';
import { CommitmentsList } from './commitments-list.cmp';
import { CommitmentDetailsDlg } from './commitment/commitment-details-dlg.cmp';
import { CommitmentsListDashboardDlg } from './commitments-list-dashboard-dlg.cmp';
import { CommitmentsRoutes } from './commitments.routing';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { DateMonthInputModule } from './../components/dateMonth-input/dateMonth-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { CloseCommitmentDlg } from './commitments-closeCommitment-dlg.cmp';
import { CommitmentsData } from './commitments.data';
import { CommitmentRowDetailsToPrint } from './commitment/commitment-row-details-toPrint.cmp';
import { CommitmentEdit } from './commitment/commitment-edit.cmp';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentsMdl } from '../payments/payments.mdl';
import { HoldingsMdl } from '../holdings/holdings.mdl';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        AutoCompleteModule,
        DateInputModule,
        DateMonthInputModule,
        FooterEditButtonsMdl,
        DateInputRangeModule,
        RouterModule.forChild(CommitmentsRoutes),
        CurrencyPipeMdl,
        AmountInputRangeModule,
        AuthGuardMdl,
        MaterialModule,
        NgbModule,
        CommitmentsSelectPickMdl,
        HttpClientModule,
        ModelInfoModule,
        PaymentsPlansMdl,
        PaymentsMdl,
        MainFormModalModule,
        HoldingsMdl,
        CustomToolTipModule
    ],
    declarations: [
        CommitmentMain,
        CommitmentsList,
        CommitmentEdit,
        CommitmentDetails,
        CloseCommitmentDlg,
        CommitmentsListDashboardDlg,
        CommitmentDetailsDlg,
        CommitmentsDetailsToPrint,
        CommitmentRowDetailsToPrint,
        CommitmentEdit,
        CommitmentEditDlg,
        CommitmentEditWin,
        CommitmentDetailsView,
        CommitmentClosedDlg,


    ],
    exports: [
        CommitmentMain,
          CommitmentsList,
        CloseCommitmentDlg,
        CommitmentDetailsDlg,
        CommitmentEdit,
        CommitmentEditDlg,
        CommitmentEditWin,
        CommitmentsSelectPickMdl,
        CommitmentsListDashboardDlg,
        CommitmentDetailsView
    ],
    providers: [
        CommitmentsData,
        LoaderBarService
    ],


    entryComponents: [
        CloseCommitmentDlg,
        CommitmentEditDlg,
        CommitmentDetailsDlg,
        CommitmentEditWin,
        CommitmentDetailsView,
        CommitmentClosedDlg
    ]
})
export class CommitmentsMdl { }


