<div *ngIf="paymentsPlan">
  <div class="modal-header">
    <div class="row">
      <div style="padding: 0px;" class="col-sm-12">
        <div fxLayout="row" style="direction: ltr; border-bottom: solid 1px;">
          <a class="btn btn-primary"
            [ngStyle]="{'border-color':(!paymentsPlan.closedDate ? 'red' : ''), 'color':(!paymentsPlan.closedDate ? 'red' : ''), 'background-color':(!paymentsPlan.closedDate ? 'white': '')}"
            (click)="openClosedPaymentsPlanDlg()">
            <i style="color: red;" class="fa fa-ban"></i>&nbsp;{{ paymentsPlan.closedDate ? 'פרטי ' : 'סיום ו'
            }}סגירת תוכנית</a>
          <a *ngIf="paymentsPlan.isHoldingPaymentsPlan" class="btn btn-success" (click)="openHoldingsSettingDlg()">חישוב מחדש הח' ביהמ"ד</a>
          <a *ngIf="id" class="btn btn-success" (click)="addCopy()"><i class="fa fa-copy"></i></a>
          <a *ngIf="paymentsPlan.paymentsPlanClosedDate == null" class="btn btn-primary"
            [routerLink]="['', {outlets: {popup: ['paymentsPlans', id, 'payments', 'add']}}]">
            <i class="fa fa-plus"></i>&nbsp;הוסף תשלום</a>
        </div>
        <div fxLayout="row">
          <h1>{{ paymentsPlan.contactName }}
            <br>
            <small>
              <!-- {{ paymentsPlan.paymentsPlan.subjectName }}  -->
            </small>
          </h1>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-body" style="resize: both; overflow: auto;" fxLayout="row" fxLayout.sm="row wrap"
  fxLayoutAlign="start start" fxLayoutGap="15px">
  <div  class="example-card">
    <mat-card fxLayout="column"  class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>פרטי תוכנית תשלום: [{{ id }}]</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <dl class="dl-horizontal" style="white-space: nowrap;">
            <dt>סכום-חודשי</dt>
            <dd>
              {{ paymentsPlan.pulseAmount | number}}{{paymentsPlan.currencyIcon}}
              <span style="float: left; padding-left: 50px;">&nbsp;&nbsp;<b>תוספת אדר אלול:</b>
                {{ paymentsPlan.specialtyMonthsAmount |
                number}}{{paymentsPlan.currencyIcon}}</span>

            </dd>
            <hr>
            <!-- <dt *ngIf="paymentsPlan.commitmentsIDs && paymentsPlan.commitmentsIDs.length > 1">התחייבויות</dt>
                        <dt *ngIf="paymentsPlan.commitmentsIDs && paymentsPlan.commitmentsIDs.length == 1">התחייבות</dt>
                        <dd>
                            <a *ngFor="let commitmentID of paymentsPlan.commitmentsIDs"
                                style="text-decoration: underline; margin-left: 10px;"
                                [routerLink]="[ '', {outlets: {popup: [ 'commitments', commitmentID]}}]">{{ commitmentID }}</a>
                        </dd>
                        <hr>  -->
            <dt *ngIf="paymentsPlan.payPlanCommitments.length > 1">עבור
              התחייבויות<br>(הסכמים)
            </dt>
            <dt *ngIf="paymentsPlan.payPlanCommitments.length == 1">עבור התחייבות
              (הסכם)
            </dt>
            <dd>
              <div class="example-full-width" style=" margin-right: -24px;">
                <div class="example-list example-full-width" style="cursor:pointer; border: none;">
                  <div *ngFor="let pc of paymentsPlan.payPlanCommitments">
                    <commitment-title [pc]="pc"></commitment-title>
                  </div>
                </div>
              </div>

            </dd>
            <hr>



            <dt>תאריך תשלום ראשון</dt>
            <dd>{{ paymentsPlan.fromDate | date:'dd/MM/yyyy'}} {{ paymentsPlan.jewishFromDate }}</dd>
            <hr>
            <dt>סוג תוכנית תשלום</dt>
            <dd>{{ paymentsPlan.paymentsPlanTypeName }}<span *ngIf=" paymentsPlan.paymentsPlanType == 1">,&nbsp;&nbsp;<b>יום בחודש:</b> {{( paymentsPlan.hebrewDate && servicesData.JewishDayOptions ? servicesData.JewishDayOptions[paymentsPlan.dayOfMonth]?.label: paymentsPlan.dayOfMonth)}}</span> </dd>
            <hr>
            <dt>אופן תשלום</dt>
            <dd>
              <a *ngIf="isLink(paymentsPlan.payMethodID);else dontLink"
                [routerLink]="[ '', {outlets: {popup: [ 'payMethods', paymentsPlan.payMethodID]}}]">
                {{ paymentsPlan.methodTitle }}</a>
              <ng-template #dontLink><span>{{ paymentsPlan.methodTitle }}</span></ng-template>
            </dd>
            <hr>
            <dt>תאריך סיום תוכנית (מתוכנן)</dt>
            <dd>{{ paymentsPlan.tillDate | date:'dd/MM/yyyy' }} {{ paymentsPlan.jewishTillDate }}</dd>
            <hr>
            <!-- <dt>חשבונות</dt>
                        <dd>{{ paymentsPlan.accountLocations }}</dd>
                        <hr> -->
         <dt>מספר תשלומים</dt>
            <dd>{{ paymentsPlan.qty }}</dd>
            <hr>
            <dt>מספר תשלומים (עד כעת)</dt>
            <dd>{{ paymentsPlan.countPayments }}</dd>
            <hr>
            <!-- <dt>שולם עד כעת</dt>
                        <dd>
                            <span *ngIf="paymentsPlan.totalPaidDollars">{{ paymentsPlan.totalPaidDollars }}$</span>
                            <span *ngIf="paymentsPlan.totalPaidShekels">{{ paymentsPlan.totalPaidShekels }}₪</span>
                            <span *ngIf="paymentsPlan.totalPaidEurosy">{{ paymentsPlan.totalPaidEurosy }}€</span>
                            <span *ngIf="paymentsPlan.totalPaidPounds">{{ paymentsPlan.totalPaidPounds }}£</span>
                            <span *ngIf="paymentsPlan.totalPaidFrancs">{{ paymentsPlan.totalPaidFrancs }}+</span>
                        </dd>
                        <hr> -->
            <dt>תאריך תשלום אחרון (עד כעת)</dt>
            <dd>{{ paymentsPlan.lastPaymentDate | date:'dd/MM/yyyy' }}
              {{ paymentsPlan.jewishLastPaymentDate}}</dd>
            <hr>
            <dt>סטטוס תשלום אחרון</dt>
            <dd>{{ paymentsPlan.lastStatusPaymentName }}</dd>
            <hr>
            <dt>תאריך תשלום הקרוב</dt>
            <dd>{{ paymentsPlan.nextPaymentDate | date:'dd/MM/yyyy' }}
              {{ paymentsPlan.jewishNextPaymentDate}}</dd>
            <hr>
            <dt>הערה</dt>
            <dd style="white-space: pre-wrap;">{{ paymentsPlan.comment }}</dd>
            <hr>
          </dl>
        </mat-card-content>
      </mat-card>
      <!-- <mat-card style="overflow: auto;" class="example-card" *ngIf="paymentsPlan.isHoldingPaymentsPlan">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>נתונים לחישוב החזקת ביהמ"ד בתוכנית תשלום זו</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%;">
          <dl class="dl-horizontal" style="white-space: nowrap;">
            <dt>חישוב אחרון החל מ</dt>
            <dd>{{ paymentsPlan.holdingsSetting?.holdingFromMonthName }} {{
              paymentsPlan.holdingsSetting?.holdingFromYearName }}</dd>
            <hr>
            <dt>סכום חודשי</dt>
            <dd>{{ paymentsPlan.holdingsSetting?.holdingAmount | number }}{{
              paymentsPlan.holdingsSetting?.holdingCurrencyIcon }}&nbsp;&nbsp;
              <b>תוספת אדר אלול:</b> {{ paymentsPlan.holdingsSetting?.holdingSpecialtyMonthsAmount
              |
              number }}{{ paymentsPlan.holdingsSetting?.holdingCurrencyIcon }}
            </dd>
            <hr>
            <dt *ngIf="paymentsPlan.holdingsSetting?.holdingContactName">שם להזכרה</dt>
            <dd *ngIf="paymentsPlan.holdingsSetting?.holdingContactName">{{
              paymentsPlan.holdingsSetting?.holdingContactName }}, {{
              paymentsPlan.holdingsSetting?.holdingContactRelationType }},
              <span *ngIf="paymentsPlan.holdingsSetting?.holdingContactIsDied">נפטר</span>
            </dd>
            <hr *ngIf="paymentsPlan.holdingsSetting?.holdingContactID">
            <dt>נתינות החזקת בית המדרש החל מ</dt>
            <dd>
              {{ paymentsPlan.holdingsSetting?.holdingFromMonthName }} {{
              paymentsPlan.holdingsSetting?.holdingFromYearName }}
            </dd>
            <hr>
          </dl>
        </mat-card-content>
      </mat-card> -->
    </div>
    <div   style="width: 100%">
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row" (click)="expendPayments=!expendPayments">
            <span *ngIf="expendPayments == false"> לחץ כדי להציג רשומות תשלומים לתוכנית תשלום</span>
            <span *ngIf="expendPayments == true"> הסתר תשלומים</span>
            <mat-icon *ngIf="expendPayments == false">expand_more</mat-icon>
            <mat-icon *ngIf="expendPayments == true">expand_less</mat-icon>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%; padding: 0 !important;" *ngIf="expendPayments">
          <payments-list #paymentsTab [paymentsPlanID]="paymentsPlan.paymentsPlanID">Loading...
          </payments-list>
        </mat-card-content>
      </mat-card>
      <!-- <mat-card style="overflow: auto;" class="example-card" *ngIf="paymentsPlan.isHoldingPaymentsPlan">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row" (click)="expendHoldings=!expendHoldings">
            <span *ngIf="expendHoldings == false"> לחץ כדי להציג רשומות החזקת ביהמ"ד לתוכנית תשלום</span>
            <span *ngIf="expendHoldings == true"> הסתר רשומות החזקת ביהמ"ד</span>
            <mat-icon *ngIf="expendHoldings == false">expand_more</mat-icon>
            <mat-icon *ngIf="expendHoldings == true">expand_less</mat-icon>
          </mat-card-title>

        </mat-card-header>
        <mat-card-content style="width: 100%; padding: 0 !important;" *ngIf="expendHoldings">
          <holdings-list #holdingsTab [paymentsPlanID]="paymentsPlan.paymentsPlanID">Loading...
          </holdings-list>


        </mat-card-content>
      </mat-card> -->
    </div>
  </div>
</div>
