<div *ngIf="data; else loading">
    <h4 style="border-bottom:1px solid rgba(0, 0, 0, 0.42);padding: 4px; background-color: rgb(216 221 226);">פרטי
        התחייבות לגיוס</h4>
    <br>
    <span style="padding: 4px;"> <b>{{data.firstName}} {{data.lastName}}</b></span><br>
    <span style="padding: 4px;"> <b>{{ data.subjectName }}</b> - {{data.donationTypeName}} {{ data.hebYear }}
    </span><br>
    <span style="padding: 4px;"> מתאריך: {{data.fromDate  | date: 'dd/MM/yyyy'}} {{data.jewishFromDate }}</span><br>
    <span style="padding: 4px;" *ngIf="data.globalAmount">סכום: {{data.globalAmount | number}}{{data.currencyIcon}}</span>
    <span style="padding: 4px;" *ngIf="data.pulseAmount">סכום: {{data.pulseAmount | number}}{{data.currencyIcon}} חו'</span>

</div>
<ng-template #loading>
    <h1>
        <i style="color: gray" class="fa fa-refresh fa-spin"></i>
    </h1>
</ng-template>