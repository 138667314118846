<div *ngIf="tishArea" class="row">
    <div class="col-sm-6">
        <panel-box title="פרטי איזור: [{{ id }}]" [showClose]="false" [showCollapse]="false">
            <span class="panel-tools">
                    <a [routerLink]="['/tishAreas', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
                </span>
            <div class="row">
                <dl class="dl-horizontal">
                    <dt>שם איזור</dt>
                    <dd>{{ tishArea.tishAreaName }}</dd>
                    <dt>אירוע</dt>
                    <dd>{{ tishArea.tishEventTitle }}</dd>
                    <dt>תיאור</dt>
                    <dd>{{ tishArea.description }}</dd>
                    <dt>הערה</dt>
                    <dd>{{ tishArea.comment }}</dd>
                </dl>
            </div>
        </panel-box>
    </div>


</div>